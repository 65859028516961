import { Link } from 'react-router-dom';
import {
  Box, Typography,
} from '@mui/material';
import { ILink } from '../interfaces';
import { Menu } from './MenuLinks';
import myBook from '../../../assets/img/myBook.png';
import CustomLink from '../custom/links/CustomLink';
import { useAppSelector } from '../../../app/hooks';

const SidebarContent = () => {
  const { userInfo } = useAppSelector(( state ) => state.auth );
  return (
    <>
      <Box display="flex" justifyContent="center">
        <Link to="/">
          <Box
            width={90}
            height={90}
            display="flex"
            alignItems="center"
            p={0.5}
          >
            <img
              src={myBook}
              alt="myBook"
              style={{
                width: '100%',
              }}
            />
          </Box>
        </Link>
      </Box>
      <Box display="block" textAlign="center">
        {Menu.map(( item: ILink, i: number ) => (
          userInfo && userInfo.type === item.typeUser && (
            <CustomLink
              to={`${item.link}`}
              key={i.toString()}
            >
              <Box
                mt={2}
                mb={2}
                width={40}
                height={40}
                p={1}
                bgcolor="#fff"
                borderRadius="100%"
                ml="auto"
                mr="auto"
              >
                <img
                  src={item.icon}
                  alt={item.icon}
                  style={{
                    width: '100%',
                  }}
                />
              </Box>
              <Typography fontSize={13}>
                {item.name}
              </Typography>
            </CustomLink>
          )))}
      </Box>
    </>
  );
};

export default SidebarContent;
