import {
  Card, CardContent, CircularProgress, Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import SellersTable from '../components/SellersTable';
import { getSellers } from '../actions/sellersActions';
import { clearSellersFilter, setFilterSellersModal, setSellersModalState } from '../slice/sellersSlice';
import SellersDialogsContainer from '../components/SellersDialogsContainer';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import TopCardActions from '../../shared/custom/cards/TopCardActions';
import SellersChipsContainer from '../components/SellersChipsContainer';
import { ISeller } from '../interfaces';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import { trackEvent } from '../../../helpers/analytics';
import { auth } from '../../firebase';

interface ISellersListProps {
  title: string;
}

const SellersList = ({ title }: ISellersListProps ) => {
  const dispatch = useAppDispatch();
  const {
    sellersList, loadingGet, loadingFilter, hasFilter, sellersFiltered,
  } = useAppSelector(( state ) => state.seller );
  const setTitle = useSetTitle({
    title: 'Lista de Vendedores',
    subItems: ['Vendedores', 'Lista Vendedores'],
    noPrintItems: false,
  });
  const [sellers, setSellers] = useState<ISeller[]>([]);
  useEffect(() => {
    document.title = title;
    dispatch( getSellers());
    dispatch( setNoMoreData( true ));
    dispatch( clearSellersFilter());
  }, [title, dispatch]);
  useEffect(() => {
    setSellers( sellersFiltered.length > 0 || hasFilter ? sellersFiltered : sellersList );
  }, [sellersFiltered, sellersList, hasFilter]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      {loadingGet ? (
        <Grid textAlign="center">
          <CircularProgress size={40} />
        </Grid>
      ) : (
        <Card>
          <CardContent>
            <TopCardActions
              buttonText="Agregar Vendedor"
              loader={loadingFilter}
              addOrEditClick={() => {
                dispatch( setSellersModalState( true ));
                trackEvent( 'push_add_seller_button_admin', {
                  elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de Vendedores',
                });
              }}
              filterClick={() => {
                dispatch( setFilterSellersModal( true ));
                trackEvent( 'push_filter_seller_button_admin', {
                  elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de Vendedores',
                });
              }}
            >
              <SellersChipsContainer filters={hasFilter} />
            </TopCardActions>
            <SellersTable sellers={sellers} />
          </CardContent>
        </Card>
      )}
      <SellersDialogsContainer />
    </>
  );
};

export default SellersList;
