import { useAppSelector } from '../../../app/hooks';

const useCodeInitialState = () => {
  const { code } = useAppSelector(( state ) => state.code );
  const { booksList } = useAppSelector(( state ) => state.book );

  const bookAutoSelected = booksList.find(( item ) => item.id === code?.bookId );

  return {
    book: code && bookAutoSelected ? bookAutoSelected : null,
    tags: code ? code.tags : [],
    quantity: 1,
    description: '',
  };
};

export default useCodeInitialState;
