import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Grid,
  Button,
  InputLabel,
  IconButton,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  OutlinedInput,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { deleteUserAction } from '../../actions/profileActions';
import { logOut } from '../../../auth/actions/authActions';

type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const CardDeletedAccount = () => {
  const [showPassword, setShowPassword] = useState( false );
  const {
    loadingDeleteUser,
  } = useAppSelector(( state ) => state.profile );
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<boolean>( false );
  const [password, setPassword] = useState<string>( '' );
  const [error, setError] = useState<boolean>( false );
  const theme = useTheme();
  const handleInputChange = ( e: InputChange ) => {
    setPassword( e.target.value );
    if ( e.target.value === '' ) {
      setError( true );
    } else {
      setError( false );
    }
  };
  const handleDispatch = async () => {
    if ( password === '' ) {
      setError( true );
    } else {
      setError( false );
      await dispatch( deleteUserAction( password ));
      await dispatch( logOut());
    }
  };

  return (
    <>
      <Card
        sx={{
          borderBottom: '1px solid #EAEAEA',
          boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
        rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
        rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          p={2}
          sx={{
            borderBottom: '1px solid #EAEAEA',
          }}
        >
          <Typography
            variant="body2"
          >
            Eliminar Cuenta
          </Typography>
        </Box>
        <CardContent>
          <Box
            textAlign="center"
          >
            <Typography variant="body2" pb={2}>
              {/* eslint-disable-next-line max-len*/}
              Si eliminas tu cuenta ya no podras seguir ingresando al sistema ni visualizando ningún dato, todas las acciones que hayas realizado no se eliminarán, todo estará como lo dejaste.
            </Typography>
            <Button
              sx={{ width: '13em' }}
              variant="contained"
              onClick={() => setModal( true )}
              endIcon={
                ( loadingDeleteUser )
                && ( <CircularProgress size={20} color="secondary" /> )
              }
              disabled={loadingDeleteUser}
              fullWidth
            >
              Eliminar
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Dialog
        open={modal}
        onClose={() => setModal( false )}
      >
        <Box sx={{ p: 3 }}>
          <DialogTitle
            sx={{
              fontFamily: 'CodePro',
              fontWeight: 700,
              textAlign: 'center',
              fontSize: 26,
            }}
          >
            Eliminar Cuenta
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                textAlign: 'center',
                marginBottom: '2em',
              }}
            >
              ¿Está seguro de que desea continuar?. Esta opción es irreversible
            </DialogContentText>
            <InputLabel
              htmlFor="password"
              sx={{ fontWeight: 700 }}
            >
              Contraseña Actual
            </InputLabel>
            <OutlinedInput
              placeholder="Ej: *****"
              type={showPassword ? 'text' : 'password'}
              id="passwordOld"
              sx={{ pr: 1 }}
              endAdornment={(
                <IconButton
                  onClick={() => setShowPassword( !showPassword )}
                >
                  { showPassword ? (
                    <Visibility
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  ) : (
                    <VisibilityOff
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  )}
                </IconButton>
              )}
              fullWidth
              onChange={handleInputChange}
            />
            { error && (
              <FormHelperText
                sx={{ padding: '0 1em' }}
                error
              >
                Ingresa tu Contraseña
              </FormHelperText>
            ) }
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="center" gap={2}>
              <Button
                onClick={() => setModal( false )}
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                disabled={loadingDeleteUser}
                endIcon={
                  loadingDeleteUser
            && ( <CircularProgress size={20} color="secondary" /> )
                }
                onClick={() => handleDispatch()}
              >
                Aceptar
              </Button>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CardDeletedAccount;
