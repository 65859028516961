import {
  Drawer,
  Hidden,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { drawerWidth } from '../Common/DrawerWidth';
import SidebarContent from './SidebarContent';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setSidebarState } from '../slice/uiSlice';

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const { sidebar } = useAppSelector(( state ) => state.ui );
  const theme = useTheme();
  const matchSmDown = useMediaQuery( theme.breakpoints.down( 'sm' ));
  return (
    <>
      <SwipeableDrawer
        sx={{
          width: 0,
          flexShrink: 0,
          background: '#F2F2F2',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: theme.palette.primary.main,
            overflowX: 'hidden',
          },
        }}
        open={matchSmDown ? sidebar : false}
        onClose={() => dispatch( setSidebarState( false ))}
        onOpen={() => dispatch( setSidebarState( true ))}
      >
        <SidebarContent />
      </SwipeableDrawer>
      <Hidden smDown>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            background: '#F2F2F2',
            '& .MuiDrawer-paper': {
              width: drawerWidth - 10,
              boxSizing: 'border-box',
              backgroundColor: theme.palette.primary.main,
              overflowX: 'hidden',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <SidebarContent />
        </Drawer>
      </Hidden>
    </>
  );
};

export default Sidebar;
