import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect } from 'react';
import { useAppSelector } from '../../../app/hooks';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import CardAcount from '../components/CardAcount';

interface IProfileProps {
  title: string;
}

const Profile = ({ title }: IProfileProps ) => {
  const {
    userInfo,
  } = useAppSelector(( state ) => state.auth );
  const setTitle = useSetTitle({
    title: 'Perfil de usuario',
    subItems: [],
    noPrintItems: false,
  });
  useEffect(() => {
    document.title = title;
  }, [title]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      <Typography variant="h5" mb={2} color={grey} fontWeight="bold">
        Administra tu información
      </Typography>
      <CardAcount userInfo={userInfo} />
    </>
  );
};

export default Profile;
