import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBook, IFilterOptions } from '../interfaces';
import {
  createBook, deleteBook, getBook, getBooks, getBooksPaginated, getBooksWithFilters, updateBook,
} from '../actions/booksActions';

interface IBookState {
  booksList: IBook[];
  book?: IBook;
  loadingGet: boolean;
  loadingCreate: boolean;
  loadingUpdate: boolean;
  loadingDelete: boolean;
  loadingGetPaginated: boolean;
  modal: boolean;
  modalDelete: boolean;
  loadingFilter: boolean;
  filterOptions?: IFilterOptions;
  modalFilter: boolean;
  booksFiltered: IBook[];
  hasFilter: boolean;
}

const initialState: IBookState = {
  booksList: [],
  loadingGet: false,
  loadingCreate: false,
  loadingUpdate: false,
  loadingDelete: false,
  loadingGetPaginated: false,
  modal: false,
  modalDelete: false,
  modalFilter: false,
  loadingFilter: false,
  booksFiltered: [],
  hasFilter: false,
};

const booksSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    setCreateEditBooksModal: ( state, action: PayloadAction<boolean> ) => {
      state.modal = action.payload;
      if ( action.payload === false ) {
        state.book = undefined;
      }
    },
    setDeleteBooksModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalDelete = action.payload;
      if ( action.payload === false ) {
        state.book = undefined;
      }
    },
    addBookInStore: ( state, action: PayloadAction<IBook> ) => {
      state.booksList = [...state.booksList, action.payload];
    },
    addBookWithIdForActions: ( state, action: PayloadAction<string> ) => {
      state.book = state.booksList.find(( book ) => book.id === action.payload );
    },
    addBookForActions: ( state, action: PayloadAction<IBook> ) => {
      state.book = action.payload!;
    },
    updateBookInStore: ( state, action: PayloadAction<IBook> ) => {
      const updateCompanyFunction = () => {
        const data = state.booksList;
        const index = data.findIndex(
          ( item: IBook ) => item.id === action.payload.id,
        );
        data.splice( index, 1, action.payload );
        return data;
      };
      state.booksList = updateCompanyFunction();
    },
    deleteBookInStore: ( state, action: PayloadAction<string> ) => {
      state.booksList = [...state.booksList.filter(( item ) => item.id !== action.payload )];
      state.modalDelete = false;
    },
    clearBooks: ( state ) => {
      state.booksList = [];
      state.book = undefined;
      state.loadingGet = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.loadingDelete = false;
      state.loadingGetPaginated = false;
      state.modal = false;
      state.modalDelete = false;
      state.modalFilter = false;
      state.loadingFilter = false;
      state.filterOptions = undefined;
      state.booksFiltered = [];
      state.hasFilter = false;
    },
    setFilterBooksModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalFilter = action.payload;
    },
    filterInStore: ( state, action: PayloadAction<IFilterOptions> ) => {
      /* const options = action.payload;
      let filtered: IBook[] = state.booksList;
      if ( options.editorial !== undefined && options.editorial !== null ) {
        filtered = filtered.filter(( book ) => book.editorial === options.editorial );
      }
      if ( options.category !== undefined && options.category !== null ) {
        filtered = filtered.filter(( book ) => book.category === options.category );
      }
      if ( options.subCategory !== undefined && options.subCategory !== null ) {
        filtered = filtered.filter(( book ) => book.subCategory === options.subCategory );
      }
      if ( options.status !== undefined && options.status !== null ) {
        filtered = filtered.filter(( book ) => book.status === options.status?.value );
      }
      if ( filtered !== state.booksList ) {
        state.booksFiltered = filtered;
        state.filterOptions = options;
        state.hasFilter = true;
      } else {
        state.booksFiltered = [];
        state.filterOptions = undefined;
        state.hasFilter = false;
      } */
      state.filterOptions = action.payload;
      state.hasFilter = true;
    },
    clearFilter: ( state ) => {
      state.filterOptions = undefined;
      state.hasFilter = false;
      state.booksFiltered = [];
    },
    searchFilterInStore: ( state, action: PayloadAction<string> ) => {
      if ( action.payload === '' ) {
        state.booksFiltered = [];
        state.hasFilter = false;
      } else {
        state.booksFiltered = state.booksList.filter(( book ) => book.name.toLowerCase().includes( action.payload.toLowerCase()));
        state.hasFilter = true;
      }
    },
    clearBookInStore: ( state ) => {
      state.book = undefined;
    },
    orderByBooks: ( state, action: PayloadAction<number> ) => {
      if ( action.payload === 1 ) {
        state.booksList.sort(( a, b ) => a.created - b.created );
      }
      if ( action.payload === 2 ) {
        state.booksList.sort(( a, b ) => a.name.localeCompare( b.name ));
      }
    },
  },
  extraReducers: ( builder ) => {
    builder
      .addCase( getBooksPaginated.pending, ( state ) => {
        state.loadingGetPaginated = true;
      })
      .addCase( getBooksPaginated.fulfilled, ( state, action ) => {
        state.loadingGetPaginated = false;
        state.booksList = [...state.booksList, ...action.payload!];
      })
      .addCase( getBooksPaginated.rejected, ( state ) => {
        state.loadingGetPaginated = false;
      });
    builder
      .addCase( getBooks.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getBooks.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.booksList = action.payload!;
      })
      .addCase( getBooks.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( createBook.pending, ( state ) => {
        state.loadingCreate = true;
      })
      .addCase( createBook.fulfilled, ( state ) => {
        state.loadingCreate = false;
      })
      .addCase( createBook.rejected, ( state ) => {
        state.loadingCreate = false;
      });
    builder
      .addCase( updateBook.pending, ( state ) => {
        state.loadingUpdate = true;
      })
      .addCase( updateBook.fulfilled, ( state ) => {
        state.loadingUpdate = false;
      })
      .addCase( updateBook.rejected, ( state ) => {
        state.loadingUpdate = false;
      });
    builder
      .addCase( deleteBook.pending, ( state ) => {
        state.loadingDelete = true;
      })
      .addCase( deleteBook.fulfilled, ( state ) => {
        state.loadingDelete = false;
      })
      .addCase( deleteBook.rejected, ( state ) => {
        state.loadingDelete = false;
      });
    builder
      .addCase( getBook.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getBook.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.book = action.payload!;
      })
      .addCase( getBook.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( getBooksWithFilters.pending, ( state ) => {
        state.loadingFilter = true;
      })
      .addCase( getBooksWithFilters.fulfilled, ( state, action ) => {
        state.loadingFilter = false;
        state.booksFiltered = action.payload!;
      })
      .addCase( getBooksWithFilters.rejected, ( state ) => {
        state.loadingFilter = false;
      });
  },
});

export const {
  setCreateEditBooksModal, addBookInStore, addBookForActions, addBookWithIdForActions, updateBookInStore, deleteBookInStore, setDeleteBooksModal, clearBooks, setFilterBooksModal, filterInStore, clearFilter, searchFilterInStore, clearBookInStore, orderByBooks,
} = booksSlice.actions;

export default booksSlice.reducer;
