import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import TestActivity from '../pages/TestActivity';

const ActivitiesRouterStudents = () => (
  <Routes>
    <Route path="/prueba" element={<TestActivity title="Probar Actividad" />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default ActivitiesRouterStudents;
