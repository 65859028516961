import TreeItem, {
  useTreeItem,
  TreeItemContentProps,
} from '@mui/lab/TreeItem';
import clsx from 'clsx';
import {
  Typography, Box, useTheme, useMediaQuery, IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { IQuestion } from '../../interfaces';
import { useAppDispatch } from '../../../../app/hooks';
import {
  addGroupWithIdForActions, addQuestionWithIdForActions, deleteQuestionFromStore, setCreateOrEditQuestionModal,
} from '../../slice/activitySlice';
import { ReactComponent as Edit } from '../../../../assets/svg/thatBook/edit.svg';
import { ReactComponent as Delete } from '../../../../assets/svg/thatBook/delete.svg';

interface ITreeQuestionProps{
  question: IQuestion;
  number: number;
}

const CustomContent = React.forwardRef((
  props: TreeItemContentProps,
  ref,
) => {
  const {
    classes,
    className,
    label,
    nodeId,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    preventSelection,
  } = useTreeItem( nodeId );
  const [mouseOver, setMouseOver] = useState( false );
  const theme = useTheme();
  const matchUpMd = useMediaQuery( theme.breakpoints.up( 'md' ));
  const topicInfo = nodeId.split( '+' );

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    preventSelection( event );
  };

  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleExpansion( event );
  };

  const dispatch = useAppDispatch();

  return (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx( className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
      onMouseEnter={() => setMouseOver( true )}
      onMouseLeave={() => setMouseOver( false )}
    >
      <Typography
        onClick={handleExpansionClick}
        component="div"
        className={classes.label}
        sx={{
          width: '160px!important',
          maxWidth: '160px!important',
        }}
        textOverflow="hidden"
        noWrap
      >
        {label}
      </Typography>
      { ( mouseOver || !matchUpMd ) && (
        <Box display="flex">
          <IconButton
            onClick={() => {
              dispatch( addQuestionWithIdForActions( topicInfo[0]));
              dispatch( addGroupWithIdForActions( topicInfo[1]));
              dispatch( setCreateOrEditQuestionModal( true ));
            }}
          >
            <Edit width={15} height={15} />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch( deleteQuestionFromStore( topicInfo[0]));
            }}
          >
            <Delete width={15} height={15} />
          </IconButton>
        </Box>
      )}
    </div>
  );
});

const CustomTreeItem = ({ question, number }: ITreeQuestionProps ) => (
  <TreeItem
    ContentComponent={CustomContent}
    nodeId={`${question.id}+${question.groupId}`}
    label={question.type === 'dragAndDropImages' ? `Pregunta ${number}` : question.sentence}
  />
);

export default CustomTreeItem;
