import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import StudentsBookDetail from '../pages/StudentsBookDetail';
// import StudentsBooksList from '../pages/StudentsBooksList';
import StudentsPreviewBookDetail from '../pages/StudentsPreviewBookDetail';

const BooksStudentsRouter = () => (
  <Routes>
    {/* <Route path="/" element={<StudentsBooksList title="Lista de Libros" />} /> */}
    <Route path="/ver-libro" element={<StudentsBookDetail />} />
    <Route path="/previsualizar-libro" element={<StudentsPreviewBookDetail />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default BooksStudentsRouter;
