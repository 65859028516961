import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import Logo from '../../../shared/custom/logo/Logo';
import { ILoginCredentials } from '../../interfaces';
import { LoginValidationSchema } from '../../validators/validatioSchemas';
import { theme } from '../../../shared/theme';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { setModalResetState, setModalState } from '../../slice/authSlice';
import { signIn } from '../../actions/authActions';

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const { loadingSignIn } = useAppSelector(( state ) => state.auth );
  const [showPassword, setShowPassword] = useState( false );
  const {
    getFieldProps, handleSubmit, errors, touched,
  } = useFormik<ILoginCredentials>({
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    validationSchema: LoginValidationSchema,
    onSubmit: async ( formValues ) => {
      await dispatch( signIn( formValues ));
    },
  });
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <Grid container justifyContent="center" sx={{ maxWidth: '400px' }}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Logo size="smallNormal" />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Typography variant="h5" color={grey[800]} sx={{ fontWeight: 'bold' }}>
            Ingresa a ThatBook
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" sx={{ mb: 4 }}>
          <Typography variant="subtitle2" color="grey" fontWeight="bold" fontSize={13}>
            Eres nuevo aquí?
          </Typography>
          <Button
            variant="text"
            sx={{
              p: 0, ml: 1, fontWeight: 'bold', fontSize: 13,
            }}
            onClick={() => dispatch( setModalState( true ))}
            disabled={loadingSignIn}
          >
            Crea una cuenta
          </Button>
        </Grid>
        <Grid item xs={9} sm={8} sx={{ mb: 2.5 }}>
          <InputLabel
            htmlFor="correo"
            error={touched.email && !!errors.email}
          >
            <Typography fontWeight="bold" fontSize={12}>Correo</Typography>
          </InputLabel>
          <TextField
            size="small"
            id="correo"
            fullWidth
            placeholder="Correo electrónico"
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            sx={{
              '& .MuiOutlinedInput-root > input': {
                backgroundColor: '#F2F2F2',
              },
            }}
            {...getFieldProps( 'email' )}
          />
        </Grid>
        <Grid item xs={9} sm={8} sx={{ mb: 2 }}>
          <InputLabel
            htmlFor="password-field"
            error={touched.password && !!errors.password}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Typography fontWeight="bold" fontSize={12}>
              Contraseña
            </Typography>
            <Button
              variant="text"
              sx={{ p: 0, fontWeight: 'bold', fontSize: 12 }}
              disabled={loadingSignIn}
              onClick={() => dispatch( setModalResetState( true ))}
            >
              Olvidé mi contraseña
            </Button>
          </InputLabel>
          <OutlinedInput
            id="password-field"
            size="small"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            placeholder="Contraseña"
            error={touched.password && !!errors.password}
            sx={{
              '& input': {
                backgroundColor: '#F2F2F2',
              },
            }}
            endAdornment={(
              <IconButton
                onClick={() => setShowPassword( !showPassword )}
              >
                { showPassword ? (
                  <Visibility
                    width={24}
                    height={24}
                    sx={{ color: theme.palette.text.primary }}
                  />
                ) : (
                  <VisibilityOff
                    width={24}
                    height={24}
                    sx={{ color: theme.palette.text.primary }}
                  />
                )}
              </IconButton>
            )}
            {...getFieldProps( 'password' )}
          />
          { errors.password && touched.password && (
            <FormHelperText
              sx={{ fontSize: 14 }}
              error
            >
              {errors.password}
            </FormHelperText>
          ) }
        </Grid>
        <Grid item xs={9} sm={8} display="flex" justifyContent="space-between">
          <Button
            type="submit"
            size="small"
            sx={{
              mb: 1,
            }}
            disabled={loadingSignIn}
            fullWidth
            endIcon={loadingSignIn && ( <CircularProgress size={10} /> )}
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
      <Typography
        variant="caption"
        display="block"
        textAlign="center"
      >
        v1.0.4
      </Typography>
    </form>
  );
};

export default LoginForm;
