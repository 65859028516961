import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/styles';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps ) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'white',
    fontSize: 11,
    textAlign: 'center',
  },
}));
