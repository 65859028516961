import { useAppSelector } from '../../../../../app/hooks';

const useUnitInitialState = () => {
  const { unit } = useAppSelector(( state ) => state.unitsAndTopics );

  return {
    name: unit ? unit.name : '',
    description: unit ? unit.description : '',
  };
};

export default useUnitInitialState;
