import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAppDispatch } from '../../../../../app/hooks';
import { auth } from '../../../../firebase';
import { IQuestion } from '../../../interfaces';
import { updateResponseInStore } from '../../../slice/activitySlice';
import { IItem } from '../interfaces';
import BoxOptions from './dragAndDrop/BoxOptions';
import BoxResponse from './dragAndDrop/BoxResponse';
import ItemMovable from './dragAndDrop/ItemMovable';

interface ITypeDragAndDropProps {
  question: IQuestion;
}

const TypeDragAndDrop = ({ question }: ITypeDragAndDropProps ) => {
  const dispatch = useAppDispatch();
  const [items, setItems] = useState<IItem[]>([
    {
      id: 1, name: question.correctOption, column: 'options', response: true,
    },
    {
      id: 2, name: question.incorrectOption1, column: 'options', response: false,
    },
    {
      id: 3, name: question.incorrectOption2, column: 'options', response: false,
    },
    {
      id: 4, name: question.incorrectOption3, column: 'options', response: false,
    },
  ]);

  const handleGetCalification = ( res: string, id: number ): void => {
    const item = items.find(( x ) => x.id === id );
    if ( item?.response ) {
      dispatch( updateResponseInStore({
        userId: auth.currentUser?.uid || '',
        questionId: question.id,
        response: item?.name || '',
        type: question.type,
        groupId: question.groupId,
        correct: true,
      }));
    } else {
      dispatch( updateResponseInStore({
        userId: auth.currentUser?.uid || '',
        questionId: question.id,
        response: item?.name || '',
        type: question.type,
        groupId: question.groupId,
        correct: false,
      }));
    }
  };

  const returnItemsForColumn = (
    columnName: string,
  ): JSX.Element | JSX.Element[] => {
    const itemsCopy = items.sort(() => Math.random() - 0.5 );
    const list = itemsCopy.filter(
      ( item: IItem ) => item.column === columnName
      && item.name !== undefined,
    );
    return (
      list.map(( item: IItem ) => (
        <ItemMovable
          key={item.id}
          id={item.id || 0}
          name={item.name || ''}
          setItems={setItems}
          onSave={handleGetCalification}
        />
      ))
    );
  };

  return (
    <Box bgcolor="#EEEEEE" p={3}>
      <DndProvider backend={HTML5Backend}>
        <Grid container wrap="wrap">
          <Grid item xs={12} sm={8}>
            <BoxResponse title={question.sentence} id="question" questionId={question.id}>
              {returnItemsForColumn( 'question' )}
            </BoxResponse>
          </Grid>
          <Grid item xs={12} sm={4}>
            <BoxOptions title="Opciones" id="options">
              {returnItemsForColumn( 'options' )}
            </BoxOptions>
          </Grid>
        </Grid>
      </DndProvider>
    </Box>
  );
};

export default TypeDragAndDrop;
