import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { ICode } from '../interfaces';

const codesConverter = {
  toFirestore: ( code: ICode ) => ({
    ...code,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): ICode => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      book,
      bookId,
      creator,
      status,
      tags,
      deleted,
      used,
      userId,
      orderId,
    } = data;

    return {
      id: snapshot.id,
      book,
      bookId,
      creator,
      status,
      tags,
      deleted,
      used,
      userId,
      created,
      orderId,
    } as ICode;
  },
};

export default codesConverter;
