import { Button, CircularProgress, Box } from '@mui/material';
import BooksForm from './BoooksForm';
import FilterBoooksForm from './FilterBoooksForm';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setCreateEditBooksModal, setFilterBooksModal } from '../slice/booksSlice';
import InfoDialog from '../../shared/custom/dialog/InfoDialog';
import {
  setCreateEditTopicsModal, setCreateEditUnitsModal, setDeleteUnitModal, setDeleteTopicModal,
} from '../subModule/units/slice/unitsAndTopicsSlice';
import UnitsForm from '../subModule/units/components/UnitsForm';
import TopicsForm from '../subModule/units/components/TopicsForm';
import { deleteTopic, deleteUnit } from '../subModule/units/actions/units&TopicsActions';
import { deleteActivity } from '../../activities/actions/activitiesActions';
import { setDeleteActivityModal } from '../../activities/slice/activitySlice';

const BooksDialogsContainer = () => {
  const {
    modal, book, loadingCreate, loadingUpdate, modalFilter, loadingFilter,
  } = useAppSelector(( state ) => state.book );
  const {
    modalUnit, modalTopic, modalTopicDelete, modalUnitDelete, loadingUnitDelete, unit, topic, loadingTopicDelete,
  } = useAppSelector(( state ) => state.unitsAndTopics );
  const {
    modalDelete, loadingDeleteActivity, activity,
  } = useAppSelector(( state ) => state.activity );
  const dispatch = useAppDispatch();
  return (
    <>
      <FormDialog
        open={modal}
        title={book ? 'Editar Libro' : 'Añadir Libro'}
        closeFunction={() => dispatch( setCreateEditBooksModal( !!loadingCreate || !!loadingUpdate ))}
      >
        <BooksForm />
      </FormDialog>
      <FormDialog
        open={modalFilter}
        title="Filtrar Libro"
        closeFunction={() => dispatch( setFilterBooksModal( !!loadingFilter ))}
      >
        <FilterBoooksForm />
      </FormDialog>
      <FormDialog
        open={modalUnit}
        title={`${unit ? 'Editar Unidad' : 'Crear Unidad'}`}
        closeFunction={() => dispatch( setCreateEditUnitsModal( false ))}
      >
        <UnitsForm />
      </FormDialog>
      <FormDialog
        open={modalTopic}
        title={`${topic ? 'Editar Tema' : 'Crear Tema'}`}
        closeFunction={() => dispatch( setCreateEditTopicsModal( false ))}
      >
        <TopicsForm />
      </FormDialog>
      <InfoDialog
        open={modalUnitDelete}
        title="Eliminar Unidad"
        contentText="Estás seguro que deseas eliminar esta unidad, esta acción es irreversible."
        closeFunction={() => dispatch( setDeleteUnitModal( !!loadingUnitDelete ))}
      >
        <Box textAlign="center" pt={3}>
          <Button onClick={() => dispatch( deleteUnit( unit?.id || '' ))} disabled={loadingUnitDelete} endIcon={loadingUnitDelete && ( <CircularProgress size={10} /> )}>
            ¡Sí, Eliminar!
          </Button>
        </Box>
      </InfoDialog>
      <InfoDialog
        open={modalTopicDelete}
        title="Eliminar Tema"
        contentText="Estás seguro que deseas eliminar este tema, esta acción es irreversible."
        closeFunction={() => dispatch( setDeleteTopicModal( !!loadingTopicDelete ))}
      >
        <Box textAlign="center" pt={3}>
          <Button onClick={() => dispatch( deleteTopic( topic?.id || '' ))} disabled={loadingTopicDelete} endIcon={loadingTopicDelete && ( <CircularProgress size={10} /> )}>
            ¡Sí, Eliminar!
          </Button>
        </Box>
      </InfoDialog>
      <InfoDialog
        open={modalDelete}
        title="Eliminar actividad"
        contentText="Estás seguro que deseas eliminar esta actividad, esta acción es irreversible."
        closeFunction={() => dispatch( setDeleteActivityModal( !!loadingDeleteActivity ))}
      >
        <Box textAlign="center" pt={3}>
          <Button onClick={() => dispatch( deleteActivity( activity?.id || '' ))} disabled={loadingDeleteActivity} endIcon={loadingTopicDelete && ( <CircularProgress size={10} /> )}>
            ¡Sí, Eliminar!
          </Button>
        </Box>
      </InfoDialog>
    </>
  );
};

export default BooksDialogsContainer;
