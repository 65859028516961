import React, { useState } from 'react';
import {
  GridColDef, GridRenderCellParams, GridRowsProp,
} from '@mui/x-data-grid';
import {
  Box, Button, Chip, CircularProgress, Tooltip, Typography,
} from '@mui/material';
import { ICodeGeneration } from '../interfaces';
import CardTableWrapper from '../../shared/custom/dataGrid/CardTableWrapper';
import CustomDataGrid from '../../shared/custom/dataGrid/CustomDataGrid';
import InfoDialog from '../../shared/custom/dialog/InfoDialog';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setCodesDeleteModalState } from '../slice/codesSlice';
import { deleteCode } from '../actions/codesActions';

interface ICodeGenerationTableProps {
  codes: ICodeGeneration[];
}

interface INumbers {
  created: number;
  used: number;
}

const CodeGenerationTable = ({ codes }: ICodeGenerationTableProps ) => {
  const dispatch = useAppDispatch();
  const { loadingDelete, modalDelete, code } = useAppSelector(( state ) => state.code );

  const acceptDelete = ( ) => {
    dispatch( deleteCode( code ));
  };
  const [perPage, setPerPage] = useState( 25 );
  const rows: GridRowsProp = codes.map(
    ( c: ICodeGeneration ) => ({
      id: c.id,
      book: c.book,
      created: c.created,
      numbers: c.numbers,
      tags: c.tags,
      description: c.description,
    }),
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'CÓDIGO',
      headerClassName: 'theme__header',
      flex: 1,
    },
    {
      field: 'book',
      headerName: 'LIBRO',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'CREADO',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ( params: GridRenderCellParams<number> ) => {
        const date = params.value;
        return (
          <Typography variant="caption">
            {new Date( date ).toLocaleDateString( 'es-ES' )}
          </Typography>
        );
      },
    },
    {
      field: 'numbers',
      headerName: 'Estadisticas',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ( params: GridRenderCellParams<INumbers> ) => {
        const numbers = params.value;
        return (
          <Tooltip title={`${numbers.used} usados de ${numbers.created}`}>
            <Box display="flex">
              <Typography variant="caption" fontWeight="bold">
                {numbers.used}
              </Typography>
              <Typography variant="caption" ml={1} mr={1}>
                -
              </Typography>
              <Typography variant="caption" fontWeight="bold">
                {numbers.created}
              </Typography>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'tags',
      headerName: 'ETIQUETAS',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ( params: GridRenderCellParams<string[]> ) => {
        const tags = params.value;
        return (
          <Box display="flex">
            {tags.map(( t, i ) => i <= 1 && (
              <Chip key={i.toString()} label={t} />
            ))}
          </Box>
        );
      },
    },
    {
      field: 'description',
      headerName: 'DESCRIPCIÓN',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
  ];

  return (
    <>
      <CardTableWrapper>
        <CustomDataGrid
          noDataMessage="Sin Códigos"
          rows={rows}
          columns={columns}
          pageSize={perPage}
          onPageSizeChange={setPerPage}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </CardTableWrapper>
      <InfoDialog
        open={modalDelete}
        title="Eliminar Código"
        contentText="Estás seguro que deseas eliminar este código, esta acción es irreversible."
        closeFunction={() => dispatch( setCodesDeleteModalState( !!loadingDelete ))}
      >
        <Box textAlign="center" pt={3}>
          <Button onClick={() => acceptDelete()} disabled={loadingDelete} endIcon={loadingDelete && ( <CircularProgress size={10} /> )}>
            ¡Sí, Eliminar!
          </Button>
        </Box>
      </InfoDialog>
    </>
  );
};

export default CodeGenerationTable;
