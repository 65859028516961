import { Route, Routes } from 'react-router-dom';
import BooksSellersRouter from '../modules/books/router/BooksSellersRouter';
import CodesRouter from '../modules/codes/router/CodesRouter';
import SellerDashboardRouter from '../modules/dashboard/router/SellerDashboardRouter';
import NotFound404 from '../modules/shared/Errors/NotFound404';

import ActivitiesRouterStudents from '../modules/activities/router/ActivitiesRouterStudents';

const SellerRouter = () => (
  <Routes>
    <Route path="/dashboard/*" element={( <SellerDashboardRouter /> )} />
    <Route path="/libros/*" element={( <BooksSellersRouter /> )} />
    <Route path="/actividades/*" element={( <ActivitiesRouterStudents /> )} />
    <Route path="/*" element={( <CodesRouter /> )} />

    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default SellerRouter;
