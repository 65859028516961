import {
  AppBar,
  Box,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  MenuOutlined,
} from '@mui/icons-material';
import { MouseEvent, useEffect, useState } from 'react';
import { drawerWidth } from '../Common/DrawerWidth';
import { useAppDispatch } from '../../../app/hooks';
import { setSidebarState } from '../slice/uiSlice';
import NavbarInfo from '../custom/navbarInfo/NavbarInfo';
import NavbarSearch from '../custom/navbarSearch/NavbarSearch';
import NavbarButton from '../custom/buttons/NavbarButton';
import NavbarMenu from '../custom/navbarMenu/NavbarMenu';
import { ReactComponent as Notifications } from '../../../assets/svg/thatBook/notifications.svg';

const Navbar = ( ) => {
  const theme = useTheme();
  const mobile = useMediaQuery( theme.breakpoints.down( 'md' ));
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>( null );
  const handleClick = ( event: MouseEvent<HTMLButtonElement> ) => {
    setAnchorEl( event.currentTarget );
  };
  const handleClose = () => {
    setAnchorEl( null );
  };
  const open = Boolean( anchorEl );
  const id = open ? 'simple-popover' : undefined;

  const [isScroll, setIsScroll] = useState<boolean>( false );

  const listenScrollEvent = () => {
    // eslint-disable-next-line no-unused-expressions
    window.scrollY > 10 ? setIsScroll( true ) : setIsScroll( false );
  };

  useEffect(() => {
    window.addEventListener( 'scroll', listenScrollEvent );
  });

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px  + 10px)`,
        ml: `${drawerWidth}px`,
        height: '7em',
        backgroundColor: isScroll ? '#EBEBEB' : '#F2F2F2',
        boxShadow: isScroll ? '0 3px 10px rgb(0 0 0 / 0.2)' : 0,
        [theme.breakpoints.down( 'sm' )]: {
          width: '100%',
        },
      }}
    >
      <Toolbar sx={{
        display: mobile ? 'block' : 'flex',
        justifyContent: 'space-between',
        height: '100%',
      }}
      >
        <NavbarInfo />
        <Box display="flex" gap={1.5}>
          <NavbarSearch />
          <NavbarButton>
            <Notifications width={25} height={20} />
          </NavbarButton>
          <NavbarMenu
            idPerzonalized={id || ''}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            handleClick={handleClick}
          />
          <Box sx={{
            marginRight: theme.spacing( 2 ),
            display: 'none',
            [theme.breakpoints.down( 'sm' )]: {
              display: 'block',
            },
          }}
          >
            <NavbarButton onClick={() => dispatch( setSidebarState( true ))}>
              <MenuOutlined />
            </NavbarButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
