import { createSlice } from '@reduxjs/toolkit';
import {
  changePassword,
  deleteUserAction,
} from '../actions/profileActions';

interface IProfileState {
  loading: boolean,
  loadingDeleteUser: boolean,
}

const initialState: IProfileState = {
  loading: false,
  loadingDeleteUser: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: ( builder ) => {
    builder
      .addCase( changePassword.pending, ( state ) => {
        state.loading = true;
      })
      .addCase( changePassword.fulfilled, ( state ) => {
        state.loading = false;
      })
      .addCase( changePassword.rejected, ( state ) => {
        state.loading = false;
      });
    builder
      .addCase( deleteUserAction.pending, ( state ) => {
        state.loadingDeleteUser = true;
      })
      .addCase( deleteUserAction.fulfilled, ( state ) => {
        state.loadingDeleteUser = false;
      })
      .addCase( deleteUserAction.rejected, ( state ) => {
        state.loadingDeleteUser = false;
      });
  },
});

export default profileSlice.reducer;
