/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Typography, Grid } from '@mui/material';
import React from 'react';
import { useDrop } from 'react-dnd';
import { useAppSelector } from '../../../../../../app/hooks';
import useTextColor from '../../../../hooks/useTextColor';

interface IProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  id: string;
  questionId: string;
}

const BoxResponse: React.FC<IProps> = ( props ) => {
  const {
    children, title, id, questionId,
  } = props;
  const { alreadyQualified } = useAppSelector(( state ) => state.activity );
  const questionColor = useTextColor( questionId );

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    drop: () => ({ name: title, id }),
    collect: ( monitor ) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const handleCreateQuestion = (): JSX.Element => {
    const str = title.split( ' ' );
    return (
      <Grid container spacing={1} display="flex" justifyContent="center">
        {str.map(( item: string, i: number ) => (
          !item.includes( '**' )
            ? (
              <Grid item xs={12} md={2} key={i.toString()}>
                <img
                  key={i.toString()}
                  src={item}
                  alt={item}
                  style={{ width: '100%', minHeight: '180px', maxHeight: '180px' }}
                />
              </Grid>
            )
            : (
              <Grid item xs={12} md={2} key={i.toString()}>
                <Box sx={{
                  border: `${alreadyQualified ? '2' : '1'}px solid ${questionColor}`,
                  minHeight: '180px',
                  maxHeight: '180px',
                  overflow: 'hidden',
                  width: '100%',
                  borderRadius: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                  '& > *': {
                    border: '0!important',
                    mb: '0!important',
                  },
                }}
                >
                  { children }
                </Box>
              </Grid>
            )
        ))}
      </Grid>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      ref={drop}
    >
      {handleCreateQuestion()}
    </Box>
  );
};

export default BoxResponse;
