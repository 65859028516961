import {
  Box, Button, Card, CardContent, Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useAppDispatch } from '../../../../app/hooks';
import { setCreateorEditGroupModal } from '../../slice/activitySlice';

const TypeCreatorCard = () => {
  const dispatch = useAppDispatch();
  return (
    <Card sx={{
      backgroundColor: 'transparent',
      border: '1px dashed #707070',
      pl: 3,
      pr: 3,
    }}
    >
      <CardContent>
        <Typography textAlign="center" fontWeight="bold" color={grey[800]} mt={4} mb={3}>
          Añadir grupo de pregunta
        </Typography>
        <Typography textAlign="center">
          Los grupos de preguntan agrupan preguntas del mismo tipo con la misma rúbrica y el mismo número de pregunta.
        </Typography>
        <Box textAlign="center" mb={4} mt={5}>
          <Button size="small" sx={{ backgroundColor: '#181C32' }} onClick={() => dispatch( setCreateorEditGroupModal( true ))}>
            Añadir Grupo
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TypeCreatorCard;
