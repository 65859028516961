import {
  Box, Chip, useMediaQuery, useTheme,
} from '@mui/material';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import useDraggableScroll from '../../shared/Hooks/useDraggableScroll';
import { deleteInstitutionsFilter } from '../actions/institutionsActions';

interface IInstitutionsChipsContainerProps {
  filters: boolean;
}

const InstitutionsChipsContainer = ({ filters }: IInstitutionsChipsContainerProps ) => {
  const { filterOptions, loadingFilter } = useAppSelector(( state ) => state.institution );
  const dispatch = useAppDispatch();
  const ref = useRef( null );
  const { onMouseDown } = useDraggableScroll( ref );
  const theme = useTheme();
  const matchSmDowm = useMediaQuery( theme.breakpoints.down( 'sm' ));
  return (
    <Box
      display="flex"
      gap={2}
      alignItems="center"
      justifyContent={filters || matchSmDowm ? 'center' : 'flex-end'}
      sx={{
        flexWrap: 'nowrap',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
      }}
      ref={ref}
      onMouseDown={onMouseDown}
    >
      {filterOptions ? (
        <>
          {filterOptions.active && (
            <Chip
              label={filterOptions.active ? 'Activo' : 'Inactivo'}
              variant="outlined"
              disabled={loadingFilter}
              onDelete={() => dispatch( deleteInstitutionsFilter({ ...filterOptions, active: null }))}
            />
          )}
          {filterOptions.from && (
            <Chip
              label="Desde"
              variant="outlined"
              disabled={loadingFilter}
              onDelete={() => dispatch( deleteInstitutionsFilter({ ...filterOptions, from: null }))}
            />
          )}
          {filterOptions.until && (
            <Chip
              label="Hasta"
              variant="outlined"
              disabled={loadingFilter}
              onDelete={() => dispatch( deleteInstitutionsFilter({ ...filterOptions, until: null }))}
            />
          )}
        </>
      ) : ( <Chip label="No hay filtros" variant="outlined" /> )}
    </Box>
  );
};

export default InstitutionsChipsContainer;
