import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IGroupActivity } from '../interfaces';

const groupConverter = {
  toFirestore: ( group: IGroupActivity ) => ({
    ...group,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IGroupActivity => {
    const data = snapshot.data( options );

    const {
      rubric,
      type,
      deleted,
    } = data;

    return {
      id: snapshot.id,
      rubric,
      type,
      deleted,
    } as IGroupActivity;
  },
};

export default groupConverter;
