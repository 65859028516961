import {
  Popover,
  Avatar,
  Card,
  CardContent,
  MenuList,
  MenuItem,
  Typography,
  Box,
} from '@mui/material';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import NavbarButton from '../buttons/NavbarButton';
import noPhoto from '../../../../assets/img/noPhoto.png';
import { logOut } from '../../../auth/actions/authActions';
import { ReactComponent as User } from '../../../../assets/svg/thatBook/user.svg';

interface INavbarMenuProps {
  idPerzonalized: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClick: ( event: MouseEvent<HTMLButtonElement> ) => void;
  handleClose: () => void;
}

const NavbarMenu = ({
  idPerzonalized, anchorEl, open, handleClick, handleClose,
}: INavbarMenuProps ) => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(( state ) => state.auth );
  const navigate = useNavigate();
  return (
    <div>
      <NavbarButton aria-describedby={idPerzonalized} variant="contained" onClick={handleClick}>
        <User width={25} height={23} />
      </NavbarButton>
      <Popover
        id={idPerzonalized}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '10px',
            mt: '1em',
          },
        }}
      >
        <Card sx={{ width: 250 }}>
          <CardContent sx={{
            display: 'flex', alignItems: 'center', borderBottom: '1px solid #eeeeee',
          }}
          >
            <Avatar sx={{ borderRadius: '10px' }} src={userInfo && userInfo.photo ? userInfo.photo : noPhoto} />
            <Box ml={2}>
              <Typography variant="subtitle2" fontSize={12} fontWeight="bold">{userInfo && userInfo.name}</Typography>
              <Typography variant="subtitle2" fontSize={11}>{userInfo && userInfo.email}</Typography>
            </Box>
          </CardContent>
          <MenuList>
            <MenuItem onClick={() => navigate( '/perfil' )}>
              <Typography variant="body2" color="text.secondary">
                Mi Perfil
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => dispatch( logOut())}>
              <Typography variant="body2" color="text.secondary">
                Cerrar Sesión
              </Typography>
            </MenuItem>
          </MenuList>
        </Card>
      </Popover>
    </div>
  );
};

export default NavbarMenu;
