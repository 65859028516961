import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import AdminOrdersList from '../pages/AdminOrdersList';

const AdminCodesRouter = () => (
  <Routes>
    <Route path="/" element={<AdminOrdersList title="Lista de Órdenes" />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default AdminCodesRouter;
