import {
  Button, Box,
  Card, CardActions, CardContent, CardHeader, Typography, Grid, CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import ThreeView from './ThreeView';

const ThreeViewChart = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { loadingLast5Codes } = useAppSelector(( state ) => state.dashboard );
  return (
    <Card sx={{
      width: '100%',
      height: '100%',
      minHeight: '100%',
      '&.MuiCard-root': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    }}
    >
      <Box>
        <CardHeader
          title={(
            <Typography component="p" sx={{ fontWeight: 700 }}>
              Códigos de activación
            </Typography>
          )}
          subheader={(
            <Typography component="p" sx={{ fontSize: '0.8em' }}>
              Visualiza las ultimas ordenes generadas
            </Typography>
          )}
        />
        <CardContent
          sx={{
            minHeight: 410,
            maxHeight: 410,
            overflow: 'hidden',
            overflowY: 'auto',
          }}
        >
          {!loadingLast5Codes ? (
            <ThreeView />
          ) : (
            <Grid
              container
              justifyContent="center"
              sx={{ marginTop: '1em' }}
            >
              <CircularProgress size={30} />
            </Grid>
          )}
        </CardContent>

      </Box>
      <CardActions
        sx={{
          borderTop: '1px solid #e0e0e0',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="text"
          sx={{ color: theme.palette.info.main }}
          onClick={() => navigate( '/admin/ordenes' )}
        >
          Ver más detalles.
        </Button>
      </CardActions>
    </Card>
  );
};

export default ThreeViewChart;
