import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAppDispatch } from '../../../../../app/hooks';
import { auth } from '../../../../firebase';
import { IQuestion } from '../../../interfaces';
import { updateResponseInStore } from '../../../slice/activitySlice';
import { IItem } from '../interfaces';
import BoxOptions from './dragAndDropImages/BoxOptions';
import BoxResponse from './dragAndDropImages/BoxResponse';
import ItemMovable from './dragAndDropImages/ItemMovable';

interface ITypeDragAndDropImagesProps {
  question: IQuestion;
}

const TypeDragAndDropImages = ({ question }: ITypeDragAndDropImagesProps ) => {
  const dispatch = useAppDispatch();
  const [items, setItems] = useState<IItem[]>([
    {
      id: 1, name: question.correctOption, column: 'options', response: true,
    },
    {
      id: 2, name: question.incorrectOption1, column: 'options', response: false,
    },
    {
      id: 3, name: question.incorrectOption2, column: 'options', response: false,
    },
    {
      id: 4, name: question.incorrectOption3, column: 'options', response: false,
    },
  ]);

  const handleGetCalification = ( res: string, id: number ): void => {
    const item = items.find(( x ) => x.id === id );
    if ( item?.response ) {
      dispatch( updateResponseInStore({
        userId: auth.currentUser?.uid || '',
        questionId: question.id,
        response: item?.name || '',
        type: question.type,
        groupId: question.groupId,
        correct: true,
      }));
    } else {
      dispatch( updateResponseInStore({
        userId: auth.currentUser?.uid || '',
        questionId: question.id,
        response: item?.name || '',
        type: question.type,
        groupId: question.groupId,
        correct: false,
      }));
    }
  };

  const returnItemsForColumn = (
    columnName: string,
  ): JSX.Element | JSX.Element[] => {
    const itemsCopy = items.sort(() => Math.random() - 0.5 );
    const list = itemsCopy.filter(
      ( item: IItem ) => item.column === columnName
      && item.name !== undefined,
    );
    return (
      <>
        {columnName === 'options' ? (
          list.map(( item: IItem ) => (
            <Grid item xs={6} md={3} key={item.id}>
              <ItemMovable
                key={item.id}
                id={item.id || 0}
                name={item.name || ''}
                setItems={setItems}
                onSave={handleGetCalification}
              />
            </Grid>
          ))
        ) : (
          list.map(( item: IItem ) => (
            <ItemMovable
              key={item.id}
              id={item.id || 0}
              name={item.name || ''}
              setItems={setItems}
              onSave={handleGetCalification}
            />
          ))
        )}
      </>
    );
  };

  return (
    <Box bgcolor="#EEEEEE" p={3}>
      <DndProvider backend={HTML5Backend}>
        <Grid container wrap="wrap">
          <Grid item xs={12}>
            <BoxResponse title={question.sentence} id="question" questionId={question.id}>
              {returnItemsForColumn( 'question' )}
            </BoxResponse>
          </Grid>
          <Grid item xs={12}>
            <BoxOptions title="Opciones" id="options">
              <Grid container spacing={1}>
                {returnItemsForColumn( 'options' )}
              </Grid>
            </BoxOptions>
          </Grid>
        </Grid>
      </DndProvider>
    </Box>
  );
};

export default TypeDragAndDropImages;
