import { Box, Typography } from '@mui/material';
import { IQuestion } from '../../../interfaces';

interface ITypeEmbedProps {
  question: IQuestion;
}

const TypeEmbed = ({ question }: ITypeEmbedProps ) => (
  <Box bgcolor="#EEEEEE" p={3}>
    <Typography sx={{
      fontWeight: 'bold',
    }}
    >
      Url de la Actividad:
      {' '}
      {question.sentence}
    </Typography>
    <iframe height="800px" width="100%" src={question.sentence} title={question.sentence} />
  </Box>
);

export default TypeEmbed;
