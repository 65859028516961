import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getBooksPaginated } from '../actions/booksActions';

const usePaginatedBooks = ( INeed: boolean ) => {
  const dispatch = useAppDispatch();
  const { booksList, filterOptions } = useAppSelector(( state ) => state.book );
  return () => dispatch( getBooksPaginated({ book: booksList[booksList.length - 1], options: filterOptions, INeedSetNoMoreData: INeed }));
};

export default usePaginatedBooks;
