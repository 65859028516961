/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material';
import React from 'react';
import { useDrop } from 'react-dnd';

interface IProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  id: string;
}

const BoxOptions: React.FC<IProps> = ( props ) => {
  const { children, title, id } = props;

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    drop: () => ({ name: title, id }),
  }));

  return (
    <Box
      ref={drop}
    >
      {children}
    </Box>
  );
};

export default BoxOptions;
