import {
  Card, CardContent, CardHeader, Typography, Box,
} from '@mui/material';
import {
  TreeView, TreeItem,
} from '@mui/lab';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { IGroupActivity, IQuestion } from '../../interfaces';
import GroupMenu from './GroupMenu';
import { ReactComponent as Question } from '../../../../assets/svg/thatBook/question.svg';
import { useAppSelector } from '../../../../app/hooks';
import TreeQuestion from './TreeQuestion';
import { activitiesTypes } from '../../../shared/Common/ActivitiesTypes';

interface IGroupCardProps {
  group: IGroupActivity;
}

const GroupCard = ({ group }: IGroupCardProps ) => {
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
  const handleToggleTopic = ( event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedGroups( nodeIds );
  };
  const { questionsList } = useAppSelector(( state ) => state.activity );
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        action={(
          <GroupMenu id={group.id} />
        )}
        title={(
          <Typography fontSize={20} fontWeight="bold" color={grey[900]} width={200} noWrap>
            {activitiesTypes.find(( type ) => type.value === group?.type )?.label || 'Pregunta'}
          </Typography>
        )}
        subheader={(
          <Typography fontSize={14} variant="body2" height={40}>
            {group.rubric}
          </Typography>
        )}
      />
      <CardContent>
        <TreeView
          aria-label="customized"
          defaultCollapseIcon={<Question fill="#707070" width={20} height={20} />}
          defaultEndIcon={<Question fill="#707070" width={20} height={20} />}
          defaultExpandIcon={
            <Question fill="#707070" width={20} height={20} />
          }
          sx={{
            flexGrow: 1, maxWidth: 400, overflowY: 'auto',
          }}
          expanded={expandedGroups}
          onNodeToggle={handleToggleTopic}
        >
          <TreeItem
            nodeId={`${group.id}`}
            label={
              (
                <Box display="flex">
                  <Typography>
                    Preguntas
                  </Typography>
                </Box>
              )
            }
            sx={{ height: expandedGroups.includes( `${group.id}1` ) ? 100 : 'auto' }}
          >
            {questionsList && questionsList.length > 0 ? questionsList.map(( question: IQuestion, indexQuestion: number ) => question.groupId === group.id && question.deleted === false
              && (
                <TreeQuestion question={question} number={indexQuestion + 1} key={indexQuestion.toString()} />
              )) : 'Sin Preguntas'}
          </TreeItem>
        </TreeView>
      </CardContent>
    </Card>
  );
};

export default GroupCard;
