import { Button, ButtonProps, Typography } from '@mui/material';
import { styled } from '../../theme';
import { ReactComponent as Filter } from '../../../../assets/svg/thatBook/filter.svg';

const CustomButtom = styled( Button )<ButtonProps>(() => ({
  backgroundColor: '#C6EEFE',
  color: '#0CAFEF',
  '&:hover': {
    backgroundColor: '#C6EEFE',
  },
}));

const FilterButton = ( props: Omit<ButtonProps, 'variant' | 'color'> ) => (
  <CustomButtom
    variant="outlined"
    {...props}
    startIcon={
      <Filter width={25} height={25} fill="#0CAFEF" />
    }
  >
    <Typography noWrap>
      Filtro
    </Typography>
  </CustomButtom>
);

export default FilterButton;
