import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { ITopic } from '../interfaces';

const topicConverter = {
  toFirestore: ( topic: ITopic ) => ({
    ...topic,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): ITopic => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      name,
      deleted,
      bookId,
      unitId,
      url,
    } = data;

    return {
      id: snapshot.id,
      name,
      deleted,
      bookId,
      unitId,
      url,
      created,
    } as ITopic;
  },
};

export default topicConverter;
