import * as yup from 'yup';

export const LoginValidationSchema = yup.object().shape({
  email: yup.string()
    .email( 'El formato es incorrecto' )
    .required( 'El correo electrónico es requerido' ),
  password: yup.string().required( 'La contraseña es requerida' ),
});

export const RegisterValidationSchema = yup.object().shape({
  email: yup.string()
    .email( 'El formato es incorrecto' )
    .required( 'Rl correo electrónico es requerido' )
    .max( 60, 'El correo no debe contener más de 60 caracteres' )
    .min( 6, 'El correo debe contener al menos 6 caracteres' ),
  password: yup.string().required( 'La contraseña es requerida' )
    .max( 20, 'La contraseña no debe contener más de 60 caracteres' )
    .min( 8, 'La contraseña debe contener al menos 8 caracteres' ),
  confirmationPassword: yup.string()
    .oneOf([yup.ref( 'password' ), null], 'Las contraseñas no coinciden' )
    .max( 20, 'La contraseña no debe contener más de 60 caracteres' )
    .min( 8, 'La contraseña debe contener al menos 8 caracteres' ),
  name: yup.string().required( 'El nombre es requerido' )
    .max( 40, 'El nombre no debe contener más de 40 caracteres' )
    .min( 6, 'El nombre debe contener al menos 6 caracteres' ),
  lastname: yup.string().required( 'El apellido es requerido' )
    .max( 40, 'El apellido no debe contener más de 40 caracteres' )
    .min( 6, 'El apellido debe contener al menos 6 caracteres' ),
  institution: yup.mixed().required( 'La institución es requerida' ),
});

export const ResetValidationSchema = yup.object().shape({
  email: yup.string()
    .email( 'El formato es incorrecto' )
    .required( 'El correo electrónico es requerido' ),
});
