import { ILink } from '../interfaces';
import bookIcon from '../../../assets/svg/bookIcon.svg';
import barCode from '../../../assets/svg/thatBook/barCode.svg';
import home from '../../../assets/svg/thatBook/image2Vector.svg';
import graduated from '../../../assets/svg/thatBook/graduated.svg';
import moneyAdministrator from '../../../assets/svg/thatBook/moneyAdministrator.svg';

export const Menu: ILink[] = [
  {
    name: 'Inicio',
    link: '/',
    icon: home,
    typeUser: 1,
  },
  {
    name: 'Libros',
    link: '/admin/libros',
    icon: bookIcon,
    typeUser: 1,
  },
  {
    name: 'Estudiantes',
    link: '/admin/estudiantes',
    icon: graduated,
    typeUser: 1,
  },
  {
    name: 'Vendedores',
    link: '/admin/vendedores',
    icon: moneyAdministrator,
    typeUser: 1,
  },
  {
    name: 'Órdenes',
    link: '/admin/ordenes',
    icon: barCode,
    typeUser: 1,
  },
  {
    name: 'Instituciones',
    link: '/admin/instituciones',
    icon: barCode,
    typeUser: 1,
  },
  {
    name: 'Inicio',
    link: '/',
    icon: home,
    typeUser: 2,
  },
  {
    name: 'Libros',
    link: '/estudiante/libros',
    icon: bookIcon,
    typeUser: 1,
  },
  // {
  //   name: 'Libros',
  //   link: '/estudiante/libros',
  //   icon: bookIcon,
  //   typeUser: 2,
  // },
  {
    name: 'Libros',
    link: '/vendedor/libros',
    icon: bookIcon,
    typeUser: 3,
  },
  {
    name: 'Órdenes',
    link: '/vendedor/ordenes',
    icon: barCode,
    typeUser: 3,
  },
];
