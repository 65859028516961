import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getCodeGenerationsPaginated } from '../actions/codesActions';

const usePaginatedCodeGenerations = () => {
  const dispatch = useAppDispatch();
  const { codeGenerationsList } = useAppSelector(( state ) => state.code );
  return () => dispatch( getCodeGenerationsPaginated( codeGenerationsList[codeGenerationsList.length - 1]));
};

export default usePaginatedCodeGenerations;
