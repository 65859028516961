import { Grid } from '@mui/material';
import { useEffect } from 'react';
import UnitsForm from '../components/UnitsForm';
import TopicsForm from '../components/TopicsForm';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { getUnits } from '../actions/units&TopicsActions';

interface UnitsAndPageForBookProps {
  title: string
}

const UnitsAndPageForBook = ({ title }: UnitsAndPageForBookProps ) => {
  const dispatch = useAppDispatch();
  const { book } = useAppSelector(( state ) => state.book );
  useEffect(() => {
    document.title = title;
    dispatch( getUnits( book?.id || '' ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <UnitsForm />
      </Grid>
      <Grid item xs={12} md={6}>
        <TopicsForm />
      </Grid>
    </Grid>
  );
};

export default UnitsAndPageForBook;
