import { Link, LinkProps } from 'react-router-dom';

import { styled } from '../../theme';

const CustomLink = styled( Link )<LinkProps>(() => ({
  textDecoration: 'none',
  color: 'white',
}));

export default CustomLink;
