import ExcelJS from 'exceljs';

export interface RowData {
  id: string;
  book: string;
  bookId: string;
  created: Date;
  tags: string[];
  orderId: string;
}

export const createWorkbook = ( data: RowData[]): ExcelJS.Workbook => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet( `Lista de códigos` );

  const headerStyle: Partial<ExcelJS.Style> = {
    font: {
      bold: true,
      name: 'Arial',
      size: 12,
    },
  };

  worksheet.columns = [
    {
      header: 'Código', key: 'id', width: 25, style: headerStyle,
    },
    {
      header: 'Nombre del libro', key: 'book', width: 25, style: headerStyle,
    },
    {
      header: 'Id de libro', key: 'bookId', width: 25, style: headerStyle,
    },
    {
      header: 'Fecha de creación', key: 'created', width: 25, style: headerStyle,
    },
    {
      header: 'Tags', key: 'tags', width: 15, style: headerStyle,
    },
    {
      header: 'Id de orden', key: 'orderId', width: 15, style: headerStyle,
    },
  ];

  data.forEach(( row: RowData ) => {
    worksheet.addRow( row ).font = { bold: false, size: 12, name: 'Arial' };
  });

  return workbook;
};
