import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addDoc, collection, doc, getDocs, limit, orderBy, query, startAfter, updateDoc, where,
  writeBatch,
} from 'firebase/firestore';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { RootState } from '../../../app/store';
import { createWorkbook, RowData } from '../../../helpers/excel';
import { auth, db } from '../../firebase';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import codesConverter from '../converters/codesConverter';
import {
  ICode, ICodeGeneration, ICodeGenerationResponse, ICodeRegister, ICodesFilterOptions,
} from '../interfaces';
import {
  addCodesInStore, clearCodesFilter, deleteCodeInStore, filterCodesInStore,
} from '../slice/codesSlice';
import codeGenerationsConverters from '../converters/codeGenerationsConverters';

export const getCodes = createAsyncThunk(
  'code/getCodes', async () => {
    try {
      const q = query( collection( db,
        'activationCodes' ),
      where( 'deleted', '==', false ),
      where( 'creator', '==', auth.currentUser?.uid ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( codesConverter ),
      );
      const codes: ICode[] = [];
      querySnapshot.forEach( async ( item ) => {
        codes.push({ ...item.data() });
      });
      return codes;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getCodesPaginated = createAsyncThunk(
  'code/getCodesPaginated', async ( code: ICode, { dispatch }) => {
    try {
      const q = query( collection( db,
        'activationCodes' ),
      where( 'deleted', '==', false ),
      where( 'creator', '==', auth.currentUser?.uid ),
      orderBy( 'created', 'asc' ), limit( 100 ),
      startAfter( new Date( code.created )));
      const querySnapshot = await getDocs(
        q.withConverter( codesConverter ),
      );
      const codes: ICode[] = [];
      querySnapshot.forEach( async ( item ) => {
        codes.push({ ...item.data() });
      });
      if ( codes.length > 0 ) {
        dispatch( setNoMoreData( true ));
        toast.success( 'Datos Obtenidos' );
      } else {
        dispatch( setNoMoreData( false ));
        toast.info( 'No hay mas datos' );
      }
      return codes;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const deleteCode = createAsyncThunk(
  'code/deleteCode', async ( code: ICode | undefined, { dispatch }) => {
    try {
      if ( code && !code.used && code.userId === null ) {
        const q = doc( db, 'activationCodes', code.id );

        await updateDoc( q, { deleted: true });

        dispatch( deleteCodeInStore( code.id ));

        toast.success( 'Código eliminado existosamente' );
      } else {
        toast.error( 'Este Código ya ha sido cangeado, por lo que es imposible de eliminar' );
      }
    } catch ( error ) {
      toast.error( 'Error al intentar eliminar el código' );
    }
  },
);

export const generateExcel = createAsyncThunk(
  'code/generateExcel', async ( _, { getState }) => {
    const { code } = getState() as RootState;

    const { recentlyCreated } = code;

    const codesList: RowData[] = [];

    recentlyCreated.forEach(( recentlyCode ) => {
      codesList.push({
        id: recentlyCode.id,
        book: recentlyCode.book,
        bookId: recentlyCode.bookId,
        created: new Date( recentlyCode.created ),
        tags: recentlyCode.tags,
        orderId: recentlyCode.orderId,
      });
    });
    const wb = createWorkbook( codesList );

    wb.xlsx.writeBuffer()
      .then(( data ) => {
        saveAs( new Blob([data], { type: 'application/octet-stream' }), 'Lista de códigos.xlsx' );
      });
  },
);

export const getCodeGenerations = createAsyncThunk(
  'code/getCodeGenerations', async () => {
    try {
      const q = query( collection( db,
        'codeGenerations' ),
      where( 'creator', '==', auth.currentUser?.uid ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( codeGenerationsConverters ),
      );
      const codeGenerations: ICodeGeneration[] = [];
      querySnapshot.forEach( async ( item ) => {
        codeGenerations.push({ ...item.data() });
      });
      return codeGenerations;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getAllCodeGenerations = createAsyncThunk(
  'code/getAllCodeGenerations', async () => {
    try {
      const q = query( collection( db,
        'codeGenerations' ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( codeGenerationsConverters ),
      );
      const codeGenerations: ICodeGeneration[] = [];
      querySnapshot.forEach( async ( item ) => {
        codeGenerations.push({ ...item.data() });
      });
      return codeGenerations;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getCodeGenerationsPaginated = createAsyncThunk(
  'code/getCodeGenerationsPaginated', async ( code: ICodeGeneration, { dispatch }) => {
    try {
      const q = query( collection( db,
        'codeGenerations' ),
      where( 'deleted', '==', false ),
      where( 'creator', '==', auth.currentUser?.uid ),
      orderBy( 'created', 'asc' ), limit( 100 ),
      startAfter( new Date( code.created )));
      const querySnapshot = await getDocs(
        q.withConverter( codeGenerationsConverters ),
      );
      const codeGenerations: ICodeGeneration[] = [];
      querySnapshot.forEach( async ( item ) => {
        codeGenerations.push({ ...item.data() });
      });
      if ( codeGenerations.length > 0 ) {
        dispatch( setNoMoreData( true ));
        toast.success( 'Datos Obtenidos' );
      } else {
        dispatch( setNoMoreData( false ));
        toast.info( 'No hay mas datos' );
      }
      return codeGenerations;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export function sliceArrayIntoGroups<T>( arr: Array<T>, size: number ): Array<Array<T>> {
  let step = 0;
  const sliceArr = [];
  const
    len = arr.length;
  while ( step < len ) {
    sliceArr.push( arr.slice( step, step += size ));
  }
  return sliceArr;
}

const delay = ( ms: number ) => new Promise(( res ) => setTimeout( res, ms ));

export const createCode = createAsyncThunk(
  'code/createCode',
  async ( code: ICodeRegister, { dispatch }) => {
    const orderData: ICodeGeneration = {
      book: code.book?.name || '',
      bookId: code.book?.id || '',
      created: new Date(),
      creator: auth.currentUser?.uid || '',
      description: code.description,
      numbers: {
        created: Number( code.quantity ),
        used: 0,
      },
      tags: code.tags,
    };
    try {
      const orderCreated = await addDoc(
        collection( db, 'codeGenerations' ), orderData,
      );
      const dataForEach = [];
      for ( let i = 0; i <= ( code.quantity - 1 ); i++ ) {
        const dataToSend = {
          book: code.book?.name || '',
          bookId: code.book?.id || '',
          created: new Date(),
          deleted: false,
          creator: auth.currentUser?.uid || '',
          status: 0,
          tags: code.tags,
          used: false,
          userId: null,
          orderId: orderCreated.id,
        };
        dataForEach.push( dataToSend );
      }
      const codesCreated: ICode[] = [];
      let counter = 0;
      toast.info( 'Agregando códigos', {
        toastId: 'toast-code-create',
        autoClose: false,
      });

      const groups = sliceArrayIntoGroups( dataForEach, 500 );
      await groups.reduce( async ( prev, items ) => {
        await prev;
        const batch = writeBatch( db );
        items.forEach(( item ) => {
          const ref = doc( collection( db, 'activationCodes' ));
          batch.set( ref, item );
          codesCreated.push({
            ...item, id: ref.id, created: item.created.getTime(), orderId: orderCreated.id,
          });
        });
        await batch.commit();
        await delay( 500 );
        counter += items.length;
        toast.update( 'toast-code-create', {
          render: `${counter} de ${code.quantity}`,
          autoClose: 5000,
        });
      }, Promise.resolve());
      const data: ICodeGenerationResponse = {
        codeGeneration: {
          ...orderData,
          id: orderCreated.id,
        },
        codes: codesCreated,
      };
      dispatch( addCodesInStore( data ));
      toast.success( 'Todos los Códigos Fueron Registrados Correctamente' );
    } catch ( err ) {
      console.log( err );
      toast.error( 'A ocurrido un error' );
    }
  },
);

export const getCodesWithFilters = createAsyncThunk(
  'code/getCodesWithFilters', async ( options: ICodesFilterOptions, { dispatch }) => {
    try {
      toast.info( 'Obteniendo datos' );
      let q = query( collection( db,
        'codeGenerations' ),
      where( 'creator', '==', auth.currentUser?.uid ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      if ( options.book ) {
        q = query( q, where( 'book', '==', options.book?.name ));
      }
      if ( options.from ) {
        q = query( q, where( 'created', '>=', options.from ));
      }
      if ( options.until ) {
        q = query( q, where( 'created', '<=', options.until ));
      }
      const querySnapshot = await getDocs(
        q.withConverter( codeGenerationsConverters ),
      );
      const codes: ICodeGeneration[] = [];
      querySnapshot.forEach( async ( item ) => {
        codes.push({ ...item.data() });
      });
      console.log( codes );
      dispatch( filterCodesInStore( options ));
      toast.success( 'Datos obtenidos correctamente' );
      return codes;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const deleteCodesFilter = createAsyncThunk(
  'code/deleteCodesFilter', async ( options: ICodesFilterOptions, { dispatch }) => {
    try {
      if ( options.book === null && options.from === null && options.until === null ) {
        dispatch( clearCodesFilter());
        dispatch( setNoMoreData( true ));
      } else {
        dispatch( getCodesWithFilters( options ));
      }
    } catch ( error ) {
      console.log( error );
    }
  },
);
