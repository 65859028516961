import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import AppRouter from './router/AppRouter';
import { theme } from './modules/shared/theme';

const App = (): JSX.Element => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AppRouter />
    <ToastContainer position="top-right" />
  </ThemeProvider>
);

export default App;
