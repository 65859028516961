import myBook from '../../../../assets/img/myBook.png';

interface Props {
  size: 'xsmall' | 'small' | 'medium' | 'smallNormal' | 'normal' | 'large',
}

const logoSizes = {
  xsmall: 24,
  small: 64,
  medium: 160,
  smallNormal: 220,
  normal: 400,
  large: 800,
};

const Logo = ({ size }: Props ) => (
  <img src={myBook} width={logoSizes[size]} alt="Logo" />

);

export default Logo;
