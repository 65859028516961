import React from 'react';
import {
  Box,
  CssBaseline,
  Toolbar,
} from '@mui/material';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar';

interface ILogedLayoutProps {
  children: JSX.Element | JSX.Element[];
}

const LogedLayout = ({ children }: ILogedLayoutProps ): JSX.Element => (
  <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <Navbar />
    <Sidebar />
    <Box
      component="main"
      sx={{
        flexGrow: 1, bgcolor: '#F2F2F2', p: '4em 1.4em 10em 1em', maxHeight: '100vh', maxWidth: '100vw',
      }}
    >
      <Toolbar />
      { children }
    </Box>
  </Box>
);

export default LogedLayout;
