import TreeItem, {
  useTreeItem,
  TreeItemContentProps,
} from '@mui/lab/TreeItem';
import clsx from 'clsx';
import {
  Typography, Box, useTheme, useMediaQuery, IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../app/hooks';
import { ReactComponent as Edit } from '../../../../../../assets/svg/thatBook/edit.svg';
import { ReactComponent as Delete } from '../../../../../../assets/svg/thatBook/delete.svg';
import { IActivity } from '../../../../../activities/interfaces';
import { addActivityWithIdForActions, setDeleteActivityModal } from '../../../../../activities/slice/activitySlice';
import { addUnitWithIdForActions } from '../../slice/unitsAndTopicsSlice';

interface ITreeActivityProps{
  activity: IActivity;
  numberActivity: number
}

const CustomContent = React.forwardRef((
  props: TreeItemContentProps,
  ref,
) => {
  const {
    classes,
    className,
    label,
    nodeId,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    preventSelection,
  } = useTreeItem( nodeId );
  const [mouseOver, setMouseOver] = useState( false );
  const theme = useTheme();
  const matchUpMd = useMediaQuery( theme.breakpoints.up( 'md' ));
  const activityInfo = nodeId.split( '+' );
  const navigate = useNavigate();

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    preventSelection( event );
  };

  const handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleExpansion( event );
  };

  const dispatch = useAppDispatch();

  return (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx( className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
      onMouseEnter={() => setMouseOver( true )}
      onMouseLeave={() => setMouseOver( false )}
    >
      <Typography
        onClick={handleExpansionClick}
        component="div"
        className={classes.label}
        sx={{
          width: '160px!important',
          maxWidth: '160px!important',
        }}
        textOverflow="hidden"
        noWrap
      >
        Actividad
        {' '}
        {Number( activityInfo[1]) + 1}
        :
        {' '}
        {label}
      </Typography>
      { ( mouseOver || !matchUpMd ) && (
        <Box display="flex">
          <IconButton
            onClick={() => {
              dispatch( addActivityWithIdForActions( activityInfo[0]));
              dispatch( addUnitWithIdForActions( activityInfo[2]));
              navigate( '/admin/actividades/prueba' );
            }}
          >
            <Edit width={15} height={15} />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch( addActivityWithIdForActions( activityInfo[0]));
              dispatch( addUnitWithIdForActions( activityInfo[2]));
              navigate( '/admin/actividades/editar' );
            }}
          >
            <Edit width={15} height={15} />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch( addActivityWithIdForActions( activityInfo[0]));
              dispatch( setDeleteActivityModal( true ));
            }}
          >
            <Delete width={15} height={15} />
          </IconButton>
        </Box>
      )}
    </div>
  );
});

const CustomTreeItem = ({ activity, numberActivity }: ITreeActivityProps ) => (
  <TreeItem
    ContentComponent={CustomContent}
    nodeId={`${activity.id}+${numberActivity}+${activity.unitId}`}
    label={activity.name}
  />
);

export default CustomTreeItem;
