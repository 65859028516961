import {
  Card, CardContent, Typography, Box,
} from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { updateUserData } from '../../../auth/actions/authActions';
import { IUserUpdate } from '../../interfaces';
import UserForm from '../UserForm';

const CardInfo = ( ) => {
  const { userInfo } = useAppSelector(( state ) => state.auth );
  const dispatch = useAppDispatch();
  const handleEditUserInfo = async ( userFromForm: IUserUpdate ) => {
    if ( userInfo ) {
      await dispatch( updateUserData({
        ...userInfo,
        name: userFromForm.name,
        lastname: userFromForm.lastname,
      }));
    }
  };

  return (
    <Card
      sx={{
        borderBottom: '1px solid #EAEAEA',
        boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
        rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
        rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
      }}
    >
      <Box
        p={2}
        display="flex"
        alignItems="center"
        sx={{
          borderBottom: '1px solid #EAEAEA',
        }}
      >
        <Typography
          variant="body2"
        >
          Edita tus Datos
        </Typography>
      </Box>
      <CardContent>
        <UserForm
          handleSendSubmit={handleEditUserInfo}
          initialValues={userInfo}
          reinitialize
        />
      </CardContent>
    </Card>
  );
};

export default CardInfo;
