import React from 'react';
import { useFormik } from 'formik';
import {
  Button,
  CircularProgress, Grid, InputLabel, TextField,
} from '@mui/material';
import { UserFormValidationSchema } from '../constants/validation-schemas';
import { useAppSelector } from '../../../app/hooks';
import { IUser } from '../../auth/interfaces';
import { IUserUpdate } from '../interfaces';

interface IUserFormm {
  handleSendSubmit: ( data: IUserUpdate ) => void;
  initialValues?: IUser;
  reinitialize: boolean;
}

const UserForm: React.FC<IUserFormm> = ( props ) => {
  const {
    handleSendSubmit,
    initialValues,
    reinitialize,
  } = props;
  const {
    loadingUpdate,
  } = useAppSelector(( state ) => state.auth );
  const {
    getFieldProps, handleSubmit, errors, touched,
  } = useFormik<IUserUpdate>({
    initialValues: initialValues ? { ...initialValues } : {
      name: '',
      lastname: '',
      email: '',
    },
    enableReinitialize: reinitialize,
    validationSchema: UserFormValidationSchema,
    onSubmit: async ({
      name,
      lastname,
      email,
    }) => {
      const data = {
        name,
        lastname,
        email,
      };
      handleSendSubmit( data );
    },
  });
  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: 'block', width: '100%' }}
      autoComplete="off"
    >
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} sx={{ marginBottom: '1em' }}>
            <InputLabel
              htmlFor="name"
              sx={{ fontWeight: 700 }}
            >
              Nombres
            </InputLabel>
            <TextField
              id="name"
              variant="outlined"
              fullWidth
              placeholder="Ej: Juan Ortega"
              error={!!errors.name && touched.name}
              helperText={touched.name && errors.name}
              {...getFieldProps( 'name' )}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: '1em' }}>
            <InputLabel
              htmlFor="lastname"
              sx={{ fontWeight: 700 }}
            >
              Apellidos
            </InputLabel>
            <TextField
              id="lastname"
              variant="outlined"
              fullWidth
              placeholder="Ej: Ortega Chichande"
              error={!!errors.lastname && touched.lastname}
              helperText={touched.lastname && errors.lastname}
              {...getFieldProps( 'lastname' )}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: '1em' }}>
            <InputLabel
              htmlFor="email"
              sx={{ fontWeight: 700 }}
            >
              Email
            </InputLabel>
            <TextField
              id="email"
              variant="outlined"
              fullWidth
              placeholder="Ej: juanortega@gmail.com"
              disabled
              {...getFieldProps( 'email' )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              sx={{ width: '13em' }}
              variant="contained"
              type="submit"
              endIcon={
                ( loadingUpdate )
                && ( <CircularProgress size={20} color="secondary" /> )
              }
              disabled={loadingUpdate}
              fullWidth
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserForm;
