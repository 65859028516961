import React from 'react';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  CircularProgress, FormHelperText, Grid, InputLabel, TextField,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ResetValidationSchema } from '../../validators/validatioSchemas';
import { IReset } from '../../interfaces';
import FormDialog from '../../../shared/custom/dialog/FormDialog';
import { setModalResetState } from '../../slice/authSlice';

interface IResetForm {
  handleSendSubmit: ( data: IReset ) => void;
  initialValues?: IReset;
  reinitialize: boolean;
}

const ResetForm: React.FC<IResetForm> = ( props ) => {
  const { handleSendSubmit, initialValues, reinitialize } = props;
  const { loadingReset, modalReset } = useAppSelector(( state ) => state.auth );
  const dispatch = useAppDispatch();
  const {
    getFieldProps, handleSubmit, errors, touched,
  } = useFormik<IReset>({
    initialValues: initialValues ? { ...initialValues } : {
      email: '',
    },
    enableReinitialize: reinitialize,
    validationSchema: ResetValidationSchema,
    onSubmit: async ({
      email,
    }) => {
      const data = {
        email,
      };
      handleSendSubmit( data );
    },
  });
  return (
    <FormDialog
      open={modalReset}
      title="Recupera tu contraseña"
      closeFunction={() => dispatch( setModalResetState( false ))}
    >
      <Box p={5}>
        <form
          onSubmit={handleSubmit}
          style={{ display: 'block', width: '100%' }}
          autoComplete="off"
        >
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="email"
                  sx={{ fontWeight: 700 }}
                >
                  Correo Electrónico
                </InputLabel>
                <TextField
                  id="email"
                  variant="outlined"
                  fullWidth
                  placeholder="Ingresa tu correo electrónico"
                  {...getFieldProps( 'email' )}
                />

                { errors.email && touched.email && (
                  <FormHelperText
                    sx={{ fontWeight: 700, fontSize: 14 }}
                    error
                  >
                    {errors.email}

                  </FormHelperText>
                ) }
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            type="submit"
            endIcon={
              loadingReset && ( <CircularProgress size={20} color="secondary" /> )
            }
            disabled={loadingReset}
            sx={{ marginTop: '3em' }}
            fullWidth
          >
            Enviar
          </Button>
        </form>
      </Box>
    </FormDialog>
  );
};

export default ResetForm;
