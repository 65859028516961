import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISeller, ISellersFilterOptions } from '../interfaces';
import {
  createSeller, deleteSeller, getSellers, getSellersPaginated, getSellersWithFilters,
} from '../actions/sellersActions';

interface ISellersState {
  sellersList: ISeller[];
  seller?: ISeller;
  loadingGet: boolean;
  loadingCreate: boolean;
  loadingUpdate: boolean;
  loadingDelete: boolean;
  modal: boolean;
  modalDelete: boolean;
  loadingFilter: boolean;
  filterOptions?: ISellersFilterOptions;
  modalFilter: boolean;
  sellersFiltered: ISeller[];
  hasFilter: boolean;
}

const initialState: ISellersState = {
  sellersList: [],
  loadingGet: false,
  loadingCreate: false,
  loadingUpdate: false,
  loadingDelete: false,
  modal: false,
  modalDelete: false,
  modalFilter: false,
  loadingFilter: false,
  sellersFiltered: [],
  hasFilter: false,
};

const sellersSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    setSellersModalState: ( state, action: PayloadAction<boolean> ) => {
      state.modal = action.payload;
      if ( action.payload === false ) {
        state.seller = undefined;
      }
    },
    setSellersDeleteModalState: ( state, action: PayloadAction<boolean> ) => {
      state.modalDelete = action.payload;
      if ( action.payload === false ) {
        state.seller = undefined;
      }
    },
    addSellerInStore: ( state, action: PayloadAction<ISeller> ) => {
      state.sellersList = [action.payload, ...state.sellersList];
    },
    addSellerForActions: ( state, action: PayloadAction<string> ) => {
      state.seller = state.sellersList.find(( seller ) => seller.id === action.payload );
    },
    deleteSellerInStore: ( state, action: PayloadAction<string> ) => {
      state.sellersList = [...state.sellersList.filter(( item ) => item.id !== action.payload )];
      state.modalDelete = false;
    },
    filterSellersInStore: ( state, action: PayloadAction<ISellersFilterOptions> ) => {
      state.filterOptions = action.payload;
      state.hasFilter = true;
    },
    clearSellersFilter: ( state ) => {
      state.filterOptions = undefined;
      state.hasFilter = false;
      state.sellersFiltered = [];
    },
    setFilterSellersModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalFilter = action.payload;
    },
    searchFilterSellersInStore: ( state, action: PayloadAction<string> ) => {
      if ( action.payload === '' ) {
        state.sellersFiltered = [];
        state.hasFilter = false;
      } else {
        state.sellersFiltered = state.sellersList.filter(( seller ) => seller.name.toLowerCase().includes( action.payload.toLowerCase()) || seller.lastname.toLowerCase().includes( action.payload.toLowerCase()) || seller.email.toLowerCase().includes( action.payload.toLowerCase()));
        state.hasFilter = true;
      }
    },
  },
  extraReducers: ( builder ) => {
    builder
      .addCase( getSellers.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getSellers.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.sellersList = action.payload!;
      })
      .addCase( getSellers.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( getSellersPaginated.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getSellersPaginated.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.sellersList = [...state.sellersList, ...action.payload!];
      })
      .addCase( getSellersPaginated.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( createSeller.pending, ( state ) => {
        state.loadingCreate = true;
      })
      .addCase( createSeller.fulfilled, ( state ) => {
        state.loadingCreate = false;
      })
      .addCase( createSeller.rejected, ( state ) => {
        state.loadingCreate = false;
      });
    builder
      .addCase( getSellersWithFilters.pending, ( state ) => {
        state.loadingFilter = true;
      })
      .addCase( getSellersWithFilters.fulfilled, ( state, action ) => {
        state.loadingFilter = false;
        state.sellersFiltered = action.payload!;
      })
      .addCase( getSellersWithFilters.rejected, ( state ) => {
        state.loadingFilter = false;
      });
    builder
      .addCase( deleteSeller.pending, ( state ) => {
        state.loadingDelete = true;
      })
      .addCase( deleteSeller.fulfilled, ( state ) => {
        state.loadingDelete = false;
      })
      .addCase( deleteSeller.rejected, ( state ) => {
        state.loadingDelete = false;
      });
  },
});

export const {
  setSellersModalState, addSellerInStore, addSellerForActions, deleteSellerInStore, setSellersDeleteModalState, filterSellersInStore, clearSellersFilter, setFilterSellersModal, searchFilterSellersInStore,
} = sellersSlice.actions;

export default sellersSlice.reducer;
