import React, { useEffect, useState } from 'react';
import {
  GridColDef, GridRenderCellParams, GridRowsProp, GridValueGetterParams,
} from '@mui/x-data-grid';
import { Box, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IBook } from '../interfaces';
import CardTableWrapper from '../../shared/custom/dataGrid/CardTableWrapper';
import CustomDataGrid from '../../shared/custom/dataGrid/CustomDataGrid';
import TableMenu from '../../shared/custom/tableMenu.tsx/TableMenu';
import BooksStatus from '../../shared/custom/status/BooksStatus';
import { addBookWithIdForActions, setDeleteBooksModal, setCreateEditBooksModal } from '../slice/booksSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import InfoDialog from '../../shared/custom/dialog/InfoDialog';
import { deleteBook } from '../actions/booksActions';

interface IBooksTableProps {
  books: IBook[];
}

const BooksTable = ({ books }: IBooksTableProps ) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    loadingDelete, modalDelete, book, loadingGet, loadingFilter,
  } = useAppSelector(( state ) => state.book );

  const [booksTable, setBooksTable] = useState<IBook[]>([]);

  const handleGoToView = ( id: string ) => {
    dispatch( addBookWithIdForActions( id ));
    navigate( '../ver-libro' );
  };

  const handleOpenEditModal = ( id: string ) => {
    dispatch( addBookWithIdForActions( id ));
    dispatch( setCreateEditBooksModal( true ));
  };

  const handleOpenDeleteModal = ( id: string ) => {
    dispatch( addBookWithIdForActions( id ));
    dispatch( setDeleteBooksModal( true ));
  };

  const acceptDelete = ( ) => {
    dispatch( deleteBook( book?.id || '' ));
  };

  const [perPage, setPerPage] = useState( 25 );
  const rows: GridRowsProp = booksTable.map(
    ( b: IBook ) => ({
      id: b.id,
      name: b.name,
      description: b.description,
      category: b.category,
      subCategory: b.subCategory,
      status: b.status,
      more: b.id,
      actions: b,
    }),
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NOMBRE',
      headerClassName: 'theme__header',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'DESCRIPCIÓN',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'category',
      headerName: 'CATEGORÍA',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'subCategory',
      headerName: 'SUBCATEGORÍA',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'ESTADO',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ( params: GridRenderCellParams<number> ) => {
        const status = params.value;
        return ( <BooksStatus status={status} /> );
      },
    },
    {
      field: 'actions',
      headerName: 'ACCIONES',
      width: 200,
      headerClassName: 'theme__header',
      align: 'center',
      headerAlign: 'center',
      renderCell: ( params: GridValueGetterParams ) => {
        const idBook = params.id as string;
        return (
          <TableMenu
            id={idBook}
            handleOpenView={handleGoToView}
            handleOpenEdit={handleOpenEditModal}
            handleOpenDelete={handleOpenDeleteModal}
            canSee
            canEdit
          />
        );
      },
    },
  ];

  useEffect(() => {
    setBooksTable( books );
  }, [books]);

  return (
    <>
      <CardTableWrapper>
        <CustomDataGrid
          noDataMessage="Sin Libros"
          rows={rows}
          columns={columns}
          pageSize={perPage}
          onPageSizeChange={setPerPage}
          rowsPerPageOptions={[25, 50, 75, 100]}
          loading={loadingGet || loadingFilter}
        />
      </CardTableWrapper>
      <InfoDialog
        open={modalDelete}
        title="Eliminar Libro"
        contentText="Estás seguro que deseas eliminar este libro, esta acción es irreversible."
        closeFunction={() => dispatch( setDeleteBooksModal( !!loadingDelete ))}
      >
        <Box textAlign="center" pt={3}>
          <Button onClick={() => acceptDelete()} disabled={loadingDelete} endIcon={loadingDelete && ( <CircularProgress size={10} /> )}>
            ¡Sí, Eliminar!
          </Button>
        </Box>
      </InfoDialog>
    </>
  );
};

export default BooksTable;
