import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';

const CustomNavbarInfo = () => {
  const { navbarInfo } = useAppSelector(( state ) => state.ui );
  return (
    <Box>
      <Typography variant="h5" fontWeight="bold" color={grey[800]}>
        {navbarInfo.title}
      </Typography>
      {!navbarInfo.noPrintItems && (
        <Typography variant="body1" fontWeight="bold" color={grey[600]}>
          <Link
            to="/"
            style={{
              textDecoration: 'none',
              color: '#757575',
              fontWeight: 'bold',
            }}
          >
            Inicio
          </Link>
          {' '}
          {navbarInfo.subItems.map(( item ) => `- ${item} ` )}
        </Typography>
      )}
    </Box>
  );
};

export default CustomNavbarInfo;
