import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import Profile from '../pages/Profile';

const ProfileRouter = () => (
  <Routes>
    <Route path="/" element={<Profile title="Perfil de usuario" />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default ProfileRouter;
