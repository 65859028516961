import { Box, Button, Pagination } from '@mui/material';
import { useGridApiContext, useGridState } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import usePaginatedBooks from '../../../books/hooks/usePaginatedBooks';
import usePaginatedStudents from '../../../students/hooks/usePaginatedStudents';
import usePaginatedSellers from '../../../sellers/hooks/usePaginatedSellers';
import { setPage } from '../../slice/uiSlice';
import usePaginatedCodes from '../../../codes/hooks/usePaginatedCodes';
import usePaginatedCodeGenerations from '../../../codes/hooks/usePaginatedCodeGenerations';
import usePaginatedInstitutions from '../../../institutions/hooks/usePaginatedInstitutions';

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const [state] = useGridState( apiRef );
  const location = useLocation();
  const getBooks = usePaginatedBooks( true );
  const getStudents = usePaginatedStudents();
  const getSellers = usePaginatedSellers();
  const getCodes = usePaginatedCodes();
  const getCodeGenerations = usePaginatedCodeGenerations();
  const getInstitutions = usePaginatedInstitutions();
  const { loadingGet: loadingBook } = useAppSelector(( stateStore ) => stateStore.book );
  const { pagination, page } = useAppSelector(( stateStore ) => stateStore.ui );
  const dispatch = useAppDispatch();
  const clickAgain = () => {
    const buttons = document.getElementsByClassName( 'MuiPaginationItem-previousNext' );
    const lastButton = buttons[buttons.length - 1] as HTMLElement;
    return lastButton.setAttribute( 'disabled', pagination ? 'false' : 'true' );
  };
  const getMoreData = async ( value: number ) => {
    const lastPageValidator = value > state.pagination.pageCount;
    dispatch( setPage( value - 1 ));
    if ( lastPageValidator ) {
      switch ( true ) {
        case location.pathname === '/admin/libros':
          await getBooks();
          break;
        case location.pathname === '/admin/estudiantes':
          await getStudents();
          break;
        case location.pathname === '/admin/vendedores':
          await getSellers();
          break;
        case location.pathname === '/vendedor/codigos':
          await getCodes();
          break;
        case location.pathname === '/vendedor/ordenes':
          await getCodeGenerations();
          break;
        case location.pathname === '/admin/ordenes':
          await getCodeGenerations();
          break;
        case location.pathname === '/admin/instituciones':
          await getInstitutions();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    clickAgain();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return (
    <Box display="flex">
      <Pagination
        shape="rounded"
        color="primary"
        sx={{
          '& .Mui-selected': { color: 'white!important' },
          '& .MuiPagination-ul > li:last-child': {
            display: ( state.pagination.page + 1 ) === state.pagination.pageCount ? 'none' : 'block',
          },
        }}
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={( event, value ) => { getMoreData( value ); }}
        disabled={loadingBook}
      />
      {( page + 1 ) === state.pagination.pageCount && (
        <Button
          sx={{
            maxWidth: '32px',
            maxHeight: '32px',
            minWidth: '10px',
            borderRadius: '4px',
            ml: '3px',
            backgroundColor: 'transparent',
            color: 'grey!important',
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:disabled': {
              backgroundColor: 'transparent',
              color: 'rgba(0, 0, 0, 0.282)!important',
            },
          }}
          onClick={() => getMoreData( state.pagination.pageCount + 1 )}
          disabled={!pagination}
        >
          <NavigateNextIcon width={6.18} />
        </Button>
      )}
    </Box>
  );
};

export default CustomPagination;
