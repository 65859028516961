import { Card, CardProps } from '@mui/material';
import { styled } from '@mui/styles';

export const CustomAuthCard = styled(
  ({ ...props }: CardProps ) => (
    <Card {...props} />
  ),
)(() => ({
  backgroundColor: '#ffffff',
  borderRadius: 10,
  padding: '20px 0px',
  maxWidth: 400,
}));
