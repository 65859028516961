import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IActivity } from '../interfaces';

const activityConverter = {
  toFirestore: ( activity: IActivity ) => ({
    ...activity,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IActivity => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      name,
      description,
      rubric,
      type,
      subType,
      bookId,
      unitId,
    } = data;

    return {
      id: snapshot.id,
      name,
      description,
      rubric,
      type,
      subType,
      bookId,
      unitId,
      created,
    } as IActivity;
  },
};

export default activityConverter;
