import * as yup from 'yup';
import { IActivityType } from '../../shared/interfaces';

export const informationValidationSchema = yup.object().shape({
  name: yup.string().required( 'El nombre es requerido' ),
  description: yup.string().required( 'La descripción es requerida' ),
});

export const typeValidationSchema = yup.object().shape({
  type: yup.mixed().required( 'El tipo es requerido' ),
  rubric: yup.mixed().when( 'type', ( type: IActivityType ) => {
    if (
      type !== null
      && ( type.value === 'complete'
      || type.value === 'dragAndDrop'
      || type.value === 'dragAndDropImages'
      || type.value === 'dragAndDropOrder'
      || type.value === 'dragAndDropCorrect'
      || type.value === 'openQuestions' )) {
      return yup.mixed().required( 'La rúbrica es requerida debido a ser una actividad interactiva' ).nullable();
    }
    return yup.mixed().nullable();
  }),
});

export const questionValidationSchema = yup.object().shape({
  sentence: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'complete' || type === 'dragAndDrop' || type === 'dragAndDropImages' || type === 'file' || type === 'openQuestions' )) {
      return yup.mixed().required( 'La oración es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  number1: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' || type === 'dragAndDropOrder' )) {
      return yup.mixed().required( 'La imagen número 1 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  number2: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' || type === 'dragAndDropOrder' )) {
      return yup.mixed().required( 'La imagen número 2 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  number3: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' || type === 'dragAndDropOrder' )) {
      return yup.mixed().required( 'La imagen número 3 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  number4: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' || type === 'dragAndDropOrder' )) {
      return yup.mixed().required( 'La imagen número 4 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  response1: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' )) {
      return yup.mixed().required( 'La respuesta número 1 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  response2: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' )) {
      return yup.mixed().required( 'La respuesta número 2 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  response3: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' )) {
      return yup.mixed().required( 'La respuesta número 3 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  response4: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' )) {
      return yup.mixed().required( 'La respuesta número 4 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  indications: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'file' )) {
      return yup.mixed().required( 'Las indicaciones son requeridas' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  correctOption: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDrop' || type === 'dragAndDropImages' )) {
      return yup.mixed().required( 'La respuesta correcta es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  incorrectOption1: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDrop' || type === 'dragAndDropImages' )) {
      return yup.mixed().required( 'La respuesta incorrecta número 1 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  incorrectOption2: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDrop' || type === 'dragAndDropImages' )) {
      return yup.mixed().required( 'La respuesta incorrecta número 2 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  incorrectOption3: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDrop' || type === 'dragAndDropImages' )) {
      return yup.mixed().required( 'La respuesta incorrecta número 3 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
});
export const editQuestionValidationSchema = yup.object().shape({
  sentence: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'complete' || type === 'dragAndDrop' || type === 'dragAndDropImages' || type === 'file' || type === 'openQuestions' )) {
      return yup.mixed().required( 'La oración es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  number1: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' || type === 'dragAndDropOrder' )) {
      return yup.mixed().required( 'La imagen número 1 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  number2: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' || type === 'dragAndDropOrder' )) {
      return yup.mixed().required( 'La imagen número 2 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  number3: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' || type === 'dragAndDropOrder' )) {
      return yup.mixed().required( 'La imagen número 3 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  number4: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' || type === 'dragAndDropOrder' )) {
      return yup.mixed().required( 'La imagen número 4 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  response1: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' )) {
      return yup.mixed().required( 'La respuesta número 1 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  response2: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' )) {
      return yup.mixed().required( 'La respuesta número 2 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  response3: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' )) {
      return yup.mixed().required( 'La respuesta número 3 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  response4: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDropCorrect' )) {
      return yup.mixed().required( 'La respuesta número 4 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  correctOption: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDrop' || type === 'dragAndDropImages' )) {
      return yup.mixed().required( 'La respuesta correcta es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  incorrectOption1: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDrop' || type === 'dragAndDropImages' )) {
      return yup.mixed().required( 'La respuesta incorrecta número 1 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  incorrectOption2: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDrop' || type === 'dragAndDropImages' )) {
      return yup.mixed().required( 'La respuesta incorrecta número 2 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
  incorrectOption3: yup.mixed().when( 'type', ( type: string ) => {
    if (
      type !== null
      && ( type === 'dragAndDrop' || type === 'dragAndDropImages' )) {
      return yup.mixed().required( 'La respuesta incorrecta número 3 es requerida' ).nullable();
    }
    return yup.mixed().nullable();
  }),
});
