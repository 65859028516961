import { useAppSelector } from '../../../app/hooks';

const useFilterCodesInitialState = () => {
  const { filterOptions } = useAppSelector(( state ) => state.code );
  const { booksList } = useAppSelector(( state ) => state.book );

  const existsBook = booksList.find(( book ) => book.name === filterOptions?.book?.name );

  return {
    book: filterOptions && existsBook ? existsBook : null,
    from: filterOptions ? filterOptions.from : null,
    until: filterOptions ? filterOptions.until : null,
  };
};

export default useFilterCodesInitialState;
