import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getActivations, renewSession, resetPassword, signIn, signUp, updatePhoto, updateUserData,
} from '../actions/authActions';
import { IActivation, IUser } from '../interfaces';

interface IAuthState {
  userInfo?: IUser;
  activations: IActivation[];
  isAuth: boolean,
  loadingSignIn: boolean;
  loadingSignUp: boolean;
  loadingReset: boolean;
  loadingActivations: boolean;
  loadingUpdate: boolean;
  modal: boolean;
  role?: string;
  activationsFiltered: IActivation[];
  modalReset: boolean;
  expirationDate: number | null;
}

const initialState: IAuthState = {
  isAuth: false,
  activations: [],
  loadingSignIn: false,
  loadingSignUp: false,
  loadingActivations: false,
  loadingUpdate: false,
  modal: false,
  activationsFiltered: [],
  loadingReset: false,
  modalReset: false,
  expirationDate: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setExpirationDate: ( state, action: PayloadAction<number> ) => {
      state.expirationDate = action.payload;
    },
    setModalState: ( state, action: PayloadAction<boolean> ) => {
      state.modal = action.payload;
    },
    setModalResetState: ( state, action: PayloadAction<boolean> ) => {
      state.modalReset = action.payload;
    },
    setRole: ( state, action: PayloadAction<string> ) => {
      state.role = action.payload;
    },
    clearAuth: ( state ) => {
      state.role = undefined;
      state.userInfo = undefined;
      state.modal = false;
      state.loadingSignIn = false;
      state.loadingSignUp = false;
      state.loadingActivations = false;
      state.isAuth = false;
    },
    setActivationInStore: ( state, action: PayloadAction<IActivation> ) => {
      state.activations = [...state.activations, action.payload!];
    },
    searchFilterActivationsInStore: ( state, action: PayloadAction<string> ) => {
      if ( action.payload === '' ) {
        state.activationsFiltered = [];
      } else {
        state.activationsFiltered = state.activations.filter(( activation ) => activation.book.name.toLowerCase().includes( action.payload.toLowerCase()));
      }
    },
    orderByActivations: ( state, action: PayloadAction<number> ) => {
      if ( action.payload === 1 ) {
        state.activations.sort(( a, b ) => a.created - b.created );
      }
      if ( action.payload === 2 ) {
        state.activations.sort(( a, b ) => a.book.name.localeCompare( b.book.name ));
      }
    },
  },
  extraReducers: ( builder ) => {
    builder
      .addCase( signIn.pending, ( state ) => {
        state.loadingSignIn = true;
      })
      .addCase( signIn.fulfilled, ( state, action ) => {
        state.loadingSignIn = false;
        state.isAuth = true;
        state.userInfo = action.payload;
      })
      .addCase( signIn.rejected, ( state ) => {
        state.loadingSignIn = false;
        state.userInfo = undefined;
        state.isAuth = false;
      });
    builder
      .addCase( signUp.pending, ( state ) => {
        state.loadingSignUp = true;
      })
      .addCase( signUp.fulfilled, ( state ) => {
        state.loadingSignUp = false;
      })
      .addCase( signUp.rejected, ( state ) => {
        state.loadingSignUp = false;
      });
    builder
      .addCase( getActivations.pending, ( state ) => {
        state.loadingActivations = true;
      })
      .addCase( getActivations.fulfilled, ( state, action ) => {
        state.loadingActivations = false;
        state.activations = action.payload!;
      })
      .addCase( getActivations.rejected, ( state ) => {
        state.loadingActivations = false;
      });
    builder
      .addCase( renewSession.fulfilled, ( state, action ) => {
        state.isAuth = true;
        state.userInfo = action.payload;
      });
    builder
      .addCase( resetPassword.pending, ( state ) => {
        state.loadingReset = true;
      })
      .addCase( resetPassword.fulfilled, ( state ) => {
        state.loadingReset = false;
      })
      .addCase( resetPassword.rejected, ( state ) => {
        state.loadingReset = false;
      });
    builder
      .addCase( updatePhoto.pending, ( state ) => {
        state.loadingUpdate = true;
      })
      .addCase( updatePhoto.fulfilled, ( state, action ) => {
        state.loadingUpdate = false;
        state.userInfo = action.payload!;
      })
      .addCase( updatePhoto.rejected, ( state ) => {
        state.loadingUpdate = false;
      });
    builder
      .addCase( updateUserData.pending, ( state ) => {
        state.loadingUpdate = true;
      })
      .addCase( updateUserData.fulfilled, ( state, action ) => {
        state.loadingUpdate = false;
        state.userInfo = action.payload!;
      })
      .addCase( updateUserData.rejected, ( state ) => {
        state.loadingUpdate = false;
      });
  },
});

export const {
  setModalState, setRole, clearAuth, setActivationInStore, searchFilterActivationsInStore, orderByActivations, setModalResetState, setExpirationDate,
} = authSlice.actions;

export default authSlice.reducer;
