import {
  Card, CardContent, CircularProgress, Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import { getCodeGenerations } from '../actions/codesActions';
import CodesForm from '../components/CodesForm';
import CodeGenerationTable from '../components/CodeGenerationTable';
import { clearCodesFilter, setCodesModalState, setFilterCodeModal } from '../slice/codesSlice';
import { getBooks } from '../../books/actions/booksActions';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import { ICodeGeneration } from '../interfaces';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import TopCardActions from '../../shared/custom/cards/TopCardActions';
import FilterCodeForm from '../components/FilterCodeForm';
import CodesChipsContainer from '../components/CodesChipsContainer';
import { trackEvent } from '../../../helpers/analytics';
import { auth } from '../../firebase';

interface ICodesListProps {
  title: string;
}

const OrdersList = ({ title }: ICodesListProps ) => {
  const dispatch = useAppDispatch();
  const {
    codeGenerationsList, loadingGet, codeGenerationsFiltered, hasFilter, modal, loadingCreate, loadingUpdate, modalFilter, loadingFilter,
  } = useAppSelector(( state ) => state.code );
  const setTitle = useSetTitle({
    title: 'Lista de Códigos',
    subItems: ['Códigos', 'Lista Códigos'],
    noPrintItems: false,
  });
  const [codeGenerations, setCodeGeneration] = useState<ICodeGeneration[]>([]);
  useEffect(() => {
    dispatch( getCodeGenerations());
    dispatch( getBooks());
    dispatch( setNoMoreData( true ));
    dispatch( clearCodesFilter());
    document.title = title;
  }, [title, dispatch]);
  useEffect(() => {
    setCodeGeneration( codeGenerationsFiltered.length > 0 || hasFilter ? codeGenerationsFiltered : codeGenerationsList );
  }, [codeGenerationsFiltered, codeGenerationsList, hasFilter]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      {loadingGet ? (
        <Grid textAlign="center">
          <CircularProgress size={40} />
        </Grid>
      ) : (
        <Card>
          <CardContent>
            <TopCardActions
              buttonText="Agregar Código"
              addOrEditClick={() => {
                dispatch( setCodesModalState( true ));
                trackEvent( 'push_add_code_button_seller', { elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de códigos' });
              }}
              filterClick={() => {
                dispatch( setFilterCodeModal( true ));
                trackEvent( 'push_filter_code_button_seller', { elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de códigos' });
              }}
            >
              <CodesChipsContainer filters={hasFilter} />
            </TopCardActions>
            <CodeGenerationTable codes={codeGenerations} />
          </CardContent>
        </Card>
      )}
      <FormDialog
        open={modal}
        title="Añadir Codigos"
        closeFunction={() => dispatch( setCodesModalState( !!loadingCreate || !!loadingUpdate ))}
      >
        <CodesForm />
      </FormDialog>
      <FormDialog
        open={modalFilter}
        title="Filtrar Códigos"
        closeFunction={() => dispatch( setFilterCodeModal( !!loadingFilter ))}
      >
        <FilterCodeForm />
      </FormDialog>
    </>
  );
};

export default OrdersList;
