import {
  Card, CardContent, CircularProgress, Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import StudentsTable from '../components/StudentsTable';
import { getStudents } from '../actions/studentsActions';
import { clearStudentsFilter, setFilterStudentModal, setStudentsModalState } from '../slice/studentSlice';
import StudentsDialogsContainer from '../components/StudentsDialogsContainer';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import TopCardActions from '../../shared/custom/cards/TopCardActions';
import StudentsChipsContainer from '../components/StudentsChipsContainer';
import { IStudent } from '../interfaces';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import { trackEvent } from '../../../helpers/analytics';
import { auth } from '../../firebase';

interface IStudentsListProps {
  title: string;
}

const StudentsList = ({ title }: IStudentsListProps ) => {
  const dispatch = useAppDispatch();
  const {
    studentsList, loadingGet, hasFilter, loadingFilter, studentsFiltered,
  } = useAppSelector(( state ) => state.student );
  const setTitle = useSetTitle({
    title: 'Lista de Estudiantes',
    subItems: ['Estudiantes', 'Lista Estudiantes'],
    noPrintItems: false,
  });
  const [students, setStudents] = useState<IStudent[]>([]);
  useEffect(() => {
    document.title = title;
    dispatch( getStudents());
    dispatch( setNoMoreData( true ));
    dispatch( clearStudentsFilter());
  }, [title, dispatch]);
  useEffect(() => {
    setStudents( studentsFiltered.length > 0 || hasFilter ? studentsFiltered : studentsList );
  }, [studentsFiltered, studentsList, hasFilter]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      {loadingGet ? (
        <Grid textAlign="center">
          <CircularProgress size={40} />
        </Grid>
      ) : (
        <Card>
          <CardContent>
            <TopCardActions
              buttonText="Agregar Estudiante"
              loader={loadingFilter}
              addOrEditClick={() => {
                dispatch( setStudentsModalState( true ));
                trackEvent( 'push_add_students_button_admin', {
                  elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de Estudiantes',
                });
              }}
              filterClick={() => {
                dispatch( setFilterStudentModal( true ));
                trackEvent( 'push_filter_students_button_admin', {
                  elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de Estudiantes',
                });
              }}
            >
              <StudentsChipsContainer filters={hasFilter} />
            </TopCardActions>
            <StudentsTable students={students} />
          </CardContent>
        </Card>
      )}
      <StudentsDialogsContainer />
    </>
  );
};

export default StudentsList;
