import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IActivation } from '../interfaces';

const activationsConverter = {
  toFirestore: ( book: IActivation ) => ({
    ...book,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IActivation => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      book,
      bookId,
      code,
      status,
      userId,
    } = data;

    return {
      id: snapshot.id,
      book: { ...book },
      bookId,
      code,
      status,
      created,
      userId,
    } as IActivation;
  },
};

export default activationsConverter;
