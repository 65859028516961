import { Routes, Route } from 'react-router-dom';
import NotFound404 from '../../../../shared/Errors/NotFound404';
import UnitsAndPageForBook from '../pages/UnitsAndPageForBook';

const UnitAndTopicsRouter = () => (
  <Routes>
    <Route path="/" element={<UnitsAndPageForBook title="Unidades y Temas" />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default UnitAndTopicsRouter;
