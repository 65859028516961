import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IStudent } from '../interfaces';

const studentConverter = {
  toFirestore: ( student: IStudent ) => ({
    ...student,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IStudent => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      active,
      deleted,
      email,
      identification,
      name,
      lastname,
      phone,
      photo,
      type,
    } = data;

    return {
      id: snapshot.id,
      active,
      deleted,
      email,
      identification,
      name,
      lastname,
      phone,
      photo,
      type,
      created,
    } as IStudent;
  },
};

export default studentConverter;
