/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { IProgress, ISeries, Pair } from '../interfaces';
import { round } from '../../../helpers/round';

interface ILineChartComponent {
  data: IProgress[] | null;
}

const LineChart: React.FC<ILineChartComponent> = ( props ) => {
  const { data } = props;
  const [seriesArray, setSeriesArray] = useState<ISeries[]>([]);
  const options = {
    chart: {
      height: 350,
      type: 'area',
      id: 'areachart-2',
    },
    colors: ['#8ECAE6', '#219EBC', '#023047', '#FFB703', '#FB8500', '#8E4F08'],
    legend: {
      showForSingleSeries: true,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
    },
    stroke: {
      curve: 'straight',
    },
    markers: {
      size: 5,
    },
  } as ApexOptions;
  const series = seriesArray;
  useEffect(() => {
    const datesData: number[] = [];
    const seriesData: ISeries[] = [];
    if ( data != null ) {
      data.forEach(( item ) => {
        if ( item.progress.length > 0 ) {
          const progressData: Pair[] = [];
          item.progress.forEach(( progress ) => {
            let percentagesRounded = 0;
            if ( progress.percentage !== 0 ) {
              percentagesRounded = round( progress.percentage );
            }
            progressData.push({ x: progress.created, y: percentagesRounded });
            datesData.push( progress.created );
          });
          seriesData.push({
            name: item.objective,
            data: progressData,
          });
        }
      });
      setSeriesArray( seriesData );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Chart
      options={options}
      series={series}
      type="area"
      height={350}
    />
  );
};

export default LineChart;
