import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addDoc,
  collection, doc, getDocs, limit, orderBy, query, startAfter, updateDoc, where, getDoc,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { uploadPhoto } from '../../../helpers/uploadPhoto';
import { auth, db } from '../../firebase';
import { setNoMoreData, setPage } from '../../shared/slice/uiSlice';
import bookConverter from '../converters/bookConverter';
import {
  IBook, IBookRegister, IBookUpdate, IFilterOptions,
} from '../interfaces';
import {
  addBookInStore, clearFilter, deleteBookInStore, filterInStore, updateBookInStore,
} from '../slice/booksSlice';

interface IGetWithFiltersAndPaginated{
  options: IFilterOptions | undefined;
  book: IBook;
  INeedSetNoMoreData: boolean;
}

export const getBooks = createAsyncThunk(
  'book/getBooks', async () => {
    try {
      const q = query( collection( db,
        'books' ),
      where( 'deleted', '==', false ),
      orderBy( 'created', 'asc' ), limit( 250 ));
      const querySnapshot = await getDocs(
        q.withConverter( bookConverter ),
      );
      const books: IBook[] = [];
      querySnapshot.forEach( async ( item ) => {
        books.push({ ...item.data() });
      });
      return books;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getBook = createAsyncThunk(
  'book/getBook', async ( bookId: string ) => {
    try {
      const docRef = doc( db, 'books', bookId ).withConverter( bookConverter );
      const docSnap = await getDoc( docRef );
      const bookData = docSnap.data();
      return bookData;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getBooksPaginated = createAsyncThunk(
  'book/getBooksPaginated', async ( data: IGetWithFiltersAndPaginated, { dispatch }) => {
    try {
      toast.info( 'Obteniendo datos' );
      let q = query( collection( db,
        'books' ),
      where( 'deleted', '==', false ),
      orderBy( 'created', 'asc' ),
      limit( 250 ),
      startAfter( new Date( data.book.created )));
      if ( data.options ) {
        if ( data.options.editorial ) {
          q = query( q, where( 'editorial', '==', data.options.editorial ));
        }
        if ( data.options.category ) {
          q = query( q, where( 'category', '==', data.options.category ));
        }
        if ( data.options.subCategory ) {
          q = query( q, where( 'subCategory', '==', data.options.subCategory ));
        }
        if ( data.options.status ) {
          q = query( q, where( 'status', '==', data.options.status.value ));
        }
      }
      const querySnapshot = await getDocs(
        q.withConverter( bookConverter ),
      );
      const books: IBook[] = [];
      querySnapshot.forEach( async ( item ) => {
        books.push({ ...item.data() });
      });
      if ( books.length > 0 ) {
        if ( data.INeedSetNoMoreData ) {
          dispatch( setNoMoreData( true ));
        }
        toast.success( 'Datos Obtenidos' );
      } else {
        dispatch( setNoMoreData( false ));
        dispatch( setPage( 0 ));
        toast.info( 'No hay mas datos' );
      }
      return books;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const createBook = createAsyncThunk(
  'book/creatBook',
  async ( book: IBookRegister, { dispatch }) => {
    try {
      const photoFile = book.photo as File;
      const dataToSend = {
        name: book.name,
        created: new Date(),
        creator: auth.currentUser?.uid || '',
        deleted: false,
        description: book.description,
        category: book.category,
        editorial: book.editorial,
        photo: null,
        status: 0,
        subCategory: book.subCategory,
        url: book.url,
        urlDemo: book.urlDemo,
        statistics: {
          activities: 0,
          topics: 0,
          units: 0,
        },
      };
      const bookCreated = await addDoc(
        collection( db, 'books' ), dataToSend,
      );
      toast.info( 'Empezamos a subir la foto' );
      const url = await uploadPhoto( bookCreated.id, photoFile, 'books' );
      await updateDoc( doc(
        db,
        'books',
        bookCreated.id || '',
      ), { photo: url });
      dispatch( addBookInStore({
        id: bookCreated.id,
        ...dataToSend,
        created: new Date( dataToSend.created ).getTime(),
        photo: url,
        statistics: {
          topics: 0,
          units: 0,
          activities: 0,
        },
      }));
      toast.success( 'Libro agregado correctamente' );
    } catch ( err ) {
      toast.error( 'A ocurrido un error.' );
    }
  },
);

export const updateBook = createAsyncThunk(
  'book/updateBook',
  async ( book: IBookUpdate, { dispatch }) => {
    try {
      // Upload Photo for get download Url
      let url = '';
      if ( book.newPhoto ) {
        const photoFile = book.photo as File;
        toast.info( 'Empezamos a subir la foto' );
        url = await uploadPhoto( book.previousBook.id, photoFile, 'books' ) as unknown as string;
      } else {
        url = book.previousBook.photo;
      }
      const toUpdateBook = {
        name: book.name,
        description: book.description,
        category: book.category,
        editorial: book.editorial,
        subCategory: book.subCategory,
        url: book.url,
        photo: url,
        urlDemo: book.urlDemo,
      };
      await updateDoc(
        doc( db, 'books', book.previousBook.id ), toUpdateBook,
      );
      toast.success( 'Libro actualizado correctamente' );
      dispatch( updateBookInStore({
        ...toUpdateBook,
        created: book.previousBook.created,
        id: book.previousBook.id,
        photo: url,
        creator: book.previousBook.creator,
        deleted: book.previousBook.deleted,
        status: book.previousBook.status,
        statistics: {
          topics: book.previousBook.statistics.topics,
          units: book.previousBook.statistics.units,
          activities: book.previousBook.statistics.activities,
        },
      }));
    } catch ( err ) {
      toast.error( 'A ocurrido un error.' );
    }
  },
);

export const deleteBook = createAsyncThunk(
  'book/deleteBook', async ( id: string, { dispatch }) => {
    try {
      const q = doc( db, 'books', id );

      await updateDoc( q, { deleted: true });

      dispatch( deleteBookInStore( id ));

      toast.success( 'Libro eliminado existosamente' );
    } catch ( error ) {
      toast.error( 'Error al intentar eliminar el libro' );
    }
  },
);

export const getBooksWithFilters = createAsyncThunk(
  'book/getBooksWithFilters', async ( options: IFilterOptions, { dispatch }) => {
    try {
      toast.info( 'Obteniendo datos' );
      let q = query( collection( db,
        'books' ),
      where( 'deleted', '==', false ),
      orderBy( 'created', 'asc' ), limit( 250 ));
      if ( options.editorial ) {
        q = query( q, where( 'editorial', '==', options.editorial ));
      }
      if ( options.category ) {
        q = query( q, where( 'category', '==', options.category ));
      }
      if ( options.subCategory ) {
        q = query( q, where( 'subCategory', '==', options.subCategory ));
      }
      if ( options.status ) {
        q = query( q, where( 'status', '==', options.status.value ));
      }
      const querySnapshot = await getDocs(
        q.withConverter( bookConverter ),
      );
      const books: IBook[] = [];
      querySnapshot.forEach( async ( item ) => {
        books.push({ ...item.data() });
      });
      dispatch( filterInStore( options ));
      toast.success( 'Datos obtenidos correctamente' );
      return books;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const deleteFilter = createAsyncThunk(
  'book/deleteFilter', async ( options: IFilterOptions, { dispatch }) => {
    try {
      if ( options.editorial === null && options.category === null && options.subCategory === null && options.status === null ) {
        dispatch( clearFilter());
        dispatch( setNoMoreData( true ));
      } else {
        dispatch( getBooksWithFilters( options ));
      }
    } catch ( error ) {
      console.log( error );
    }
  },
);
