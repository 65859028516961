import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import ActivityForm from '../components/ActivityForm';
import { clearQuestions } from '../slice/activitySlice';

interface ICreateActivityProps {
  title: string;
}

const CreateActivity = ({ title }: ICreateActivityProps ) => {
  const { unit } = useAppSelector(( state ) => state.unitsAndTopics );
  const { book } = useAppSelector(( state ) => state.book );
  const { activity } = useAppSelector(( state ) => state.activity );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setTitle = useSetTitle({
    title: activity ? 'Editar Actividad' : 'Añadir Actividad',
    subItems: ['Libro', book?.name || '', unit?.name || '', activity ? 'Editar Actividad' : 'Añadir Actividad'],
    noPrintItems: false,
  });
  useEffect(() => {
    if ( !unit ) {
      navigate( '/' );
    }
    document.title = title;
  }, [title, navigate, unit]);
  useEffect(() => {
    dispatch( clearQuestions());
  }, [dispatch]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      <ActivityForm />
    </>
  );
};

export default CreateActivity;
