import { useAppDispatch } from '../../../app/hooks';
import { INavbarInfo } from '../interfaces';
import { setTitle } from '../slice/uiSlice';

const useSetTitle = ( data: INavbarInfo ) => {
  const dispatch = useAppDispatch();
  return () => dispatch( setTitle( data ));
};

export default useSetTitle;
