import {
  Card, CardContent, CircularProgress, Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import InstitutionsTable from '../components/InstitutionsTable';
import { getInstitutions } from '../actions/institutionsActions';
import { clearInstitutionsFilter, setFilterInstitutionsModal, setInstitutionsModalState } from '../slice/institutionsSlice';
import InstitutionsDialogsContainer from '../components/InstitutionsDialogsContainer';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import TopCardActions from '../../shared/custom/cards/TopCardActions';
import InstitutionsChipsContainer from '../components/InstitutionsChipsContainer';
import { IInstitution } from '../interfaces';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import { trackEvent } from '../../../helpers/analytics';
import { auth } from '../../firebase';

interface IInstitutionsListProps {
  title: string;
}

const InstitutionsList = ({ title }: IInstitutionsListProps ) => {
  const dispatch = useAppDispatch();
  const {
    institutionsList, loadingGet, loadingFilter, hasFilter, institutionsFiltered,
  } = useAppSelector(( state ) => state.institution );
  const setTitle = useSetTitle({
    title: 'Lista de Instituciones',
    subItems: ['Instituciones', 'Lista Instituciones'],
    noPrintItems: false,
  });
  const [institutions, setInstitutions] = useState<IInstitution[]>([]);
  useEffect(() => {
    document.title = title;
    dispatch( getInstitutions());
    dispatch( setNoMoreData( true ));
    dispatch( clearInstitutionsFilter());
  }, [title, dispatch]);
  useEffect(() => {
    setInstitutions( institutionsFiltered.length > 0 || hasFilter ? institutionsFiltered : institutionsList );
  }, [institutionsFiltered, institutionsList, hasFilter]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      {loadingGet ? (
        <Grid textAlign="center">
          <CircularProgress size={40} />
        </Grid>
      ) : (
        <Card>
          <CardContent>
            <TopCardActions
              buttonText="Agregar Institución"
              loader={loadingFilter}
              addOrEditClick={() => {
                dispatch( setInstitutionsModalState( true ));
                trackEvent( 'push_add_institution_button_admin', {
                  elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de Instituciones',
                });
              }}
              filterClick={() => {
                dispatch( setFilterInstitutionsModal( true ));
                trackEvent( 'push_filter_institution_button_admin', {
                  elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de Instituciones',
                });
              }}
            >
              <InstitutionsChipsContainer filters={hasFilter} />
            </TopCardActions>
            <InstitutionsTable institutions={institutions} />
          </CardContent>
        </Card>
      )}
      <InstitutionsDialogsContainer />
    </>
  );
};

export default InstitutionsList;
