import {
  Box, Card, CircularProgress, Typography,
} from '@mui/material';
import { useAppSelector } from '../../../app/hooks';
import { ReactComponent as Student } from '../../../assets/svg/thatBook/graduatedNoColor.svg';

const CardTotalStudents = () => {
  const { generalStatistics, loadingGeneralStatistic } = useAppSelector(( state ) => state.dashboard );
  const { students } = generalStatistics;
  return (
    <Card sx={{ backgroundColor: '#673AB7', color: 'white', height: '100%' }}>
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box minHeight={60}>
          <Typography variant="h6">
            {loadingGeneralStatistic ? <CircularProgress size={24} color="inherit" /> : (
              <>
                {students.created}
              </>
            )}
          </Typography>
          <Typography variant="subtitle1">Estudiantes</Typography>
        </Box>
        <Student width={50} height={50} fill="white" />
      </Box>
    </Card>
  );
};

export default CardTotalStudents;
