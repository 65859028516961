/* eslint no-nested-ternary: "off"*/
import React, { useState } from 'react';
import {
  Form, Formik, FormikConfig, FormikValues,
} from 'formik';

import { StepIconProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { setLastStep } from '../../../activities/slice/activitySlice';
import StepperFormCard from '../cards/StepperFormCard';

import { styled } from '../../theme';

const CustomStepIconRoot = styled( 'div' )<
{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    fontWeight: 700,
    color: '#fff',
    '& .CustomStepIcon-circle': {
      width: 32,
      height: 32,
      borderRadius: '50%',
      backgroundColor: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...( ownerState.active && {
        backgroundColor: theme.palette.primary.main,
      }),
      '&.completed': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

const CustomStepIcon = ({ currentStep, ...props }:
{ currentStep: number } & StepIconProps ) => {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="CustomStepIcon-circle completed">
          <CheckIcon fontSize="small" />
        </div>
      ) : (
        <div className="CustomStepIcon-circle">
          { currentStep }
        </div>
      )}
    </CustomStepIconRoot>
  );
};

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormStep({ children }: FormikStepProps ) {
  return <>{children}</>;
}

export const FormStepper = (
  { children, ...props }: FormikConfig<FormikValues>,
) => {
  const theme = useTheme();
  const matchesUpMd = useMediaQuery( theme.breakpoints.up( 'md' ));
  const dispatch = useAppDispatch();
  const { loadingCreateActivity, loadingUpdateActivity } = useAppSelector(( state ) => state.activity );

  const childrenArray = React.Children
    .toArray( children ) as React.ReactElement<FormikStepProps>[];

  const [step, setStep] = useState( 0 );
  const currentChild = childrenArray[step];

  const [completed, setCompleted] = useState( false );

  const isLastStep = () => step === childrenArray.length - 1;

  return (
    <StepperFormCard>
      <Formik
        {...props}
        validationSchema={currentChild.props.validationSchema}
        onSubmit={async ( values, helpers ) => {
          if ( isLastStep()) {
            await props.onSubmit( values, helpers );
            setCompleted( true );
          } else {
            setStep(( s ) => s + 1 );
            helpers.setTouched({});
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <Stepper
              activeStep={step}
              sx={{ mb: 4 }}
              orientation={matchesUpMd ? 'horizontal' : 'vertical'}
            >
              {childrenArray.map(( child, index ) => (
                <Step
                  key={child.props.label}
                  completed={step > index || completed}
                >
                  <StepLabel StepIconComponent={
                    () => (
                      <CustomStepIcon
                        currentStep={index + 1}
                        icon={null}
                        completed={step > index || completed}
                        active={step === index}
                      />
                    )
                  }
                  >
                    <Typography
                      variant="h5"
                      component="h2"
                      fontWeight={700}
                      fontSize={18}
                      sx={{ color: 'secondary.main' }}
                    >
                      {child.props.label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            {currentChild}

            <Grid
              container
              spacing={2}
              sx={{ mt: 4 }}
              justifyContent="flex-end"
            >
              {step > 0 && (
                <Grid item>
                  <Button
                    disabled={isSubmitting || loadingUpdateActivity || loadingCreateActivity}
                    onClick={() => {
                      setStep(( s ) => s - 1 );
                      dispatch( setLastStep( false ));
                    }}
                    variant="outlined"
                  >
                    Regresar
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  disabled={isSubmitting || loadingUpdateActivity || loadingCreateActivity}
                  startIcon={
                    ( isSubmitting || loadingUpdateActivity || loadingCreateActivity ) && <CircularProgress size={20} />
                  }
                  type="submit"
                >
                  {( isSubmitting || loadingUpdateActivity || loadingCreateActivity )
                    ? 'Enviando'
                    : isLastStep()
                      ? 'Enviar'
                      : 'Siguiente'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </StepperFormCard>
  );
};
