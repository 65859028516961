import {
  Grid, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { IActivation } from '../../auth/interfaces';
import BookCard from '../../shared/custom/cards/BookCard';
import NewCodeCard from '../../shared/custom/cards/NewCodeCard';
import TopCardActions from '../../shared/custom/cards/TopCardActions';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import { setActivateCodeModal } from '../slice/dashboardSlice';
import ActivationCodeForm from '../components/ActivationCodeForm';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import { clearActivities } from '../../activities/slice/activitySlice';
import { clearUnitsAndTopics } from '../../books/subModule/units/slice/unitsAndTopicsSlice';

interface IStudentDashboardProps {
  title: string;
}

const StudentDashboard = ({ title }: IStudentDashboardProps ) => {
  const { activations: activationsInStore, activationsFiltered } = useAppSelector(( state ) => state.auth );
  const { modalActivateCode } = useAppSelector(( state ) => state.dashboard );
  const dispatch = useAppDispatch();
  const [activations, setActivations] = useState<IActivation[]>([]);
  const setTitle = useSetTitle({
    title: 'Estadisticas',
    subItems: [],
    noPrintItems: true,
  });
  useEffect(() => {
    document.title = title;
  }, [title]);
  useEffect(() => {
    let already = false;
    if ( !already ) {
      setActivations( activationsFiltered.length > 0 ? activationsFiltered : activationsInStore );
      already = true;
    }
  }, [activationsInStore, activationsFiltered]);
  useEffect(() => {
    setTitle();
    dispatch( clearActivities());
    dispatch( clearUnitsAndTopics());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTitle]);
  return (
    <>
      <Grid container spacing={2} display="flex" justifyContent="center">
        <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
          <Grid container spacing={5} justifyContent="space-between">
            <Grid item xs={12} display="flex">
              <Typography fontWeight="bold" whiteSpace="nowrap" mr={1} fontSize={25} display="flex" alignItems="center">
                Libros
                (
                {activations && `${activations.length}`}
                )
              </Typography>
              <TopCardActions
                onlySearch
                hideFilter
                sort
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={11}>
          <Grid container spacing={5}>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <NewCodeCard />
            </Grid>
            {activations ? activations.map(( activation: IActivation, index: number ) => (
              <Grid item xs={6} sm={6} md={4} lg={3} key={index.toString()}>
                <BookCard book={activation.book} />
              </Grid>
            )) : 'No hay libros'}
          </Grid>
        </Grid>
      </Grid>
      <FormDialog
        open={modalActivateCode}
        title="Activar Código"
        closeFunction={() => dispatch( setActivateCodeModal( false ))}
      >
        <ActivationCodeForm />
      </FormDialog>
    </>
  );
};

export default StudentDashboard;
