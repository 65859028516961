/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useDrag } from 'react-dnd';
import { IItem } from '../../interfaces';

interface IProps {
  item: IItem;
  setItems: ( currenItem: IItem, column1: string, column2?: string ) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  id: number;
}

const ItemMovable: React.FC<IProps> = ( props ) => {
  const {
    setItems, item: itemFromParent,
  } = props;
  const changeItemColumn = (
    currenItem: IItem, column1: string, column2?: string,
  ): void => {
    setItems( currenItem, column1, column2 );
  };

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'ITEM',
    item: { name: itemFromParent.name },
    end: ( item, monitor ) => {
      const dropResult = monitor.getDropResult<any>();
      if ( dropResult ) {
        switch ( dropResult.id ) {
          case 'options':
            changeItemColumn( itemFromParent, 'options' );
            break;
          case 'response1':
            changeItemColumn( itemFromParent, 'response1', 'options' );
            break;
          case 'response2':
            changeItemColumn( itemFromParent, 'response2', 'options' );
            break;
          case 'response3':
            changeItemColumn( itemFromParent, 'response3', 'options' );
            break;
          case 'response4':
            changeItemColumn( itemFromParent, 'response4', 'options' );
            break;
          default:
            break;
        }
      } else {
        changeItemColumn( itemFromParent, 'options' );
      }
    },
    collect: ( monitor ) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Box sx={{
      backgroundColor: isDragging ? '#000' : '',
      borderRadius: 1,
      mb: 1,
      p: 1,
      opacity: isDragging ? 0.6 : 1,
      color: isDragging ? '#fff' : '#000',
      border: '1px solid #000',
      textAlign: 'center',
      width: '40px',
    }}
    >
      <Box ref={dragPreview} textAlign="center">
        <Typography ref={drag}>{itemFromParent.name}</Typography>
      </Box>
    </Box>
  );
};

export default ItemMovable;
