import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { IFileContainer, IQuestion } from '../interfaces';
import { ParentForm } from '../../shared/custom/stepper/FormikQuestionsSeparator';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import useQuestionInitialState from '../hooks/useQuestionInitialState';
import {
  addQuestionBeforeSendInStore, clearQuestion, setCreateOrEditQuestionModal, updateQuestionInStore,
} from '../slice/activitySlice';
import FileFields from './QuestionTypes/FileFields';
import CompleteFields from './QuestionTypes/CompleteFields';
import DragAndDropFields from './QuestionTypes/DragAndDropFields';
import DragAndDropImagesFields from './QuestionTypes/DragAndDropImagesFields';
import OpenQuestionsFields from './QuestionTypes/OpenQuestionsFields';
import DragAndDropOrderFields from './QuestionTypes/DragAndDropOrderFields';
import DragAndDropCorrectFields from './QuestionTypes/DragAndDropCorrectFields';
import { editQuestionValidationSchema, questionValidationSchema } from '../validators/activitiesValidationSchema';
import Embed from './QuestionTypes/Embed';

interface IQuestionForm2Props {
  sendFiles: ( files: IFileContainer[]) => void;
}

const QuestionForm2 = ({ sendFiles }: IQuestionForm2Props ) => {
  const { question: questionInStore, group } = useAppSelector(( state ) => state.activity );
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<IFileContainer[]>([]);
  const initial = useQuestionInitialState();
  const [initialState, setInitialState] = useState<IQuestion>( initial );
  const setFilesFunction = ( data: IFileContainer ) => {
    const exist = files.filter(( file ) => file.id !== data.id );
    setFiles([...exist, data]);
  };
  useEffect(() => {
    setInitialState( initial );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionInStore]);

  return (
    <ParentForm
      enableReinitialize
      initialValues={initialState}
      validationSchema={questionInStore ? editQuestionValidationSchema : questionValidationSchema}
      onSubmit={async ( formValues, helpers ) => {
        if ( formValues.id === '' ) formValues.id = v4();
        formValues.groupId = group?.id || '';
        formValues.type = group?.type || '';
        if ( questionInStore ) {
          dispatch( updateQuestionInStore( formValues as IQuestion ));
          dispatch( clearQuestion());
          toast.info( 'Pregunta Actualizada' );
        } else {
          dispatch( addQuestionBeforeSendInStore( formValues as IQuestion ));
          toast.info( 'Pregunta Creada' );
        }
        if ( files.length > 0 ) {
          sendFiles( files );
        }
        dispatch( setCreateOrEditQuestionModal( false ));
        helpers.resetForm();
      }}
    >
      {group?.type !== null && group?.type === 'file' && (
        <FileFields passingFile={setFilesFunction} />
      )}
      {group?.type !== null && group?.type === 'complete' && (
        <CompleteFields />
      )}
      {group?.type !== null && group?.type === 'dragAndDrop' && (
        <DragAndDropFields />
      )}
      {group?.type !== null && group?.type === 'dragAndDropImages' && (
        <DragAndDropImagesFields passingFile={setFilesFunction} />
      )}
      {group?.type !== null && group?.type === 'dragAndDropOrder' && (
        <DragAndDropOrderFields passingFile={setFilesFunction} />
      )}
      {group?.type !== null && group?.type === 'openQuestions' && (
        <OpenQuestionsFields />
      )}
      {group?.type !== null && group?.type === 'dragAndDropCorrect' && (
        <DragAndDropCorrectFields passingFile={setFilesFunction} />
      )}
      {group?.type !== null && group?.type === 'embed' && (
        <Embed />
      )}
    </ParentForm>
  );
};

export default QuestionForm2;
