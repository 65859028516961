import AccessDenied403 from '../../modules/shared/Errors/AccessDenied403';
import { useAppSelector } from '../../app/hooks';

interface Props {
  children: JSX.Element;
  canAccess: string[];
}

const RoleGuard = ({ children, canAccess }: Props ) => {
  const { role } = useAppSelector(( state ) => state.auth );

  return canAccess.includes( role! ) ? children : <AccessDenied403 />;
};

export default RoleGuard;
