import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import InfoDialog from '../../shared/custom/dialog/InfoDialog';
import NavbarAuth from '../../shared/Navbar/NavbarAuth';
import { resetPassword } from '../actions/authActions';
import AuthLayout from '../components/AuthLayout';
import { CustomAuthCard } from '../components/CustomAuthCard';
import LoginForm from '../components/Login/LoginForm';
import RegisterForm from '../components/Register/RegisterForm';
import ResetForm from '../components/Reset/ResetForm';
import { IReset } from '../interfaces';
import { setModalState } from '../slice/authSlice';

interface ILoginProps {
  title: string
}

const Auth = ({ title }: ILoginProps ) => {
  const dispatch = useAppDispatch();
  const { modal, loadingSignUp } = useAppSelector(( state ) => state.auth );
  const handleResetPassword = ( data: IReset ) => {
    dispatch( resetPassword( data ));
  };
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <>
      <NavbarAuth />
      <AuthLayout>
        <CustomAuthCard>
          <LoginForm />
        </CustomAuthCard>
      </AuthLayout>
      <InfoDialog
        open={modal}
        title="Registrate"
        contentText="Rellena la información solicitada"
        closeFunction={() => dispatch( setModalState( !!loadingSignUp ))}
      >
        <RegisterForm />
      </InfoDialog>
      <ResetForm handleSendSubmit={handleResetPassword} reinitialize={false} />
    </>
  );
};

export default Auth;
