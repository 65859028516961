import {
  Autocomplete,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { IFilterOptions } from '../interfaces';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setFilterBooksModal } from '../slice/booksSlice';
import { editorials } from '../../shared/Common/Editorials';
import { categories } from '../../shared/Common/Categories';
import { subCategories } from '../../shared/Common/SubCategories';
import useFilterBooksInitialState from '../hooks/useFilterBooksInitialState';
import { Status } from '../../shared/Common/Status';
import { getBooksWithFilters } from '../actions/booksActions';

const FilterBoooksForm = () => {
  const dispatch = useAppDispatch();
  const { loadingFilter } = useAppSelector(( state ) => state.book );
  const {
    handleSubmit, errors, touched, setFieldValue, resetForm, values,
  } = useFormik<IFilterOptions>({
    initialValues: useFilterBooksInitialState(),
    enableReinitialize: true,
    onSubmit: async ( formValues ) => {
      dispatch( getBooksWithFilters( formValues ));
      dispatch( setFilterBooksModal( false ));
      resetForm();
    },
  });
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <CardContent sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={10}>
            <InputLabel
              htmlFor="editorial"
              error={touched.editorial && !!errors.editorial}
            >
              <Typography fontWeight="bold" fontSize={12}>Editorial</Typography>
            </InputLabel>
            <Autocomplete
              disablePortal
              id="editorial"
              options={editorials}
              fullWidth
              size="small"
              isOptionEqualToValue={( option, value ) => option === value}
              renderInput={( params ) => (
                <TextField
                  {...params}
                  placeholder="Escoja un Editorial"
                  error={touched.editorial && !!errors.editorial}
                  helperText={touched.editorial && errors.editorial}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                />
              )}
              value={values.editorial}
              onChange={( _, value ) => {
                setFieldValue( 'editorial', value );
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <InputLabel
              htmlFor="category"
              error={touched.category && !!errors.category}
            >
              <Typography fontWeight="bold" fontSize={12}>Categoría</Typography>
            </InputLabel>
            <Autocomplete
              disablePortal
              id="category"
              options={categories}
              fullWidth
              size="small"
              isOptionEqualToValue={( option, value ) => option === value}
              renderInput={( params ) => (
                <TextField
                  {...params}
                  placeholder="Escoja una Categoría"
                  error={touched.category && !!errors.category}
                  helperText={touched.category && errors.category}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                />
              )}
              value={values.category}
              onChange={( _, value ) => {
                setFieldValue( 'category', value );
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <InputLabel
              htmlFor="subCategory"
              error={touched.subCategory && !!errors.subCategory}
            >
              <Typography fontWeight="bold" fontSize={12}>Subcategoría</Typography>
            </InputLabel>
            <Autocomplete
              disablePortal
              id="subCategory"
              options={subCategories}
              fullWidth
              size="small"
              isOptionEqualToValue={( option, value ) => option === value}
              renderInput={( params ) => (
                <TextField
                  {...params}
                  placeholder="Escoja una Subcategoría"
                  error={touched.subCategory && !!errors.subCategory}
                  helperText={touched.subCategory && errors.subCategory}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                />
              )}
              value={values.subCategory}
              onChange={( _, value ) => {
                setFieldValue( 'subCategory', value );
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <InputLabel
              htmlFor="status"
              error={touched.status && !!errors.status}
            >
              <Typography fontWeight="bold" fontSize={12}>Estado</Typography>
            </InputLabel>
            <Autocomplete
              disablePortal
              id="status"
              options={Status}
              fullWidth
              size="small"
              isOptionEqualToValue={( option, value ) => option.label === value.label}
              renderInput={( params ) => (
                <TextField
                  {...params}
                  placeholder="Escoja un estado"
                  error={touched.status && !!errors.status}
                  helperText={touched.status && errors.status}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                />
              )}
              value={values.status}
              onChange={( _, value ) => {
                setFieldValue( 'status', value );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center">
          <Grid item xs={10} display="flex" justifyContent="center" gap={2} sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingFilter}
              onClick={() => dispatch( setFilterBooksModal( false ))}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingFilter}
              endIcon={loadingFilter && ( <CircularProgress size={10} /> )}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

export default FilterBoooksForm;
