import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

interface Props {
  children: JSX.Element;
}

const PublicGuard = ({ children }: Props ) => {
  const { isAuth } = useAppSelector(( state ) => state.auth );
  const { role } = useAppSelector(( state ) => state.auth );

  const goToRoleDashboard = ( ) => {
    if ( role === 'Admin' ) {
      return <Navigate to="/admin/dashboard" />;
    }
    if ( role === 'Seller' ) {
      return <Navigate to="/vendedor/dashboard" />;
    }
    return <Navigate to="/estudiante/dashboard" />;
  };

  return !isAuth ? children : goToRoleDashboard();
};

export default PublicGuard;
