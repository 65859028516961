import Grid from '@mui/material/Grid';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const StepperFormCard = ({ children }: Props ) => (
  <Grid
    container
    sx={{
      p: 5, bgcolor: '#fff', borderRadius: '25px', boxShadow: 'shadows.1',
    }}
  >
    <Grid item xs={12}>
      { children }
    </Grid>
  </Grid>
);

export default StepperFormCard;
