import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../modules/auth/slice/authSlice';
import uiSlice from '../modules/shared/slice/uiSlice';
import booksSlice from '../modules/books/slice/booksSlice';
import studentSlice from '../modules/students/slice/studentSlice';
import sellersSlice from '../modules/sellers/slice/sellersSlice';
import codesSlice from '../modules/codes/slice/codesSlice';
import activitySlice from '../modules/activities/slice/activitySlice';
import unitsAndTopicsSlice from '../modules/books/subModule/units/slice/unitsAndTopicsSlice';
import dashboardSlice from '../modules/dashboard/slice/dashboardSlice';
import institutionsSlice from '../modules/institutions/slice/institutionsSlice';
import profileSlice from '../modules/profile/slices/profileSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    ui: uiSlice,
    book: booksSlice,
    student: studentSlice,
    seller: sellersSlice,
    code: codesSlice,
    activity: activitySlice,
    unitsAndTopics: unitsAndTopicsSlice,
    dashboard: dashboardSlice,
    institution: institutionsSlice,
    profile: profileSlice,
  },
  middleware: ( getDefaultMiddleware ) => getDefaultMiddleware({
    serializableCheck: false,
  }),

});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
