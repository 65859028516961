import { IconButton, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../app/hooks';
import {
  setCreateEditTopicsModal, setCreateEditUnitsModal, addUnitWithIdForActions, setDeleteUnitModal,
} from '../slice/unitsAndTopicsSlice';

interface IUnitMenuProps {
  id: string;
}

const UnitMenu = ({
  id,
}: IUnitMenuProps ) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );
  const open = Boolean( anchorEl );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = ( event: MouseEvent<HTMLButtonElement> ) => {
    setAnchorEl( event.currentTarget );
  };

  const handleClose = () => {
    setAnchorEl( null );
  };

  const handleOpenEditUnit = () => {
    setAnchorEl( null );
    dispatch( setCreateEditUnitsModal( true ));
    dispatch( addUnitWithIdForActions( id ));
  };

  const handleOpenCreateTopic = () => {
    setAnchorEl( null );
    dispatch( addUnitWithIdForActions( id ));
    dispatch( setCreateEditTopicsModal( true ));
  };

  const handleGoToCreateActivity = () => {
    setAnchorEl( null );
    dispatch( addUnitWithIdForActions( id ));
    navigate( '/admin/actividades/crear' );
  };

  const handleOpenDeleteUnit = () => {
    setAnchorEl( null );
    dispatch( setDeleteUnitModal( true ));
    dispatch( addUnitWithIdForActions( id ));
  };

  return (
    <div>
      <IconButton
        aria-label="settings"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ '& .MuiPaper-root': { borderRadius: '10px', mt: 1 } }}
      >
        <MenuItem onClick={() => handleOpenEditUnit()}>Editar Unidad</MenuItem>
        <MenuItem onClick={() => handleOpenCreateTopic()}>Crear Tema</MenuItem>
        <MenuItem onClick={() => handleGoToCreateActivity()}>Crear Actividad</MenuItem>
        <MenuItem onClick={() => handleOpenDeleteUnit()}>Eliminar Unidad</MenuItem>
      </Menu>
    </div>
  );
};

export default UnitMenu;
