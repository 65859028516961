/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Typography, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useAppSelector } from '../../../../../../app/hooks';
import useTextColor from '../../../../hooks/useTextColor';
import useTextColorDrag from '../../../../hooks/useTextColorDrag';

interface IProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  id: string;
}

const BoxResponse: React.FC<IProps> = ( props ) => {
  const {
    children, title, id,
  } = props;
  const { alreadyQualified } = useAppSelector(( state ) => state.activity );
  const [color, setColor] = useState<boolean | null>( null );
  const borderColor = useTextColorDrag( color );

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    drop: () => ({ name: title, id }),
    collect: ( monitor ) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const handleCreateQuestion = (): JSX.Element => {
    const str = title.split( ' ' );
    return (
      <Grid container spacing={1}>
        {str.map(( item: string, i: number ) => (
          !item.includes( '**' )
            ? (
              <Grid
                item
                xs={12}
                key={i.toString()}
              >
                <img
                  key={i.toString()}
                  src={item}
                  alt={item}
                  style={{ width: '100%', minHeight: '100px', maxHeight: '200px' }}
                />
              </Grid>
            )
            : (
              <Grid
                item
                xs={12}
                key={i.toString()}
              >
                <Box
                  key={i.toString()}
                  sx={{
                    border: `${alreadyQualified ? '2' : '1'}px dashed ${borderColor}`,
                    minHeight: '40px',
                    maxHeight: '40px',
                    overflow: 'hidden',
                    width: '100%',
                    pl: 1,
                    pr: 1,
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    '& > *': {
                      border: '0!important',
                      mb: '0!important',
                    },
                  }}
                >
                  { children }
                </Box>
              </Grid>
            )
        ))}
      </Grid>
    );
  };

  useEffect(() => {
    const data = children as JSX.Element[];
    if ( data.length > 0 && alreadyQualified ) {
      if ( id.includes( data[0].props.item.name )) {
        setColor( true );
      } else {
        setColor( false );
      }
    } else {
      setColor( null );
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [children]);

  return (
    <Grid
      item
      xs={12}
      lg={3}
      display="flex"
      alignItems="center"
      ref={drop}
    >
      {handleCreateQuestion()}
    </Grid>
  );
};

export default BoxResponse;
