import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import { getAllStatistic, getLast5Codes, getLast7days } from '../actions/dashboardAction';
import CardTotalBooks from '../components/CardTotalBooks';
import CardTotalCodes from '../components/CardTotalCodes';
import CardTotalSellers from '../components/CardTotalSellers';
import CardTotalStudents from '../components/CardTotalStudents';
import LineChartCard from '../components/LineChartCard';
import ThreeViewChart from '../components/ThreView/ThreeViewChart';

interface IAdminDashboardProps {
  title: string;
}

const AdminDashboard = ({ title }: IAdminDashboardProps ) => {
  const dispatch = useAppDispatch();
  const setTitle = useSetTitle({
    title: 'Dashboard',
    subItems: ['Dashboard'],
  });
  useEffect(() => {
    document.title = title;
    dispatch( getLast5Codes());
    dispatch( getAllStatistic());
    dispatch( getLast7days());
  }, [title, dispatch]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <Grid container spacing={2} display="flex" justifyContent="center">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <CardTotalStudents />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardTotalSellers />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardTotalBooks />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardTotalCodes />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <LineChartCard />
      </Grid>
      <Grid item xs={4}>
        <ThreeViewChart />
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
