import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IQuestion } from '../interfaces';

const questionConverter = {
  toFirestore: ( question: IQuestion ) => ({
    ...question,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IQuestion => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      groupId,
      sentence,
      answer,
      question,
      type,
      correctOption,
      incorrectOption1,
      incorrectOption2,
      incorrectOption3,
      deleted,
      indications,
      capital,
      accentMark,
      number1,
      number2,
      number3,
      number4,
      response1,
      response2,
      response3,
      response4,
    } = data;

    return {
      id: snapshot.id,
      groupId,
      sentence,
      answer,
      question,
      type,
      correctOption,
      incorrectOption1,
      incorrectOption2,
      incorrectOption3,
      deleted,
      created,
      indications,
      newFile: false,
      capital,
      accentMark,
      number1,
      number2,
      number3,
      number4,
      response1,
      response2,
      response3,
      response4,
    } as IQuestion;
  },
};

export default questionConverter;
