import {
  Autocomplete,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { ICodesFilterOptions } from '../interfaces';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getCodesWithFilters } from '../actions/codesActions';
import { setFilterCodeModal } from '../slice/codesSlice';
import useFilterCodesInitialState from '../hooks/useFilterCodesInitialState';

const FilterCodeForm = () => {
  const dispatch = useAppDispatch();
  const { loadingFilter } = useAppSelector(( state ) => state.code );
  const { booksList } = useAppSelector(( state ) => state.book );
  const {
    handleSubmit, errors, touched, setFieldValue, resetForm, values,
  } = useFormik<ICodesFilterOptions>({
    initialValues: useFilterCodesInitialState(),
    enableReinitialize: true,
    onSubmit: async ( formValues ) => {
      dispatch( getCodesWithFilters( formValues ));
      dispatch( setFilterCodeModal( false ));
      resetForm();
    },
  });
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <CardContent sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={10}>
            <InputLabel
              htmlFor="from"
              error={touched.from && !!errors.from}
            >
              <Typography fontWeight="bold" fontSize={12}>Desde</Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={values.from}
                onChange={( newValue ) => {
                  setFieldValue( 'from', newValue );
                }}
                renderInput={( params ) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={10}>
            <InputLabel
              htmlFor="until"
              error={touched.until && !!errors.until}
            >
              <Typography fontWeight="bold" fontSize={12}>Hasta</Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={values.until}
                onChange={( newValue ) => {
                  setFieldValue( 'until', newValue );
                }}
                renderInput={( params ) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={10}>
            <InputLabel
              htmlFor="book"
              error={touched.book && !!errors.book}
            >
              <Typography fontWeight="bold" fontSize={12}>Libro</Typography>
            </InputLabel>
            <Autocomplete
              disablePortal
              id="book"
              options={booksList}
              fullWidth
              size="small"
              getOptionLabel={( option ) => option.name}
              renderInput={( params ) => (
                <TextField
                  {...params}
                  placeholder="Escoja un Libro"
                  error={touched.book && !!errors.book}
                  helperText={touched.book && errors.book}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                />
              )}
              value={values.book}
              onChange={( _, value ) => {
                if ( value ) setFieldValue( 'book', value );
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center">
          <Grid item xs={10} display="flex" justifyContent="center" gap={2} sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingFilter}
              onClick={() => dispatch( setFilterCodeModal( false ))}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingFilter}
              endIcon={loadingFilter && ( <CircularProgress size={10} /> )}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

export default FilterCodeForm;
