import { Grid, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import TypeCreatorCard from './TypeCreatorCard';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import GroupCard from './GroupCard';
import { IGroupActivity } from '../../interfaces';
import { getGroupsAndQuestionsByActivityId } from '../../actions/activitiesActions';

const TypeContainer = () => {
  const { groups, activity, loadingGetQuestion } = useAppSelector(( state ) => state.activity );
  const dispatch = useAppDispatch();
  useEffect(() => {
    let alreadyConsulted = false;
    if ( activity && !alreadyConsulted ) {
      dispatch( getGroupsAndQuestionsByActivityId( activity?.id || '' ));
      alreadyConsulted = true;
    }
  }, [dispatch, activity]);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <TypeCreatorCard />
      </Grid>
      {loadingGetQuestion ? (
        <Grid item xs={12} lg={4} width="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={50} />
        </Grid>
      ) : (
        <>
          {groups && groups.length > 0 && groups.map(( group: IGroupActivity, index: number ) => group.deleted === false && (
            <Grid item xs={12} lg={4} key={index.toString()}>
              <GroupCard group={group} />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default TypeContainer;
