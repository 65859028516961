import { InputAdornment, TextField } from '@mui/material';
import { ReactComponent as Loupe } from '../../../../assets/svg/thatBook/loupe.svg';

const NavbarSearch = () => (
  <TextField
    id="input-with-icon-textfield"
    size="small"
    placeholder="Buscar..."
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Loupe width={25} height={20} />
        </InputAdornment>
      ),
    }}
    variant="outlined"
  />
);

export default NavbarSearch;
