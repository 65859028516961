import { useState } from 'react';
import {
  GridColDef, GridRenderCellParams, GridRowsProp, GridValueGetterParams,
} from '@mui/x-data-grid';
import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import { IInstitution } from '../interfaces';
import CardTableWrapper from '../../shared/custom/dataGrid/CardTableWrapper';
import CustomDataGrid from '../../shared/custom/dataGrid/CustomDataGrid';
import StudentsStatus from '../../shared/custom/status/StudentsStatus';
import InfoDialog from '../../shared/custom/dialog/InfoDialog';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { addInstitutionForActions, setInstitutionsDeleteModalState, setInstitutionsModalState } from '../slice/institutionsSlice';
import TableMenu from '../../shared/custom/tableMenu.tsx/TableMenu';
import { deleteInstitution } from '../actions/institutionsActions';

interface IInstitutionsTableProps {
  institutions: IInstitution[];
}

const InstitutionsTable = ({ institutions }: IInstitutionsTableProps ) => {
  const dispatch = useAppDispatch();
  const { loadingDelete, modalDelete, institution } = useAppSelector(( state ) => state.institution );

  const handleOpenEditModal = ( id: string ) => {
    dispatch( addInstitutionForActions( id ));
    dispatch( setInstitutionsModalState( true ));
  };

  const handleOpenDeleteModal = ( id: string ) => {
    dispatch( addInstitutionForActions( id ));
    dispatch( setInstitutionsDeleteModalState( true ));
  };

  const acceptDelete = ( ) => {
    dispatch( deleteInstitution( institution?.id || '' ));
  };
  const [perPage, setPerPage] = useState( 25 );
  const rows: GridRowsProp = institutions.map(
    ( b: IInstitution ) => ({
      id: b.id,
      name: b.name,
      email: b.email,
      created: b.created,
      active: b.active,
    }),
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'NOMBRES',
      headerClassName: 'theme__header',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'EMAIL',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'CREADO',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ( params: GridRenderCellParams<number> ) => {
        const date = params.value;
        return (
          <Typography variant="caption">
            {new Date( date ).toLocaleDateString( 'es-ES' )}
          </Typography>
        );
      },
    },
    {
      field: 'active',
      headerName: 'ESTADO',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ( params: GridRenderCellParams<number> ) => {
        const status = params.value;
        return ( <StudentsStatus status={status} /> );
      },
    },
    {
      field: 'actions',
      headerName: 'ACCIONES',
      width: 200,
      headerClassName: 'theme__header',
      align: 'center',
      headerAlign: 'center',
      renderCell: ( params: GridValueGetterParams ) => {
        const idInstitutions = params.id as string;
        return (
          <TableMenu
            id={idInstitutions}
            canEdit
            handleOpenEdit={handleOpenEditModal}
            handleOpenDelete={handleOpenDeleteModal}
          />
        );
      },
    },
  ];

  return (
    <>
      <CardTableWrapper>
        <CustomDataGrid
          noDataMessage="Sin Estudiantes"
          rows={rows}
          columns={columns}
          pageSize={perPage}
          onPageSizeChange={setPerPage}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </CardTableWrapper>
      <InfoDialog
        open={modalDelete}
        title="Eliminar Institución"
        contentText="Estás seguro que deseas eliminar a esta Institución, esta acción es irreversible."
        closeFunction={() => dispatch( setInstitutionsDeleteModalState( !!loadingDelete ))}
      >
        <Box textAlign="center" pt={3}>
          <Button onClick={() => acceptDelete()} disabled={loadingDelete} endIcon={loadingDelete && ( <CircularProgress size={10} /> )}>
            ¡Sí, Eliminar!
          </Button>
        </Box>
      </InfoDialog>
    </>
  );
};

export default InstitutionsTable;
