import {
  Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { IQuestion } from '../../interfaces';

const CompleteFields = () => {
  const { question: questionInStore, group } = useAppSelector(( state ) => state.activity );
  const {
    touched,
    errors,
    getFieldProps,
    setFieldValue,
  } = useFormikContext<
  Pick<IQuestion,
  'sentence' | 'capital' | 'accentMark'>
  >();
  const [capital, setCapital] = useState<boolean>( false );
  const [accentMark, setAccentMark] = useState<boolean>( false );
  useEffect(() => {
    if ( questionInStore ) {
      setCapital( questionInStore.capital );
      setAccentMark( questionInStore.accentMark );
    } else {
      if ( group ) {
        setFieldValue( 'type', group.type );
      }
      setCapital( false );
      setAccentMark( false );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionInStore]);
  return (
    <Grid item xs={12}>
      <InputLabel htmlFor="sentence">Oracion A Completar</InputLabel>
      <TextField
        size="small"
        id="sentence"
        fullWidth
        placeholder="Ingrese la oración a completar"
        error={touched.sentence && !!errors.sentence}
        helperText={touched.sentence && errors.sentence}
        sx={{
          '& .MuiOutlinedInput-root > input': {
            backgroundColor: '#F2F2F2',
          },
        }}
        {...getFieldProps( 'sentence' )}
      />
      <FormHelperText>
        Ejem: Las matemáticas son ((dificiles))
      </FormHelperText>
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              checked={capital}
              onChange={( _, value ) => {
                setFieldValue( 'capital', value );
                setCapital( value );
              }}
            />
          )}
          label="Respetar Mayúsculas"
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={accentMark}
              onChange={( _, value ) => {
                setFieldValue( 'accentMark', value );
                setAccentMark( value );
              }}
            />
          )}
          label="Respetar Tildes"
        />
      </FormGroup>
    </Grid>
  );
};

export default CompleteFields;
