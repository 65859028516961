import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  CircularProgress, FormHelperText, Grid, IconButton, InputLabel, OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordValidationSchema } from '../constants/validation-schemas';
import { useAppSelector } from '../../../app/hooks';
import { IPasswords } from '../interfaces';

interface IPasswordForm{
  handleSendSubmit: ( data: IPasswords ) => void;
  reinitialize: boolean;
}

const PasswordForm: React.FC<IPasswordForm> = ( props ) => {
  const [showPassword, setShowPassword] = useState( false );
  const [showPassword2, setShowPassword2] = useState( false );
  const [showPasswordOld, setShowPasswordOld] = useState( false );
  const theme = useTheme();
  const {
    handleSendSubmit,
    reinitialize,
  } = props;
  const {
    loading,
  } = useAppSelector(( state ) => state.profile );
  const {
    getFieldProps, handleSubmit, errors, touched,
  } = useFormik<IPasswords>({
    initialValues: {
      passwordOld: '',
      password: '',
      password2: '',
    },
    enableReinitialize: reinitialize,
    validationSchema: PasswordValidationSchema,
    onSubmit: async ({
      passwordOld,
      password,
      password2,
    }) => {
      const data = {
        passwordOld,
        password,
        password2,
      };
      handleSendSubmit( data );
    },
  });
  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: 'block', width: '100%' }}
      autoComplete="off"
    >
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ marginBottom: '1em' }}>
            <InputLabel
              htmlFor="passwordOld"
              sx={{ fontWeight: 700 }}
              error={touched.passwordOld && !!errors.passwordOld}
            >
              Contraseña Actual
            </InputLabel>
            <OutlinedInput
              id="passwordOld"
              size="small"
              fullWidth
              type={showPasswordOld ? 'text' : 'password'}
              placeholder="Contraseña"
              error={touched.passwordOld && !!errors.passwordOld}
              endAdornment={(
                <IconButton
                  onClick={() => setShowPasswordOld( !showPasswordOld )}
                >
                  { showPasswordOld ? (
                    <Visibility
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  ) : (
                    <VisibilityOff
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  )}
                </IconButton>
              )}
              {...getFieldProps( 'passwordOld' )}
            />
            { errors.passwordOld && touched.passwordOld && (
              <FormHelperText
                sx={{ padding: '0 1em' }}
                error
              >
                {errors.passwordOld}

              </FormHelperText>
            ) }
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: '1em' }}>
            <InputLabel
              htmlFor="password"
              sx={{ fontWeight: 700 }}
              error={touched.password && !!errors.password}
            >
              Nueva Contraseña
            </InputLabel>
            <OutlinedInput
              id="password"
              size="small"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              placeholder="Contraseña"
              error={touched.password && !!errors.password}
              endAdornment={(
                <IconButton
                  onClick={() => setShowPassword( !showPassword )}
                >
                  { showPassword ? (
                    <Visibility
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  ) : (
                    <VisibilityOff
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  )}
                </IconButton>
              )}
              {...getFieldProps( 'password' )}
            />
            { errors.password && touched.password && (
              <FormHelperText
                sx={{ padding: '0 1em' }}
                error
              >
                {errors.password}
              </FormHelperText>
            ) }
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: '1em' }}>
            <InputLabel
              htmlFor="password2"
              sx={{ fontWeight: 700 }}
              error={touched.password2 && !!errors.password2}
            >
              Repita Contraseña
            </InputLabel>
            <OutlinedInput
              id="password2"
              size="small"
              fullWidth
              type={showPassword2 ? 'text' : 'password'}
              placeholder="Contraseña"
              error={touched.password2 && !!errors.password2}
              endAdornment={(
                <IconButton
                  onClick={() => setShowPassword2( !showPassword2 )}
                >
                  { showPassword2 ? (
                    <Visibility
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  ) : (
                    <VisibilityOff
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  )}
                </IconButton>
              )}
              {...getFieldProps( 'password2' )}
            />
            { errors.password2 && touched.password2 && (
              <FormHelperText
                sx={{ padding: '0 1em' }}
                error
              >
                {errors.password2}

              </FormHelperText>
            ) }
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              sx={{ width: '13em' }}
              variant="contained"
              type="submit"
              endIcon={
                ( loading )
                && ( <CircularProgress size={20} color="secondary" /> )
              }
              disabled={loading}
              fullWidth
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PasswordForm;
