import { useAppSelector } from '../../../app/hooks';

const useFilterSellersInitialState = () => {
  const { filterOptions } = useAppSelector(( state ) => state.seller );

  return {
    active: filterOptions ? filterOptions.active : null,
    from: filterOptions ? filterOptions.from : null,
    until: filterOptions ? filterOptions.until : null,
  };
};

export default useFilterSellersInitialState;
