import { IActivityType } from '../interfaces';

export const activitiesTypes: IActivityType[] = [
  { label: 'Archivo', value: 'file' },
  { label: 'Completar', value: 'complete' },
  { label: 'Arrastrar y Soltar (Palabras)', value: 'dragAndDrop' },
  { label: 'Preguntas Abiertas', value: 'openQuestions' },
  { label: 'Arrastrar y Soltar (Imagenes)', value: 'dragAndDropImages' },
  { label: 'Arrastrar y Soltar (Ordenar)', value: 'dragAndDropOrder' },
  { label: 'Arrastrar y Soltar (Correcto)', value: 'dragAndDropCorrect' },
  { label: 'Embebida', value: 'embed' },
];
