import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { auth } from '../../firebase';

export const reauthenticate = ( currentPassword : string ) => {
  const user = auth.currentUser;
  const email = auth.currentUser?.email || '';
  const cred = EmailAuthProvider.credential(
    email, currentPassword,
  );
  if ( user ) {
    return reauthenticateWithCredential( user, cred );
  }
};
