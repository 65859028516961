import {
  Autocomplete,
  Button,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { ICodeRegister } from '../interfaces';
import { SellersValidationSchema } from '../validators/validatioSchemas';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setCodesModalState } from '../slice/codesSlice';
import { createCode, generateExcel } from '../actions/codesActions';
import useCodeInitialState from '../hooks/useCodeInitialState';

type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const CodesForm = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matchSmDowm = useMediaQuery( theme.breakpoints.down( 'sm' ));
  const { booksList } = useAppSelector(( state ) => state.book );
  const {
    loadingCreate, loadingUpdate, code,
  } = useAppSelector(( state ) => state.code );
  const [writingInTags, setWritingInTags] = useState<boolean>( false );
  const [currentWrite, setCurrentWrite] = useState<string>( '' );
  const [tagsList, setTagsList] = useState<string[]>([]);
  const {
    getFieldProps, handleSubmit, errors, touched, resetForm, setFieldValue, values,
  } = useFormik<ICodeRegister>({
    initialValues: useCodeInitialState(),
    validationSchema: SellersValidationSchema,
    enableReinitialize: true,
    onSubmit: async ( formValues ) => {
      await dispatch( createCode( formValues ));
      dispatch( generateExcel());
      dispatch( setCodesModalState( false ));
      resetForm();
      setTagsList([]);
    },
  });
  const onEnterIsPressed = () => {
    setCurrentWrite( '' );
    const tags = [...tagsList, currentWrite];
    setTagsList( tags );
  };
  const handleDeleteTag = ( label: string ) => {
    const tags = tagsList.filter(( tag ) => tag !== label );
    setTagsList( tagsList.filter(( tag ) => tag !== label ));
    setFieldValue( 'tags', tags );
  };
  useEffect(() => {
    setCurrentWrite( '' );
    if ( code ) {
      setTagsList( code.tags );
    } else {
      setTagsList([]);
    }
  }, [code, setFieldValue]);
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <CardContent sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="book"
              error={touched.book && !!errors.book}
            >
              <Typography fontWeight="bold" fontSize={12}>Libro</Typography>
            </InputLabel>
            <Autocomplete
              disablePortal
              id="book"
              options={booksList}
              fullWidth
              size="small"
              getOptionLabel={( option ) => option.name}
              renderInput={( params ) => (
                <TextField
                  {...params}
                  placeholder="Escoja un Libro"
                  error={touched.book && !!errors.book}
                  helperText={touched.book && errors.book}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                />
              )}
              value={values.book}
              onChange={( _, value ) => {
                if ( value ) setFieldValue( 'book', value );
              }}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="tags"
              error={touched.tags && !!errors.tags}
            >
              <Typography fontWeight="bold" fontSize={12}>Tags</Typography>
            </InputLabel>
            <OutlinedInput
              size="small"
              id="tags"
              fullWidth
              endAdornment={matchSmDowm && (
                <IconButton onClick={() => onEnterIsPressed()}>
                  <Add />
                </IconButton>
              )}
              onFocus={() => setWritingInTags( true )}
              onBlur={() => {
                setWritingInTags( false );
                setFieldValue( 'tags', tagsList );
              }}
              value={currentWrite}
              onChange={( event: InputChange ) => {
                setCurrentWrite( event.target.value );
              }}
              onKeyPress={( event ) => {
                if ( event.key === 'Enter' ) {
                  onEnterIsPressed();
                }
              }}
              placeholder="Ingrese los tags"
              error={touched.tags && !!errors.tags}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
            />
            { errors.tags && touched.tags && (
              <FormHelperText
                sx={{ fontSize: 14 }}
                error
              >
                {errors.tags}
              </FormHelperText>
            ) }
            {tagsList.map(( tag, index ) => (
              <Chip label={tag} key={index.toString()} sx={{ margin: '0.3em 0.1em 0 0.1em' }} onDelete={() => handleDeleteTag( tag )} />
            ))}
          </Grid>
          {!code && (
            <>
              <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
                <InputLabel
                  htmlFor="quantity"
                  error={touched.quantity && !!errors.quantity}
                >
                  <Typography fontWeight="bold" fontSize={12}>Número de Códigos</Typography>
                </InputLabel>
                <TextField
                  size="small"
                  id="quantity"
                  fullWidth
                  placeholder="Ingrese una cantidad"
                  error={touched.quantity && !!errors.quantity}
                  helperText={touched.quantity && errors.quantity}
                  sx={{
                    '& .MuiOutlinedInput-root > input': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                  {...getFieldProps( 'quantity' )}
                />
              </Grid>
              <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
                <InputLabel
                  htmlFor="description"
                  error={touched.description && !!errors.description}
                >
                  <Typography fontWeight="bold" fontSize={12}>Descripción de la Orden</Typography>
                </InputLabel>
                <TextField
                  size="small"
                  id="description"
                  fullWidth
                  placeholder="Ingrese una descripción"
                  error={touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                  sx={{
                    '& .MuiOutlinedInput-root > input': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                  {...getFieldProps( 'description' )}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <CardActions sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={10} display="flex" justifyContent="center" gap={2} sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingCreate || loadingUpdate}
              onClick={() => dispatch( setCodesModalState( false ))}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingCreate || loadingUpdate || writingInTags}
              endIcon={( loadingCreate || loadingUpdate ) && ( <CircularProgress size={10} /> )}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

export default CodesForm;
