import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

interface Props {
  children: JSX.Element;
}

const PrivateGuard = ({ children }: Props ) => {
  const { isAuth } = useAppSelector(( state ) => state.auth );

  return isAuth ? children : <Navigate to="/" />;
};

export default PrivateGuard;
