import { initializeApp } from 'firebase/app';
import {
  getAuth,
  EmailAuthProvider,
} from 'firebase/auth';
import {
  getFirestore,
  Timestamp,
  FieldValue as FieldValueFirestore,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const config = {
  // TEST
  // apiKey: 'AIzaSyBq3vCDdhykIK1zt0omTZaREySvNpbQOzo',
  // authDomain: 'thatbook-5cf58.firebaseapp.com',
  // projectId: 'thatbook-5cf58',
  // storageBucket: 'thatbook-5cf58.appspot.com',
  // messagingSenderId: '992524529219',
  // appId: '1:992524529219:web:3e1fb712a0903f3396aef6',
  // PRO
  apiKey: 'AIzaSyB2UGFOhgfNHq9zYljsZrA4ohnVyuOhr_w',
  authDomain: 'thatbook-pro.firebaseapp.com',
  projectId: 'thatbook-pro',
  storageBucket: 'thatbook-pro.appspot.com',
  messagingSenderId: '358019920280',
  appId: '1:358019920280:web:a672e79d04479ed16b7bed',
  measurementId: 'G-JESSMY0GZ6',
};

const app = initializeApp( config );

export const auth = getAuth( app );
export const db = getFirestore( app );
export const storage = getStorage( app );
export const functions = getFunctions( app );
export const analytics = getAnalytics( app );
export type TimeStamp = Timestamp;
export const timeStamp = Timestamp;
export const fieldValue = FieldValueFirestore;
export type FieldValue = FieldValueFirestore;
export const emailAuthProvider = EmailAuthProvider;
