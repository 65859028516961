import { AttachFile, Download } from '@mui/icons-material';
import {
  Box, CircularProgress, IconButton, Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { size5MB } from '../../../../../helpers/5mb';
import { auth } from '../../../../firebase';
import PhotoButton from '../../../../shared/custom/buttons/PhotoButton';
import InfoDialog from '../../../../shared/custom/dialog/InfoDialog';
import useTextColor from '../../../hooks/useTextColor';
import { IQuestion } from '../../../interfaces';
import { updateResponseInStore } from '../../../slice/activitySlice';

// eslint-disable-next-line max-len
const typesFile = ['doc', 'docx', 'pdf', 'png', 'jpeg', 'ppt', 'pptx', 'xls', 'xlsx'];

interface ITypeFileProps {
  question: IQuestion;
}

const TypeFile = ({ question }: ITypeFileProps ) => {
  const dispatch = useAppDispatch();
  const { loadingGetQuestion, alreadyQualified } = useAppSelector(
    ( state ) => state.activity,
  );

  const [open, setOpen] = useState( false );
  const [titleDialog, setTitleDialog] = useState<string>( '' );
  const [messageDialog, setMessageDialog] = useState<string>( '' );

  const handleOpenDialog = ( title: string, msg: string ): void => {
    setOpen( !open );
    setMessageDialog( msg );
    setTitleDialog( title );
  };

  const inputFileRef = useRef<HTMLInputElement | null>( null );
  const onBtnClick = () => {
    if ( inputFileRef.current ) {
      inputFileRef.current.click();
    }
  };

  const onFileChangeCapture = ( e: React.ChangeEvent<HTMLInputElement> ): void => {
    const { id } = question;
    // eslint-disable-next-line no-console
    if ( id && e.target.files && e.target.files.length > 0 ) {
      if ( size5MB( e.target.files[0].size )) {
        const format = e.target.files[0].type.split( '/' )[1];
        if ( typesFile.includes( format )) {
          dispatch( updateResponseInStore({
            userId: auth.currentUser?.uid || '',
            questionId: question.id,
            response: e.target.files[0].name,
            type: question.type,
            groupId: question.groupId,
            correct: true,
          }));
        } else {
          const title = `El formato ( ${format} ) no esta permitido`;
          const msg = `El archivo tiene el formato ( ${format} ) 
          que no es permitido, los formatos aceptados son doc, docx, xlsx, xls,
          ppt,pptx, pdf, jpg y png.`;
          handleOpenDialog( title, msg );
        }
      } else {
        const title = `Tamaño de archivo superior a 5MB`;
        const msg = `El tamaño de archivo elegido supera
        el tamaño maximo que es de 5MB, por favor
        eliga otro archivo o comentelo con su docente.`;
        handleOpenDialog( title, msg );
      }
    }
  };

  return (
    <Box bgcolor="#EEEEEE" p={3} mb={2}>
      {question.indications as string
        ? (
          <Box display="flex" alignItems="center">
            <a href={question.indications as string} download target="_blank" rel="noreferrer">
              <IconButton>
                <Download color="primary" />
              </IconButton>
            </a>
            <Typography ml={1}>
              Descargar Indicaciones
            </Typography>
          </Box>
        )
        : <p>EL docente no ha subido el archivo de indicaciones.</p>}
      <Box display="flex" alignItems="center">
        <Typography
          sx={{
            color: useTextColor( question.id ),
          }}
          mr={1}
        >
          Respuesta:
          {' '}
        </Typography>
        {!open
      && (
        <>
          <input
            type="file"
            id="file"
            ref={inputFileRef}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          <PhotoButton
            variant="contained"
            onClick={() => onBtnClick()}
            disabled={loadingGetQuestion || alreadyQualified}
            size="small"
            endIcon={<AttachFile />}
          >
            Seleccionar
          </PhotoButton>
        </>
      )}
      </Box>
      {loadingGetQuestion && <CircularProgress size={20} />}
      <InfoDialog
        open={open}
        title={titleDialog}
        closeFunction={() => setOpen( !open )}
        contentText="Agregar archivo"
      >
        <Box
          display="flex"
        >
          <span>
            {messageDialog}
          </span>
        </Box>
      </InfoDialog>
    </Box>
  );
};

export default TypeFile;
