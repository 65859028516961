import { useAppSelector } from '../../../app/hooks';

const useInstitutionInitialState = () => {
  const { institution } = useAppSelector(( state ) => state.institution );

  return {
    name: institution ? institution.name : '',
    email: institution ? institution.email : '',
    phone: institution && institution.phone ? institution.phone.toString() : '',
    photo: null,
    newPhoto: false,
  };
};

export default useInstitutionInitialState;
