import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import CreateActivities from '../pages/CreateActivity';
import TestActivity from '../pages/TestActivity';

const ActivitiesRouter = () => (
  <Routes>
    <Route path="/crear" element={<CreateActivities title="Crear Actividad" />} />
    <Route path="/editar" element={<CreateActivities title="Editar Actividad" />} />
    <Route path="/prueba" element={<TestActivity title="Editar Actividad" />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default ActivitiesRouter;
