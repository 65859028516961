import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IStatistics } from '../interfaces';

const statisticsConverter = {
  toFirestore: ( statistics: IStatistics ) => ({
    ...statistics,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IStatistics => {
    const data = snapshot.data( options );

    const date = data.date ? data.date.toDate().getTime() : 0;

    const createdAt = data.createdAt.toDate().getTime();

    const updatedAt = data.updatedAt.toDate().getTime();

    const {
      books,
      codes,
      period,
      sellers,
      students,
      type,
    } = data;

    return {
      books,
      codes,
      createdAt,
      date,
      period,
      sellers,
      students,
      type,
      updatedAt,
    } as IStatistics;
  },
};

export default statisticsConverter;
