/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useDrag } from 'react-dnd';
import { IItem } from '../../interfaces';

interface IProps {
  name: string;
  setItems: any;
  id: number;
  onSave: any;
}

const ItemMovable: React.FC<IProps> = ( props ) => {
  const {
    setItems, name, id, onSave,
  } = props;

  const changeItemColumn = (
    currenItem: IItem, column1: string, column2?: string,
  ): void => {
    setItems((
      prevState: IItem[],
    ) => prevState.map(( e ) => ({
      ...e,
      column: e.name === currenItem.name
        ? column1
        : ( column2 || e.column ),
    })));
  };

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'ITEM',
    item: { name },
    end: ( item, monitor ) => {
      const dropResult = monitor.getDropResult<any>();
      if ( dropResult && dropResult.id === 'options' ) {
        changeItemColumn( item, 'options' );
        onSave();
      } else if ( dropResult ) {
        onSave( item.name, id );
        changeItemColumn( item, 'question', 'options' );
      }
    },
    collect: ( monitor ) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Box sx={{
      backgroundColor: isDragging ? '#000' : '',
      borderRadius: 1,
      mb: 1,
      p: 1,
      opacity: isDragging ? 0.6 : 1,
      color: isDragging ? '#fff' : '#000',
      border: '1px solid #000',
      textAlign: 'left',
    }}
    >
      <Box ref={dragPreview}>
        <Typography ref={drag}>{name}</Typography>
      </Box>
    </Box>
  );
};

export default ItemMovable;
