import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import StudentDashboard from '../Pages/StudentDashboard';

const StudentDashboardRouter = () => (
  <Routes>
    <Route
      path="/"
      element={<StudentDashboard title="Dashboard Estudiantes" />}
    />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default StudentDashboardRouter;
