import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getSellersPaginated } from '../actions/sellersActions';

const usePaginatedSellers = () => {
  const dispatch = useAppDispatch();
  const { sellersList } = useAppSelector(( state ) => state.seller );
  return () => dispatch( getSellersPaginated( sellersList[sellersList.length - 1]));
};

export default usePaginatedSellers;
