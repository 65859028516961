import {
  Box,
  CircularProgress,
  Grid,
} from '@mui/material';
import { useAppSelector } from '../../../../../app/hooks';
import { IUnit } from '../interfaces';
import AdminActivityItem from './AdminActivityItem';

const UnitCard = () => {
  const {
    unitsList, loadingUnitGet,
  } = useAppSelector(( state ) => state.unitsAndTopics );
  return (
    <Grid container spacing={4}>
      {loadingUnitGet ? (
        <Box textAlign="center" width="100%" mt={2}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <>
          {unitsList && unitsList.length > 0 ? unitsList.map(( unit: IUnit, index: number ) => (
            <AdminActivityItem unit={unit} key={index.toString()} />
          )) : (
            <Grid item xs={12}>
              <span>Sin Unidades</span>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default UnitCard;
