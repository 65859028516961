import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../modules/firebase';

export const uploadPhoto = async ( id: string, file: File, where: string ) => {
  const metadata = {
    contentType: file.type,
  };
  const storageRef = ref( storage, `${where}/${id}` );
  const photoPath = await uploadBytes( storageRef, file, metadata );
  const url = await getDownloadURL( photoPath.ref );
  return url;
};
