import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import Information from './ActivityForm/Information';
import {
  createActivity, getQuestionsByActivityId, updateActivity,
} from '../actions/activitiesActions';
import { ActivityFormValues, IFileContainer } from '../interfaces';
import { FormStepper, FormStep } from '../../shared/custom/stepper/FormActivityStepper';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import useActivityInitialState from '../hooks/useActivityInitialState';
import { informationValidationSchema } from '../validators/activitiesValidationSchema';
import TypeContainer from './Type/TypeContainer';
import ActivitiesDialogsContainer from './ActivitiesDialogsContainer';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import { setCreateOrEditQuestionModal, setModalCloseActivity } from '../slice/activitySlice';
import QuestionForm2 from './QuestionForm2';
import InfoDialog from '../../shared/custom/dialog/InfoDialog';

const ActivityForm = () => {
  const navigate = useNavigate();
  const {
    activity, groups, questionsList, modalCreateorEditQuestion, modalCloseActivity, question,
  } = useAppSelector(( state ) => state.activity );
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<IFileContainer[]>([]);
  useEffect(() => {
    if ( activity ) {
      dispatch( getQuestionsByActivityId( activity.id || '' ));
    }
  }, [activity, dispatch]);
  const setFilesFunction = ( data: IFileContainer[]) => {
    const updateCompanyFunction = () => {
      const filesCopy = files;
      data.forEach(( file ) => {
        const exist = filesCopy.find(
          ( item: IFileContainer ) => item.id === file.id,
        );
        if ( exist ) {
          const index = filesCopy.findIndex(
            ( item: IFileContainer ) => item.id === file.id,
          );
          filesCopy.splice( index, 1, file );
        } else {
          filesCopy.push( file );
        }
      });
      console.log( filesCopy );
      return filesCopy;
    };
    setFiles( updateCompanyFunction());
  };
  return (
    <>
      <Box mb={4} mt={4} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">
          Formulario de Actividad
        </Typography>
        <Button sx={{ pl: 10, pr: 10 }} onClick={() => dispatch( setModalCloseActivity( true ))}>
          Cancelar
        </Button>
      </Box>
      <FormStepper
        enableReinitialize
        initialValues={useActivityInitialState()}
        onSubmit={async ( formValues ) => {
          if ( groups.length <= 0 || groups.filter(( group ) => group.deleted === true ).length === groups.length ) {
            return toast.error( 'Debe agregar al menos un grupo' );
          }
          if ( questionsList.length <= 0 || questionsList.filter(( questionCreated ) => questionCreated.deleted === true ).length === questionsList.length ) {
            return toast.error( 'Debe agregar al menos una pregunta' );
          }
          formValues.indications = files;
          const values = formValues as ActivityFormValues;
          if ( activity ) {
            await dispatch( updateActivity( values ));
          } else {
            await dispatch( createActivity( values ));
          }
          navigate( -1 );
        }}
      >
        <FormStep label="Información" validationSchema={informationValidationSchema}>
          <Information />
        </FormStep>
        <FormStep label="Preguntas">
          <TypeContainer />
        </FormStep>
      </FormStepper>
      <ActivitiesDialogsContainer />
      <FormDialog
        open={modalCreateorEditQuestion}
        title={question ? 'Editar Pregunta' : 'Crear Pregunta'}
        closeFunction={() => dispatch( setCreateOrEditQuestionModal( false ))}
      >
        <QuestionForm2 sendFiles={setFilesFunction} />
      </FormDialog>
      <InfoDialog
        open={modalCloseActivity}
        title="¿Deseas Salir?"
        contentText="Estás seguro que deseas salir, no se guardarán los cambios."
        closeFunction={() => dispatch( setModalCloseActivity( false ))}
      >
        <Button onClick={() => navigate( -1 )} sx={{ mt: 1, mb: 1 }} fullWidth>
          Salir
        </Button>
      </InfoDialog>
    </>
  );
};

export default ActivityForm;
