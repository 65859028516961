import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { IActivationCode } from '../interfaces';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setActivateCodeModal } from '../slice/dashboardSlice';
import { activateCode } from '../actions/dashboardAction';
import { activationCodeValidationSchema } from '../validators/activationCodeValidationSchema';

const ActivationCodeForm = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(( state ) => state.dashboard );
  const {
    getFieldProps, handleSubmit, errors, touched, resetForm,
  } = useFormik<IActivationCode>({
    initialValues: { activationCode: '' },
    validationSchema: activationCodeValidationSchema,
    onSubmit: async ( formValues ) => {
      await dispatch( activateCode( formValues.activationCode ));
      dispatch( setActivateCodeModal( false ));
      resetForm();
    },
  });
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <CardContent sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="activationCode"
              error={touched.activationCode && !!errors.activationCode}
            >
              <Typography fontWeight="bold" fontSize={12}>Código de Activación</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="activationCode"
              fullWidth
              placeholder="Ingresa el código de activación"
              error={touched.activationCode && !!errors.activationCode}
              helperText={touched.activationCode && errors.activationCode}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'activationCode' )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={10} display="flex" justifyContent="center" gap={2} sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loading}
              onClick={() => dispatch( setActivateCodeModal( false ))}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loading}
              endIcon={( loading ) && ( <CircularProgress size={10} /> )}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

export default ActivationCodeForm;
