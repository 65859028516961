import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const DragAndDropWords = () => (
  <Box textAlign="center">
    <Typography fontWeight="bold" color={grey[900]}>
      Arrastrar y Soltar (Palabras)
    </Typography>
    <Typography variant="body2">
      Completa la frase con la palabra que hace falta. Recuerda que se debe escribir de manera correcta.
    </Typography>
    <Typography variant="body2" mt={3}>
      Ejem: El sol es una ……….. del tipo G
    </Typography>
  </Box>
);

export default DragAndDropWords;
