import { Grid } from '@mui/material';

interface Props {
  children: JSX.Element;
}

const AuthLayout = ({ children }: Props ) => (
  <Grid
    container
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{ height: '100vh' }}
  >
    {children}
  </Grid>
);

export default AuthLayout;
