import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import StudentsList from '../pages/StudentsList';

const StudentsRouter = () => (
  <Routes>
    <Route path="/" element={<StudentsList title="Lista de Estudiantes" />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default StudentsRouter;
