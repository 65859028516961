import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getCodesPaginated } from '../actions/codesActions';

const usePaginatedCodes = () => {
  const dispatch = useAppDispatch();
  const { codesList } = useAppSelector(( state ) => state.code );
  return () => dispatch( getCodesPaginated( codesList[codesList.length - 1]));
};

export default usePaginatedCodes;
