import { Box, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { IGroupActivity } from '../../interfaces';
import { useAppSelector } from '../../../../app/hooks';

interface IProps {
  // eslint-disable-next-line react/require-default-props
  groups?: IGroupActivity[];
}

const RubricEvaluation = ({ groups }: IProps ) => {
  const { responses, alreadyQualified } = useAppSelector(( state ) => state.activity );
  const [calification, setCalification] = useState<number>( 0 );

  const getCalificationStatus = ( groupId: string ) => {
    const corrects = responses.filter(( response ) => response.groupId === groupId && response.correct );
    const total = responses.filter(( response ) => response.groupId === groupId );
    // const inCorrects = responses.filter(( response ) => response.groupId === groupId && !response.correct );
    return `${corrects.length}/${total.length}`;
  };

  useEffect(() => {
    let endCalification = 0;
    const eachQuestionValue = 10 / responses.length;
    const incorrectResponses: number = responses.filter(( response ) => !response.correct ).length;
    if ( incorrectResponses > 0 ) {
      endCalification = ( Math.abs(( incorrectResponses * eachQuestionValue ) - 10 ));
    } else {
      endCalification = ( 10 );
    }
    if ( alreadyQualified ) {
      setCalification( endCalification );
    } else {
      setCalification( 0 );
    }
  }, [responses, alreadyQualified]);
  return (
    <>
      <Box mt={2} mb={3}>
        {groups && groups.map(( group: IGroupActivity, i: number ) => group.type !== 'embed' && (
          <Box key={i.toString()} display="flex" alignItems="center" justifyContent="space-between" mt={1} mb={1}>
            <span>
              {group.type === 'file' ? 'Subir Archivo' : group.rubric}
            </span>
            <Paper
              sx={{
                borderRadius: 3,
                textAlign: 'center',
                p: '5px 5px',
                width: '60px',
              }}
            >
              {!alreadyQualified ? '0' : getCalificationStatus( group.id )}
            </Paper>
          </Box>
        ))}
      </Box>
      {responses.length > 0 ? (
        <Paper
          elevation={3}
          sx={{
            borderRadius: 3, p: '10px 5px', display: 'flex', justifyContent: 'space-evenly',
          }}
        >
          <Typography variant="h6" fontWeight="bold">Calificación</Typography>
          <Typography variant="h6" fontWeight="bold">{Math.round( calification )}</Typography>
        </Paper>
      ) : ( <Typography variant="button" fontWeight="bold" color="black">Si la actividad es de tipo embedida, está se califica en otro sitio.</Typography> )}
    </>
  );
};

export default RubricEvaluation;
