import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getStudentsPaginated } from '../actions/studentsActions';

const usePaginatedStudents = () => {
  const dispatch = useAppDispatch();
  const { studentsList } = useAppSelector(( state ) => state.student );
  return () => dispatch( getStudentsPaginated( studentsList[studentsList.length - 1]));
};

export default usePaginatedStudents;
