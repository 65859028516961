import {
  Box, FormHelperText, Grid, InputLabel, Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { useAppSelector } from '../../../../app/hooks';
import { size2MB } from '../../../../helpers/2mb';
import PhotoButton from '../../../shared/custom/buttons/PhotoButton';
import { IFileContainer, IQuestion } from '../../interfaces';

const typesFile = ['png', 'jpeg', 'jpg'];

interface IDragAndDropOrderFieldsProps{
  passingFile: ( data: IFileContainer ) => void;
}

const DragAndDropOrderFields = ({ passingFile }: IDragAndDropOrderFieldsProps ) => {
  const {
    errors,
    setFieldValue,
    values,
    touched,
  } = useFormikContext<
  Pick<IQuestion,
  'number1' | 'number2' | 'number3' | 'number4' | 'id'>
  >();
  const { question: questionInStore, group } = useAppSelector(( state ) => state.activity );
  const inputFileRefNumber1 = useRef<HTMLInputElement | null>( null );
  const inputFileRefNumber2 = useRef<HTMLInputElement | null>( null );
  const inputFileRefNumber3 = useRef<HTMLInputElement | null>( null );
  const inputFileRefNumber4 = useRef<HTMLInputElement | null>( null );
  const [pcFileNameNumber1, setPcFileNameNumber1] = useState<string>( '' );
  const [pcFileNameNumber2, setPcFileNameNumber2] = useState<string>( '' );
  const [pcFileNameNumber3, setPcFileNameNumber3] = useState<string>( '' );
  const [pcFileNameNumber4, setPcFileNameNumber4] = useState<string>( '' );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onBtnClick = ( e: any ) => {
    switch ( e.target.id ) {
      case 'number1Button':
        if ( inputFileRefNumber1.current ) {
          inputFileRefNumber1.current.click();
        }
        break;
      case 'number2Button':
        if ( inputFileRefNumber2.current ) {
          inputFileRefNumber2.current.click();
        }
        break;
      case 'number3Button':
        if ( inputFileRefNumber3.current ) {
          inputFileRefNumber3.current.click();
        }
        break;
      case 'number4Button':
        if ( inputFileRefNumber4.current ) {
          inputFileRefNumber4.current.click();
        }
        break;
      default:
        break;
    }
  };
  const onFileChangeCapture = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    console.log( e.target.id );
    if ( e.target.files && e.target.files.length > 0 ) {
      const format = e.target.files[0].type.split( '/' )[1];
      if ( size2MB( e.target.files[0].size )) {
        if ( typesFile.includes( format )) {
          switch ( e.target.id ) {
            case 'number1':
              setPcFileNameNumber1( e.target.files[0].name );
              setFieldValue( 'number1', 'number1' );
              break;
            case 'number2':
              setPcFileNameNumber2( e.target.files[0].name );
              setFieldValue( 'number2', 'number2' );
              break;
            case 'number3':
              setPcFileNameNumber3( e.target.files[0].name );
              setFieldValue( 'number3', 'number3' );
              break;
            case 'number4':
              setPcFileNameNumber4( e.target.files[0].name );
              setFieldValue( 'number4', 'number4' );
              break;
            default:
              break;
          }
          passingFile({ id: `${values.id}${e.target.id}`, file: e.target.files[0] });
          console.log( `${values.id}${e.target.id}` );
        } else {
          toast.error( 'Solo se admiten formatos PNG, JPEG, JPG' );
        }
      } else {
        toast.error( 'El tamaño maximo de la foto debe ser de 2MB' );
      }
    }
  };
  useEffect(() => {
    if ( values.id === '' ) {
      setFieldValue( 'id', v4());
    }
  }, [setFieldValue, values.id]);
  useEffect(() => {
    if ( questionInStore ) {
      const text = 'Seleccionamos el archivo anterior automaticamente.';
      setPcFileNameNumber1( text );
      setPcFileNameNumber2( text );
      setPcFileNameNumber3( text );
      setPcFileNameNumber4( text );
    } else if ( group ) {
      setFieldValue( 'type', group.type );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionInStore]);
  return (
    <>
      <Grid item xs={12}>
        <InputLabel htmlFor="number1">Opcion Número (1)</InputLabel>
        <Box display="flex" alignItems="center">
          <input
            type="file"
            id="number1"
            ref={inputFileRefNumber1}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          <PhotoButton
            variant="contained"
            onClick={( e ) => onBtnClick( e )}
            size="small"
            id="number1Button"
            error={values.number1 === null && errors.number1 !== undefined}
          >
            Seleccionar
          </PhotoButton>
          <Typography ml={2}>
            {pcFileNameNumber1 || 'Selecciona un archivo'}
          </Typography>
        </Box>
        { errors.number1 && touched.number1 && (
          <FormHelperText
            sx={{ fontSize: 14 }}
            error
          >
            {errors.number1}
          </FormHelperText>
        ) }
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="number2">Opcion Número (2)</InputLabel>
        <Box display="flex" alignItems="center">
          <input
            type="file"
            id="number2"
            ref={inputFileRefNumber2}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          <PhotoButton
            variant="contained"
            onClick={( e ) => onBtnClick( e )}
            size="small"
            id="number2Button"
            error={values.number2 === null && errors.number2 !== undefined}
          >
            Seleccionar
          </PhotoButton>
          <Typography ml={2}>
            {pcFileNameNumber2 || 'Selecciona un archivo'}
          </Typography>
        </Box>
        { errors.number2 && touched.number2 && (
          <FormHelperText
            sx={{ fontSize: 14 }}
            error
          >
            {errors.number2}
          </FormHelperText>
        ) }
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="number3">Opcion Número (3)</InputLabel>
        <Box display="flex" alignItems="center">
          <input
            type="file"
            id="number3"
            ref={inputFileRefNumber3}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          <PhotoButton
            variant="contained"
            onClick={( e ) => onBtnClick( e )}
            size="small"
            id="number3Button"
            error={values.number3 === null && errors.number3 !== undefined}
          >
            Seleccionar
          </PhotoButton>
          <Typography ml={2}>
            {pcFileNameNumber3 || 'Selecciona un archivo'}
          </Typography>
        </Box>
        { errors.number3 && touched.number3 && (
          <FormHelperText
            sx={{ fontSize: 14 }}
            error
          >
            {errors.number3}
          </FormHelperText>
        ) }
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="number4">Opcion Número (4)</InputLabel>
        <Box display="flex" alignItems="center">
          <input
            type="file"
            id="number4"
            ref={inputFileRefNumber4}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          <PhotoButton
            variant="contained"
            onClick={( e ) => onBtnClick( e )}
            size="small"
            id="number4Button"
            error={values.number4 === null && errors.number4 !== undefined}
          >
            Seleccionar
          </PhotoButton>
          <Typography ml={2}>
            {pcFileNameNumber4 || 'Selecciona un archivo'}
          </Typography>
        </Box>
        { errors.number4 && touched.number4 && (
          <FormHelperText
            sx={{ fontSize: 14 }}
            error
          >
            {errors.number4}
          </FormHelperText>
        ) }
      </Grid>
    </>
  );
};

export default DragAndDropOrderFields;
