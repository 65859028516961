import { Route, Routes } from 'react-router-dom';
import AdminBooksRouter from '../modules/books/router/AdminBooksRouter';
import StudentsRouter from '../modules/students/router/StudentsRouter';
import SellersRouter from '../modules/sellers/router/SellersRouter';
import AdminDashboardRouter from '../modules/dashboard/router/AdminDashboardRouter';
import ActivitiesRouter from '../modules/activities/router/ActivitiesRouter';
import NotFound404 from '../modules/shared/Errors/NotFound404';
import AdminCodesRouter from '../modules/codes/router/AdminCodesRouter';
import InstitutionsRouter from '../modules/institutions/router/InstitutionsRouter';

const AdminRouter = () => (
  <Routes>
    <Route path="/dashboard/*" element={( <AdminDashboardRouter /> )} />
    <Route path="/libros/*" element={( <AdminBooksRouter /> )} />
    <Route path="/estudiantes/*" element={( <StudentsRouter /> )} />
    <Route path="/vendedores/*" element={( <SellersRouter /> )} />
    <Route path="/actividades/*" element={( <ActivitiesRouter /> )} />
    <Route path="/ordenes/*" element={( <AdminCodesRouter /> )} />
    <Route path="/instituciones/*" element={( <InstitutionsRouter /> )} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default AdminRouter;
