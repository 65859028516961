import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import { setFilterSellersModal, setSellersModalState } from '../slice/sellersSlice';
import FilterSellersForm from './FilterSellersForm';
import SellersForm from './SellersForm';

const SellersDialogsContainer = () => {
  const dispatch = useAppDispatch();
  const {
    modal, loadingCreate, loadingUpdate, modalFilter, loadingFilter,
  } = useAppSelector(( state ) => state.seller );
  return (
    <>
      <FormDialog
        open={modal}
        title="Añadir Vendedor"
        closeFunction={() => dispatch( setSellersModalState( !!loadingCreate || !!loadingUpdate ))}
      >
        <SellersForm />
      </FormDialog>
      <FormDialog
        open={modalFilter}
        title="Filtrar Vendedor"
        closeFunction={() => dispatch( setFilterSellersModal( !!loadingFilter ))}
      >
        <FilterSellersForm />
      </FormDialog>
    </>
  );
};

export default SellersDialogsContainer;
