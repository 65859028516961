/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAppDispatch } from '../../../../../app/hooks';
import { auth } from '../../../../firebase';
import { IQuestion } from '../../../interfaces';
import { updateResponseInStore } from '../../../slice/activitySlice';
import { IItem } from '../interfaces';
import BoxOptions from './dragAndDropOrder/BoxOptions';
import BoxResponse from './dragAndDropOrder/BoxResponse';
import ItemMovable from './dragAndDropOrder/ItemMovable';

interface ITypeDragAndDropOrderProps {
  question: IQuestion;
}

interface IResponseToMap {
  number: string,
  text: string,
}

const TypeDragAndDropOrder = ({ question }: ITypeDragAndDropOrderProps ) => {
  const dispatch = useAppDispatch();
  const [lastItem, setLastItem] = useState<IItem>({
    id: 0, name: '', column: '', response: false,
  });
  const [responses, setResponses] = useState<IResponseToMap[]>([]);
  const [items, setItems] = useState<IItem[]>([
    {
      id: 1, name: '1', column: 'options', response: true,
    },
    {
      id: 2, name: '2', column: 'options', response: false,
    },
    {
      id: 3, name: '3', column: 'options', response: false,
    },
    {
      id: 4, name: '4', column: 'options', response: false,
    },
  ]);

  const setItemsResponse = ( currenItem: IItem, column1: string ) => {
    setLastItem({ ...currenItem, column: column1 });
    setItems((
      prevState: IItem[],
    ) => prevState.map(( e ) => ({
      ...e,
      column: e.name === currenItem.name
        ? column1
        : e.column,
    })));
  };

  const returnItemsForColumn = (
    columnName: string,
  ): JSX.Element | JSX.Element[] => {
    const itemsCopy = items.sort(() => Math.random() - 0.5 );
    const list = itemsCopy.filter(
      ( item: IItem ) => item.column === columnName
      && item.name !== undefined,
    );
    return (
      list.map(( item: IItem ) => (
        <ItemMovable
          key={item.id}
          id={item.id || 0}
          item={item}
          setItems={setItemsResponse}
        />
      ))
    );
  };

  useEffect(() => {
    const moreThan1 = items.filter(( item ) => item.column === lastItem.column );
    if ( moreThan1.length > 1 ) {
      setItems( items.map(( e ) => ({
        ...e,
        column: e.id !== lastItem.id && e.column === lastItem.column ? 'options' : e.column,
      })));
    }
    let counter = 0;
    items.forEach(( item ) => {
      if ( item.column?.includes( item?.name || '' )) {
        counter += 1;
      } else if ( counter > 0 ) counter -= 1;
      if ( counter === 4 ) {
        dispatch( updateResponseInStore({
          userId: auth.currentUser?.uid || '',
          questionId: question.id,
          response: item?.name || '',
          type: question.type,
          groupId: question.groupId,
          correct: true,
        }));
      } else {
        dispatch( updateResponseInStore({
          userId: auth.currentUser?.uid || '',
          questionId: question.id,
          response: item?.name || '',
          type: question.type,
          groupId: question.groupId,
          correct: false,
        }));
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    const finalResponses: IResponseToMap[] = [];
    if ( question.number1 !== undefined && question.number1 !== '' ) {
      finalResponses.push({ number: '1', text: question.number1 });
    }
    if ( question.number2 !== undefined && question.number2 !== '' ) {
      finalResponses.push({ number: '2', text: question.number2 });
    }
    if ( question.number3 !== undefined && question.number3 !== '' ) {
      finalResponses.push({ number: '3', text: question.number3 });
    }
    if ( question.number4 !== undefined && question.number4 !== '' ) {
      finalResponses.push({ number: '4', text: question.number4 });
    }
    finalResponses.sort(() => Math.random() - 0.5 );
    setResponses( finalResponses );
  }, [question]);

  return (
    <Box bgcolor="#EEEEEE" p={3}>
      <DndProvider backend={HTML5Backend}>
        <Grid container wrap="wrap">
          <Grid item xs={12}>
            <Grid container spacing={5}>
              {responses.length > 0 && responses.map(( response, index: number ) => (
                <BoxResponse key={index.toString()} title={response.text} id={`response${response.number}`}>
                  {returnItemsForColumn( `response${response.number}` )}
                </BoxResponse>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BoxOptions title="Opciones" id="options">
              {returnItemsForColumn( 'options' )}
            </BoxOptions>
          </Grid>
        </Grid>
      </DndProvider>
    </Box>
  );
};

export default TypeDragAndDropOrder;
