import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import SellersBooksList from '../pages/SellersBooksList';
import SellersPreviewBookDetail from '../pages/SellersPreviewBookDetail';

const BooksRouter = () => (
  <Routes>
    <Route path="/" element={<SellersBooksList title="Lista de Libros" />} />
    <Route path="/previsualizar-libro" element={<SellersPreviewBookDetail />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default BooksRouter;
