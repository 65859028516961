import { AttachMoney } from '@mui/icons-material';
import {
  Avatar, Box, Card, CardContent, Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { trackEvent } from '../../../helpers/analytics';
import { auth } from '../../firebase';
import CardBookButton from '../../shared/custom/buttons/CardBookButton';
import { IBook } from '../interfaces';
import { addBookForActions } from '../slice/booksSlice';

interface ICardBookProps {
  book: IBook;
}

const CardBook = ({ book }: ICardBookProps ) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [userPath, setUserPath] = useState<string>( '/estudiante/libros/previsualizar-libro' );
  const { activations } = useAppSelector(( state ) => state.auth );
  const activated = activations.find(( activation ) => activation.book.id === book.id );
  useEffect(() => {
    if ( location.pathname === '/estudiante/libros' ) {
      if ( activated ) {
        setUserPath( '/estudiante/libros/ver-libro' );
      } else {
        setUserPath( '/estudiante/libros/previsualizar-libro' );
      }
    } else {
      setUserPath( '/vendedor/libros/previsualizar-libro' );
    }
  }, [location, activated]);
  return (
    <Link
      to={userPath}
      onClick={() => {
        dispatch( addBookForActions( book ));
        trackEvent( `push_view_preview_book_card_${location.pathname === '/estudiante/libros' ? 'student' : 'seller'}`, {
          elementPushed: 'card_with_info', userInAction: auth.currentUser?.uid, bookName: book.name, viewName: 'Lista de libros',
        });
      }}
      style={{ textDecoration: 'none' }}
    >
      <Card sx={{ boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', width: '100%' }}>
        <CardContent sx={{ textAlign: 'center' }}>
          {activated && (
            <Box position="relative">
              <Box position="absolute" right={0}>
                <AttachMoney color="primary" />
              </Box>
            </Box>
          )}
          <Box sx={{
            display: 'flex', justifyContent: 'center', mb: '1em', mt: '1.5em',
          }}
          >
            <Avatar src={book.photo} alt={book.name} sx={{ width: '70px', height: '70px' }} />
          </Box>
          <Typography fontWeight="bold" color={grey[800]}>
            {book.name}
          </Typography>
          <Typography mb={1.5}>
            Editorial
            {' '}
            {book.editorial}
          </Typography>
          <Box mb={1.5} display="flex" gap={2} justifyContent="center">
            <CardBookButton quantity={book.statistics.units} text="Unidades" />
            <CardBookButton quantity={book.statistics.topics} text="Temas" />
            <CardBookButton quantity={book.statistics.activities} text="Actividades" />
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export default CardBook;
