import { Route, Routes } from 'react-router-dom';
import ActivitiesRouterStudents from '../modules/activities/router/ActivitiesRouterStudents';
import BooksStudentsRouter from '../modules/books/router/BooksStudentsRouter';
import CodesRouter from '../modules/codes/router/CodesRouter';
import StudentDashboardRouter from '../modules/dashboard/router/StudentDashboardRouter';

const StudentRouter = () => (
  <Routes>
    <Route path="/dashboard/*" element={( <StudentDashboardRouter /> )} />
    <Route path="/libros/*" element={( <BooksStudentsRouter /> )} />
    <Route path="/codigos/*" element={( <CodesRouter /> )} />
    <Route path="/actividades/*" element={( <ActivitiesRouterStudents /> )} />
  </Routes>
);

export default StudentRouter;
