import { Button, ButtonProps } from '@mui/material';
import { styled, theme } from '../../theme';

const NavbarButton = styled( Button )<ButtonProps>(() => ({
  minWidth: 'fit-content',
  backgroundColor: 'transparent',
  color: 'grey',
  border: '1px solid grey',
  '&:hover': {
    backgroundColor: '#DFDFDF',
  },
  '&:focus': {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  '&.MuiButton-root': {
    padding: '0.5em',
  },
}));

export default NavbarButton;
