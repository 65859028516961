import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { ICodeGeneration } from '../interfaces';

const codeGenerationsConverters = {
  toFirestore: ( code: ICodeGeneration ) => ({
    ...code,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): ICodeGeneration => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      book,
      bookId,
      creator,
      tags,
      description,
      numbers,
    } = data;

    return {
      id: snapshot.id,
      book,
      bookId,
      creator,
      tags,
      description,
      numbers,
      created,
    } as ICodeGeneration;
  },
};

export default codeGenerationsConverters;
