import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IBookRegister } from '../interfaces';
import { BooksValidationSchema } from '../validators/validatioSchemas';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setCreateEditBooksModal } from '../slice/booksSlice';
import { createBook, updateBook } from '../actions/booksActions';
import { editorials } from '../../shared/Common/Editorials';
import { categories } from '../../shared/Common/Categories';
import { subCategories } from '../../shared/Common/SubCategories';
import { size5MB } from '../../../helpers/5mb';
import PhotoButton from '../../shared/custom/buttons/PhotoButton';
import useBooksInitialState from '../hooks/useBooksInitialState';

const typesFile = ['png', 'jpeg', 'jpg'];

const BoooksForm = () => {
  const dispatch = useAppDispatch();
  const { loadingCreate, book, loadingUpdate } = useAppSelector(( state ) => state.book );
  const [pcPhotoName, setPcPhotoName] = useState<string>( '' );
  const [pcPhotoPath, setPcPhotoPath] = useState<string>( '' );
  const {
    getFieldProps, handleSubmit, errors, touched, setFieldValue, resetForm, values,
  } = useFormik<IBookRegister>({
    initialValues: useBooksInitialState(),
    validationSchema: BooksValidationSchema,
    enableReinitialize: true,
    onSubmit: async ( formValues ) => {
      if ( book ) {
        await dispatch( updateBook({ ...formValues, previousBook: book }));
        setPcPhotoName( '' );
        setPcPhotoPath( '' );
      } else {
        await dispatch( createBook( formValues ));
        setPcPhotoName( '' );
        setPcPhotoPath( '' );
      }
      dispatch( setCreateEditBooksModal( false ));
      resetForm();
    },
  });
  const inputFileRef = useRef<HTMLInputElement | null>( null );
  const onBtnClick = () => {
    if ( inputFileRef.current ) {
      inputFileRef.current.click();
    }
  };
  const onFileChangeCapture = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    if ( e.target.files && e.target.files.length > 0 ) {
      const format = e.target.files[0].type.split( '/' )[1];
      if ( size5MB( e.target.files[0].size )) {
        if ( typesFile.includes( format )) {
          setFieldValue( 'photo', e.target.files[0]);
          setFieldValue( 'newPhoto', true );
          setPcPhotoName( e.target.files[0].name );
          setPcPhotoPath( URL.createObjectURL( e.target.files[0]));
        } else {
          toast.error( 'Solo se admiten formatos PNG, JPEG, JPG' );
        }
      } else {
        toast.error( 'El tamaño maximo de la foto debe ser de 5MB' );
      }
    }
  };
  useEffect(() => {
    if ( book ) {
      setPcPhotoPath( book.photo );
      setPcPhotoName( 'Seleccionamos la foto anterior automaticamente' );
      setFieldValue( 'photo', book.photo );
    } else {
      setPcPhotoPath( '' );
      setPcPhotoName( '' );
    }
  }, [book, setFieldValue]);
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <CardContent sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="name"
              error={touched.name && !!errors.name}
            >
              <Typography fontWeight="bold" fontSize={12}>Nombre</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="name"
              fullWidth
              placeholder="Ingrese un Nombre"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'name' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="description"
              error={touched.description && !!errors.description}
            >
              <Typography fontWeight="bold" fontSize={12}>Descripción</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="description"
              fullWidth
              placeholder="Ingrese una Descripción"
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'description' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="editorial"
              error={touched.editorial && !!errors.editorial}
            >
              <Typography fontWeight="bold" fontSize={12}>Editorial</Typography>
            </InputLabel>
            <Autocomplete
              disablePortal
              id="editorial"
              options={editorials}
              fullWidth
              size="small"
              isOptionEqualToValue={( option, value ) => option === value}
              renderInput={( params ) => (
                <TextField
                  {...params}
                  placeholder="Ingrese un Editorial"
                  error={touched.editorial && !!errors.editorial}
                  helperText={touched.editorial && errors.editorial}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: '#F2F2F2',
                    },
                  }}
                />
              )}
              value={values.editorial}
              onChange={( _, value ) => {
                if ( value ) setFieldValue( 'editorial', value );
              }}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  htmlFor="category"
                  error={touched.category && !!errors.category}
                >
                  <Typography fontWeight="bold" fontSize={12}>Categoría</Typography>
                </InputLabel>
                <Autocomplete
                  disablePortal
                  id="category"
                  options={categories}
                  fullWidth
                  size="small"
                  isOptionEqualToValue={( option, value ) => option === value}
                  renderInput={( params ) => (
                    <TextField
                      {...params}
                      placeholder="Ingrese una Categoría"
                      error={touched.category && !!errors.category}
                      helperText={touched.category && errors.category}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: '#F2F2F2',
                        },
                      }}
                    />
                  )}
                  value={values.category}
                  onChange={( _, value ) => {
                    if ( value ) setFieldValue( 'category', value );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  htmlFor="subCategory"
                  error={touched.subCategory && !!errors.subCategory}
                >
                  <Typography fontWeight="bold" fontSize={12}>Subcategoría</Typography>
                </InputLabel>
                <Autocomplete
                  disablePortal
                  id="subCategory"
                  options={subCategories}
                  fullWidth
                  size="small"
                  isOptionEqualToValue={( option, value ) => option === value}
                  renderInput={( params ) => (
                    <TextField
                      {...params}
                      placeholder="Ingrese una Subcategoría"
                      error={touched.subCategory && !!errors.subCategory}
                      helperText={touched.subCategory && errors.subCategory}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: '#F2F2F2',
                        },
                      }}
                    />
                  )}
                  value={values.subCategory}
                  onChange={( _, value ) => {
                    if ( value ) setFieldValue( 'subCategory', value );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="url"
              error={touched.url && !!errors.url}
            >
              <Typography fontWeight="bold" fontSize={12}>Url</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="url"
              fullWidth
              placeholder="Ingrese una URL"
              error={touched.url && !!errors.url}
              helperText={touched.url && errors.url}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'url' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="urlDemo"
              error={touched.urlDemo && !!errors.urlDemo}
            >
              <Typography fontWeight="bold" fontSize={12}>Url Demo</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="urlDemo"
              fullWidth
              placeholder="Ingrese una URL Demo"
              error={touched.urlDemo && !!errors.urlDemo}
              helperText={touched.urlDemo && errors.urlDemo}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'urlDemo' )}
            />
          </Grid>
          <Grid item xs={9} sm={11}>
            <Box display="flex" alignItems="center">
              <Avatar
                src={pcPhotoPath}
                alt="Foto"
                sx={{
                  minWidth: '15px', minHeight: '15px', maxWidth: '1.5em', maxHeight: '1.5em', mr: 2,
                }}
              />
              <input
                type="file"
                id="file"
                ref={inputFileRef}
                onChangeCapture={onFileChangeCapture}
                style={{ display: 'none' }}
              />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
              <PhotoButton
                variant="contained"
                onClick={() => onBtnClick()}
                disabled={loadingCreate}
                size="small"
              >
                Seleccionar
              </PhotoButton>
              <Typography ml={2}>
                {pcPhotoName || 'Selecciona un archivo'}
              </Typography>
            </Box>
            <Box ml={2}>
              {values.photo === null && errors.photo && touched.photo && (
                <FormHelperText error>{ errors.photo }</FormHelperText>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={10} display="flex" justifyContent="center" gap={2} sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingCreate || loadingUpdate}
              onClick={() => dispatch( setCreateEditBooksModal( false ))}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingCreate || loadingUpdate}
              endIcon={( loadingCreate || loadingUpdate ) && ( <CircularProgress size={10} /> )}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

export default BoooksForm;
