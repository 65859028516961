const useTextColorDrag = ( verificator: boolean | null ) => {
  let finalColor = '#B52213';
  if ( verificator === null ) {
    finalColor = '#000000';
  }
  if ( verificator ) {
    finalColor = '#67B513';
  }
  return finalColor;
};

export default useTextColorDrag;
