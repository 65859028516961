import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { ISellerRegister } from '../interfaces';
import { SellersValidationSchema } from '../validators/validatioSchemas';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setSellersModalState } from '../slice/sellersSlice';
import { createSeller } from '../actions/sellersActions';
import useSellersInitialState from '../hooks/useSellersInitialState';

const SellersForm = () => {
  const dispatch = useAppDispatch();
  const { loadingCreate } = useAppSelector(( state ) => state.seller );
  const {
    getFieldProps, handleSubmit, errors, touched, resetForm,
  } = useFormik<ISellerRegister>({
    initialValues: useSellersInitialState(),
    validationSchema: SellersValidationSchema,
    enableReinitialize: true,
    onSubmit: async ( formValues ) => {
      await dispatch( createSeller( formValues ));
      dispatch( setSellersModalState( false ));
      resetForm();
    },
  });
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <CardContent sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="name"
              error={touched.name && !!errors.name}
            >
              <Typography fontWeight="bold" fontSize={12}>Nombre</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="name"
              fullWidth
              placeholder="Ingrese un Nombre"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'name' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="description"
              error={touched.lastname && !!errors.lastname}
            >
              <Typography fontWeight="bold" fontSize={12}>Apellido</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="lastname"
              fullWidth
              placeholder="Ingrese una Descripción"
              error={touched.lastname && !!errors.lastname}
              helperText={touched.lastname && errors.lastname}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'lastname' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="email"
              error={touched.email && !!errors.email}
            >
              <Typography fontWeight="bold" fontSize={12}>Correo</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="email"
              fullWidth
              placeholder="Ingrese una Descripción"
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'email' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="identification"
              error={touched.identification && !!errors.identification}
            >
              <Typography fontWeight="bold" fontSize={12}>Número de Cédula</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="identification"
              fullWidth
              placeholder="Ingrese una Descripción"
              error={touched.identification && !!errors.identification}
              helperText={touched.identification && errors.identification}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'identification' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="phone"
              error={touched.phone && !!errors.phone}
            >
              <Typography fontWeight="bold" fontSize={12}>Teléfono</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="phone"
              fullWidth
              placeholder="Ingrese una Descripción"
              error={touched.phone && !!errors.phone}
              helperText={touched.phone && errors.phone}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'phone' )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={10} display="flex" justifyContent="center" gap={2} sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingCreate}
              onClick={() => dispatch( setSellersModalState( false ))}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingCreate}
              endIcon={loadingCreate && ( <CircularProgress size={10} /> )}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

export default SellersForm;
