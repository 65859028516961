import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import CodesList from '../pages/CodesList';
import OrdersList from '../pages/OrdersList';

const CodesRouter = () => (
  <Routes>
    <Route path="/codigos" element={<CodesList title="Lista de Códigos" />} />
    <Route path="/ordenes" element={<OrdersList title="Lista de Órdenes" />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default CodesRouter;
