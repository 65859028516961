import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IInstitutionRegister } from '../interfaces';
import { InstitutionsValidationSchema } from '../validators/validatioSchemas';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setInstitutionsModalState } from '../slice/institutionsSlice';
import { createInstitution, updateInstitution } from '../actions/institutionsActions';
import useInstitutionInitialState from '../hooks/useInstitutionInitialState';
import { size5MB } from '../../../helpers/5mb';
import PhotoButton from '../../shared/custom/buttons/PhotoButton';

const typesFile = ['png', 'jpeg', 'jpg'];

const InstitutionsForm = () => {
  const dispatch = useAppDispatch();
  const { loadingCreate, institution, loadingUpdate } = useAppSelector(( state ) => state.institution );
  const [pcPhotoName, setPcPhotoName] = useState<string>( '' );
  const [pcPhotoPath, setPcPhotoPath] = useState<string>( '' );
  const {
    getFieldProps, handleSubmit, errors, touched, resetForm, setFieldValue, values,
  } = useFormik<IInstitutionRegister>({
    initialValues: useInstitutionInitialState(),
    validationSchema: InstitutionsValidationSchema,
    enableReinitialize: true,
    onSubmit: async ( formValues ) => {
      if ( institution ) {
        await dispatch( updateInstitution({ ...formValues, previousInstitution: institution }));
        setPcPhotoName( '' );
        setPcPhotoPath( '' );
      } else {
        await dispatch( createInstitution( formValues ));
        setPcPhotoName( '' );
        setPcPhotoPath( '' );
      }
      dispatch( setInstitutionsModalState( false ));
      resetForm();
    },
  });
  const inputFileRef = useRef<HTMLInputElement | null>( null );
  const onBtnClick = () => {
    if ( inputFileRef.current ) {
      inputFileRef.current.click();
    }
  };
  const onFileChangeCapture = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    if ( e.target.files && e.target.files.length > 0 ) {
      const format = e.target.files[0].type.split( '/' )[1];
      if ( size5MB( e.target.files[0].size )) {
        if ( typesFile.includes( format )) {
          setFieldValue( 'photo', e.target.files[0]);
          setFieldValue( 'newPhoto', true );
          setPcPhotoName( e.target.files[0].name );
          setPcPhotoPath( URL.createObjectURL( e.target.files[0]));
        } else {
          toast.error( 'Solo se admiten formatos PNG, JPEG, JPG' );
        }
      } else {
        toast.error( 'El tamaño maximo de la foto debe ser de 5MB' );
      }
    }
  };
  useEffect(() => {
    if ( institution ) {
      setPcPhotoPath( institution.photo );
      setPcPhotoName( 'Seleccionamos la foto anterior automaticamente' );
      setFieldValue( 'photo', institution.photo );
    } else {
      setPcPhotoPath( '' );
      setPcPhotoName( '' );
    }
  }, [institution, setFieldValue]);
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <CardContent sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="name"
              error={touched.name && !!errors.name}
            >
              <Typography fontWeight="bold" fontSize={12}>Nombre</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="name"
              fullWidth
              placeholder="Ingrese un Nombre"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'name' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="email"
              error={touched.email && !!errors.email}
            >
              <Typography fontWeight="bold" fontSize={12}>Correo</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="email"
              fullWidth
              placeholder="Ingrese una Descripción"
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'email' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="phone"
              error={touched.phone && !!errors.phone}
            >
              <Typography fontWeight="bold" fontSize={12}>Teléfono</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="phone"
              fullWidth
              placeholder="Ingrese una Descripción"
              error={touched.phone && !!errors.phone}
              helperText={touched.phone && errors.phone}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'phone' )}
            />
          </Grid>
          <Grid item xs={9} sm={11}>
            <Box display="flex" alignItems="center">
              <Avatar
                src={pcPhotoPath}
                alt="Foto"
                sx={{
                  minWidth: '15px', minHeight: '15px', maxWidth: '1.5em', maxHeight: '1.5em', mr: 2,
                }}
              />
              <input
                type="file"
                id="file"
                ref={inputFileRef}
                onChangeCapture={onFileChangeCapture}
                style={{ display: 'none' }}
              />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
              <PhotoButton
                variant="contained"
                onClick={() => onBtnClick()}
                disabled={loadingCreate || loadingUpdate}
                size="small"
              >
                Seleccionar
              </PhotoButton>
              <Typography ml={2}>
                {pcPhotoName || 'Selecciona un archivo'}
              </Typography>
            </Box>
            <Box ml={2}>
              {values.photo === null && errors.photo && touched.photo && (
                <FormHelperText error>{ errors.photo }</FormHelperText>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={10} display="flex" justifyContent="center" gap={2} sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingCreate || loadingUpdate}
              onClick={() => dispatch( setInstitutionsModalState( false ))}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingCreate || loadingUpdate}
              endIcon={( loadingCreate || loadingUpdate ) && ( <CircularProgress size={10} /> )}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

export default InstitutionsForm;
