import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getInstitutionsPaginated } from '../actions/institutionsActions';

const usePaginatedInstitutions = () => {
  const dispatch = useAppDispatch();
  const { institutionsList } = useAppSelector(( state ) => state.institution );
  return () => dispatch( getInstitutionsPaginated( institutionsList[institutionsList.length - 1]));
};

export default usePaginatedInstitutions;
