import { Box, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { auth } from '../../../../firebase';
import useTextColor from '../../../hooks/useTextColor';
import { IQuestion } from '../../../interfaces';
import { updateResponseInStore } from '../../../slice/activitySlice';

interface ITypeOpenQuestionProps {
  question: IQuestion;
}

type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const TypeOpenQuestion = ({ question }: ITypeOpenQuestionProps ) => {
  const [valueInput, setValueInput] = useState<string>( '' );
  const { alreadyQualified } = useAppSelector(( state ) => state.activity );
  const dispatch = useAppDispatch();
  const onChange = ( e: InputChange ): void => {
    if ( e.target.value !== '' ) {
      dispatch( updateResponseInStore({
        userId: auth.currentUser?.uid || '',
        questionId: question.id,
        response: e.target.value,
        type: question.type,
        groupId: question.groupId,
        correct: true,
      }));
    } else {
      dispatch( updateResponseInStore({
        userId: auth.currentUser?.uid || '',
        questionId: question.id,
        response: e.target.value,
        type: question.type,
        groupId: question.groupId,
        correct: false,
      }));
    }
    setValueInput( e.target.value );
  };
  return (
    <Box bgcolor="#EEEEEE" p={3}>
      <Typography sx={{
        color: useTextColor( question.id ),
      }}
      >
        {question.sentence}
      </Typography>
      <div>
        <TextField
          disabled={alreadyQualified}
          value={valueInput}
          onChange={onChange}
          variant="outlined"
          size="small"
          fullWidth
          multiline
          sx={{
            backgroundColor: 'white',
          }}
        />
      </div>
    </Box>
  );
};

export default TypeOpenQuestion;
