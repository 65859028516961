import {
  Card, CardContent, Typography, Box, Avatar,
} from '@mui/material';
import React from 'react';
import PhotoForm from '../PhotoForm';
import { useAppSelector } from '../../../../app/hooks';

interface ICardPhoto {
  photo: string | undefined;
}

const CardPhoto = ({ photo }: ICardPhoto ) => {
  const { userInfo } = useAppSelector(( state ) => state.auth );
  return (
    <Card
      sx={{
        borderBottom: '1px solid #EAEAEA',
        boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
        rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
        rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        p={2}
        sx={{
          borderBottom: '1px solid #EAEAEA',
        }}
      >
        <Typography
          variant="body2"
        >
          Foto de Perfil
        </Typography>
      </Box>
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
        >
          <Avatar
            sx={{ width: 130, height: 130 }}
            alt="User Photo"
            src={photo || ( userInfo && userInfo.name )}
          />
        </Box>
        <PhotoForm />
      </CardContent>
    </Card>
  );
};

export default CardPhoto;
