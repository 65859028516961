import { TreeView } from '@mui/lab';
import ArrowIcon from '@mui/icons-material/ArrowBackIos';
import { Box } from '@mui/material';
import { CSSObject } from 'tss-react';
import ThreeViewItem from './ThreeViewItem';
import { useAppSelector } from '../../../../app/hooks';
import { ICodeGeneration } from '../../../codes/interfaces';

const typographyStyles: CSSObject = {
  textTransform: 'uppercase',
  fontFamily: 'CodePro, sans-serif',
  fontWeight: 'bold',
};

const ThreeView = () => {
  const { last5Codes } = useAppSelector(( state ) => state.dashboard );
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          borderTop: '1px solid #e0e0e0',
          borderBottom: '1px solid #e0e0e0',
          padding: '0.3em 0 0.3em 0',
        }}
      >
        <Box sx={{ ...typographyStyles, marginLeft: '2em' }}>Libro</Box>
        <Box sx={{ ...typographyStyles, marginRight: '2em' }}>Activación</Box>
      </Box>
      <TreeView
        aria-label="objective"
        defaultExpanded={['3']}
        defaultCollapseIcon={(
          <ArrowIcon
            viewBox="0 0 12 24"
            sx={{ transform: 'rotate(90deg)', transition: 'transform 0.4s' }}
          />
        )}
        defaultExpandIcon={(
          <ArrowIcon
            viewBox="0 0 12 24"
            sx={{ transform: 'rotate(270deg)', transition: 'transform 0.4s' }}
          />
        )}
        defaultEndIcon={<div style={{ width: 24 }} />}
        sx={{
          height: '100%',
          flexGrow: 1,
          maxWidth: '100%',
          width: '100%',
          overflowY: 'auto',
        }}
      >
        {last5Codes.map(( code: ICodeGeneration, indexCode: number ) => (
          <ThreeViewItem
            key={`code${indexCode.toString()}`}
            nodeId={code.id || ''}
            labelText={code.book}
            labelInfo={`${code.numbers.used}/${code.numbers.created}`}
          />
        ))}
      </TreeView>
    </>
  );
};

export default ThreeView;
