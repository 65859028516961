import {
  FormHelperText, Grid, InputLabel, TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { IQuestion } from '../../interfaces';

const DragAndDropFields = () => {
  const {
    touched,
    errors,
    getFieldProps,
    setFieldValue,
  } = useFormikContext<
  Pick<IQuestion,
  'sentence' | 'correctOption' | 'incorrectOption1' | 'incorrectOption2' | 'incorrectOption3'>
  >();
  const { question: questionInStore, group } = useAppSelector(( state ) => state.activity );
  useEffect(() => {
    if ( !questionInStore && group ) {
      setFieldValue( 'type', group.type );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionInStore]);
  return (
    <>
      <Grid item xs={12}>
        <InputLabel htmlFor="sentence">Oracion</InputLabel>
        <TextField
          size="small"
          id="sentence"
          fullWidth
          placeholder="Ingrese la oración"
          error={touched.sentence && !!errors.sentence}
          helperText={touched.sentence && errors.sentence}
          sx={{
            '& .MuiOutlinedInput-root > input': {
              backgroundColor: '#F2F2F2',
            },
          }}
          {...getFieldProps( 'sentence' )}
        />
        <FormHelperText>
          Ejem: Las matemáticas son **
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="correctOption">Opción Correcta</InputLabel>
        <TextField
          size="small"
          id="correctOption"
          fullWidth
          placeholder="Ingrese la opción"
          error={touched.correctOption && !!errors.correctOption}
          helperText={touched.correctOption && errors.correctOption}
          sx={{
            '& .MuiOutlinedInput-root > input': {
              backgroundColor: '#F2F2F2',
            },
          }}
          {...getFieldProps( 'correctOption' )}
        />
        <FormHelperText>
          Ejem: dificiles
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="incorrectOption1">Opcion Incorrecta (1)</InputLabel>
        <TextField
          size="small"
          id="incorrectOption1"
          fullWidth
          placeholder="Ingrese la opción"
          error={touched.incorrectOption1 && !!errors.incorrectOption1}
          helperText={touched.incorrectOption1 && errors.incorrectOption1}
          sx={{
            '& .MuiOutlinedInput-root > input': {
              backgroundColor: '#F2F2F2',
            },
          }}
          {...getFieldProps( 'incorrectOption1' )}
        />
        <FormHelperText>
          Ejem: faciles
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="incorrectOption2">Opcion Incorrecta (2)</InputLabel>
        <TextField
          size="small"
          id="incorrectOption2"
          fullWidth
          placeholder="Ingrese la opción"
          error={touched.incorrectOption2 && !!errors.incorrectOption2}
          helperText={touched.incorrectOption2 && errors.incorrectOption2}
          sx={{
            '& .MuiOutlinedInput-root > input': {
              backgroundColor: '#F2F2F2',
            },
          }}
          {...getFieldProps( 'incorrectOption2' )}
        />
        <FormHelperText>
          Ejem: faciles
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="incorrectOption3">Opcion Incorrecta (3)</InputLabel>
        <TextField
          size="small"
          id="incorrectOption3"
          fullWidth
          placeholder="Ingrese la opción"
          error={touched.incorrectOption3 && !!errors.incorrectOption3}
          helperText={touched.incorrectOption3 && errors.incorrectOption3}
          sx={{
            '& .MuiOutlinedInput-root > input': {
              backgroundColor: '#F2F2F2',
            },
          }}
          {...getFieldProps( 'incorrectOption3' )}
        />
        <FormHelperText>
          Ejem: faciles
        </FormHelperText>
      </Grid>
    </>
  );
};

export default DragAndDropFields;
