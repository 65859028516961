import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import AdminDashboard from '../Pages/AdminDashboard';

const AdminDashboardRouter = () => (
  <Routes>
    <Route
      path="/"
      element={<AdminDashboard title="Dashboard Administrativo" />}
    />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default AdminDashboardRouter;
