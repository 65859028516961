import {
  Box, Dialog, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import { grey } from '@mui/material/colors';

interface IInfoDialogProps {
  children: JSX.Element | JSX.Element[];
  open: boolean;
  closeFunction: () => void;
  title: string;
  contentText: string;
}

const InfoDialog = ({
  children, open, closeFunction, title, contentText,
}: IInfoDialogProps ) => (
  <Dialog
    open={open}
    keepMounted
    onClose={closeFunction}
  >
    <Box sx={{
      pt: 1, pb: 1, pl: 3, pr: 3,
    }}
    >
      <DialogTitle
        sx={{
          fontWeight: 700,
          textAlign: 'center',
          fontSize: 26,
          color: grey[800],
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            textAlign: 'center',
          }}
        >
          {contentText}
        </DialogContentText>
        {children}
      </DialogContent>
    </Box>
  </Dialog>
);

export default InfoDialog;
