import {
  Button, Grid, Typography, useTheme, useMediaQuery, CircularProgress, Autocomplete, TextField,
} from '@mui/material';
import { useDebounce } from 'use-debounce';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FilterButton from '../buttons/FilterButton';
import PagesSearch from '../pagesSearch/PagesSearch';
import { useAppDispatch } from '../../../../app/hooks';
import { orderByBooks, searchFilterInStore } from '../../../books/slice/booksSlice';
import { searchFilterSellersInStore } from '../../../sellers/slice/sellersSlice';
import { searchFilterStudentsInStore } from '../../../students/slice/studentSlice';
import { searchFilterCodeInStore } from '../../../codes/slice/codesSlice';
import { orderByActivations, searchFilterActivationsInStore } from '../../../auth/slice/authSlice';
import { searchFilterInstitutionsInStore } from '../../../institutions/slice/institutionsSlice';

interface ITopCardActionsProps {
  addOrEditClick?: () => void;
  filterClick?: () => void;
  children?: JSX.Element;
  loader?: boolean;
  onlySearch?: boolean;
  hideFilter?: boolean;
  buttonText?: string;
  sort?: boolean;
}

const TopCardActions = ({
  addOrEditClick, filterClick, children, loader, buttonText, onlySearch, hideFilter, sort,
}: ITopCardActionsProps ) => {
  const theme = useTheme();
  const matchSmDowm = useMediaQuery( theme.breakpoints.down( 'sm' ));
  const [loading, setLoading] = useState<boolean>( false );
  const location = useLocation();
  const dispatch = useAppDispatch();
  const makeFilter = ( value: string ) => {
    if ( location.pathname === '/admin/libros' || location.pathname === '/estudiante/libros' || location.pathname === '/vendedor/libros' ) {
      dispatch( searchFilterInStore( value ));
    }
    if ( location.pathname === '/admin/vendedores' ) {
      dispatch( searchFilterSellersInStore( value ));
    }
    if ( location.pathname === '/admin/estudiantes' ) {
      dispatch( searchFilterStudentsInStore( value ));
    }
    if ( location.pathname === '/admin/ordenes' ) {
      dispatch( searchFilterCodeInStore( value ));
    }
    if ( location.pathname === '/estudiante/dashboard' ) {
      dispatch( searchFilterActivationsInStore( value ));
    }
    if ( location.pathname === '/admin/instituciones' ) {
      dispatch( searchFilterInstitutionsInStore( value ));
    }
    setLoading( false );
  };
  const [text, setText] = useState<string>( '' );
  const options = [{
    label: 'Añadidos Recientemente', type: 1,
  }, {
    label: 'Por Nombre', type: 2,
  }];
  const [valueDevounce] = useDebounce( text, 1000 );
  useEffect(() => {
    makeFilter( valueDevounce );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueDevounce]);
  return (
    <Grid container spacing={2} display="flex" justifyContent="space-between">
      <Grid item xs={12} md={12} lg={3} display="flex" alignItems="center">
        <PagesSearch
          onChange={( e ) => {
            setLoading( true );
            setText( e.target.value );
          }}
        />
        {loading && (
          <CircularProgress sx={{ ml: 1 }} size={30} /> )}
      </Grid>
      <Grid item xs={12} md={12} lg={6} display="flex" alignItems="center">
        {children}
      </Grid>
      <Grid item xs={12} md={12} lg={3} display="flex" gap={2} justifyContent={matchSmDowm ? 'center' : 'flex-end'}>
        {!hideFilter && (
          <FilterButton onClick={filterClick} disabled={loader} /> )}
        {!onlySearch && (
          <Button onClick={addOrEditClick} disabled={loader}>
            <Typography noWrap>
              {buttonText}
            </Typography>
          </Button>
        )}
        {sort && (
          <Autocomplete
            sx={{
              width: 150, backgroundColor: '#ffffff', '& .MuiFormControl-root': { height: '100%' }, '& .MuiOutlinedInput-root': { height: '100%' },
            }}
            options={options}
            size="small"
            renderInput={( params ) => <TextField {...params} label="Ordenar" placeholder="Ordenar" />}
            onChange={( _, value ) => {
              if ( value?.type === 1 ) {
                if ( location.pathname === '/estudiante/dashboard' ) {
                  dispatch( orderByActivations( 1 ));
                }
                dispatch( orderByBooks( 1 ));
              }
              if ( value?.type === 2 ) {
                if ( location.pathname === '/estudiante/dashboard' ) {
                  dispatch( orderByActivations( 2 ));
                }
                dispatch( orderByBooks( 2 ));
              }
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TopCardActions;
