import { useEffect } from 'react';

interface ISellerDashboardProps {
  title: string;
}

const SellerDashboard = ({ title }: ISellerDashboardProps ) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <div>
      seller dashboard
    </div>
  );
};

export default SellerDashboard;
