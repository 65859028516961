import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IActivity, IGroupActivity, IQuestion, IRegisterGroupActivity,
} from '../interfaces';
import {
  createActivity, createQuestion, deleteActivity, deleteQuestion, getActivitiesByUnitId, getGroupsAndQuestionsByActivityId, getQuestionsByActivityId, updateActivity, updateQuestion,
} from '../actions/activitiesActions';
import { IResponse } from '../components/ActivitiesPrinter/interfaces';

interface IActivityState {
  activitiesList: IActivity[];
  questionsList: IQuestion[];
  activitiesConsulted: string[],
  activity?: IActivity;
  question?: IQuestion;
  loadingGetActivity: boolean,
  loadingCreateActivity: boolean,
  loadingUpdateActivity: boolean,
  loadingDeleteActivity: boolean,
  loadingGetQuestion: boolean,
  loadingCreateQuestion: boolean,
  loadingUpdateQuestion: boolean,
  loadingDeleteQuestion: boolean,
  modalDelete: boolean,
  lastStep: boolean,
  modalCreateorEditGroup: boolean,
  groups: IGroupActivity[],
  group?: IGroupActivity,
  modalCreateorEditQuestion: boolean,
  alreadyInBd: string[],
  responses: IResponse[],
  alreadyQualified: boolean,
  modalCloseActivity: boolean,
}

const initialState: IActivityState = {
  activitiesList: [],
  questionsList: [],
  activitiesConsulted: [],
  loadingGetActivity: false,
  loadingCreateActivity: false,
  loadingUpdateActivity: false,
  loadingDeleteActivity: false,
  loadingGetQuestion: false,
  loadingCreateQuestion: false,
  loadingUpdateQuestion: false,
  loadingDeleteQuestion: false,
  modalDelete: false,
  modalCreateorEditGroup: false,
  lastStep: false,
  groups: [],
  modalCreateorEditQuestion: false,
  alreadyInBd: [],
  responses: [],
  alreadyQualified: false,
  modalCloseActivity: false,
};

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    addActivityWithIdForActions: ( state, action: PayloadAction<string> ) => {
      state.activity = state.activitiesList.find(( activity ) => activity.id === action.payload );
    },
    setDeleteActivityModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalDelete = action.payload;
      if ( action.payload === false ) {
        state.activity = undefined;
      }
    },
    addActivityInStore: ( state, action: PayloadAction<IActivity> ) => {
      state.activity = action.payload!;
    },
    addQuestionWithIdForActions: ( state, action: PayloadAction<string> ) => {
      state.question = state.questionsList.find(( question ) => question.id === action.payload );
    },
    setLastStep: ( state, action: PayloadAction<boolean> ) => {
      state.lastStep = action.payload;
    },
    clearQuestion: ( state ) => {
      state.question = undefined;
    },
    clearQuestions: ( state ) => {
      state.questionsList = [];
    },
    clearActivity: ( state ) => {
      state.activity = undefined;
    },
    clearActivities: ( state ) => {
      state.activitiesList = [];
    },
    clearActivitiesConsulted: ( state ) => {
      state.activitiesConsulted = [];
    },
    addGroupBeforeSendInStore: ( state, action: PayloadAction<IRegisterGroupActivity> ) => {
      state.groups = [...state.groups, { ...action.payload!, type: action.payload!.type?.value || '', deleted: false }];
    },
    addGroupInStore: ( state, action: PayloadAction<IGroupActivity> ) => {
      state.group = action.payload!;
    },
    setCreateorEditGroupModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalCreateorEditGroup = action.payload;
      if ( action.payload === false ) {
        state.group = undefined;
      }
    },
    setCreateOrEditQuestionModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalCreateorEditQuestion = action.payload;
      if ( action.payload === false ) {
        state.group = undefined;
        state.question = undefined;
      }
    },
    addGroupWithIdForActions: ( state, action: PayloadAction<string> ) => {
      state.group = state.groups.find(( group ) => group.id === action.payload );
    },
    addQuestionBeforeSendInStore: ( state, action: PayloadAction<IQuestion> ) => {
      state.questionsList = [...state.questionsList, action.payload!];
    },
    deleteQuestionFromStore: ( state, action: PayloadAction<string> ) => {
      if ( state.alreadyInBd.includes( action.payload )) {
        const updateCompanyFunction = () => {
          const data = state.questionsList;
          const question = data.find(
            ( item: IQuestion ) => item.id === action.payload,
          );
          const index = data.findIndex(
            ( item: IQuestion ) => item.id === action.payload,
          );
          if ( question ) {
            data.splice( index, 1, { ...question, deleted: true });
          }
          return data;
        };
        state.questionsList = updateCompanyFunction();
      } else {
        state.questionsList = state.questionsList.filter(( question ) => question.id !== action.payload );
      }
    },
    updateQuestionInStore: ( state, action: PayloadAction<IQuestion> ) => {
      const updateCompanyFunction = () => {
        const data = state.questionsList;
        const index = data.findIndex(
          ( item: IQuestion ) => item.id === action.payload.id,
        );
        data.splice( index, 1, action.payload );
        return data;
      };
      state.questionsList = updateCompanyFunction();
    },
    updateGroupInStore: ( state, action: PayloadAction<IRegisterGroupActivity> ) => {
      const updateCompanyFunction = () => {
        const data = state.groups;
        const index = data.findIndex(
          ( item: IGroupActivity ) => item.id === action.payload.id,
        );
        data.splice( index, 1, { ...action.payload!, type: action.payload!.type?.value || '', deleted: false });
        return data;
      };
      state.groups = updateCompanyFunction();
    },
    setAlreadyInBd: ( state, action: PayloadAction<string[]> ) => {
      state.alreadyInBd = [...state.alreadyInBd, ...action.payload];
    },
    deleteGroupInStore: ( state, action: PayloadAction<string> ) => {
      if ( state.alreadyInBd.includes( action.payload )) {
        const updateCompanyFunction = () => {
          const data = state.groups;
          const group = data.find(
            ( item: IGroupActivity ) => item.id === action.payload,
          );
          const index = data.findIndex(
            ( item: IGroupActivity ) => item.id === action.payload,
          );
          if ( group ) {
            data.splice( index, 1, { ...group, deleted: true });
          }
          return data;
        };
        state.groups = updateCompanyFunction();
      } else {
        state.groups = state.groups.filter(( group ) => group.id !== action.payload );
        state.questionsList = state.questionsList.filter(( question ) => question.groupId !== action.payload );
      }
    },
    clearAlreadyInBd: ( state ) => {
      state.alreadyInBd = [];
    },
    clearGroups: ( state ) => {
      state.groups = [];
    },
    addInitialResponses: ( state, action: PayloadAction<IResponse[]> ) => {
      action.payload!.forEach(( response ) => {
        const exist = state.responses.find(( item ) => item.questionId === response.questionId );
        if ( !exist ) {
          state.responses = [...state.responses, response];
        }
      });
    },
    updateResponseInStore: ( state, action: PayloadAction<IResponse> ) => {
      const updateCompanyFunction = () => {
        const data = state.responses;
        const index = data.findIndex(
          ( item: IResponse ) => item.questionId === action.payload.questionId,
        );
        data.splice( index, 1, action.payload! );
        return data;
      };
      state.responses = updateCompanyFunction();
    },
    setAsQualified: ( state, action: PayloadAction<boolean> ) => {
      state.alreadyQualified = action.payload;
    },
    clearResponses: ( state ) => {
      state.responses = [];
    },
    setModalCloseActivity: ( state, action: PayloadAction<boolean> ) => {
      state.modalCloseActivity = action.payload;
    },
    addQuestionToStore: ( state, action: PayloadAction<IQuestion> ) => {
      state.question = action.payload;
    },
  },
  extraReducers: ( builder ) => {
    builder
      .addCase( getActivitiesByUnitId.pending, ( state ) => {
        state.loadingGetActivity = true;
      })
      .addCase( getActivitiesByUnitId.fulfilled, ( state, action ) => {
        state.loadingGetActivity = false;
        const filteredActivities: IActivity[] = [];
        action.payload!.activities.forEach(( item ) => {
          const alreadyExist = state.activitiesList.find(( activity ) => activity.id === item.id );
          if ( !alreadyExist ) {
            filteredActivities.push( item );
          }
        });
        state.activitiesList = [...state.activitiesList, ...filteredActivities].sort(( a, b ) => a.created - b.created );
        state.activitiesConsulted = [...state.activitiesConsulted, action.payload!.unitId || ''];
      })
      .addCase( getActivitiesByUnitId.rejected, ( state ) => {
        state.loadingGetActivity = false;
      });
    builder
      .addCase( getQuestionsByActivityId.pending, ( state ) => {
        state.loadingGetQuestion = true;
      })
      .addCase( getQuestionsByActivityId.fulfilled, ( state, action ) => {
        state.loadingGetQuestion = false;
        state.questionsList = [...state.questionsList, ...action.payload!];
      })
      .addCase( getQuestionsByActivityId.rejected, ( state ) => {
        state.loadingGetQuestion = false;
      });
    builder
      .addCase( createActivity.pending, ( state ) => {
        state.loadingCreateActivity = true;
      })
      .addCase( createActivity.fulfilled, ( state, action ) => {
        state.loadingCreateActivity = false;
        state.activitiesList = [...state.activitiesList, action.payload!];
      })
      .addCase( createActivity.rejected, ( state ) => {
        state.loadingCreateActivity = false;
      });
    builder
      .addCase( createQuestion.pending, ( state ) => {
        state.loadingCreateQuestion = true;
      })
      .addCase( createQuestion.fulfilled, ( state, action ) => {
        state.loadingCreateQuestion = false;
        state.questionsList = [...state.questionsList, action.payload!];
      })
      .addCase( createQuestion.rejected, ( state ) => {
        state.loadingCreateQuestion = false;
      });
    builder
      .addCase( updateQuestion.pending, ( state ) => {
        state.loadingUpdateQuestion = true;
      })
      .addCase( updateQuestion.fulfilled, ( state, action ) => {
        state.loadingUpdateQuestion = false;
        const updateQuestionFunction = () => {
          const data = state.questionsList;
          const index = data.findIndex(
            ( item: IQuestion ) => item.id === action.payload!.id,
          );
          data.splice( index, 1, action.payload! );
          return data;
        };
        state.questionsList = updateQuestionFunction();
      })
      .addCase( updateQuestion.rejected, ( state ) => {
        state.loadingUpdateQuestion = false;
      });
    builder
      .addCase( updateActivity.pending, ( state ) => {
        state.loadingUpdateActivity = true;
      })
      .addCase( updateActivity.fulfilled, ( state, action ) => {
        state.loadingUpdateActivity = false;
        const updateQuestionFunction = () => {
          const data = state.activitiesList;
          const index = data.findIndex(
            ( item: IActivity ) => item.id === action.payload!.id,
          );
          data.splice( index, 1, action.payload! );
          return data;
        };
        state.activitiesList = updateQuestionFunction();
      })
      .addCase( updateActivity.rejected, ( state ) => {
        state.loadingUpdateActivity = false;
      });
    builder
      .addCase( deleteQuestion.pending, ( state ) => {
        state.loadingDeleteQuestion = true;
      })
      .addCase( deleteQuestion.fulfilled, ( state, action ) => {
        state.loadingDeleteQuestion = false;
        state.questionsList = [...state.questionsList.filter(( item ) => item.id !== action.payload! )];
      })
      .addCase( deleteQuestion.rejected, ( state ) => {
        state.loadingDeleteQuestion = false;
      });
    builder
      .addCase( deleteActivity.pending, ( state ) => {
        state.loadingDeleteActivity = true;
      })
      .addCase( deleteActivity.fulfilled, ( state, action ) => {
        state.loadingDeleteActivity = false;
        state.modalDelete = false;
        state.activitiesList = [...state.activitiesList.filter(( item ) => item.id !== action.payload! )];
      })
      .addCase( deleteActivity.rejected, ( state ) => {
        state.loadingDeleteActivity = false;
      });
    builder
      .addCase( getGroupsAndQuestionsByActivityId.pending, ( state ) => {
        state.loadingGetQuestion = true;
      })
      .addCase( getGroupsAndQuestionsByActivityId.fulfilled, ( state, action ) => {
        state.loadingGetQuestion = false;
        state.questionsList = [...action.payload!.questions];
        state.groups = [...action.payload!.groups];
        state.alreadyInBd = [...action.payload!.questions.map(( question ) => question.id ), ...action.payload!.groups.map(( group ) => group.id )];
      })
      .addCase( getGroupsAndQuestionsByActivityId.rejected, ( state ) => {
        state.loadingGetQuestion = false;
      });
  },
});

export const {
  addActivityWithIdForActions, setDeleteActivityModal, addActivityInStore, setLastStep, addQuestionWithIdForActions, clearQuestion, clearActivity, clearQuestions, clearActivities, clearActivitiesConsulted, setCreateorEditGroupModal, addGroupBeforeSendInStore, addGroupInStore, setCreateOrEditQuestionModal, addGroupWithIdForActions, addQuestionBeforeSendInStore, deleteQuestionFromStore, updateQuestionInStore, updateGroupInStore, setAlreadyInBd, deleteGroupInStore, clearAlreadyInBd, clearGroups, addInitialResponses, updateResponseInStore, setAsQualified, clearResponses, setModalCloseActivity, addQuestionToStore,
} = activitySlice.actions;

export default activitySlice.reducer;
