import { IconButton, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { useAppDispatch } from '../../../../app/hooks';
import {
  addGroupWithIdForActions,
  deleteGroupInStore,
  setCreateorEditGroupModal, setCreateOrEditQuestionModal,
} from '../../slice/activitySlice';

interface IGroupMenuProps {
  id: string;
}

const GroupMenu = ({
  id,
}: IGroupMenuProps ) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );
  const open = Boolean( anchorEl );
  const dispatch = useAppDispatch();

  const handleClick = ( event: MouseEvent<HTMLButtonElement> ) => {
    setAnchorEl( event.currentTarget );
  };

  const handleClose = () => {
    setAnchorEl( null );
  };

  const handleOpenEdit = () => {
    setAnchorEl( null );
    dispatch( setCreateorEditGroupModal( true ));
    dispatch( addGroupWithIdForActions( id ));
  };

  const handleGoToCreateActivity = () => {
    setAnchorEl( null );
    dispatch( addGroupWithIdForActions( id ));
    dispatch( setCreateOrEditQuestionModal( true ));
  };

  const handleOpenDelete = () => {
    setAnchorEl( null );
    dispatch( deleteGroupInStore( id ));
  };

  return (
    <div>
      <IconButton
        aria-label="settings"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ '& .MuiPaper-root': { borderRadius: '10px', mt: 1 } }}
      >
        <MenuItem onClick={() => handleOpenEdit()}>Editar</MenuItem>
        <MenuItem onClick={() => handleGoToCreateActivity()}>Agregar Pregunta</MenuItem>
        <MenuItem onClick={() => handleOpenDelete()}>Eliminar</MenuItem>
      </Menu>
    </div>
  );
};

export default GroupMenu;
