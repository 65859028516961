import { BrowserRouter, Route, Routes } from 'react-router-dom';
// pages
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import PrivateGuard from './guards/PrivateGuard';
import PrivateRouter from './PrivateRouter';
import PublicGuard from './guards/PublicGuard';
import Login from '../modules/auth/pages/Auth';
import { useAppDispatch } from '../app/hooks';
import { auth } from '../modules/firebase';
import { renewSession } from '../modules/auth/actions/authActions';
import CenterTotal from '../modules/shared/custom/centerTotal/CenterTotal';
import Spinner from '../modules/shared/custom/spinner/Spinner';

const AppRouter = () => {
  const dispatch = useAppDispatch();

  const [checking, setChecking] = useState( true );

  useEffect(() => {
    onAuthStateChanged( auth, async ( user ) => {
      if ( user?.uid ) {
        await dispatch( renewSession( user ));
      }

      setChecking( false );
    });
  }, [dispatch]);

  if ( checking ) {
    return (
      <CenterTotal>
        <Spinner />
      </CenterTotal>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={(
            <PublicGuard>
              <Login title="Iniciar Sesión" />
            </PublicGuard>
          )}
        />
        <Route
          path="/*"
          element={(
            <PrivateGuard>
              <PrivateRouter />
            </PrivateGuard>
          )}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
