/* eslint no-nested-ternary: "off"*/
import React, { useState } from 'react';
import {
  Form, Formik, FormikConfig, FormikValues,
} from 'formik';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useAppDispatch } from '../../../../app/hooks';
import { setLastStep } from '../../../activities/slice/activitySlice';

import StepperFormCard from '../cards/StepperFormCard';

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label?: string;
}

export function ChildrenForm({ children }: FormikStepProps ) {
  return <>{children}</>;
}

export const ParentForm = (
  { children, ...props }: FormikConfig<FormikValues>,
) => {
  const dispatch = useAppDispatch();

  const childrenArray = React.Children
    .toArray( children ) as React.ReactElement<FormikStepProps>[];

  const [step, setStep] = useState( 0 );
  const currentChild = childrenArray[step];

  const isLastStep = () => step === childrenArray.length - 1;

  return (
    <StepperFormCard>
      <Formik
        {...props}
        onSubmit={async ( values, helpers ) => {
          if ( isLastStep()) {
            await props.onSubmit( values, helpers );
          } else {
            setStep(( s ) => s + 1 );
            helpers.setTouched({});
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            {currentChild}
            <Grid
              container
              spacing={2}
              sx={{ mt: 4 }}
              justifyContent="flex-end"
            >
              {step > 0 && (
                <Grid item>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      setStep(( s ) => s - 1 );
                      dispatch( setLastStep( false ));
                    }}
                    variant="outlined"
                  >
                    Regresar
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  disabled={isSubmitting}
                  startIcon={
                    isSubmitting && <CircularProgress size={20} />
                  }
                  type="submit"
                >
                  {isSubmitting
                    ? 'Enviando'
                    : isLastStep()
                      ? 'Enviar'
                      : 'Siguiente'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </StepperFormCard>
  );
};
