import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection, doc, getDocs, limit, orderBy, query, startAfter, updateDoc, where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';
import { db, functions } from '../../firebase';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import studentConverter from '../converters/studentConverter';
import { IStudent, IStudentRegister, IStudentsFilterOptions } from '../interfaces';
import {
  addStudentInStore, clearStudentsFilter, deleteStudentInStore, filterStudentsInStore,
} from '../slice/studentSlice';

interface IRecentlyCreated {
  user: string
}

export const getStudents = createAsyncThunk(
  'student/getStudents', async () => {
    try {
      const q = query( collection( db,
        'users' ),
      where( 'deleted', '==', false ),
      where( 'type', '==', 2 ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( studentConverter ),
      );
      const students: IStudent[] = [];
      querySnapshot.forEach( async ( item ) => {
        students.push({ ...item.data() });
      });
      return students;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getStudentsPaginated = createAsyncThunk(
  'student/getStudentsPaginated', async ( student: IStudent, { dispatch }) => {
    try {
      const q = query( collection( db,
        'users' ),
      where( 'deleted', '==', false ),
      where( 'type', '==', 2 ),
      orderBy( 'created', 'asc' ), limit( 100 ),
      startAfter( new Date( student.created )));
      const querySnapshot = await getDocs(
        q.withConverter( studentConverter ),
      );
      const students: IStudent[] = [];
      querySnapshot.forEach( async ( item ) => {
        students.push({ ...item.data() });
      });
      if ( students.length > 0 ) {
        dispatch( setNoMoreData( true ));
        toast.success( 'Datos Obtenidos' );
      } else {
        dispatch( setNoMoreData( false ));
        toast.info( 'No hay mas datos' );
      }
      return students;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const createStudent = createAsyncThunk(
  'student/createStudent',
  async ( student: IStudentRegister, { dispatch }) => {
    try {
      const dataToSend = {
        ...student,
        type: 2,
      };
      const cloudFunction = httpsCallable( functions, 'user-create' );
      const { data } = await cloudFunction( dataToSend );
      const studentCreated = data as IRecentlyCreated;
      dispatch( addStudentInStore({
        id: studentCreated.user,
        ...dataToSend,
        created: new Date().getTime(),
        active: true,
        deleted: false,
        identification: 0,
        phone: 0,
        photo: '',
      }));
      toast.success( 'Estudiante Registrado Correctamente' );
    } catch ( err ) {
      toast.error( 'A ocurrido un error' );
    }
  },
);

export const getStudentsWithFilters = createAsyncThunk(
  'student/getStudentsWithFilters', async ( options: IStudentsFilterOptions, { dispatch }) => {
    try {
      toast.info( 'Obteniendo datos' );
      let q = query( collection( db,
        'users' ),
      where( 'type', '==', 2 ),
      where( 'deleted', '==', false ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      if ( options.active ) {
        q = query( q, where( 'active', '==', options.active.value ));
      }
      if ( options.from ) {
        q = query( q, where( 'created', '>=', options.from ));
      }
      if ( options.until ) {
        q = query( q, where( 'created', '<=', options.until ));
      }
      const querySnapshot = await getDocs(
        q.withConverter( studentConverter ),
      );
      const students: IStudent[] = [];
      querySnapshot.forEach( async ( item ) => {
        students.push({ ...item.data() });
      });
      dispatch( filterStudentsInStore( options ));
      toast.success( 'Datos obtenidos correctamente' );
      return students;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const deleteStudentsFilter = createAsyncThunk(
  'student/deleteStudentsFilter', async ( options: IStudentsFilterOptions, { dispatch }) => {
    try {
      if ( options.active === null && options.from === null && options.until === null ) {
        dispatch( clearStudentsFilter());
        dispatch( setNoMoreData( true ));
      } else {
        dispatch( getStudentsWithFilters( options ));
      }
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const deleteStudent = createAsyncThunk(
  'student/deleteStudent', async ( id: string, { dispatch }) => {
    try {
      const q = doc( db, 'users', id );

      await updateDoc( q, { deleted: true });

      dispatch( deleteStudentInStore( id ));

      toast.success( 'Estudiante eliminado existosamente' );
    } catch ( error ) {
      toast.error( 'Error al intentar eliminar al estudiante' );
    }
  },
);
