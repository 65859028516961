import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createCode, deleteCode, getAllCodeGenerations, getCodeGenerations, getCodeGenerationsPaginated, getCodes, getCodesPaginated, getCodesWithFilters,
} from '../actions/codesActions';
import {
  ICode, ICodeGeneration, ICodeGenerationResponse, ICodesFilterOptions,
} from '../interfaces';

interface ICodesState {
  codesList: ICode[];
  codeGenerationsList: ICodeGeneration[];
  code?: ICode;
  recentlyCreated: ICode[];
  loadingGet: boolean;
  loadingCreate: boolean;
  loadingUpdate: boolean;
  loadingDelete: boolean;
  modal: boolean;
  modalDelete: boolean;
  codeGenerationsFiltered: ICodeGeneration[];
  hasFilter: boolean;
  modalFilter: boolean;
  filterOptions?: ICodesFilterOptions;
  loadingFilter: boolean;
}

const initialState: ICodesState = {
  codesList: [],
  codeGenerationsList: [],
  recentlyCreated: [],
  loadingGet: false,
  loadingCreate: false,
  loadingUpdate: false,
  loadingDelete: false,
  modal: false,
  modalDelete: false,
  codeGenerationsFiltered: [],
  hasFilter: false,
  modalFilter: false,
  loadingFilter: false,
};

const codesSlice = createSlice({
  name: 'codes',
  initialState,
  reducers: {
    setCodesModalState: ( state, action: PayloadAction<boolean> ) => {
      state.modal = action.payload;
      if ( action.payload === false ) {
        state.code = undefined;
      }
    },
    setCodesDeleteModalState: ( state, action: PayloadAction<boolean> ) => {
      state.modalDelete = action.payload;
      if ( action.payload === false ) {
        state.code = undefined;
      }
    },
    addCodeInStore: ( state, action: PayloadAction<ICode> ) => {
      state.codesList = [...state.codesList, action.payload];
    },
    addCodesInStore: ( state, action: PayloadAction<ICodeGenerationResponse> ) => {
      state.codeGenerationsList = [...state.codeGenerationsList, action.payload.codeGeneration];
      state.recentlyCreated = action.payload.codes;
    },
    addCodeForActions: ( state, action: PayloadAction<string> ) => {
      state.code = state.codesList.find(( code ) => code.id === action.payload );
    },
    updateCodeInStore: ( state, action: PayloadAction<ICode> ) => {
      const updateCompanyFunction = () => {
        const data = state.codesList;
        const index = data.findIndex(
          ( item: ICode ) => item.id === action.payload.id,
        );
        data.splice( index, 1, action.payload );
        return data;
      };
      state.codesList = updateCompanyFunction();
    },
    deleteCodeInStore: ( state, action: PayloadAction<string> ) => {
      state.codesList = [...state.codesList.filter(( item ) => item.id !== action.payload )];
      state.modalDelete = false;
    },
    clearCode: ( state ) => {
      state.codesList = [];
      state.codeGenerationsList = [];
      state.code = undefined;
      state.recentlyCreated = [];
      state.loadingGet = false;
      state.loadingCreate = false;
      state.loadingUpdate = false;
      state.loadingDelete = false;
      state.modal = false;
      state.modalDelete = false;
      state.codeGenerationsFiltered = [];
      state.hasFilter = false;
      state.modalFilter = false;
      state.loadingFilter = false;
      state.filterOptions = undefined;
    },
    searchFilterCodeInStore: ( state, action: PayloadAction<string> ) => {
      if ( action.payload === '' ) {
        state.codeGenerationsFiltered = [];
        state.hasFilter = false;
      } else {
        state.codeGenerationsFiltered = state.codeGenerationsList.filter(( code ) => code.book.toLowerCase().includes( action.payload.toLowerCase()));
        state.hasFilter = true;
      }
    },
    setFilterCodeModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalFilter = action.payload;
    },
    filterCodesInStore: ( state, action: PayloadAction<ICodesFilterOptions> ) => {
      state.filterOptions = action.payload;
      state.hasFilter = true;
    },
    clearCodesFilter: ( state ) => {
      state.filterOptions = undefined;
      state.hasFilter = false;
      state.codeGenerationsFiltered = [];
    },
  },
  extraReducers: ( builder ) => {
    builder
      .addCase( getCodes.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getCodes.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.codesList = action.payload!;
      })
      .addCase( getCodes.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( getCodesPaginated.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getCodesPaginated.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.codesList = [...state.codesList, ...action.payload!];
      })
      .addCase( getCodesPaginated.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( createCode.pending, ( state ) => {
        state.loadingCreate = true;
      })
      .addCase( createCode.fulfilled, ( state ) => {
        state.loadingCreate = false;
      })
      .addCase( createCode.rejected, ( state ) => {
        state.loadingCreate = false;
      });
    builder
      .addCase( deleteCode.pending, ( state ) => {
        state.loadingDelete = true;
      })
      .addCase( deleteCode.fulfilled, ( state ) => {
        state.loadingDelete = false;
      })
      .addCase( deleteCode.rejected, ( state ) => {
        state.loadingDelete = false;
      });
    builder
      .addCase( getCodeGenerations.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getCodeGenerations.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.codeGenerationsList = action.payload!;
      })
      .addCase( getCodeGenerations.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( getCodeGenerationsPaginated.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getCodeGenerationsPaginated.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.codeGenerationsList = [...state.codeGenerationsList, ...action.payload!];
      })
      .addCase( getCodeGenerationsPaginated.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( getAllCodeGenerations.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getAllCodeGenerations.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.codeGenerationsList = action.payload!;
      })
      .addCase( getAllCodeGenerations.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( getCodesWithFilters.pending, ( state ) => {
        state.loadingFilter = true;
      })
      .addCase( getCodesWithFilters.fulfilled, ( state, action ) => {
        state.loadingFilter = false;
        state.codeGenerationsFiltered = action.payload!;
      })
      .addCase( getCodesWithFilters.rejected, ( state ) => {
        state.loadingFilter = false;
      });
  },
});

export const {
  setCodesModalState, addCodeInStore, addCodeForActions, updateCodeInStore, deleteCodeInStore, setCodesDeleteModalState, addCodesInStore, clearCode, searchFilterCodeInStore, setFilterCodeModal, filterCodesInStore, clearCodesFilter,
} = codesSlice.actions;

export default codesSlice.reducer;
