import { Button, ButtonProps, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '../../theme';

interface ICardBookButtonProps extends ButtonProps {
  quantity: number;
  text: string;
}

const CustomButtom = styled( Button )<ButtonProps>(() => ({
  display: 'block',
  paddingTop: '0',
  paddingBottom: '0',
}));

const CardBookButton = ( props: Omit<ICardBookButtonProps, 'variant' | 'color'> ) => {
  const { quantity, text } = props;

  return (
    <CustomButtom
      variant="outlined"
      color="inherit"
      size="small"
      {...props}
      sx={{ pl: 0, pr: 0 }}
    >
      <Typography fontWeight="bold" color={grey[800]}>
        {quantity}
      </Typography>
      <Typography fontSize={13} ml={1} mr={1} overflow="hidden">
        {text}
      </Typography>
    </CustomButtom>
  );
};

export default CardBookButton;
