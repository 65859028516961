import { useAppSelector } from '../../../../../app/hooks';

const useTopicInitialState = () => {
  const { topic } = useAppSelector(( state ) => state.unitsAndTopics );

  return {
    name: topic ? topic.name : '',
    url: topic ? topic.url : '',
  };
};

export default useTopicInitialState;
