import {
  Card, CardContent, CircularProgress, Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import BooksTable from '../components/BooksTable';
import { getBooks } from '../actions/booksActions';
import {
  clearBookInStore, clearFilter, setCreateEditBooksModal, setFilterBooksModal,
} from '../slice/booksSlice';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import TopCardActions from '../../shared/custom/cards/TopCardActions';
import BooksDialogsContainer from '../components/BooksDialogsContainer';
import { IBook } from '../interfaces';
import BooksChipsContainer from '../components/BooksChipsContainer';
import { clearActivities, clearResponses, setAsQualified } from '../../activities/slice/activitySlice';
import { trackEvent } from '../../../helpers/analytics';
import { auth } from '../../firebase';
import { clearUnitsAndTopics } from '../subModule/units/slice/unitsAndTopicsSlice';

interface IAdminBooksListProps {
  title: string;
}

const AdminBooksList = ({ title }: IAdminBooksListProps ) => {
  const dispatch = useAppDispatch();
  const setTitle = useSetTitle({
    title: 'Lista de Libros',
    subItems: ['Libros', 'Listar Libros'],
    noPrintItems: false,
  });
  const {
    booksList, loadingGet, booksFiltered, hasFilter, loadingFilter,
  } = useAppSelector(( state ) => state.book );
  const [books, setBooks] = useState<IBook[]>([]);
  useEffect(() => {
    document.title = title;
    dispatch( getBooks());
    dispatch( setNoMoreData( true ));
    dispatch( clearBookInStore());
    dispatch( clearFilter());
    dispatch( setAsQualified( false ));
    dispatch( clearResponses());
    dispatch( clearActivities());
    dispatch( clearUnitsAndTopics());
  }, [title, dispatch]);
  useEffect(() => {
    setBooks( booksFiltered.length > 0 || hasFilter ? booksFiltered : booksList );
  }, [booksFiltered, booksList, hasFilter]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      {loadingGet ? (
        <Grid textAlign="center">
          <CircularProgress size={40} />
        </Grid>
      ) : (
        <Card>
          <CardContent>
            <TopCardActions
              loader={loadingFilter}
              buttonText="Agregar Libro"
              addOrEditClick={() => {
                dispatch( setCreateEditBooksModal( true ));
                trackEvent( 'push_add_book_button_admin', {
                  elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de Libros',
                });
              }}
              filterClick={() => {
                dispatch( setFilterBooksModal( true ));
                trackEvent( 'push_filter_book_button_admin', {
                  elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de Libros',
                });
              }}
            >
              <BooksChipsContainer filters={hasFilter} />
            </TopCardActions>
            <BooksTable books={books} />
          </CardContent>
        </Card>
      )}
      <BooksDialogsContainer />
    </>
  );
};

export default AdminBooksList;
