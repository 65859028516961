import { InputLabel, InputLabelProps } from '@mui/material';
import { styled } from '../../theme';

const CustomInputLabel = styled( InputLabel )<InputLabelProps>(() => ({
  marginTop: '-0.5em',
  '&.Mui-focused': {
    marginTop: '0',
  },
  '&.MuiFormLabel-filled': {
    marginTop: '0',
  },
}));

export default CustomInputLabel;
