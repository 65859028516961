import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getGroupsAndQuestionsByActivityId } from '../actions/activitiesActions';
import Printer from '../components/ActivitiesPrinter/Printer';

interface ITestActivityProps {
  title: string;
}

const TestActivity = ({ title }: ITestActivityProps ) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { unit } = useAppSelector(( state ) => state.unitsAndTopics );
  const { activity } = useAppSelector(( state ) => state.activity );
  useEffect(() => {
    if ( !unit || !activity ) {
      navigate( '/' );
    }
    if ( activity ) {
      dispatch( getGroupsAndQuestionsByActivityId( activity?.id || '' ));
    }
    document.title = title;
  }, [title, navigate, unit, activity, dispatch]);
  return (
    <Printer />
  );
};

export default TestActivity;
