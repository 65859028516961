import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpsCallable } from 'firebase/functions';
import {
  updatePassword,
} from 'firebase/auth';
import { toast } from 'react-toastify';
import { reauthenticate } from '../helper/reauthenticate';
import { auth, functions } from '../../firebase';
import { IPasswords } from '../interfaces';

export const changePassword = createAsyncThunk(
  'profile/changePassword',
  async ( passwords: IPasswords ) => {
    try {
      const user = auth.currentUser;
      if ( user ) {
        await reauthenticate( passwords.passwordOld );
        await updatePassword( user, passwords.password );
        toast.success( 'Contraseña cambiada correctamente' );
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch ( err: any ) {
      // eslint-disable-next-line no-nested-ternary
      const message = err.code === 'auth/wrong-password'
        ? 'Las credenciales ingrresadas son incorrectas'
        : err.code === 'auth/too-many-requests'
          ? 'Usuario desabililitado temporalmente'
          : 'Ocurrió un error al iniciar sesión';
      toast.error( message );
    }
  },
);

export const deleteUserAction = createAsyncThunk(
  'profile/deleteUserAction',
  async ( password: string ) => {
    try {
      const user = auth.currentUser;
      if ( user ) {
        await reauthenticate( password );
        const cf = httpsCallable( functions, 'user-delete' );
        await cf();
        toast.success( 'Usuario eliminado correctamente' );
      }
    } catch ( err ) {
      toast.error( 'A ocurrido un error.' );
    }
  },
);
