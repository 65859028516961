import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import InstitutionsList from '../pages/InstitutionsList';

const InstitutionsRouter = () => (
  <Routes>
    <Route path="/" element={<InstitutionsList title="Lista de Instituciones" />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default InstitutionsRouter;
