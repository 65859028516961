import {
  Box, Chip, useMediaQuery, useTheme,
} from '@mui/material';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import useDraggableScroll from '../../shared/Hooks/useDraggableScroll';
import { deleteFilter } from '../actions/booksActions';

interface IBooksChipsContainerProps {
  filters: boolean;
}

const BooksChipsContainer = ({ filters }: IBooksChipsContainerProps ) => {
  const { filterOptions, loadingFilter } = useAppSelector(( state ) => state.book );
  const dispatch = useAppDispatch();
  const ref = useRef( null );
  const { onMouseDown } = useDraggableScroll( ref );
  const theme = useTheme();
  const matchSmDowm = useMediaQuery( theme.breakpoints.down( 'sm' ));
  return (
    <Box
      display="flex"
      gap={2}
      alignItems="center"
      justifyContent={filters || matchSmDowm ? 'center' : 'flex-end'}
      sx={{
        flexWrap: 'nowrap',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
      }}
      ref={ref}
      onMouseDown={onMouseDown}
    >
      {filterOptions ? (
        <>
          {filterOptions.editorial && (
            <Chip
              label={filterOptions.editorial}
              variant="outlined"
              disabled={loadingFilter}
              onDelete={() => dispatch( deleteFilter({ ...filterOptions, editorial: null }))}
            />
          )}
          {filterOptions.category && (
            <Chip
              label={filterOptions.category}
              variant="outlined"
              disabled={loadingFilter}
              onDelete={() => dispatch( deleteFilter({ ...filterOptions, category: null }))}
            />
          )}
          {filterOptions.subCategory && (
            <Chip
              label={filterOptions.subCategory}
              variant="outlined"
              disabled={loadingFilter}
              onDelete={() => dispatch( deleteFilter({ ...filterOptions, subCategory: null }))}
            />
          )}
          {filterOptions.status && (
            <Chip
              label={filterOptions.status?.label}
              variant="outlined"
              disabled={loadingFilter}
              onDelete={() => dispatch( deleteFilter({ ...filterOptions, status: null }))}
            />
          )}
        </>
      ) : ( <Chip label="No hay filtros" variant="outlined" /> )}
    </Box>
  );
};

export default BooksChipsContainer;
