import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Loupe1 } from '../../../../assets/svg/thatBook/loupe1.svg';

const PagesSearch = ( props: TextFieldProps ) => {
  const location = useLocation();
  return (
    <TextField
      {...props}
      id="input-with-icon-textfield"
      size="small"
      placeholder="Buscar..."
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: location.pathname === '/estudiante/dashboard' || location.pathname === '/estudiante/libros' || location.pathname === '/vendedor/libros' ? '#ffffff' : '#F2F2F2',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Loupe1 width={25} height={20} />
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};

export default PagesSearch;
