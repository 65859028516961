import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, updateDoc, where,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import sha256 from 'crypto-js/sha256';
import { setActivationInStore } from '../../auth/slice/authSlice';
import bookConverter from '../../books/converters/bookConverter';
import codeGenerationsConverters from '../../codes/converters/codeGenerationsConverters';
import codesConverter from '../../codes/converters/codesConverter';
import { ICodeGeneration } from '../../codes/interfaces';
import { db, auth } from '../../firebase';
import staticConverter from '../converters/staticConverter';
import staticConverterUser from '../converters/staticConverterUser';
import { IStatistic, IStatistics, IStatisticUser } from '../interfaces';
import statisticsConverter from '../converters/statisticsConverter';

export const activateCode = createAsyncThunk(
  'dashboard/activateCode',
  async ( activationCode: string, { dispatch }) => {
    try {
      const userId = auth.currentUser?.uid;
      const docRefCode = doc( db, 'activationCodes', activationCode ).withConverter( codesConverter );
      const docSnapCode = await getDoc( docRefCode );
      const codeInfo = docSnapCode.data();
      if ( codeInfo?.used ) {
        return toast.error( 'El código ya ha sido cangeado.' );
      }
      if ( !codeInfo ) {
        return toast.error( 'Código de activacion no existente.' );
      }
      const docRefBook = doc( db, 'books', codeInfo?.bookId || '' ).withConverter( bookConverter );
      const docSnapBook = await getDoc( docRefBook );
      const bookInfo = docSnapBook.data();
      if ( !bookInfo ) {
        return toast.error( 'Libro no existente.' );
      }
      const dataToSend = {
        created: new Date(), code: activationCode, status: 0, bookId: bookInfo?.id || '', userId,
      };
      const activationCreated = await addDoc(
        collection( db, `users/${userId}/activations` ), dataToSend,
      );
      await updateDoc(
        doc( db, 'activationCodes', activationCode ), { used: true, userId: userId || '' },
      );
      dispatch( setActivationInStore({
        ...dataToSend, id: activationCreated.id, created: dataToSend.created.getTime(), book: { ...bookInfo, created: bookInfo.created }, userId: userId || '',
      }));
      toast.success( 'Código cangeado correctamente' );
    } catch ( err ) {
      toast.error( 'A ocurrido un error.' );
    }
  },
);

export const getLast5Codes = createAsyncThunk(
  'dashboard/getLast5Codes',
  async () => {
    try {
      const q = query( collection( db,
        'codeGenerations' ),
      orderBy( 'created', 'asc' ), limit( 5 ));
      const querySnapshot = await getDocs(
        q.withConverter( codeGenerationsConverters ),
      );
      const codesGenerations: ICodeGeneration[] = [];
      querySnapshot.forEach( async ( item ) => {
        codesGenerations.push({ ...item.data() });
      });
      return codesGenerations;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getUsersStatistic = createAsyncThunk(
  'dashboard/getUsersStatistic',
  async () => {
    try {
      const q = query( collection( db,
        'statistics' ), where( 'category', '==', 'users' ));
      const querySnapshot = await getDocs(
        q.withConverter( staticConverterUser ),
      );
      const statistics: IStatisticUser[] = [];
      querySnapshot.forEach( async ( item ) => {
        statistics.push({ ...item.data() });
      });
      return statistics[0];
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getCodesStatistic = createAsyncThunk(
  'dashboard/getCodesStatistic',
  async () => {
    try {
      const q = query( collection( db,
        'statistics' ), where( 'category', '==', 'codes' ));
      const querySnapshot = await getDocs(
        q.withConverter( staticConverter ),
      );
      const statistics: IStatistic[] = [];
      querySnapshot.forEach( async ( item ) => {
        statistics.push({ ...item.data() });
      });
      return statistics[0];
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getBooksStatistic = createAsyncThunk(
  'dashboard/getBooksStatistic',
  async () => {
    try {
      const q = query( collection( db,
        'statistics' ), where( 'category', '==', 'books' ));
      const querySnapshot = await getDocs(
        q.withConverter( staticConverter ),
      );
      const statistics: IStatistic[] = [];
      querySnapshot.forEach( async ( item ) => {
        statistics.push({ ...item.data() });
      });
      return statistics[0];
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getAllStatistic = createAsyncThunk(
  'dashboard/getAllStatistic',
  async () => {
    try {
      const theSha = sha256( `general` );
      const docRef = doc( db, 'statistics', theSha.toString()).withConverter( statisticsConverter );
      const docSnap = await getDoc( docRef );
      return docSnap.data();
    } catch ( err ) {
      console.log( err );
    }
  },
);

export const getLast7days = createAsyncThunk(
  'dashboard/getLast7days',
  async () => {
    try {
      const currentYear: string = new Date().getFullYear().toString();
      const currentMonth: string = ( new Date().getMonth() + 1 ) < 10 ? `0${new Date().getMonth() + 1}` : ( new Date().getMonth() + 1 ).toString();
      const today: number = new Date().getDate();
      const theEndDay: number = today - 6;
      const theDays: string[] = [];
      for ( let i = Math.sign( theEndDay ) === -1 ? 1 : theEndDay; i <= today; i++ ) {
        const theDay = i < 10 ? `0${i}` : `${i}`;
        theDays.push( `${currentYear}${currentMonth}${theDay}` );
      }
      const q = query( collection( db,
        'statistics' ), where( 'period', 'in', theDays ));
      const querySnapshot = await getDocs(
        q.withConverter( statisticsConverter ),
      );
      const statistics: IStatistics[] = [];
      querySnapshot.forEach( async ( item ) => {
        statistics.push({ ...item.data() });
      });
      return statistics;
    } catch ( error ) {
      console.log( error );
    }
  },
);
