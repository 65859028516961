import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { IUnitRegister } from '../interfaces';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import useUnitInitialState from '../hooks/useUnitInitialState';
import { UnitValidationSchema } from '../validators/unitValidationSchema';
import { setCreateEditUnitsModal } from '../slice/unitsAndTopicsSlice';
import { createUnit, updateUnit } from '../actions/units&TopicsActions';

const UnitsForm = () => {
  const { loadingUnitCreate, loadingUnitUpdate, unit } = useAppSelector(( state ) => state.unitsAndTopics );
  const { book } = useAppSelector(( state ) => state.book );
  const dispatch = useAppDispatch();
  const {
    getFieldProps, handleSubmit, errors, touched, resetForm,
  } = useFormik<IUnitRegister>({
    initialValues: useUnitInitialState(),
    validationSchema: UnitValidationSchema,
    enableReinitialize: true,
    onSubmit: async ( formValues ) => {
      if ( unit === undefined && book ) {
        await dispatch( createUnit({ ...formValues, bookId: book.id, bookName: book.name }));
      }
      if ( unit && book ) {
        await dispatch( updateUnit({
          ...unit, name: formValues.name, description: formValues.description, id: unit?.id || '',
        }));
      }
      dispatch( setCreateEditUnitsModal( false ));
      resetForm();
    },
  });
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <CardContent sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="name"
              error={touched.name && !!errors.name}
            >
              <Typography fontWeight="bold" fontSize={12}>Nombre</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="name"
              fullWidth
              placeholder="Ingrese un Nombre"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'name' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="description"
              error={touched.description && !!errors.description}
            >
              <Typography fontWeight="bold" fontSize={12}>Descripción</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="description"
              fullWidth
              placeholder="Ingrese un Nombre"
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'description' )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={10} display="flex" justifyContent="center" gap={2} sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingUnitCreate || loadingUnitUpdate}
              onClick={() => dispatch( setCreateEditUnitsModal( false ))}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingUnitCreate || loadingUnitUpdate}
              endIcon={( loadingUnitCreate || loadingUnitUpdate ) && ( <CircularProgress size={10} /> )}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

export default UnitsForm;
