import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INavbarInfo } from '../interfaces';

interface IUiState {
  sidebar: boolean;
  pagination: boolean;
  page: number;
  counter: number;
  navbarInfo: INavbarInfo;
}

const initialState: IUiState = {
  sidebar: false,
  pagination: true,
  page: 0,
  counter: 1,
  navbarInfo: {
    title: '',
    subItems: [],
    noPrintItems: false,
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSidebarState: ( state, action: PayloadAction<boolean> ) => {
      state.sidebar = action.payload;
    },
    setNoMoreData: ( state, action: PayloadAction<boolean> ) => {
      state.pagination = action.payload;
      state.page = 0;
    },
    setPage: ( state, action: PayloadAction<number> ) => {
      state.page = action.payload;
    },
    setCounter: ( state, action: PayloadAction<number> ) => {
      state.counter = action.payload;
    },
    clearUi: ( state ) => {
      state.sidebar = false;
      state.pagination = true;
      state.page = 0;
    },
    setTitle: ( state, action: PayloadAction<INavbarInfo> ) => {
      state.navbarInfo = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setSidebarState, setNoMoreData, setPage, clearUi, setCounter, setTitle,
} = uiSlice.actions;

export default uiSlice.reducer;
