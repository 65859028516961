import {
  Box, FormHelperText, Grid, InputLabel, Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { useAppSelector } from '../../../../app/hooks';
import { size2MB } from '../../../../helpers/2mb';
import PhotoButton from '../../../shared/custom/buttons/PhotoButton';
import { IFileContainer, IQuestion } from '../../interfaces';

const typesFile = ['png', 'jpeg', 'jpg'];

interface IDragAndDropImagesFieldsProps{
  passingFile: ( data: IFileContainer ) => void;
}

const DragAndDropImagesFields = ({ passingFile }: IDragAndDropImagesFieldsProps ) => {
  const {
    errors,
    setFieldValue,
    values,
    touched,
  } = useFormikContext<
  Pick<IQuestion,
  'sentence' | 'correctOption' | 'incorrectOption1' | 'incorrectOption2' | 'incorrectOption3' | 'id'>
  >();
  const { question: questionInStore, group } = useAppSelector(( state ) => state.activity );
  const inputFileRefSentence = useRef<HTMLInputElement | null>( null );
  const inputFileRefCorrect = useRef<HTMLInputElement | null>( null );
  const inputFileRefIncorrect1 = useRef<HTMLInputElement | null>( null );
  const inputFileRefIncorrect2 = useRef<HTMLInputElement | null>( null );
  const inputFileRefIncorrect3 = useRef<HTMLInputElement | null>( null );
  const [pcFileNameSentence, setPcFileNameSentence] = useState<string>( '' );
  const [pcFileNameCorrectOption, setPcFileNameCorrectOption] = useState<string>( '' );
  const [pcFileNameIncorrectOption1, setPcFileNameIncorrectOption1] = useState<string>( '' );
  const [pcFileNameIncorrectOption2, setPcFileNameIncorrectOption2] = useState<string>( '' );
  const [pcFileNameIncorrectOption3, setPcFileNameIncorrectOption3] = useState<string>( '' );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onBtnClick = ( e: any ) => {
    switch ( e.target.id ) {
      case 'sentenceButton':
        if ( inputFileRefSentence.current ) {
          inputFileRefSentence.current.click();
        }
        break;
      case 'correctOptionButton':
        if ( inputFileRefCorrect.current ) {
          inputFileRefCorrect.current.click();
        }
        break;
      case 'incorrectOption1Button':
        if ( inputFileRefIncorrect1.current ) {
          inputFileRefIncorrect1.current.click();
        }
        break;
      case 'incorrectOption2Button':
        if ( inputFileRefIncorrect2.current ) {
          inputFileRefIncorrect2.current.click();
        }
        break;
      case 'incorrectOption3Button':
        if ( inputFileRefIncorrect3.current ) {
          inputFileRefIncorrect3.current.click();
        }
        break;
      default:
        break;
    }
  };
  const onFileChangeCapture = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    console.log( e.target.id );
    if ( e.target.files && e.target.files.length > 0 ) {
      const format = e.target.files[0].type.split( '/' )[1];
      if ( size2MB( e.target.files[0].size )) {
        if ( typesFile.includes( format )) {
          switch ( e.target.id ) {
            case 'sentence':
              setPcFileNameSentence( e.target.files[0].name );
              setFieldValue( 'sentence', 'sentence' );
              break;
            case 'correctOption':
              setPcFileNameCorrectOption( e.target.files[0].name );
              setFieldValue( 'correctOption', 'correctOption' );
              break;
            case 'incorrectOption1':
              setPcFileNameIncorrectOption1( e.target.files[0].name );
              setFieldValue( 'incorrectOption1', 'incorrectOption1' );
              break;
            case 'incorrectOption2':
              setPcFileNameIncorrectOption2( e.target.files[0].name );
              setFieldValue( 'incorrectOption2', 'incorrectOption2' );
              break;
            case 'incorrectOption3':
              setPcFileNameIncorrectOption3( e.target.files[0].name );
              setFieldValue( 'incorrectOption3', 'incorrectOption3' );
              break;
            default:
              break;
          }
          passingFile({ id: `${values.id}${e.target.id}`, file: e.target.files[0] });
          console.log( `${values.id}${e.target.id}` );
        } else {
          toast.error( 'Solo se admiten formatos PNG, JPEG, JPG' );
        }
      } else {
        toast.error( 'El tamaño maximo de la foto debe ser de 2MB' );
      }
    }
  };
  useEffect(() => {
    if ( values.id === '' ) {
      setFieldValue( 'id', v4());
    }
  }, [setFieldValue, values.id]);
  useEffect(() => {
    if ( questionInStore ) {
      const text = 'Seleccionamos el archivo anterior automaticamente.';
      setPcFileNameSentence( text );
      setPcFileNameIncorrectOption1( text );
      setPcFileNameIncorrectOption2( text );
      setPcFileNameIncorrectOption3( text );
      setPcFileNameCorrectOption( text );
    } else if ( group ) {
      setFieldValue( 'type', group.type );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionInStore]);
  return (
    <>
      <Grid item xs={12}>
        <InputLabel htmlFor="sentence">Imagen de la pregunta</InputLabel>
        <Box display="flex" alignItems="center">
          <input
            type="file"
            id="sentence"
            ref={inputFileRefSentence}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          <PhotoButton
            variant="contained"
            onClick={( e ) => onBtnClick( e )}
            id="sentenceButton"
            size="small"
            error={values.sentence === null && errors.sentence !== undefined}
          >
            Seleccionar
          </PhotoButton>
          <Typography ml={2}>
            {pcFileNameSentence || 'Selecciona un archivo'}
          </Typography>
        </Box>
        { errors.sentence && touched.sentence && (
          <FormHelperText
            sx={{ fontSize: 14 }}
            error
          >
            {errors.sentence}
          </FormHelperText>
        ) }
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="correctOption">Opción Correcta</InputLabel>
        <Box display="flex" alignItems="center">
          <input
            type="file"
            id="correctOption"
            ref={inputFileRefCorrect}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          <PhotoButton
            variant="contained"
            onClick={( e ) => onBtnClick( e )}
            id="correctOptionButton"
            size="small"
            error={values.correctOption === null && errors.correctOption !== undefined}
          >
            Seleccionar
          </PhotoButton>
          <Typography ml={2}>
            {pcFileNameCorrectOption || 'Selecciona un archivo'}
          </Typography>
        </Box>
        { errors.correctOption && touched.correctOption && (
          <FormHelperText
            sx={{ fontSize: 14 }}
            error
          >
            {errors.correctOption}
          </FormHelperText>
        ) }
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="incorrectOption1">Opcion Incorrecta (1)</InputLabel>
        <Box display="flex" alignItems="center">
          <input
            type="file"
            id="incorrectOption1"
            ref={inputFileRefIncorrect1}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          <PhotoButton
            variant="contained"
            onClick={( e ) => onBtnClick( e )}
            size="small"
            id="incorrectOption1Button"
            error={values.incorrectOption1 === null && errors.incorrectOption1 !== undefined}
          >
            Seleccionar
          </PhotoButton>
          <Typography ml={2}>
            {pcFileNameIncorrectOption1 || 'Selecciona un archivo'}
          </Typography>
        </Box>
        { errors.incorrectOption1 && touched.incorrectOption1 && (
          <FormHelperText
            sx={{ fontSize: 14 }}
            error
          >
            {errors.incorrectOption1}
          </FormHelperText>
        ) }
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="incorrectOption2">Opcion Incorrecta (2)</InputLabel>
        <Box display="flex" alignItems="center">
          <input
            type="file"
            id="incorrectOption2"
            ref={inputFileRefIncorrect2}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          <PhotoButton
            variant="contained"
            onClick={( e ) => onBtnClick( e )}
            size="small"
            id="incorrectOption2Button"
            error={values.incorrectOption2 === null && errors.incorrectOption2 !== undefined}
          >
            Seleccionar
          </PhotoButton>
          <Typography ml={2}>
            {pcFileNameIncorrectOption2 || 'Selecciona un archivo'}
          </Typography>
        </Box>
        { errors.incorrectOption2 && touched.incorrectOption2 && (
          <FormHelperText
            sx={{ fontSize: 14 }}
            error
          >
            {errors.incorrectOption2}
          </FormHelperText>
        ) }
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="incorrectOption3">Opcion Incorrecta (3)</InputLabel>
        <Box display="flex" alignItems="center">
          <input
            type="file"
            id="incorrectOption3"
            ref={inputFileRefIncorrect3}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          <PhotoButton
            variant="contained"
            onClick={( e ) => onBtnClick( e )}
            size="small"
            id="incorrectOption3Button"
            error={values.incorrectOption3 === null && errors.incorrectOption3 !== undefined}
          >
            Seleccionar
          </PhotoButton>
          <Typography ml={2}>
            {pcFileNameIncorrectOption3 || 'Selecciona un archivo'}
          </Typography>
        </Box>
        { errors.incorrectOption3 && touched.incorrectOption3 && (
          <FormHelperText
            sx={{ fontSize: 14 }}
            error
          >
            {errors.incorrectOption3}
          </FormHelperText>
        ) }
      </Grid>
    </>
  );
};

export default DragAndDropImagesFields;
