import { useAppSelector } from '../../../app/hooks';

const useTextColor = ( questionId: string ) => {
  const { responses, alreadyQualified } = useAppSelector(( state ) => state.activity );
  let finalColor = '#B52213';
  if ( !alreadyQualified ) {
    finalColor = '#000000';
  }
  if ( alreadyQualified && responses.find(( response ) => response.questionId === questionId )?.correct ) {
    finalColor = '#67B513';
  }
  return finalColor;
};

export default useTextColor;
