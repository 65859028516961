import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TextField } from '@mui/material';
import { ActivityFormValues } from '../../interfaces';

const Information = () => {
  const {
    touched,
    errors,
    getFieldProps,
  } = useFormikContext<
  Pick<ActivityFormValues,
  'name' | 'description'>
  >();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <InputLabel htmlFor="name">Nombre</InputLabel>
        <TextField
          size="small"
          id="name"
          fullWidth
          placeholder="Ingrese una Descripción"
          error={touched.name && !!errors.name}
          helperText={touched.name && errors.name}
          sx={{
            '& .MuiOutlinedInput-root > input': {
              backgroundColor: '#F2F2F2',
            },
          }}
          {...getFieldProps( 'name' )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputLabel htmlFor="description">Descripción</InputLabel>
        <TextField
          size="small"
          id="description"
          fullWidth
          placeholder="Ingrese una Descripción"
          error={touched.description && !!errors.description}
          helperText={touched.description && errors.description}
          sx={{
            '& .MuiOutlinedInput-root > input': {
              backgroundColor: '#F2F2F2',
            },
          }}
          {...getFieldProps( 'description' )}
        />
      </Grid>
    </Grid>
  );
};

export default Information;
