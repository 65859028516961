import {
  Box,
  Card, CardContent, Grid, Tab, Tabs, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import CardInfoBook from '../components/CardInfoBook';
import StudentUnitCard from '../subModule/units/components/StudentUnitCard';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel( props: TabPanelProps ) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps( index: number ) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

const StudentsPreviewBookDetail = () => {
  const { book } = useAppSelector(( state ) => state.book );
  const navigate = useNavigate();
  const setTitle = useSetTitle({
    title: 'Previsualición de Libro',
    subItems: ['Libros', 'Previsualizar Libro', book?.name || ''],
    noPrintItems: false,
  });
  const [value, setValue] = useState( 0 );
  const handleChange = ( event: React.SyntheticEvent, newValue: number ) => {
    setValue( newValue );
  };
  useEffect(() => {
    if ( !book ) {
      navigate( '/' );
    }
  }, [book, navigate]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      {book && (
        <Grid container spacing={4}>
          <Grid item xs={12} lg={3}>
            <CardInfoBook book={book} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Grid container>
              <Grid item xs={12}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="profile-tab"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab label="Lectura" {...a11yProps( 0 )} />
                  <Tab label="Unidades & Actividades" {...a11yProps( 1 )} />
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <TabPanel value={value} index={0}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" fontWeight="bold">
                        Libro
                      </Typography>
                      <iframe
                        width="100%"
                        height="500px"
                        src={book.urlDemo}
                        title={book.name}
                        seamless={false}
                        // allowTransparency
                        allowFullScreen
                      />
                    </CardContent>
                  </Card>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <StudentUnitCard />
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StudentsPreviewBookDetail;
