import { useAppSelector } from '../../../app/hooks';

const useStudentsInitialState = () => {
  const { student } = useAppSelector(( state ) => state.student );

  return {
    name: student ? student.name : '',
    lastname: student ? student.lastname : '',
    email: student ? student.email : '',
  };
};

export default useStudentsInitialState;
