import { useAppSelector } from '../../../app/hooks';

const useActivityInitialState = () => {
  const { activity } = useAppSelector(( state ) => state.activity );

  return {
    name: activity ? activity.name : '',
    description: activity ? activity.description : '',
    bookId: activity ? activity.bookId : '',
    unit: activity ? activity.unitId : '',
  };
};

export default useActivityInitialState;
