import { PlayArrow } from '@mui/icons-material';
import {
  Box,
  Card, CardContent, CardMedia, Fab, Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { addBookForActions } from '../../../books/slice/booksSlice';
import { IBook } from '../../../books/interfaces';
import { auth } from '../../../firebase';
import { trackEvent } from '../../../../helpers/analytics';

interface IBookCardProps {
  book: IBook;
}

const BookCard = ({ book }: IBookCardProps ) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const goToView = async () => {
    navigate( '/estudiante/libros/ver-libro' );
    dispatch( addBookForActions( book ));
    trackEvent( 'push_view_my_book_buttom_student', {
      elementPushed: 'buttom', userInAction: auth.currentUser?.uid, bookName: book.name, viewName: 'Lista de libros',
    });
  };
  return (
    <Card sx={{ minHeight: 395, maxHeight: 395 }}>
      <CardContent>
        <CardMedia sx={{ pl: 2, pr: 2, pb: 1 }} component="img" image={book.photo} alt={book.name} width={229} height={256} />
        <Box textAlign="center">
          <Typography variant="body2" fontWeight="bold" color={grey[800]} noWrap>
            {book.name}
          </Typography>
          <Typography variant="body2" mb={1}>
            Editorial
            {' '}
            {book.editorial}
          </Typography>
          <Fab sx={{ backgroundColor: '#FECA19', color: 'white' }} onClick={() => goToView()}>
            <PlayArrow />
          </Fab>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BookCard;
