import { Box } from '@mui/material';
import React from 'react';

interface ICenterTotalProps {
  children: JSX.Element | JSX.Element[]
}

const CenterTotal: React.FC<ICenterTotalProps> = ( props ) => {
  const { children } = props;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
      flexDirection="column"
    >
      {children}
    </Box>
  );
};

export default CenterTotal;
