import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IInstitution } from '../interfaces';

const institutionConverter = {
  toFirestore: ( institution: IInstitution ) => ({
    ...institution,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IInstitution => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      active,
      deleted,
      email,
      name,
      phone,
      photo,
      creator,
    } = data;

    return {
      id: snapshot.id,
      active,
      deleted,
      email,
      name,
      phone,
      photo,
      created,
      creator,
    } as IInstitution;
  },
};

export default institutionConverter;
