import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IBook } from '../interfaces';

const bookConverter = {
  toFirestore: ( book: IBook ) => ({
    ...book,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IBook => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      category,
      creator,
      deleted,
      description,
      editorial,
      name,
      photo,
      status,
      subCategory,
      url,
      urlDemo,
      statistics,
    } = data;

    return {
      id: snapshot.id,
      category,
      creator,
      deleted,
      description,
      editorial,
      name,
      photo,
      status,
      subCategory,
      url,
      urlDemo,
      created,
      statistics,
    } as IBook;
  },
};

export default bookConverter;
