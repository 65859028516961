import {
  Box,
  FormHelperText, Grid, InputLabel, TextField, Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { useAppSelector } from '../../../../app/hooks';
import { size5MB } from '../../../../helpers/5mb';
import PhotoButton from '../../../shared/custom/buttons/PhotoButton';
import { IFileContainer, IQuestion } from '../../interfaces';

const typesFile = ['pdf', 'docx', 'txt'];

interface IFileFieldsProps{
  passingFile: ( data: IFileContainer ) => void;
}

const FileFields = ({ passingFile }: IFileFieldsProps ) => {
  const { question: questionInStore, group } = useAppSelector(( state ) => state.activity );
  const {
    touched,
    errors,
    getFieldProps,
    setFieldValue,
    values,
  } = useFormikContext<
  Pick<IQuestion,
  'sentence' | 'indications' | 'id'>
  >();
  const inputFileRef = useRef<HTMLInputElement | null>( null );
  const [pcFileName, setPcFileName] = useState<string>( '' );
  const onBtnClick = () => {
    if ( inputFileRef.current ) {
      inputFileRef.current.click();
    }
  };
  const onFileChangeCapture = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    if ( e.target.files && e.target.files.length > 0 ) {
      const format = e.target.files[0].type.split( '/' )[1];
      if ( size5MB( e.target.files[0].size )) {
        if ( typesFile.includes( format )) {
          setFieldValue( 'indications', e.target.files[0].name );
          setFieldValue( 'newFile', true );
          setPcFileName( e.target.files[0].name );
          passingFile({ id: values.id, file: e.target.files[0] });
        } else {
          toast.error( 'Solo se admiten formatos PDF, DOCX, TXT' );
        }
      } else {
        toast.error( 'El tamaño maximo del archivo debe ser de 5MB' );
      }
    }
  };
  useEffect(() => {
    if ( questionInStore ) {
      setPcFileName( 'Seleccionamos el archivo anterior automaticamente' );
      setFieldValue( 'indications', questionInStore?.indications );
      console.log( values );
    } else {
      if ( group ) {
        setFieldValue( 'type', group.type );
      }
      setPcFileName( '' );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionInStore, setFieldValue]);
  useEffect(() => {
    if ( values.id === '' ) {
      setFieldValue( 'id', v4());
    }
  }, [setFieldValue, values.id]);
  return (
    <>
      <Grid item xs={12}>
        <InputLabel htmlFor="sentence">Indicaciones</InputLabel>
        <TextField
          size="small"
          id="sentence"
          fullWidth
          placeholder="Ingrese la oración a completar"
          error={touched.sentence && !!errors.sentence}
          helperText={touched.sentence && errors.sentence}
          sx={{
            '& .MuiOutlinedInput-root > input': {
              backgroundColor: '#F2F2F2',
            },
          }}
          {...getFieldProps( 'sentence' )}
        />
        <FormHelperText>
          Ejem: En este archivo encontraran las indicaciones para...
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <input
            type="file"
            id="file"
            ref={inputFileRef}
            onChangeCapture={onFileChangeCapture}
            style={{ display: 'none' }}
          />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          <PhotoButton
            variant="contained"
            onClick={() => onBtnClick()}
            size="small"
            error={values.indications === null && errors.indications !== undefined}
          >
            Seleccionar
          </PhotoButton>
          <Typography ml={2}>
            {pcFileName || 'Selecciona un archivo'}
          </Typography>
        </Box>
        <Box ml={2}>
          { errors.indications && touched.indications && (
            <FormHelperText
              sx={{ fontSize: 14 }}
              error
            >
              {errors.indications}
            </FormHelperText>
          ) }
        </Box>
      </Grid>
    </>
  );
};

export default FileFields;
