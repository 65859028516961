import { Chip } from '@mui/material';

interface IBooksStatusProps {
  status: number;
}

const StudentsStatus = ({ status }: IBooksStatusProps ) => {
  const renderCorrectStatus = () => {
    if ( status ) {
      return <Chip label="Activo" sx={{ borderColor: 'green', color: 'green' }} variant="outlined" />;
    }
    return <Chip label="Inactivo" sx={{ borderColor: 'red', color: 'red' }} variant="outlined" />;
  };

  return (
    <>
      { renderCorrectStatus() }
    </>
  );
};

export default StudentsStatus;
