import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import SellersList from '../pages/SellersList';

const SellersRouter = () => (
  <Routes>
    <Route path="/" element={<SellersList title="Lista de Vendedores" />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default SellersRouter;
