import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addDoc, collection, doc, getDocs, limit, orderBy, query, updateDoc, where,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from '../../firebase';
import {
  ActivityFormValues, IActivity, IFileContainer, IGroupActivity, IQuestion,
} from '../interfaces';
import activityConverter from '../converters/activityConverter';
import { RootState } from '../../../app/store';
import questionConverter from '../converters/questionConverter';
import { uploadFile } from '../../../helpers/uploadFile';
import { asyncForEach } from '../../../helpers/async-foreach';
import groupConverter from '../converters/groupConverter';

interface ICreateQuestion {
  formValues: IQuestion;
  idActivity: string;
  idGroup: string;
  files: IFileContainer[];
}

export const getGroupsAndQuestionsByActivityId = createAsyncThunk(
  'activities/getGroupsAndQuestionsByActivityId',
  async ( idActivity: string ) => {
    const q = query( collection( db,
      `activities/${idActivity}/groups` ),
    where( 'deleted', '==', false ));
    const querySnapshot = await getDocs(
      q.withConverter( groupConverter ),
    );
    const groups: IGroupActivity[] = [];
    const questions: IQuestion[] = [];
    querySnapshot.forEach( async ( item ) => {
      groups.push({ ...item.data() });
    });
    await asyncForEach( groups, async ( group ) => {
      const queryQuestion = query( collection( db,
        `activities/${idActivity}/groups/${group.id}/questions` ),
      where( 'deleted', '==', false ));
      const queryQuestionSnapshot = await getDocs(
        queryQuestion.withConverter( questionConverter ),
      );
      queryQuestionSnapshot.forEach( async ( itemQuestion ) => {
        questions.push({ ...itemQuestion.data() });
      });
    });
    return { groups, questions };
  },
);

const returnStringUrl = async ( newFile: boolean, file: File, questionId: string, idActivity: string, idGroup: string ) => {
  let url = '';
  toast.info( 'Empezamos a subir el archivo' );
  url = await uploadFile( questionId, file ) as unknown as string;
  await updateDoc(
    doc( db, `activities/${idActivity}/groups/${idGroup}/questions/${questionId}` ), { indications: url },
  );
  return url;
};

const uploadEachDragOrder = async ( files: IFileContainer[], questionId: string, idActivity: string, idGroup: string ) => {
  const urls = {
    number1: '',
    number2: '',
    number3: '',
    number4: '',
    sentence: 'Actividad de ordenar',
  };
  await asyncForEach( files, async ( file ) => {
    switch ( true ) {
      case file.id.includes( 'number1' ):
        urls.number1 = await uploadFile( `${questionId}number1`, file.file ) as unknown as string;
        toast.info( 'Subida Imagen N.- 1' );
        break;
      case file.id.includes( 'number2' ):
        urls.number2 = await uploadFile( `${questionId}number2`, file.file ) as unknown as string;
        toast.info( 'Subida Imagen N.- 2' );
        break;
      case file.id.includes( 'number3' ):
        urls.number3 = await uploadFile( `${questionId}number3`, file.file ) as unknown as string;
        toast.info( 'Subida Imagen N.- 3' );
        break;
      case file.id.includes( 'number4' ):
        urls.number4 = await uploadFile( `${questionId}number4`, file.file ) as unknown as string;
        toast.info( 'Subida Imagen N.- 4' );
        break;
      default:
        break;
    }
  });
  await updateDoc(
    doc( db, `activities/${idActivity}/groups/${idGroup}/questions/${questionId}` ), {
      number1: `${urls.number1} **`, number2: `${urls.number2} **`, number3: `${urls.number3} **`, number4: `${urls.number4} **`, sentence: 'Actividad de ordenar',
    },
  );
  return urls;
};
const uploadEachDragImage = async ( files: IFileContainer[], questionId: string, idActivity: string, idGroup: string ) => {
  const urls = {
    sentence: '',
    correctOption: '',
    incorrectOption1: '',
    incorrectOption2: '',
    incorrectOption3: '',
    incorrectOption4: '',
  };
  await asyncForEach( files, async ( file ) => {
    switch ( true ) {
      case file.id.includes( 'sentence' ):
        urls.sentence = await uploadFile( `${questionId}sentence`, file.file ) as unknown as string;
        toast.info( 'Subida Imagen de pregunta' );
        break;
      case file.id.includes( 'incorrectOption1' ):
        urls.incorrectOption1 = await uploadFile( `${questionId}incorrectOption1`, file.file ) as unknown as string;
        toast.info( 'Subida Imagen incorrecta 1' );
        break;
      case file.id.includes( 'incorrectOption2' ):
        urls.incorrectOption2 = await uploadFile( `${questionId}incorrectOption2`, file.file ) as unknown as string;
        toast.info( 'Subida Imagen incorrecta 2' );
        break;
      case file.id.includes( 'incorrectOption3' ):
        urls.incorrectOption3 = await uploadFile( `${questionId}incorrectOption3`, file.file ) as unknown as string;
        toast.info( 'Subida Imagen incorrecta 3' );
        break;
      case file.id.includes( 'correctOption' ):
        urls.correctOption = await uploadFile( `${questionId}correctOption`, file.file ) as unknown as string;
        toast.info( 'Subida Imagen correcta' );
        break;
      default:
        break;
    }
  });
  await updateDoc(
    doc( db, `activities/${idActivity}/groups/${idGroup}/questions/${questionId}` ), {
      correctOption: urls.correctOption, incorrectOption1: urls.incorrectOption1, incorrectOption2: urls.incorrectOption2, incorrectOption3: urls.incorrectOption3, sentence: `${urls.sentence} **`,
    },
  );
  return urls;
};

export const createQuestion = createAsyncThunk(
  'activities/createQuestion',
  async ( data: ICreateQuestion, { getState }) => {
    const { activity } = getState() as RootState;
    const {
      formValues, idActivity, idGroup, files,
    } = data;
    try {
      const questionObject = {
        groupId: idGroup,
        type: formValues.type,
        sentence: formValues.sentence,
        created: new Date(),
        deleted: formValues.deleted,
        response1: formValues.response1,
        response2: formValues.response2,
        response3: formValues.response3,
        response4: formValues.response4,
      };
      if ( formValues.type === 'dragAndDrop' ) {
        Object.assign( questionObject, {
          correctOption: formValues.correctOption,
          incorrectOption1: formValues.incorrectOption1,
          incorrectOption2: formValues.incorrectOption2,
          incorrectOption3: formValues.incorrectOption3,
        });
      }
      if ( formValues.type === 'complete' ) {
        const question = formValues.sentence.split( '((' );
        let answer = question[1].split( '))' )[0];
        if ( !formValues.capital ) {
          answer = answer.toLowerCase();
        }
        if ( !formValues.accentMark ) {
          let finalWord = '';
          answer.split( '' ).forEach(( letter ) => {
            switch ( letter ) {
              case 'á':
                finalWord += 'a';
                return;
              case 'é':
                finalWord += 'e';
                return;
              case 'í':
                finalWord += 'i';
                return;
              case 'ó':
                finalWord += 'o';
                return;
              case 'ú':
                finalWord += 'u';
                return;
              default:
                finalWord += letter;
            }
          });
          answer = finalWord;
        }
        Object.assign( questionObject, {
          answer,
          question: `${question[0]}**`,
          capital: formValues.capital,
          accentMark: formValues.accentMark,
        });
      }
      let questionId = '';
      let url = '';
      if ( !activity.alreadyInBd.includes( formValues.id )) {
        const questionCreated = await addDoc(
          collection( db, `activities/${idActivity}/groups/${idGroup}/questions` ), questionObject,
        );
        questionId = questionCreated.id;
        if ( formValues.type === 'file' ) {
          if ( files.length > 0 && formValues.indications !== '' ) {
            url = await returnStringUrl( true, files[0].file, questionId, idActivity, idGroup );
          }
        }
        if ( formValues.type === 'dragAndDropImages' ) {
          if ( files.length > 0 ) {
            const urls = await uploadEachDragImage( files, questionId, idActivity, idGroup );
            formValues.correctOption = urls.correctOption;
            formValues.incorrectOption1 = urls.incorrectOption1;
            formValues.incorrectOption2 = urls.incorrectOption2;
            formValues.incorrectOption3 = urls.incorrectOption3;
            formValues.sentence = urls.sentence;
          }
        }
        if ( formValues.type === 'dragAndDropOrder' || formValues.type === 'dragAndDropCorrect' ) {
          if ( files.length > 0 ) {
            const urls = await uploadEachDragOrder( files, questionId, idActivity, idGroup );
            formValues.number1 = urls.number1;
            formValues.number2 = urls.number2;
            formValues.number3 = urls.number3;
            formValues.number4 = urls.number4;
            formValues.sentence = urls.sentence;
          }
        }
      } else {
        await updateDoc(
          doc( db, `activities/${idActivity}/groups/${idGroup}/questions/${formValues.id}` ), questionObject,
        );
        questionId = formValues.id;
        if ( formValues.type === 'file' ) {
          if ( files.length > 0 ) {
            url = await returnStringUrl( true, files[0].file, questionId, idActivity, idGroup );
          } else {
            url = formValues.indications as string;
          }
        }
        if ( formValues.type === 'dragAndDropImages' ) {
          if ( files.length > 0 ) {
            const urls = await uploadEachDragImage( files, questionId, idActivity, idGroup );
            formValues.correctOption = urls.correctOption;
            formValues.incorrectOption1 = urls.incorrectOption1;
            formValues.incorrectOption2 = urls.incorrectOption2;
            formValues.incorrectOption3 = urls.incorrectOption3;
            formValues.sentence = urls.sentence;
          }
        }
        if ( formValues.type === 'dragAndDropOrder' || formValues.type === 'dragAndDropCorrect' ) {
          if ( files.length > 0 ) {
            const urls = await uploadEachDragOrder( files, questionId, idActivity, idGroup );
            formValues.number1 = urls.number1;
            formValues.number2 = urls.number2;
            formValues.number3 = urls.number3;
            formValues.number4 = urls.number4;
            formValues.sentence = urls.sentence;
          }
        }
      }
      return {
        ...questionObject,
        created: questionObject.created.getTime(),
        id: questionId,
        correctOption: formValues.correctOption,
        incorrectOption1: formValues.incorrectOption1,
        incorrectOption2: formValues.incorrectOption2,
        incorrectOption3: formValues.incorrectOption3,
        answer: '',
        question: '',
        indications: url,
        newFile: false,
        capital: false,
        accentMark: false,
        number1: formValues.number1,
        number2: formValues.number2,
        number3: formValues.number3,
        number4: formValues.number4,
        response1: formValues.response1,
        response2: formValues.response2,
        response3: formValues.response3,
        response4: formValues.response4,
      };
    } catch ( err ) {
      console.log( err );
    }
  },
);

export const createActivity = createAsyncThunk(
  'activities/createActivity',
  async ( formValues: ActivityFormValues, { dispatch, getState }) => {
    try {
      const { unitsAndTopics, book, activity } = getState() as RootState;
      const dataToSend = {
        description: formValues.description,
        name: formValues.name,
        bookId: book.book?.id || '',
        unitId: unitsAndTopics.unit?.id || '',
        created: new Date(),
        deleted: false,
      };
      const activityCreated = await addDoc(
        collection( db, 'activities' ), dataToSend,
      );
      toast.info( 'Actividad creada correctamente', { toastId: 'toast-activity-create', autoClose: false });
      let counter = 0;
      asyncForEach( activity.groups, async ( item ) => {
        counter += 1;
        toast.update( 'toast-activity-create', {
          render: `Realizando operaciones N.- ${counter}`,
        });
        const groupCreated = await addDoc(
          collection( db, `activities/${activityCreated.id}/groups` ), {
            rubric: item.rubric, type: item.type, created: new Date(), deleted: false,
          },
        );
        const ownGroupQuestions = activity.questionsList.filter(( question ) => question.groupId === item.id );
        await asyncForEach( ownGroupQuestions, async ( question ) => {
          counter += 1;
          toast.update( 'toast-activity-create', {
            render: `Creando Preguntas N.- ${counter}`,
            autoClose: 5000,
          });
          let ownFiles: IFileContainer[] = [];
          if ( question.type === 'dragAndDropImages' || question.type === 'dragAndDropOrder' || question.type === 'dragAndDropCorrect' ) {
            ownFiles = formValues.indications?.filter(( data ) => data.id.includes( question.id )) || [];
          } else {
            ownFiles = formValues.indications?.filter(( data ) => data.id === question.id ) || [];
          }
          await dispatch( createQuestion({
            formValues: question, idActivity: activityCreated.id, idGroup: groupCreated.id, files: ownFiles || [],
          }));
        });
      });
      return {
        ...dataToSend, id: activityCreated.id, created: dataToSend.created.getTime(), indications: '',
      };
    } catch ( err ) {
      console.log( err );
    }
  },
);

export const updateActivity = createAsyncThunk(
  'activities/updateActivity',
  async ( formValues: ActivityFormValues, { dispatch, getState }) => {
    try {
      const { activity } = getState() as RootState;
      const idActivity = activity.activity?.id || '';
      const dataToSend = {
        description: formValues.description,
        name: formValues.name,
      };
      await updateDoc(
        doc( db, `activities/${idActivity}` ), dataToSend,
      );
      toast.info( 'Actividad editada correctamente', { toastId: 'toast-activity-edit', autoClose: false });
      let counter = 0;
      await asyncForEach( activity.groups, async ( item ) => {
        counter += 1;
        toast.update( 'toast-activity-edit', {
          render: `Realizando operaciones N.- ${counter}`,
        });
        let groupId = '';
        if ( !activity.alreadyInBd.includes( item.id )) {
          const groupCreated = await addDoc(
            collection( db, `activities/${idActivity}/groups` ), {
              rubric: item.rubric, type: item.type, created: new Date(), deleted: item.deleted,
            },
          );
          groupId = groupCreated.id;
        } else {
          await updateDoc(
            doc( db, `activities/${idActivity}/groups/${item.id}` ), {
              rubric: item.rubric, deleted: item.deleted,
            },
          );
          groupId = item.id;
        }
        const ownGroupQuestions = activity.questionsList.filter(( question ) => question.groupId === item.id );
        await asyncForEach( ownGroupQuestions, async ( question ) => {
          counter += 1;
          toast.update( 'toast-activity-edit', {
            render: `Actualizando preguntas N.- ${counter}`,
            autoClose: 5000,
          });
          let ownFiles: IFileContainer[] = [];
          if ( question.type === 'dragAndDropImages' || question.type === 'dragAndDropOrder' ) {
            ownFiles = formValues.indications?.filter(( data ) => data.id.includes( question.id )) || [];
          } else {
            ownFiles = formValues.indications?.filter(( data ) => data.id === question.id ) || [];
          }
          await dispatch( createQuestion({
            formValues: question, idActivity, idGroup: groupId, files: ownFiles || [],
          }));
        });
      });
      return {
        ...dataToSend, id: idActivity, created: activity.activity?.created || new Date().getTime(), indications: '', bookId: activity.activity?.bookId || '', unitId: activity.activity?.unitId || '',
      };
    } catch ( err ) {
      console.log( err );
    }
  },
);

export const updateQuestion = createAsyncThunk(
  'activities/updateQuestion',
  async ( formValues: IQuestion, { getState }) => {
    try {
      const { activity } = getState() as RootState;
      const questionObject = {
        sentence: formValues.sentence,
      };
      if ( formValues.type === 'dragAndDrop' ) {
        Object.assign( questionObject, {
          correctOption: formValues.correctOption,
          incorrectOption1: formValues.incorrectOption1,
          incorrectOption2: formValues.incorrectOption2,
          incorrectOption3: formValues.incorrectOption3,
        });
      }
      if ( formValues.type === 'complete' ) {
        const question = formValues.sentence.split( '((' );
        const answer = question[1].split( '))' )[0];
        Object.assign( questionObject, {
          answer,
          question: `${question[0]}*`,
        });
      }
      await updateDoc(
        doc( db, `activities/${activity.activity?.id}/questions/${formValues.id}` ), questionObject,
      );
      toast.success( 'Pregunta actualizada correctamente' );
      return {
        ...formValues,
        answer: '',
        question: '',
      };
    } catch ( err ) {
      console.log( err );
    }
  },
);

export const deleteQuestion = createAsyncThunk(
  'activities/deleteQuestion',
  async ( data: string, { getState }) => {
    try {
      const { activity } = getState() as RootState;
      await updateDoc(
        doc( db, `activities/${activity.activity?.id}/questions/${data}` ), { deleted: true },
      );
      toast.success( 'Pregunta eliminada correctamente' );
      return data;
    } catch ( err ) {
      console.log( err );
    }
  },
);

export const deleteActivity = createAsyncThunk(
  'activities/deleteActivity',
  async ( activity: string ) => {
    try {
      await updateDoc(
        doc( db, `activities/${activity}` ), { deleted: true },
      );
      toast.success( 'Actividad eliminada correctamente' );
      return activity;
    } catch ( err ) {
      console.log( err );
    }
  },
);

export const getActivitiesByUnitId = createAsyncThunk(
  'activities/getActivitiesByUnitId', async ( unitId: string ) => {
    try {
      // toast.info( 'Solicitando las actividades' );
      const q = query( collection( db,
        'activities' ),
      where( 'deleted', '==', false ),
      where( 'unitId', '==', unitId ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( activityConverter ),
      );
      const activities: IActivity[] = [];
      querySnapshot.forEach( async ( item ) => {
        activities.push({ ...item.data() });
      });
      /* if ( activities.length > 0 ) {
        toast.success( 'Actividades obtenidas correctamente' );
      } else {
        toast.success( 'No hay actividades para esta unidad' );
      }*/
      return { unitId, activities };
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getQuestionsByActivityId = createAsyncThunk(
  'activities/getQuestionsByActivityId', async ( activityId: string ) => {
    try {
      const q = query( collection( db,
        `activities/${activityId}/questions` ),
      where( 'deleted', '==', false ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( questionConverter ),
      );
      const questions: IQuestion[] = [];
      querySnapshot.forEach( async ( item ) => {
        questions.push({ ...item.data() });
      });
      return questions;
    } catch ( error ) {
      console.log( error );
    }
  },
);
