import { Button, ButtonProps } from '@mui/material';
import { styled, theme } from '../../theme';

interface IButtonProps extends ButtonProps {
  error?: boolean
}

const PhotoButton = styled( Button )<IButtonProps>(({ error }) => ({
  color: 'white',
  backgroundColor: error ? 'red' : theme.palette.secondary.main,
  paddingTop: 3,
  paddingBottom: 3,
}));

export default PhotoButton;
