import * as yup from 'yup';

export const BooksValidationSchema = yup.object().shape({
  name: yup.string().required( 'El nombre es requerido' ),
  description: yup.string().required( 'La descripción es requerida' ),
  editorial: yup.string().required( 'La editorial es requerida' ),
  url: yup.string().url( 'El formato debe ser de tipo URL' ).required( 'La url es requerida' ),
  urlDemo: yup.string().url( 'El formato debe ser de tipo URL' ).required( 'La url de prueba es requerida' ),
  category: yup.string().required( 'La categoría es requerida' ),
  subCategory: yup.string().required( 'La subcategoría es requerida' ),
  photo: yup.mixed().required( 'La foto es requerida' ),
});
