import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import { CustomTooltip } from '../../../shared/custom/tooltip/CustomToolTip';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelInfo?: string;
  labelText: string;
};

const StyledTreeItemRoot = styled( TreeItem )(({ theme }) => ({
  color: theme.palette.text.secondary,
  borderBottom: '1px solid #e0e0e0',
  [`& .${treeItemClasses.content}`]: {
    padding: '0.3em 0 0.3em 0.3em',
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: '0',
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
      borderBottom: '1px solid #e0e0e0',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      // eslint-disable-next-line max-len
      backgroundColor: 'white',
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing( 1 ),
    },
  },
}));

export default function ThreeViewItem( props: StyledTreeItemProps ) {
  const {
    bgColor,
    color,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={(
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: '100%',
          p: 0.5,
          pr: 0,
        }}
        >

          <CustomTooltip title={labelText}>
            <Typography
              variant="body2"
              sx={{ width: '290px' }}
              textOverflow="hidden"
              noWrap
            >
              {labelText}
            </Typography>
          </CustomTooltip>
          <Typography
            variant="caption"
            color="inherit"
            sx={{ marginRight: '6em', fontWeight: 700 }}
          >
            {labelInfo}
          </Typography>
        </Box>
      )}
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}
