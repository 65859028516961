/* eslint-disable no-constant-condition */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  browserLocalPersistence, browserSessionPersistence, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, User,
} from 'firebase/auth';
import {
  collection, doc, getDoc, getDocs, query, updateDoc, where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';
import { asyncForEach } from '../../../helpers/async-foreach';
import { uploadPhoto } from '../../../helpers/uploadPhoto';
import { clearActivities } from '../../activities/slice/activitySlice';
import bookConverter from '../../books/converters/bookConverter';
import { IBook } from '../../books/interfaces';
import { clearBooks } from '../../books/slice/booksSlice';
import { clearUnitsAndTopics } from '../../books/subModule/units/slice/unitsAndTopicsSlice';
import { clearCode } from '../../codes/slice/codesSlice';
import { auth, db, functions } from '../../firebase';
import { clearInstitutions } from '../../institutions/slice/institutionsSlice';
import { clearUi } from '../../shared/slice/uiSlice';
import activationsConverter from '../converters/activationsConverter';
import {
  IActivation, ILoginCredentials, IRegisterCredentials, IReset, IUser,
} from '../interfaces';
import { clearAuth, setRole } from '../slice/authSlice';

interface IPhoto{
  file: File,
  user: IUser;
}

export const getActivations = createAsyncThunk(
  'auth/getActivations',
  async ( id: string ) => {
    try {
      const activations: IActivation[] = [];
      const endActivations: IActivation[] = [];
      const docRef = query( collection( db, 'users', id, 'activations' ));
      const querySnapshot = await getDocs(
        docRef.withConverter( activationsConverter ),
      );
      querySnapshot.forEach( async ( item ) => {
        activations.push({ ...item.data() });
      });
      const q = query( collection( db,
        'books' ),
      where( 'deleted', '==', false ));
      const querySnapshotBooks = await getDocs(
        q.withConverter( bookConverter ),
      );
      const books: IBook[] = [];
      querySnapshotBooks.forEach( async ( item ) => {
        books.push({ ...item.data() });
      });
      await asyncForEach( activations, async ( activation ) => {
        const book = books.find(( bookItem: IBook ) => bookItem.id === activation.bookId );
        if ( book ) {
          endActivations.push({ ...activation, book });
        }
      });
      return endActivations;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const signIn = createAsyncThunk<
IUser,
ILoginCredentials,
{ rejectValue: string }
>( 'auth/signIn', async ({ email, password, remember }, { rejectWithValue, dispatch }) => {
  try {
    // Setting Type Session
    if ( remember ) {
      auth.setPersistence( browserSessionPersistence );
    } else {
      auth.setPersistence( browserLocalPersistence );
    }
    const { user } = await signInWithEmailAndPassword( auth, email, password );

    const { claims } = await user.getIdTokenResult();

    const { role } = claims as { role: string };

    if ( role === 'User' ) {
      dispatch( getActivations( user.uid ));
    }

    dispatch( setRole( role ));

    // Get user data
    const docRef = doc( db, 'users', user.uid );
    const docSnap = await getDoc( docRef );
    const userData = docSnap.data() as IUser;

    // Get created date
    const created = docSnap.data()!.created.toDate().getTime() as number;

    toast.success( `Bienvenido ${userData.name}` );

    // Return back user info to session
    return { ...userData, created };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch ( err: any ) {
    console.log( err.code );
    // eslint-disable-next-line no-nested-ternary
    const message = err.code === 'auth/wrong-password'
      ? 'La contraseña actual ingresada es incorrecta'
      // eslint-disable-next-line no-nested-ternary
      : err.code === 'auth/too-many-requests'
        ? 'Usuario desabililitado temporalmente'
        : 'auth/user-not-found'
          ? 'El usuario no existe'
          : 'Ocurrió un error al iniciar sesión';

    toast.error( message );

    return rejectWithValue( 'Credenciales inválidas' );
  }
});

export const signUp = createAsyncThunk<
void,
IRegisterCredentials,
{ rejectValue: string }
>( 'auth/signUp', async ({
  email, name, lastname, password, institution,
}, { rejectWithValue, dispatch }) => {
  try {
    const cloudFunction = await httpsCallable( functions, 'user-register' );
    await cloudFunction({
      email, name, lastname, password, instituteId: institution?.id, institute: institution?.name,
    });
    toast.success( 'Te has registrado satisfactoriamente' );
    // Return back user info to session
    dispatch( signIn({ email, password, remember: false }));
  } catch ( err ) {
    toast.error( 'Las credenciales ingrresadas son incorrectas' );

    return rejectWithValue( 'Credenciales inválidas' );
  }
});

export const renewSession = createAsyncThunk<
IUser,
User,
{ rejectValue: string }
>( 'auth/renewSession', async ( user, { rejectWithValue, dispatch }) => {
  try {
    const { claims } = await user.getIdTokenResult();

    const { role } = claims as { role: string };

    dispatch( setRole( role ));

    if ( role === 'User' ) {
      dispatch( getActivations( user.uid ));
    }

    // Get user data
    const docRef = doc( db, 'users', user.uid );
    const docSnap = await getDoc( docRef );
    const userData = docSnap.data() as IUser;

    // Get created date
    const created = docSnap.data()!.created.toDate().getTime() as number;

    // Return back user info to session
    return { ...userData, created };
  } catch ( error ) {
    return rejectWithValue( 'No autenticado' );
  }
});

export const logOut = createAsyncThunk(
  'auth/logOut',
  async ( _, { dispatch, rejectWithValue }) => {
    try {
      // SignOut with Firebase
      await signOut( auth );

      // TODO: Clean values
      dispatch( clearAuth());
      dispatch( clearBooks());
      dispatch( clearUi());
      dispatch( clearCode());
      dispatch( clearActivities());
      dispatch( clearUnitsAndTopics());
      dispatch( clearInstitutions());
    } catch ( err ) {
      return rejectWithValue( 'Error al cerrar sesión' );
    }
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ( data: IReset ) => {
    try {
      await sendPasswordResetEmail( auth, data.email );
      toast.success( 'Hemos envido un E-mail para reestablecer tu contraseña' );
    } catch ( err ) {
      toast.success( 'Ha ocurrido un error' );
    }
  },
);

export const updateUserData = createAsyncThunk(
  'auth/updateUserData',
  async ( user: IUser ) => {
    try {
      await updateDoc( doc(
        db,
        'users',
        auth.currentUser?.uid || '',
      ), { name: user.name, lastname: user.lastname });
      toast.success( 'Información actualizada correctamente' );
      return { ...user };
    } catch ( err ) {
      toast.error( 'A ocurrido un error' );
    }
  },
);

export const updatePhoto = createAsyncThunk(
  'auth/updatePhoto',
  async ( data: IPhoto ) => {
    try {
      const userId = auth.currentUser?.uid || '';
      toast.info( 'Hemos empezado a subir tu foto' );
      const url = await uploadPhoto( userId, data.file, 'users' );
      await updateDoc( doc(
        db,
        'users',
        userId,
      ), { photo: url });
      toast.success( 'Foto de perfil cambiada correctamente' );
      return { ...data.user, photo: url };
    } catch ( err ) {
      console.log( err );
      toast.error( 'A ocurrido un error' );
    }
  },
);
