import {
  Grid, CircularProgress, Box,
} from '@mui/material';
import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../../app/hooks';
import { IUnit } from '../interfaces';
import { getUnits } from '../actions/units&TopicsActions';
import StudentActivityItem from './StudentActivityItem';

const StudentUnitCard = () => {
  const {
    unitsList, loadingUnitGet,
  } = useAppSelector(( state ) => state.unitsAndTopics );
  const { book } = useAppSelector(( state ) => state.book );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch( getUnits( book?.id || '' ));
  }, [dispatch, book?.id]);
  return (
    <Grid container spacing={4}>
      {loadingUnitGet ? (
        <Box textAlign="center" width="100%" mt={2}>
          <CircularProgress size={30} />
        </Box>
      ) : (
        <>
          {unitsList && unitsList.length > 0 ? unitsList.map(( unit: IUnit, index: number ) => ( <StudentActivityItem unit={unit} key={index.toString()} /> )) : (
            <Grid item xs={12}>
              <span>Sin Unidades</span>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default StudentUnitCard;
