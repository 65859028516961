import { useAppSelector } from '../../../app/hooks';

const useFilterBooksInitialState = () => {
  const { filterOptions } = useAppSelector(( state ) => state.book );

  return {
    editorial: filterOptions ? filterOptions.editorial : null,
    category: filterOptions ? filterOptions.category : null,
    subCategory: filterOptions ? filterOptions.subCategory : null,
    status: filterOptions ? filterOptions.status : null,
  };
};

export default useFilterBooksInitialState;
