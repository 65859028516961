import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStudent, IStudentsFilterOptions } from '../interfaces';
import {
  createStudent, deleteStudent, getStudents, getStudentsPaginated, getStudentsWithFilters,
} from '../actions/studentsActions';

interface IStudentState {
  studentsList: IStudent[];
  student?: IStudent;
  loadingGet: boolean;
  loadingCreate: boolean;
  loadingUpdate: boolean;
  loadingDelete: boolean;
  modal: boolean;
  modalDelete: boolean;
  loadingFilter: boolean;
  filterOptions?: IStudentsFilterOptions;
  modalFilter: boolean;
  studentsFiltered: IStudent[];
  hasFilter: boolean;
}

const initialState: IStudentState = {
  studentsList: [],
  loadingGet: false,
  loadingCreate: false,
  loadingUpdate: false,
  loadingDelete: false,
  modal: false,
  modalDelete: false,
  modalFilter: false,
  loadingFilter: false,
  studentsFiltered: [],
  hasFilter: false,
};

const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    setStudentsModalState: ( state, action: PayloadAction<boolean> ) => {
      state.modal = action.payload;
      if ( action.payload === false ) {
        state.student = undefined;
      }
    },
    setStudentsDeleteModalState: ( state, action: PayloadAction<boolean> ) => {
      state.modalDelete = action.payload;
      if ( action.payload === false ) {
        state.student = undefined;
      }
    },
    addStudentInStore: ( state, action: PayloadAction<IStudent> ) => {
      state.studentsList = [action.payload, ...state.studentsList];
    },
    addStudentForActions: ( state, action: PayloadAction<string> ) => {
      state.student = state.studentsList.find(( student ) => student.id === action.payload );
    },
    deleteStudentInStore: ( state, action: PayloadAction<string> ) => {
      state.studentsList = [...state.studentsList.filter(( item ) => item.id !== action.payload )];
      state.modalDelete = false;
    },
    filterStudentsInStore: ( state, action: PayloadAction<IStudentsFilterOptions> ) => {
      state.filterOptions = action.payload;
      state.hasFilter = true;
    },
    clearStudentsFilter: ( state ) => {
      state.filterOptions = undefined;
      state.hasFilter = false;
      state.studentsFiltered = [];
    },
    setFilterStudentModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalFilter = action.payload;
    },
    searchFilterStudentsInStore: ( state, action: PayloadAction<string> ) => {
      if ( action.payload === '' ) {
        state.studentsFiltered = [];
        state.hasFilter = false;
      } else {
        state.studentsFiltered = state.studentsList.filter(( student ) => student.name.toLowerCase().includes( action.payload.toLowerCase()) || student.lastname.toLowerCase().includes( action.payload.toLowerCase()) || student.email.toLowerCase().includes( action.payload.toLowerCase()));
        state.hasFilter = true;
      }
    },
  },
  extraReducers: ( builder ) => {
    builder
      .addCase( getStudents.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getStudents.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.studentsList = action.payload!;
      })
      .addCase( getStudents.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( getStudentsPaginated.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getStudentsPaginated.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.studentsList = [...state.studentsList, ...action.payload!];
      })
      .addCase( getStudentsPaginated.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( createStudent.pending, ( state ) => {
        state.loadingCreate = true;
      })
      .addCase( createStudent.fulfilled, ( state ) => {
        state.loadingCreate = false;
      })
      .addCase( createStudent.rejected, ( state ) => {
        state.loadingCreate = false;
      });
    builder
      .addCase( getStudentsWithFilters.pending, ( state ) => {
        state.loadingFilter = true;
      })
      .addCase( getStudentsWithFilters.fulfilled, ( state, action ) => {
        state.loadingFilter = false;
        state.studentsFiltered = action.payload!;
      })
      .addCase( getStudentsWithFilters.rejected, ( state ) => {
        state.loadingFilter = false;
      });
    builder
      .addCase( deleteStudent.pending, ( state ) => {
        state.loadingDelete = true;
      })
      .addCase( deleteStudent.fulfilled, ( state ) => {
        state.loadingDelete = false;
      })
      .addCase( deleteStudent.rejected, ( state ) => {
        state.loadingDelete = false;
      });
  },
});

export const {
  setStudentsModalState, addStudentInStore, addStudentForActions, deleteStudentInStore, setStudentsDeleteModalState, filterStudentsInStore, clearStudentsFilter, setFilterStudentModal, searchFilterStudentsInStore,
} = studentSlice.actions;

export default studentSlice.reducer;
