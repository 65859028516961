import { IQuestion } from '../../interfaces';
import TypeComplete from './QuestionsType/TypeComplete';
import TypeDragAndDrop from './QuestionsType/TypeDragAndDrop';
import TypeDragAndDropImages from './QuestionsType/TypeDragAndDropImages';
import TypeDragAndDropOrder from './QuestionsType/TypeDragAndDropOrder';
import TypeDragAndDropCorrect from './QuestionsType/TypeDragAndDropCorrect';
import TypeFile from './QuestionsType/TypeFile';
import TypeOpenQuestion from './QuestionsType/TypeOpenQuestion';
import TypeEmbed from './QuestionsType/TypeEmbed';

interface IQuestionContainerProps {
  question: IQuestion;
}

const QuestionContainer = ({ question }: IQuestionContainerProps ) => (
  <>
    {question.type === 'file' && (
      <TypeFile question={question} />
    )}
    {question.type === 'complete' && (
      <TypeComplete question={question} />
    )}
    {question.type === 'openQuestions' && (
      <TypeOpenQuestion question={question} />
    )}
    {question.type === 'dragAndDrop' && (
      <TypeDragAndDrop question={question} />
    )}
    {question.type === 'dragAndDropImages' && (
      <TypeDragAndDropImages question={question} />
    )}
    {question.type === 'dragAndDropOrder' && (
      <TypeDragAndDropOrder question={question} />
    )}
    {question.type === 'dragAndDropCorrect' && (
      <TypeDragAndDropCorrect question={question} />
    )}
    {question.type === 'embed' && (
      <TypeEmbed question={question} />
    )}
  </>
);

export default QuestionContainer;
