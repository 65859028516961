import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInstitution, IInstitutionsFilterOptions } from '../interfaces';
import {
  createInstitution, deleteInstitution, getInstitutions, getInstitutionsPaginated, getInstitutionsWithFilters, updateInstitution,
} from '../actions/institutionsActions';

interface IInstitutionsState {
  institutionsList: IInstitution[];
  institution?: IInstitution;
  loadingGet: boolean;
  loadingCreate: boolean;
  loadingUpdate: boolean;
  loadingDelete: boolean;
  modal: boolean;
  modalDelete: boolean;
  loadingFilter: boolean;
  filterOptions?: IInstitutionsFilterOptions;
  modalFilter: boolean;
  institutionsFiltered: IInstitution[];
  hasFilter: boolean;
}

const initialState: IInstitutionsState = {
  institutionsList: [],
  loadingGet: false,
  loadingCreate: false,
  loadingUpdate: false,
  loadingDelete: false,
  modal: false,
  modalDelete: false,
  modalFilter: false,
  loadingFilter: false,
  institutionsFiltered: [],
  hasFilter: false,
};

const institutionsSlice = createSlice({
  name: 'institution',
  initialState,
  reducers: {
    setInstitutionsModalState: ( state, action: PayloadAction<boolean> ) => {
      state.modal = action.payload;
      if ( action.payload === false ) {
        state.institution = undefined;
      }
    },
    setInstitutionsDeleteModalState: ( state, action: PayloadAction<boolean> ) => {
      state.modalDelete = action.payload;
      if ( action.payload === false ) {
        state.institution = undefined;
      }
    },
    addInstitutionInStore: ( state, action: PayloadAction<IInstitution> ) => {
      state.institutionsList = [action.payload, ...state.institutionsList];
    },
    addInstitutionForActions: ( state, action: PayloadAction<string> ) => {
      state.institution = state.institutionsList.find(( institution ) => institution.id === action.payload );
    },
    deleteInstitutionInStore: ( state, action: PayloadAction<string> ) => {
      state.institutionsList = [...state.institutionsList.filter(( item ) => item.id !== action.payload )];
      state.modalDelete = false;
    },
    filterInstitutionsInStore: ( state, action: PayloadAction<IInstitutionsFilterOptions> ) => {
      state.filterOptions = action.payload;
      state.hasFilter = true;
    },
    clearInstitutionsFilter: ( state ) => {
      state.filterOptions = undefined;
      state.hasFilter = false;
      state.institutionsFiltered = [];
    },
    clearInstitutions: ( state ) => {
      state.institutionsList = [];
    },
    setFilterInstitutionsModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalFilter = action.payload;
    },
    searchFilterInstitutionsInStore: ( state, action: PayloadAction<string> ) => {
      if ( action.payload === '' ) {
        state.institutionsFiltered = [];
        state.hasFilter = false;
      } else {
        state.institutionsFiltered = state.institutionsList.filter(( institution ) => institution.name.toLowerCase().includes( action.payload.toLowerCase()) || institution.email.toLowerCase().includes( action.payload.toLowerCase()));
        state.hasFilter = true;
      }
    },
    updateInstitutionInStore: ( state, action: PayloadAction<IInstitution> ) => {
      const updateInstitutionFunction = () => {
        const data = state.institutionsList;
        const index = data.findIndex(
          ( item: IInstitution ) => item.id === action.payload.id,
        );
        data.splice( index, 1, action.payload );
        return data;
      };
      state.institutionsList = updateInstitutionFunction();
    },
  },
  extraReducers: ( builder ) => {
    builder
      .addCase( getInstitutions.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getInstitutions.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.institutionsList = action.payload!;
      })
      .addCase( getInstitutions.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( getInstitutionsPaginated.pending, ( state ) => {
        state.loadingGet = true;
      })
      .addCase( getInstitutionsPaginated.fulfilled, ( state, action ) => {
        state.loadingGet = false;
        state.institutionsList = [...state.institutionsList, ...action.payload!];
      })
      .addCase( getInstitutionsPaginated.rejected, ( state ) => {
        state.loadingGet = false;
      });
    builder
      .addCase( createInstitution.pending, ( state ) => {
        state.loadingCreate = true;
      })
      .addCase( createInstitution.fulfilled, ( state ) => {
        state.loadingCreate = false;
      })
      .addCase( createInstitution.rejected, ( state ) => {
        state.loadingCreate = false;
      });
    builder
      .addCase( getInstitutionsWithFilters.pending, ( state ) => {
        state.loadingFilter = true;
      })
      .addCase( getInstitutionsWithFilters.fulfilled, ( state, action ) => {
        state.loadingFilter = false;
        state.institutionsFiltered = action.payload!;
      })
      .addCase( getInstitutionsWithFilters.rejected, ( state ) => {
        state.loadingFilter = false;
      });
    builder
      .addCase( updateInstitution.pending, ( state ) => {
        state.loadingUpdate = true;
      })
      .addCase( updateInstitution.fulfilled, ( state ) => {
        state.loadingUpdate = false;
      })
      .addCase( updateInstitution.rejected, ( state ) => {
        state.loadingUpdate = false;
      });
    builder
      .addCase( deleteInstitution.pending, ( state ) => {
        state.loadingDelete = true;
      })
      .addCase( deleteInstitution.fulfilled, ( state ) => {
        state.loadingDelete = false;
      })
      .addCase( deleteInstitution.rejected, ( state ) => {
        state.loadingDelete = false;
      });
  },
});

export const {
  setInstitutionsModalState, clearInstitutions, addInstitutionInStore, addInstitutionForActions, deleteInstitutionInStore, setInstitutionsDeleteModalState, filterInstitutionsInStore, clearInstitutionsFilter, setFilterInstitutionsModal, searchFilterInstitutionsInStore, updateInstitutionInStore,
} = institutionsSlice.actions;

export default institutionsSlice.reducer;
