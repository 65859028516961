import {
  Toolbar,
  AppBar,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';

const NavbarAuth = (): JSX.Element => (
  <AppBar position="fixed" sx={{ p: '0 2em 0 2em' }}>
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
      <Box display="flex" gap={2} alignItems="center">
        <Typography variant="body1" color="white" fontWeight="bold">
          ¿Quienes somos?
        </Typography>
        <Typography variant="body1" color="white" fontWeight="bold">
          Soporte técnico
        </Typography>
        <Typography variant="body1" color="white" fontWeight="bold">
          Contáctanos
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          sx={{
            width: 'fit-content',
            minWidth: 'fit-content',
            padding: 0,
            backgroundColor: 'transparent!important',
          }}
        >
          <Instagram />
        </Button>
        <Button
          variant="contained"
          sx={{
            width: 'fit-content',
            minWidth: 'fit-content',
            padding: '0.2em',
            backgroundColor: 'transparent!important',
          }}
        >
          <Facebook />
        </Button>
      </Box>
    </Toolbar>
  </AppBar>
);

export default NavbarAuth;
