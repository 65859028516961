import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IStatisticUser } from '../interfaces';

const staticConverterUser = {
  toFirestore: ( statistic: IStatisticUser ) => ({
    ...statistic,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IStatisticUser => {
    const data = snapshot.data( options );

    const {
      category,
      sellers,
      students,
    } = data;

    return {
      category,
      sellers,
      students,
    } as IStatisticUser;
  },
};

export default staticConverterUser;
