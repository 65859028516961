import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../modules/shared/Errors/NotFound404';

import LogedLayout from '../modules/shared/LogedLayout/LogedLayout';
import AdminRouter from './AdminRouter';
import SellerRouter from './SellerRouter';
import StudentRouter from './StudentRouter';
import RoleGuard from './guards/RoleGuard';
import ProfileRouter from '../modules/profile/router/ProfileRouter';

const PrivateRouter = () => (
  <LogedLayout>
    <Routes>
      <Route
        path="/admin/*"
        element={(
          <RoleGuard canAccess={['Admin']}>
            <AdminRouter />
          </RoleGuard>
        )}
      />
      <Route
        path="/estudiante/*"
        element={(
          <RoleGuard canAccess={['User']}>
            <StudentRouter />
          </RoleGuard>
        )}
      />
      <Route
        path="/vendedor/*"
        element={(
          <RoleGuard canAccess={['Seller']}>
            <SellerRouter />
          </RoleGuard>
        )}
      />
      <Route
        path="/perfil/*"
        element={(
          <ProfileRouter />
        )}
      />
      <Route
        path="*"
        element={(
          <NotFound404 /> )}
      />
    </Routes>
  </LogedLayout>
);

export default PrivateRouter;
