import { Box, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { auth } from '../../../../firebase';
import { IQuestion } from '../../../interfaces';
import { updateResponseInStore } from '../../../slice/activitySlice';
import useTextColor from '../../../hooks/useTextColor';

interface ITypeCompleteProps {
  question: IQuestion;
}

type InputChange = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const TypeComplete = ({ question: questionProp }: ITypeCompleteProps ) => {
  const dispatch = useAppDispatch();
  const { alreadyQualified } = useAppSelector(( state ) => state.activity );
  const { question, ...rest } = questionProp;
  const [valueInput, setValueInput] = useState<string>( '' );
  const questionColor = useTextColor( rest.id );
  const onChange = ( e: InputChange ): void => {
    if ( e.target.value === rest.answer ) {
      dispatch( updateResponseInStore({
        userId: auth.currentUser?.uid || '',
        questionId: rest.id,
        response: e.target.value,
        type: rest.type,
        groupId: rest.groupId,
        correct: true,
      }));
    } else {
      dispatch( updateResponseInStore({
        userId: auth.currentUser?.uid || '',
        questionId: rest.id,
        response: e.target.value,
        type: rest.type,
        groupId: rest.groupId,
        correct: false,
      }));
    }
    setValueInput( e.target.value );
  };

  const handleCreateQuestionView = () => {
    const str = question.split( ' ' );
    return (
      str.map(( item: string, i: number ) => (
        !item.includes( '**' )
          ? (
            <Typography
              key={i.toString()}
              mr={0.5}
              sx={{
                color: questionColor,
              }}
            >
              {item}
            </Typography>
          )
          : (
            <TextField
              key={i.toString()}
              value={valueInput}
              onChange={onChange}
              disabled={alreadyQualified}
              variant="outlined"
              size="small"
              multiline
              sx={{
                backgroundColor: 'white',
                width: 200,
              }}
            />
          )
      ))
    );
  };
  return (
    <Box display="flex" alignItems="center" bgcolor="#EEEEEE" p={3}>
      {handleCreateQuestionView()}
    </Box>
  );
};

export default TypeComplete;
