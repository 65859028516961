import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import { setFilterStudentModal, setStudentsModalState } from '../slice/studentSlice';
import FilterStudentsForm from './FilterStudentsForm';
import StudentsForm from './StudentsForm';

const StudentsDialogsContainer = () => {
  const dispatch = useAppDispatch();
  const {
    modal, loadingCreate, loadingUpdate, modalFilter, loadingFilter,
  } = useAppSelector(( state ) => state.student );
  return (
    <>
      <FormDialog
        open={modal}
        title="Añadir Estudiante"
        closeFunction={() => dispatch( setStudentsModalState( !!loadingCreate || !!loadingUpdate ))}
      >
        <StudentsForm />
      </FormDialog>
      <FormDialog
        open={modalFilter}
        title="Filtrar Vendedor"
        closeFunction={() => dispatch( setFilterStudentModal( !!loadingFilter ))}
      >
        <FilterStudentsForm />
      </FormDialog>
    </>
  );
};

export default StudentsDialogsContainer;
