import * as yup from 'yup';

export const UserFormValidationSchema = yup.object().shape({
  name: yup.string()
    .required( 'Los nombres son requeridos.' ),
  lastname: yup.string()
    .required( 'Los apellidos son requeridos.' ),
  email: yup.string()
    .required( 'El email es requerido.' )
    .email(),
});

export const PasswordValidationSchema = yup.object().shape({
  passwordOld: yup.string()
    .required( 'La antigua contraseña es requerida.' )
    .min( 6, 'La contraseña debe contener al menos 6 caracteres.' )
    .max( 15, 'La contraseña no debe contener más de 15 caracteres.' ),
  password: yup.string()
    .required( 'La nueva contraseña es requerida.' )
    .min( 6, 'La contraseña debe contener al menos 6 caracteres.' )
    .max( 15, 'La contraseña no debe contener más de 15 caracteres.' ),
  password2: yup.string()
    .required( 'Repíta su contraseña contraseña es requerida.' )
    .min( 6, 'La contraseña debe contener al menos 6 caracteres.' )
    .max( 15, 'La contraseña no debe contener más de 15 caracteres.' )
    .oneOf([yup.ref( 'password' )], 'Las contraseñas no coinciden.' ),
});
