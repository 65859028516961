import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { ITopicRegister } from '../interfaces';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import useTopicInitialState from '../hooks/useTopicInitialState';
import { createTopic, updateTopic } from '../actions/units&TopicsActions';
import { setCreateEditTopicsModal } from '../slice/unitsAndTopicsSlice';
import { topicValidationSchema } from '../validators/topicValidationSchema';

const TopicsForm = () => {
  const {
    loadingTopicCreate, loadingTopicUpdate, topic, unit,
  } = useAppSelector(( state ) => state.unitsAndTopics );
  const { book } = useAppSelector(( state ) => state.book );
  const dispatch = useAppDispatch();
  const {
    getFieldProps, handleSubmit, errors, touched, resetForm,
  } = useFormik<ITopicRegister>({
    initialValues: useTopicInitialState(),
    validationSchema: topicValidationSchema,
    enableReinitialize: true,
    onSubmit: async ( formValues ) => {
      if ( topic === undefined && book && unit ) {
        await dispatch( createTopic({
          ...formValues, bookId: book.id, bookName: book.name, unitId: unit.id,
        }));
      }
      if ( topic && book ) {
        await dispatch( updateTopic({
          ...topic, name: formValues.name, url: formValues.url, id: topic?.id || '',
        }));
      }
      dispatch( setCreateEditTopicsModal( false ));
      resetForm();
    },
  });
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <CardContent sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="name"
              error={touched.name && !!errors.name}
            >
              <Typography fontWeight="bold" fontSize={12}>Nombre</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="name"
              fullWidth
              placeholder="Ingrese un Nombre"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'name' )}
            />
          </Grid>
          <Grid item xs={9} sm={11} sx={{ mb: 2.5 }}>
            <InputLabel
              htmlFor="url"
              error={touched.url && !!errors.url}
            >
              <Typography fontWeight="bold" fontSize={12}>Url</Typography>
            </InputLabel>
            <TextField
              size="small"
              id="url"
              fullWidth
              placeholder="Ingrese un Nombre"
              error={touched.url && !!errors.url}
              helperText={touched.url && errors.url}
              sx={{
                '& .MuiOutlinedInput-root > input': {
                  backgroundColor: '#F2F2F2',
                },
              }}
              {...getFieldProps( 'url' )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={10} display="flex" justifyContent="center" gap={2} sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingTopicCreate || loadingTopicUpdate}
              onClick={() => dispatch( setCreateEditTopicsModal( false ))}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="small"
              sx={{
                mb: 1,
              }}
              disabled={loadingTopicCreate || loadingTopicUpdate}
              endIcon={( loadingTopicCreate || loadingTopicUpdate ) && ( <CircularProgress size={10} /> )}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

export default TopicsForm;
