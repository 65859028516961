import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import { setCreateorEditGroupModal } from '../slice/activitySlice';
import TypeForm from './TypeForm';

const ActivitiesDialogsContainer = () => {
  const { modalCreateorEditGroup, group } = useAppSelector(( state ) => state.activity );
  const dispatch = useAppDispatch();
  return (
    <>
      <FormDialog
        open={modalCreateorEditGroup}
        title={group ? 'Editar Grupo' : 'Crear Grupo'}
        closeFunction={() => dispatch( setCreateorEditGroupModal( false ))}
        dialogMaxWidth="1000px"
      >
        <TypeForm />
      </FormDialog>
    </>
  );
};

export default ActivitiesDialogsContainer;
