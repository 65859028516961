import React, { useState } from 'react';
import {
  GridColDef, GridRenderCellParams, GridRowsProp,
} from '@mui/x-data-grid';
import {
  Box, Button, Chip, CircularProgress,
} from '@mui/material';
import { ICode } from '../interfaces';
import CardTableWrapper from '../../shared/custom/dataGrid/CardTableWrapper';
import CustomDataGrid from '../../shared/custom/dataGrid/CustomDataGrid';
import StudentsStatus from '../../shared/custom/status/StudentsStatus';
import InfoDialog from '../../shared/custom/dialog/InfoDialog';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { addCodeForActions, setCodesDeleteModalState, setCodesModalState } from '../slice/codesSlice';
import { deleteCode } from '../actions/codesActions';
import TableMenu from '../../shared/custom/tableMenu.tsx/TableMenu';

interface ICodesTableProps {
  codes: ICode[];
}

const CodesTable = ({ codes }: ICodesTableProps ) => {
  const dispatch = useAppDispatch();
  const { loadingDelete, modalDelete, code } = useAppSelector(( state ) => state.code );

  const handleOpenEditModal = ( id: string ) => {
    dispatch( addCodeForActions( id ));
    dispatch( setCodesModalState( true ));
  };

  const handleOpenDeleteModal = ( id: string ) => {
    dispatch( addCodeForActions( id ));
    dispatch( setCodesDeleteModalState( true ));
  };

  const acceptDelete = ( ) => {
    dispatch( deleteCode( code ));
  };
  const [perPage, setPerPage] = useState( 25 );
  const rows: GridRowsProp = codes.map(
    ( c: ICode ) => ({
      id: c.id,
      book: c.book,
      created: c.created,
      tags: c.tags,
      status: c.status,
      actions: c,
    }),
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'CÓDIGO',
      headerClassName: 'theme__header',
      flex: 1,
    },
    {
      field: 'book',
      headerName: 'LIBRO',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'CREADO',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'tags',
      headerName: 'ETIQUETAS',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ( params: GridRenderCellParams<string[]> ) => {
        const tags = params.value;
        return (
          <Box display="flex">
            {tags.map(( t, i ) => i <= 1 && (
              <Chip key={i.toString()} label={t} />
            ))}
          </Box>
        );
      },
    },
    {
      field: 'active',
      headerName: 'ESTADO',
      headerClassName: 'theme__header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: ( params: GridRenderCellParams<number> ) => {
        const status = params.value;
        return ( <StudentsStatus status={status} /> );
      },
    },
    {
      field: 'actions',
      headerName: 'ACCIONES',
      width: 200,
      headerClassName: 'theme__header',
      align: 'center',
      headerAlign: 'center',
      renderCell: ( params: GridRenderCellParams ) => {
        const codeVerify = params.value as ICode;
        return (
          <>
            {!codeVerify.used ? (
              <TableMenu
                id={codeVerify.id}
                handleOpenEdit={handleOpenEditModal}
                handleOpenDelete={handleOpenDeleteModal}
              />
            ) : (
              'Código cangeado'
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <CardTableWrapper>
        <CustomDataGrid
          noDataMessage="Sin Códigos"
          rows={rows}
          columns={columns}
          pageSize={perPage}
          onPageSizeChange={setPerPage}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </CardTableWrapper>
      <InfoDialog
        open={modalDelete}
        title="Eliminar Código"
        contentText="Estás seguro que deseas eliminar este código, esta acción es irreversible."
        closeFunction={() => dispatch( setCodesDeleteModalState( !!loadingDelete ))}
      >
        <Box textAlign="center" pt={3}>
          <Button onClick={() => acceptDelete()} disabled={loadingDelete} endIcon={loadingDelete && ( <CircularProgress size={10} /> )}>
            ¡Sí, Eliminar!
          </Button>
        </Box>
      </InfoDialog>
    </>
  );
};

export default CodesTable;
