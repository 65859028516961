import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IUnit } from '../interfaces';

const unitConverter = {
  toFirestore: ( unit: IUnit ) => ({
    ...unit,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IUnit => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      name,
      description,
      deleted,
      bookId,
      bookName,
    } = data;

    return {
      id: snapshot.id,
      name,
      description,
      deleted,
      bookId,
      bookName,
      created,
    } as IUnit;
  },
};

export default unitConverter;
