import {
  Button,
  Card, CardContent, CircularProgress, Grid,
} from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CodesTable from '../components/CodesTable';
import { getCodes } from '../actions/codesActions';
import { setCodesModalState } from '../slice/codesSlice';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import PagesSearch from '../../shared/custom/pagesSearch/PagesSearch';
import SellersForm from '../components/CodesForm';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import { getBooks } from '../../books/actions/booksActions';
import FilterButton from '../../shared/custom/buttons/FilterButton';

interface ICodesListProps {
  title: string;
}

const CodesList = ({ title }: ICodesListProps ) => {
  const dispatch = useAppDispatch();
  const {
    codesList, modal, loadingCreate, loadingUpdate, loadingGet,
  } = useAppSelector(( state ) => state.code );
  useEffect(() => {
    document.title = title;
    dispatch( getCodes());
    dispatch( getBooks());
    dispatch( setNoMoreData( true ));
  }, [title, dispatch]);
  return (
    <>
      {loadingGet ? (
        <Grid textAlign="center">
          <CircularProgress size={40} />
        </Grid>
      ) : (
        <Card>
          <CardContent>
            <Grid container spacing={2} display="flex" justifyContent="space-between">
              <Grid item xs={12} sm={3}>
                <PagesSearch />
              </Grid>
              <Grid item xs={12} sm={3} display="flex" justifyContent="flex-end" gap={2}>
                <FilterButton />
                <Button onClick={() => dispatch( setCodesModalState( true ))}>Agregar Códigos</Button>
              </Grid>
            </Grid>
            <CodesTable codes={codesList} />
          </CardContent>
        </Card>
      )}
      <FormDialog
        open={modal}
        title="Añadir Códigos"
        closeFunction={() => dispatch( setCodesModalState( !!loadingCreate || !!loadingUpdate ))}
      >
        <SellersForm />
      </FormDialog>
    </>
  );
};

export default CodesList;
