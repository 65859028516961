import {
  Button,
  Grid, Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CardInfoBook from '../components/CardInfoBook';
import UnitCard from '../subModule/units/components/UnitCard';
import { getUnits } from '../subModule/units/actions/units&TopicsActions';
import BooksDialogsContainer from '../components/BooksDialogsContainer';
import { setCreateEditUnitsModal } from '../subModule/units/slice/unitsAndTopicsSlice';
import {
  clearActivity, clearGroups, clearQuestion, clearQuestions, clearResponses, setAsQualified, setModalCloseActivity,
} from '../../activities/slice/activitySlice';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import { trackEvent } from '../../../helpers/analytics';
import { auth } from '../../firebase';

const AdminBookDetail = () => {
  const { book } = useAppSelector(( state ) => state.book );
  const { loadingUnitGet } = useAppSelector(( state ) => state.unitsAndTopics );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setTitle = useSetTitle({
    title: 'Ver Libro',
    subItems: ['Libros', 'Ver Libro', book?.name || ''],
    noPrintItems: false,
  });
  useEffect(() => {
    if ( !book ) {
      navigate( '/' );
    }
    trackEvent( 'push_view_book_menu_button_admin', {
      elementPushed: 'menu_button', userInAction: auth.currentUser?.uid, book: book?.name, viewName: 'Lista de Libros',
    });
    dispatch( getUnits( book?.id || '' ));
  }, [book, navigate, dispatch]);
  useEffect(() => {
    dispatch( clearQuestion());
    dispatch( clearQuestions());
    dispatch( clearActivity());
    dispatch( clearGroups());
    dispatch( setAsQualified( false ));
    dispatch( clearResponses());
    dispatch( setModalCloseActivity( false ));
  }, [dispatch]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      {book && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={3}>
            <CardInfoBook book={book} />
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" fontWeight="bold">
                  Unidades Y Actividades
                </Typography>
                <Button onClick={() => {
                  dispatch( setCreateEditUnitsModal( true ));
                  trackEvent( 'push_add_unit_button_admin', {
                    elementPushed: 'button', userInAction: auth.currentUser?.uid, book: book.name, viewName: 'Detalle del Libro',
                  });
                }}
                >
                  Agregar Unidad
                </Button>
              </Grid>
              <Grid item xs={12}>
                {loadingUnitGet ? (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress size={30} />
                  </Box>
                ) : (
                  <UnitCard />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <BooksDialogsContainer />
    </>
  );
};

export default AdminBookDetail;
