import { Route, Routes } from 'react-router-dom';
import NotFound404 from '../../shared/Errors/NotFound404';
import UnitAndTopicsRouter from '../subModule/units/router/UnitAndTopicsRouter';
import AdminBooksList from '../pages/AdminBooksList';
import AdminBookDetail from '../pages/AdminBookDetail';

const AdminBooksRouter = () => (
  <Routes>
    <Route path="/" element={<AdminBooksList title="Lista de Libros" />} />
    <Route path="/unidades-temas" element={<UnitAndTopicsRouter />} />
    <Route path="/ver-libro" element={<AdminBookDetail />} />
    <Route
      path="*"
      element={(
        <NotFound404 /> )}
    />
  </Routes>
);

export default AdminBooksRouter;
