import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import Logo from '../../../shared/custom/logo/Logo';
import { IRegisterCredentials } from '../../interfaces';
import { RegisterValidationSchema } from '../../validators/validatioSchemas';
import { theme } from '../../../shared/theme';
import CustomInputLabel from '../../../shared/custom/label/CustomLabel';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { setModalState } from '../../slice/authSlice';
import { signUp } from '../../actions/authActions';
import { getInstitutions } from '../../../institutions/actions/institutionsActions';

const RegisterForm = () => {
  const dispatch = useAppDispatch();
  const { loadingSignUp } = useAppSelector(( state ) => state.auth );
  const { institutionsList } = useAppSelector(( state ) => state.institution );
  const [showPassword, setShowPassword] = useState( false );
  const [showConfirmationPassword, setShowConfirmationPassword] = useState( false );
  const {
    getFieldProps, handleSubmit, errors, touched, values, setFieldValue,
  } = useFormik<IRegisterCredentials>({
    initialValues: {
      email: '',
      password: '',
      confirmationPassword: '',
      name: '',
      lastname: '',
      institution: null,
    },
    validationSchema: RegisterValidationSchema,
    onSubmit: async ({
      email, password, name, lastname, institution,
    }) => {
      await dispatch( signUp({
        email, password, name, lastname, institution,
      }));
      await dispatch( setModalState( false ));
      toast.info( 'Te estamos redirigiendo...' );
    },
  });
  useEffect(() => {
    dispatch( getInstitutions());
  }, [dispatch]);
  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <Grid container justifyContent="center" sx={{ maxWidth: '400px' }}>
        <Grid item xs={8} sm={6} textAlign="center">
          <Logo size="medium" />
        </Grid>
        <Grid item xs={9} sm={8} sx={{ mb: 2.5 }}>
          <Autocomplete
            disablePortal
            id="institution"
            options={institutionsList}
            fullWidth
            size="small"
            getOptionLabel={( option ) => option.name}
            isOptionEqualToValue={( option, value ) => option === value}
            renderInput={( params ) => (
              <TextField
                {...params}
                placeholder="Escoja una institución"
                error={touched.institution && !!errors.institution}
                helperText={touched.institution && errors.institution}
              />
            )}
            value={values.institution}
            onChange={( _, value ) => {
              setFieldValue( 'institution', value );
            }}
          />
        </Grid>
        <Grid item xs={9} sm={8} sx={{ mb: 2.5 }}>
          <TextField
            size="small"
            label="Nombre"
            fullWidth
            placeholder="Nombres completos"
            error={touched.name && !!errors.name}
            helperText={touched.name && errors.name}
            {...getFieldProps( 'name' )}
          />
        </Grid>
        <Grid item xs={9} sm={8} sx={{ mb: 2.5 }}>
          <TextField
            size="small"
            label="Apellidos"
            fullWidth
            placeholder="Apellidos completos"
            error={touched.lastname && !!errors.lastname}
            helperText={touched.lastname && errors.lastname}
            {...getFieldProps( 'lastname' )}
          />
        </Grid>
        <Grid item xs={9} sm={8} sx={{ mb: 2.5 }}>
          <TextField
            size="small"
            label="Correo"
            fullWidth
            placeholder="Correo electrónico"
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            {...getFieldProps( 'email' )}
          />
        </Grid>
        <Grid item xs={9} sm={8} sx={{ mb: 2 }}>
          <FormControl sx={{ width: '100%' }}>
            <CustomInputLabel
              htmlFor="register-password-field"
              error={touched.password && !!errors.password}
            >
              Contraseña
            </CustomInputLabel>
            <OutlinedInput
              id="register-password-field"
              size="small"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label="Contraseña"
              placeholder="Contraseña"
              error={touched.password && !!errors.password}
              endAdornment={(
                <IconButton
                  onClick={() => setShowPassword( !showPassword )}
                >
                  { showPassword ? (
                    <Visibility
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  ) : (
                    <VisibilityOff
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  )}
                </IconButton>
              )}
              {...getFieldProps( 'password' )}
            />
          </FormControl>
          { errors.password && touched.password && (
            <FormHelperText
              sx={{ fontSize: 14 }}
              error
            >
              {errors.password}
            </FormHelperText>
          ) }
        </Grid>
        <Grid item xs={9} sm={8} sx={{ mb: 2 }}>
          <FormControl sx={{ width: '100%' }}>
            <CustomInputLabel
              htmlFor="register-password-confirmation-field"
              error={touched.password && !!errors.password}
            >
              Confirma tu Contraseña
            </CustomInputLabel>
            <OutlinedInput
              id="register-password-confirmation-field"
              size="small"
              fullWidth
              type={showConfirmationPassword ? 'text' : 'password'}
              label="Confirma tu Contraseña"
              placeholder="Repite tu Contraseña"
              error={touched.confirmationPassword && !!errors.confirmationPassword}
              endAdornment={(
                <IconButton
                  onClick={() => setShowConfirmationPassword( !showConfirmationPassword )}
                >
                  { showConfirmationPassword ? (
                    <Visibility
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  ) : (
                    <VisibilityOff
                      width={24}
                      height={24}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  )}
                </IconButton>
              )}
              {...getFieldProps( 'confirmationPassword' )}
            />
          </FormControl>
          { errors.confirmationPassword && touched.confirmationPassword && (
            <FormHelperText
              sx={{ fontSize: 14 }}
              error
            >
              {errors.confirmationPassword}
            </FormHelperText>
          ) }
        </Grid>
        <Grid item xs={9} sm={8} textAlign="center">
          <Button
            type="submit"
            size="small"
            fullWidth
            disabled={loadingSignUp}
            endIcon={loadingSignUp && ( <CircularProgress size={10} /> )}
          >
            Enviar
          </Button>
        </Grid>
        <Grid item xs={9} sm={8} textAlign="center">
          <Button
            variant="text"
            onClick={() => dispatch( setModalState( !!loadingSignUp ))}
            disabled={loadingSignUp}
          >
            Ya tengo una cuenta!
          </Button>
        </Grid>
      </Grid>
      <Typography
        variant="caption"
        display="block"
        textAlign="center"
      >
        v1.0.4
      </Typography>
    </form>
  );
};

export default RegisterForm;
