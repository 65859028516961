import {
  GridFooterContainer, GridPagination,
} from '@mui/x-data-grid';
import CustomPagination from './CustomPagination';

const CustomFooter = () => (
  <GridFooterContainer>
    <GridPagination />
    <CustomPagination />
  </GridFooterContainer>
);

export default CustomFooter;
