import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { ISeller } from '../interfaces';

const sellerConverter = {
  toFirestore: ( seller: ISeller ) => ({
    ...seller,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): ISeller => {
    const data = snapshot.data( options );

    const created = data.created.toDate().getTime();

    const {
      active,
      deleted,
      email,
      identification,
      name,
      lastname,
      phone,
      photo,
      type,
    } = data;

    return {
      id: snapshot.id,
      active,
      deleted,
      email,
      identification,
      name,
      lastname,
      phone,
      photo,
      type,
      created,
    } as ISeller;
  },
};

export default sellerConverter;
