import {
  Card, CardContent, CardHeader, Typography, Box, CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import LineChart from './LineChart';
import { initialDashboard } from '../hooks/InitialDashboard';
// import { dashboardData } from './TestData';
import { useAppSelector } from '../../../app/hooks';
import { ILineChartProgress, IStatistics } from '../interfaces';

const LineChartCard = () => {
  const loading = false;
  const [dashboard, setDashboard] = useState( initialDashboard );
  const { last7Days } = useAppSelector(( state ) => state.dashboard );
  useEffect(() => {
    const created : ILineChartProgress[] = [];
    const used: ILineChartProgress[] = [];
    const canceled: ILineChartProgress[] = [];
    last7Days.forEach(( item: IStatistics ) => {
      created.push({ id: v4(), percentage: item.codes.created, created: item.date });
      used.push({ id: v4(), percentage: item.codes.used, created: item.date });
      canceled.push({ id: v4(), percentage: item.codes.canceled, created: item.date });
    });
    setDashboard({
      progress: [{
        id: v4(),
        objective: 'Códigos Creados',
        progress: created,
      },
      {
        id: v4(),
        objective: 'Códigos Utilizados',
        progress: used,
      },
      {
        id: v4(),
        objective: 'Códigos Cancelados',
        progress: canceled,
      }],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last7Days]);
  return (
    <Card sx={{
      width: '100%',
      height: '100%',
      minHeight: '100%',
    }}
    >
      <CardHeader
        title={(
          <Box>
            <Typography component="p" sx={{ fontWeight: 700 }}>
              Detalle últimos 7 Días
            </Typography>
          </Box>
        )}
        subheader={(
          <Typography component="p" variant="body2" sx={{ fontSize: '0.8em' }}>
            Visualiza el comportamiento de la categoría seleccionada en los últimos días
          </Typography>
        )}
      />
      <CardContent>
        {loading ? (
          <CircularProgress size="30" />
        ) : (
          <>
            {dashboard && dashboard.progress ? ( <LineChart data={dashboard.progress} /> ) : ( <span>Sin Datos</span> )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default LineChartCard;
