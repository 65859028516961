import {
  Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { auth } from '../../../firebase';
import { IGroupActivity, IQuestion } from '../../interfaces';
import { IResponse } from './interfaces';
import QuestionContainer from './QuestionContainer';
import RubricEvaluation from './RubricEvaluation';
import { addInitialResponses, setAsQualified } from '../../slice/activitySlice';

const Printer = () => {
  const dispatch = useAppDispatch();
  // Getting data from the store
  const {
    groups, questionsList, loadingGetQuestion, activity, responses, alreadyQualified,
  } = useAppSelector(( state ) => state.activity );
  useEffect(() => {
    const responsesToReturn: IResponse[] = [...responses];
    if ( questionsList.length > 0 ) {
      questionsList.forEach(( question ) => {
        const alreadyExist = responses.find(( response ) => response.questionId === question.id );
        if ( !alreadyExist && question && question.type !== 'embed' ) {
          responsesToReturn.push({
            userId: auth.currentUser?.uid || '',
            questionId: question.id || '',
            response: '',
            type: question.type,
            groupId: question.groupId,
            correct: false,
          });
        }
      });
      dispatch( addInitialResponses( responsesToReturn ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsList, dispatch]);
  return (
    <>
      {loadingGetQuestion ? (
        <Box textAlign="center">
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <Card>
              <CardHeader
                title={(
                  <Typography variant="h5" fontWeight="bold">
                    {activity?.name}
                  </Typography>
                )}
                subheader={(
                  <Typography variant="h6" fontWeight="bold" color={grey[500]} ml={1}>
                    {activity?.description}
                  </Typography>
                )}
              />
              <CardContent>
                {groups && groups.length > 0 && groups.map(( group: IGroupActivity, index: number ) => (
                  <Box key={index.toString()} mb={3}>
                    {group.type === 'file' && (
                      <Typography variant="h6" fontWeight="bold">
                        Subir Archivo
                      </Typography>
                    )}
                    {group.type === 'complete' && (
                      <Typography variant="h6" fontWeight="bold">
                        Completar
                      </Typography>
                    )}
                    {group.type === 'openQuestions' && (
                      <Typography variant="h6" fontWeight="bold">
                        Preguntas Abiertas
                      </Typography>
                    )}
                    {group.type === 'dragAndDrop' && (
                      <Typography variant="h6" fontWeight="bold">
                        Arrastrar y Soltar
                      </Typography>
                    )}
                    {group.type === 'dragAndDropImages' && (
                      <Typography variant="h6" fontWeight="bold">
                        Arrastrar y Soltar Imagenes
                      </Typography>
                    )}
                    {group.type === 'dragAndDropOrder' && (
                      <Typography variant="h6" fontWeight="bold">
                        Arrastrar y Soltar (Ordenar)
                      </Typography>
                    )}
                    {group.type === 'dragAndDropCorrect' && (
                      <Typography variant="h6" fontWeight="bold">
                        Arrastrar y Soltar (Opción Correcta)
                      </Typography>
                    )}
                    {group.type === 'embed' && (
                      <Typography variant="h6" fontWeight="bold">
                        Actividad Embebida
                      </Typography>
                    )}
                    <Typography variant="body1" mb={1}>
                      {group.type === 'file' ? 'Subir Archivo Solicitado' : group.rubric}
                    </Typography>
                    {questionsList.map(( question: IQuestion, indexQuestion: number ) => question.groupId === group.id && (
                      <QuestionContainer key={indexQuestion.toString()} question={question} />
                    ))}
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={3} sx={{ display: 'block' }}>
            <Grid
              container
              spacing={2}
              sx={{
                position: 'sticky',
                top: 50,
              }}
            >
              {responses.length > 0 && (
                <Grid item xs={12}>
                  <Button fullWidth onClick={() => dispatch( setAsQualified( true ))} disabled={alreadyQualified}>
                    Calificar
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Card
                  sx={{
                    backgroundColor: '#E7E6E6',
                  }}
                >
                  <CardHeader
                    sx={{ paddingBottom: 0 }}
                    title={(
                      <Typography variant="h6" fontWeight="bold" textAlign="center">
                        Rúbrica de Evaluación
                      </Typography>
                    )}
                  />
                  <CardContent
                    sx={{ paddingTop: 0 }}
                  >
                    <RubricEvaluation groups={groups} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Printer;
