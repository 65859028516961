import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getBooks } from '../actions/booksActions';
import { setFilterBooksModal } from '../slice/booksSlice';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import BooksPagination from '../components/BooksPagination';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import { IBook } from '../interfaces';
import TopCardActions from '../../shared/custom/cards/TopCardActions';
import BooksChipsContainer from '../components/BooksChipsContainer';
import FilterBoooksForm from '../components/FilterBoooksForm';
import { trackEvent } from '../../../helpers/analytics';
import { auth } from '../../firebase';

interface ISellersBooksListProps {
  title: string;
}

const SellersBooksList = ({ title }: ISellersBooksListProps ) => {
  const dispatch = useAppDispatch();
  const [books, setBooks] = useState<IBook[]>([]);
  const {
    booksList, loadingGet, booksFiltered, hasFilter, modalFilter, loadingFilter,
  } = useAppSelector(( state ) => state.book );
  const setTitle = useSetTitle({
    title: 'Lista de Libros',
    subItems: ['Libros', 'Listar Libros'],
    noPrintItems: false,
  });
  useEffect(() => {
    document.title = title;
    dispatch( getBooks());
    dispatch( setNoMoreData( true ));
  }, [title, dispatch]);
  useEffect(() => {
    setBooks( booksFiltered.length > 0 || hasFilter ? booksFiltered : booksList );
  }, [booksFiltered, booksList, hasFilter]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      {loadingGet ? (
        <Grid textAlign="center">
          <CircularProgress size={40} />
        </Grid>
      ) : (
        <Box>
          <Grid container spacing={2} display="flex" justifyContent="space-between">
            <Grid item xs={12} display="flex">
              <Typography fontWeight="bold" whiteSpace="nowrap" mr={1} fontSize={25} display="flex" alignItems="center">
                Libros
                {' '}
                (
                {booksList.length}
                )
              </Typography>
              <TopCardActions
                onlySearch
                filterClick={() => {
                  dispatch( setFilterBooksModal( true ));
                  trackEvent( 'push_filter_book_button_seller', {
                    elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de Libros',
                  });
                }}
                sort
              >
                <BooksChipsContainer filters={hasFilter} />
              </TopCardActions>
            </Grid>
          </Grid>
          <Box mt={5} mb={4}>
            <BooksPagination books={books} />
          </Box>
        </Box>
      )}
      <FormDialog
        open={modalFilter}
        title="Filtrar Libro"
        closeFunction={() => dispatch( setFilterBooksModal( !!loadingFilter ))}
      >
        <FilterBoooksForm />
      </FormDialog>
    </>
  );
};

export default SellersBooksList;
