import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../modules/firebase';

export const uploadFile = async ( id: string, file: File ) => {
  const metadata = {
    contentType: file.type,
  };
  const storageRef = ref( storage, `indications/${id}` );
  const photoPath = await uploadBytes( storageRef, file, metadata );
  const url = await getDownloadURL( photoPath.ref );
  return url;
};
