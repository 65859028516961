/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useDrop } from 'react-dnd';
import useTextColor from '../../../../hooks/useTextColor';

interface IProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  id: string;
  questionId: string;
}

const BoxResponse: React.FC<IProps> = ( props ) => {
  const {
    children, title, id, questionId,
  } = props;
  const questionColor = useTextColor( questionId );

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'ITEM',
    drop: () => ({ name: title, id }),
    collect: ( monitor ) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const handleCreateQuestion = (): JSX.Element[] => {
    const str = title.split( ' ' );
    return (
      str.map(( item: string, i: number ) => (
        !item.includes( '**' )
          ? (
            <Typography
              component="span"
              mr={0.5}
              key={i.toString()}
              sx={{
                color: questionColor,
              }}
            >
              {item}
            </Typography>
          )
          : (
            <Box
              key={i.toString()}
              sx={{
                border: '1px solid #707070',
                height: 40,
                width: 120,
                pl: 1,
                pr: 1,
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#fff',
                '& > *': {
                  border: '0!important',
                  mb: '0!important',
                },
              }}
            >
              { children }
            </Box>
          )
      ))
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      ref={drop}
    >
      {handleCreateQuestion()}
    </Box>
  );
};

export default BoxResponse;
