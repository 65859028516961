import {
  Avatar, Box, Card, CardContent, Divider, Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useAppSelector } from '../../../app/hooks';
import CardBookButton from '../../shared/custom/buttons/CardBookButton';
import { oneYearLater } from '../helpers/oneYearLater';
import { IBook } from '../interfaces';

interface ICardInfoBookProps {
  book: IBook;
}

const CardInfoBook = ({ book }: ICardInfoBookProps ) => {
  const { expirationDate } = useAppSelector(( state ) => state.auth );
  return (
    <Card sx={{ boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
      <CardContent sx={{ textAlign: 'center' }}>
        <Box sx={{
          display: 'flex', justifyContent: 'center', mb: '1em', mt: '1.5em',
        }}
        >
          <Avatar src={book.photo} alt={book.name} sx={{ width: '100px', height: '100px' }} />
        </Box>
        <Typography fontWeight="bold" color={grey[800]}>
          {book.name}
        </Typography>
        <Typography mb={1.5}>
          Editorial
          {' '}
          {book.editorial}
        </Typography>
        <Box mb={1.5} display="flex" gap={1} justifyContent="center">
          <CardBookButton quantity={book.statistics.units} text="Unidades" />
          <CardBookButton quantity={book.statistics.topics} text="Temas" />
          <CardBookButton quantity={book.statistics.activities} text="Actividades" />
        </Box>
        <Box textAlign="start">
          <Typography variant="h6" fontWeight="bold" color={grey[800]} mt={2} mb={2}>
            Detalles
          </Typography>
          <Divider />
          <Typography variant="body1" fontWeight="bold" color={grey[800]} mt={2}>
            ID Libro
          </Typography>
          <Typography variant="body2" color={grey[800]} mb={2}>
            {book.id}
          </Typography>
          <Typography variant="body1" fontWeight="bold" color={grey[800]} mt={2}>
            Fecha de Registro
          </Typography>
          <Typography variant="body2" color={grey[800]} mb={2}>
            {new Date( expirationDate || new Date().getTime()).toLocaleDateString()}
          </Typography>
          <Typography variant="body1" fontWeight="bold" color={grey[800]} mt={2}>
            Fecha de Vencimiento
          </Typography>
          <Typography variant="body2" color={grey[800]} mb={2}>
            {oneYearLater( expirationDate || new Date().getTime()).toLocaleDateString()}
          </Typography>
          <Typography variant="body1" fontWeight="bold" color={grey[800]} mt={2}>
            Categoría
          </Typography>
          <Typography variant="body2" color={grey[800]} mb={2}>
            {book.category}
          </Typography>
          <Typography variant="body1" fontWeight="bold" color={grey[800]} mt={2}>
            Materia
          </Typography>
          <Typography variant="body2" color={grey[800]} mb={2}>
            {book.subCategory}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardInfoBook;
