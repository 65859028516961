import {
  Box,
  Button, Card, CardContent, Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useAppDispatch } from '../../../../app/hooks';
import { ReactComponent as BarCode } from '../../../../assets/svg/thatBook/barCode.svg';
import { setActivateCodeModal } from '../../../dashboard/slice/dashboardSlice';
import { trackEvent } from '../../../../helpers/analytics';
import { auth } from '../../../firebase';

const NewCodeCard = () => {
  const dispatch = useAppDispatch();
  return (
    <Card sx={{
      backgroundColor: 'transparent',
      border: '1px dashed #707070',
      pl: 3,
      pr: 3,
      minHeight: 395,
      maxHeight: 395,
    }}
    >
      <CardContent>
        <Typography textAlign="center" fontWeight="bold" color={grey[800]} mt={6} mb={3}>
          Añadir nuevo libro
        </Typography>
        <Typography textAlign="center">
          Ingresa el código que encontrarás en la pasta posterior del libro.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} mb={6}>
          <BarCode width={90} height={90} />
          <Button size="small" sx={{ backgroundColor: '#181C32' }} onClick={() => { dispatch( setActivateCodeModal( true )); trackEvent( 'push_activate_code_button_student', { elementPushed: 'buttom', userInAction: auth.currentUser?.uid, viewName: 'Dashboard Estudiantes' }); }}>
            Ingresar el Código
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewCodeCard;
