import { useFormik } from 'formik';
import {
  Grid, Typography, TextField, InputLabel, Autocomplete, Box, CardContent, CardActions, Button,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import Complete from './Descriptions/Complete';
import File from './Descriptions/File';
import { activitiesTypes } from '../../shared/Common/ActivitiesTypes';
import DragAndDropWords from './Descriptions/DragAndDropWords';
import OpenQuestions from './Descriptions/OpenQuestions';
import { IRegisterGroupActivity } from '../interfaces';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { typeValidationSchema } from '../validators/activitiesValidationSchema';
import { addGroupBeforeSendInStore, setCreateorEditGroupModal, updateGroupInStore } from '../slice/activitySlice';
import useGroupInitialState from '../hooks/useGroupInitialState';

const TypeForm = () => {
  const { group } = useAppSelector(( state ) => state.activity );
  const dispatch = useAppDispatch();
  const [reinitializable, setReinitializable] = useState<boolean>( false );
  const {
    getFieldProps, handleSubmit, errors, touched, resetForm, values, setFieldValue,
  } = useFormik<IRegisterGroupActivity>({
    initialValues: useGroupInitialState(),
    validationSchema: typeValidationSchema,
    enableReinitialize: reinitializable,
    onSubmit: async ( formValues ) => {
      formValues.id = v4();
      if ( group ) {
        dispatch( updateGroupInStore( formValues ));
        toast.info( 'Grupo Actualizado' );
      } else {
        dispatch( addGroupBeforeSendInStore( formValues ));
        toast.info( 'Grupo Creado' );
      }
      dispatch( setCreateorEditGroupModal( false ));
      resetForm();
    },
  });

  useEffect(() => setReinitializable( true ), []);

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <CardContent sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor="type"
                  error={touched.type && !!errors.type}
                >
                  <Typography fontWeight="bold" fontSize={12}>Tipos</Typography>
                </InputLabel>
                <Autocomplete
                  disablePortal
                  id="type"
                  options={activitiesTypes.sort(( a, b ) => a.label.localeCompare( b.label ))}
                  getOptionLabel={( option ) => option.label}
                  fullWidth
                  size="small"
                  disabled={group !== undefined}
                  clearIcon={null}
                  renderInput={( params ) => (
                    <TextField
                      {...params}
                      placeholder="Escoja un tipo de actividad"
                      error={touched.type && !!errors.type}
                      helperText={touched.type && errors.type}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: '#F2F2F2',
                        },
                      }}
                    />
                  )}
                  value={values.type}
                  onChange={( _, value ) => {
                    if ( value !== null ) setFieldValue( 'type', value );
                  }}
                />
              </Grid>
              { values.type?.value !== 'file' && values.type?.value !== 'embed' && (
                <Grid item xs={12}>
                  <InputLabel htmlFor="rubric">Rúbrica</InputLabel>
                  <TextField
                    size="small"
                    id="rubric"
                    fullWidth
                    placeholder="Ingrese la rúbrica de califición"
                    error={touched.rubric && !!errors.rubric}
                    helperText={touched.rubric && errors.rubric}
                    sx={{
                      '& .MuiOutlinedInput-root > input': {
                        backgroundColor: '#F2F2F2',
                      },
                    }}
                    {...getFieldProps( 'rubric' )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              Descripción
            </Typography>
            {values.type !== null ? (
              <Box>
                {values.type.value !== null && values.type.value === 'file' && (
                  <File />
                )}
                {values.type.value !== null && values.type.value === 'complete' && (
                  <Complete />
                )}
                {values.type.value !== null && values.type.value === 'dragAndDrop' && (
                  <DragAndDropWords />
                )}
                {values.type.value !== null && values.type.value === 'openQuestions' && (
                  <OpenQuestions />
                )}
              </Box>
            ) : (
              <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                <Typography fontWeight="bold">
                  Porfavor, escoja algún tipo de actividad.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ borderBottom: '1px solid #E0E0E0' }}>
        <Grid container justifyContent="center" sx={{ maxWidth: '810px' }}>
          <Grid item xs={9} sm={10} display="flex" justifyContent="center" gap={2} sx={{ mb: 1.5, mt: 1.5 }}>
            <Button
              color="inherit"
              size="small"
              sx={{
                mb: 1,
              }}
              onClick={() => dispatch( setCreateorEditGroupModal( false ))}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="small"
              sx={{
                mb: 1,
              }}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

export default TypeForm;
