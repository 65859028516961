import {
  Card, CardContent, Tab, Tabs, Box, Grid,
} from '@mui/material';
import React, { useState } from 'react';
import { IUser } from '../../auth/interfaces';
import CardDeletedAccount from './CardComponents/CardDeleteAcount';
import CardInfo from './CardComponents/CardInfo';
import CardPassword from './CardComponents/CardPassword';
import CardPhoto from './CardComponents/CardPhoto';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ICardAcount {
  userInfo: IUser | undefined;
}

function TabPanel( props: TabPanelProps ) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps( index: number ) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

const CardAcount: React.FC<ICardAcount> = ( props ) => {
  const { userInfo } = props;
  const [value, setValue] = useState( 0 );
  const handleChange = ( event: React.SyntheticEvent, newValue: number ) => {
    setValue( newValue );
  };

  return (
    <Card>
      <Box
        sx={{
          borderBottom: '1px solid #EAEAEA',
          padding: '1em 0 0 0',
          marginLeft: '1em',
          marginRight: '1em',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="profile-tab"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Perfil" {...a11yProps( 0 )} />
          <Tab label="Seguridad" {...a11yProps( 1 )} />
        </Tabs>
      </Box>
      <CardContent>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={5}>
              <CardPhoto
                photo={userInfo?.photo}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <CardInfo />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
              <CardPassword />
            </Grid>
            <Grid item xs={12} lg={5}>
              <CardDeletedAccount />
            </Grid>
          </Grid>
        </TabPanel>
      </CardContent>
    </Card>
  );
};

export default CardAcount;
