import { useAppSelector } from '../../../app/hooks';
import { activitiesTypes } from '../../shared/Common/ActivitiesTypes';

const useGroupInitialState = () => {
  const { group } = useAppSelector(( state ) => state.activity );

  const typeGroup = activitiesTypes.find(( type ) => type.value === group?.type );

  return {
    id: group ? group.id : '',
    rubric: group ? group.rubric : '',
    type: group && typeGroup ? typeGroup : null,
    deleted: group ? group.deleted : null,
  };
};

export default useGroupInitialState;
