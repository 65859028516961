import { TreeItem, TreeView } from '@mui/lab';
import {
  Box, Card, CardContent, CardHeader, CircularProgress, Grid, Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { ReactComponent as Pen } from '../../../../../assets/svg/thatBook/pen.svg';
import { getActivitiesByUnitId } from '../../../../activities/actions/activitiesActions';
import { IActivity } from '../../../../activities/interfaces';
import { addActivityWithIdForActions } from '../../../../activities/slice/activitySlice';
import { IUnit } from '../interfaces';
import { addUnitWithIdForActions } from '../slice/unitsAndTopicsSlice';

interface IStudentActivityItemProps{
  unit: IUnit;
}

const StudentActivityItem = ({ unit }: IStudentActivityItemProps ) => {
  const { activitiesList, loadingGetActivity } = useAppSelector(( state ) => state.activity );
  const { role } = useAppSelector(( state ) => state.auth );
  const dispatch = useAppDispatch();
  const [expandedActivities, setExpandedActivities] = useState<string[]>([]);
  const navigate = useNavigate();
  const handleToggleActivities = ( event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedActivities( nodeIds );
  };
  useEffect(() => {
    dispatch( getActivitiesByUnitId( unit.id ));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card sx={{ height: 322 }}>
        <CardHeader
          title={(
            <Typography fontSize={20} fontWeight="bold" color={grey[900]} noWrap maxWidth={310}>
              {unit.name}
            </Typography>
          )}
          subheader={(
            <Typography fontSize={14} variant="body2" height={40}>
              {unit.description}
            </Typography>
          )}
        />
        <CardContent>
          <TreeView
            aria-label="customized"
            defaultCollapseIcon={<Pen fill="#707070" width={20} height={20} />}
            defaultEndIcon={<Pen fill="#707070" width={20} height={20} />}
            defaultExpandIcon={
              <Pen fill="#707070" width={20} height={20} />
            }
            sx={{
              flexGrow: 1, maxWidth: 400, overflowY: 'auto',
            }}
            expanded={expandedActivities}
            onNodeToggle={handleToggleActivities}
          >
            <TreeItem
              nodeId={`${unit.id}2`}
              label={
                (
                  <Box display="flex">
                    <Typography>
                      Temas
                    </Typography>
                    <Typography ml={5}>
                      (
                      {activitiesList.filter(( item: IActivity ) => item.unitId === unit.id ).length}
                      )
                    </Typography>
                  </Box>
                )
              }
              sx={{ height: expandedActivities.includes( `${unit.id}2` ) ? 100 : 'auto' }}
            >
              {loadingGetActivity ? ( <Box textAlign="center"><CircularProgress size={20} /></Box> ) : (
                <>
                  {activitiesList && activitiesList.length > 0 ? activitiesList.map(( activity: IActivity, indexActivity: number ) => activity.unitId === unit.id
                  && (
                    <TreeItem
                      nodeId={activity.id}
                      label={activity.name}
                      key={indexActivity.toString()}
                      onClick={() => {
                        dispatch( addActivityWithIdForActions( activity.id ));
                        dispatch( addUnitWithIdForActions( unit.id ));
                        navigate( `/${role === 'Seller' ? 'vendedor' : 'estudiante'}/actividades/prueba` );
                      }}
                    />
                  )) : 'Sin Temas'}
                </>
              )}
            </TreeItem>
          </TreeView>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default StudentActivityItem;
