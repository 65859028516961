import { useAppSelector } from '../../../app/hooks';

const useSellersInitialState = () => {
  const { seller } = useAppSelector(( state ) => state.seller );

  return {
    name: seller ? seller.name : '',
    lastname: seller ? seller.lastname : '',
    email: seller ? seller.email : '',
    identification: seller ? seller.identification.toString() : '',
    phone: seller && seller.phone ? seller.phone.toString() : '',
  };
};

export default useSellersInitialState;
