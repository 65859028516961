import {
  Card, CardContent, CircularProgress, Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getAllCodeGenerations } from '../actions/codesActions';
import CodeGenerationTable from '../components/CodeGenerationTable';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import TopCardActions from '../../shared/custom/cards/TopCardActions';
import { ICodeGeneration } from '../interfaces';
import useSetTitle from '../../shared/Hooks/useSetTitle';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import { clearCodesFilter, setFilterCodeModal } from '../slice/codesSlice';
import FilterCodeForm from '../components/FilterCodeForm';
import { trackEvent } from '../../../helpers/analytics';
import { auth } from '../../firebase';
import { getBooks } from '../../books/actions/booksActions';
import CodesChipsContainer from '../components/CodesChipsContainer';

interface ICodesListProps {
  title: string;
}

const AdminOrdersList = ({ title }: ICodesListProps ) => {
  const dispatch = useAppDispatch();
  const {
    codeGenerationsList, loadingGet, codeGenerationsFiltered, hasFilter, modalFilter, loadingFilter,
  } = useAppSelector(( state ) => state.code );
  const setTitle = useSetTitle({
    title: 'Lista de Códigos',
    subItems: ['Códigos', 'Lista Códigos'],
    noPrintItems: false,
  });
  const [codeGenerations, setCodeGeneration] = useState<ICodeGeneration[]>([]);
  useEffect(() => {
    dispatch( getAllCodeGenerations());
    dispatch( setNoMoreData( true ));
    dispatch( getBooks());
    dispatch( clearCodesFilter());
    document.title = title;
  }, [title, dispatch]);
  useEffect(() => {
    setCodeGeneration( codeGenerationsFiltered.length > 0 || hasFilter ? codeGenerationsFiltered : codeGenerationsList );
  }, [codeGenerationsFiltered, codeGenerationsList, hasFilter]);
  useEffect(() => {
    setTitle();
  }, [setTitle]);
  return (
    <>
      {loadingGet ? (
        <Grid textAlign="center">
          <CircularProgress size={40} />
        </Grid>
      ) : (
        <Card>
          <CardContent>
            <TopCardActions
              onlySearch
              filterClick={() => {
                dispatch( setFilterCodeModal( true ));
                trackEvent( 'push_filter_code_button_admin', { elementPushed: 'button', userInAction: auth.currentUser?.uid, viewName: 'Lista de códigos' });
              }}
            >
              <CodesChipsContainer filters={hasFilter} />
            </TopCardActions>
            <CodeGenerationTable codes={codeGenerations} />
          </CardContent>
        </Card>
      )}
      <FormDialog
        open={modalFilter}
        title="Filtrar Códigos"
        closeFunction={() => dispatch( setFilterCodeModal( !!loadingFilter ))}
      >
        <FilterCodeForm />
      </FormDialog>
    </>
  );
};

export default AdminOrdersList;
