import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createTopic, createUnit, deleteTopic, deleteUnit, getTopics, getUnits, updateTopic, updateUnit, getTopicsByUnitId,
} from '../actions/units&TopicsActions';
import { IUnit, ITopic } from '../interfaces';

interface IBookState {
  unitsList: IUnit[];
  unit?: IUnit;
  topicsList: ITopic[];
  topic?: ITopic;
  topicsConsulted: string[];
  loadingUnitGet: boolean;
  loadingUnitCreate: boolean;
  loadingUnitUpdate: boolean;
  loadingUnitDelete: boolean;
  loadingTopicGet: boolean;
  loadingTopicCreate: boolean;
  loadingTopicUpdate: boolean;
  loadingTopicDelete: boolean;
  modal: boolean;
  modalUnit: boolean;
  modalTopic: boolean;
  modalUnitDelete: boolean,
  modalTopicDelete: boolean,
}

const initialState: IBookState = {
  unitsList: [],
  topicsList: [],
  topicsConsulted: [],
  loadingUnitGet: false,
  loadingUnitCreate: false,
  loadingUnitUpdate: false,
  loadingUnitDelete: false,
  loadingTopicGet: false,
  loadingTopicCreate: false,
  loadingTopicUpdate: false,
  loadingTopicDelete: false,
  modal: false,
  modalUnit: false,
  modalTopic: false,
  modalUnitDelete: false,
  modalTopicDelete: false,
};

const unitsAndTopicsSlice = createSlice({
  name: 'unitsTopics',
  initialState,
  reducers: {
    clearUnitsAndTopics: ( state ) => {
      state.unitsList = [];
      state.unit = undefined;
      state.topicsList = [];
      state.topic = undefined;
      state.topicsConsulted = [];
      state.loadingUnitGet = false;
      state.loadingUnitCreate = false;
      state.loadingUnitUpdate = false;
      state.loadingUnitDelete = false;
      state.loadingTopicGet = false;
      state.loadingTopicCreate = false;
      state.loadingTopicUpdate = false;
      state.loadingTopicDelete = false;
      state.modal = false;
      state.modalUnit = false;
      state.modalTopic = false;
      state.modalUnitDelete = false;
      state.modalTopicDelete = false;
    },
    addUnitInStore: ( state, action: PayloadAction<IUnit> ) => {
      state.unitsList = [action.payload, ...state.unitsList];
    },
    addTopicInStore: ( state, action: PayloadAction<ITopic> ) => {
      state.topicsList = [...state.topicsList, action.payload].sort(( a, b ) => a.created - b.created );
    },
    setModalUnitsAndTopics: ( state, action: PayloadAction<boolean> ) => {
      state.modal = action.payload;
      if ( action.payload === false ) {
        state.unit = undefined;
        state.topic = undefined;
      }
    },
    setDeleteUnitModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalUnitDelete = action.payload;
      if ( action.payload === false ) {
        state.unit = undefined;
      }
    },
    setDeleteTopicModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalTopicDelete = action.payload;
      if ( action.payload === false ) {
        state.topic = undefined;
      }
    },
    setCreateEditUnitsModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalUnit = action.payload;
      if ( action.payload === false ) {
        state.unit = undefined;
      }
    },
    setCreateEditTopicsModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalTopic = action.payload;
      if ( action.payload === false ) {
        state.topic = undefined;
        state.unit = undefined;
      }
    },
    addUnitWithIdForActions: ( state, action: PayloadAction<string> ) => {
      state.unit = state.unitsList.find(( unit ) => unit.id === action.payload );
    },
    addTopicWithIdForActions: ( state, action: PayloadAction<string> ) => {
      state.topic = state.topicsList.find(( topic ) => topic.id === action.payload );
    },
    deleteUnitInStore: ( state, action: PayloadAction<string> ) => {
      state.unitsList = [...state.unitsList.filter(( item ) => item.id !== action.payload )];
      state.modalUnitDelete = false;
    },
    deleteTopicInStore: ( state, action: PayloadAction<string> ) => {
      state.topicsList = [...state.topicsList.filter(( item ) => item.id !== action.payload )];
      state.modalTopicDelete = false;
    },
    updateUnitInStore: ( state, action: PayloadAction<IUnit> ) => {
      const updateUnitFunction = () => {
        const data = state.unitsList;
        const index = data.findIndex(
          ( item: IUnit ) => item.id === action.payload.id,
        );
        data.splice( index, 1, action.payload );
        return data;
      };
      state.unitsList = updateUnitFunction();
    },
    updateTopicInStore: ( state, action: PayloadAction<ITopic> ) => {
      const updateTopicFunction = () => {
        const data = state.topicsList;
        const index = data.findIndex(
          ( item: ITopic ) => item.id === action.payload.id,
        );
        data.splice( index, 1, action.payload );
        return data;
      };
      state.topicsList = updateTopicFunction();
    },
  },
  extraReducers: ( builder ) => {
    builder
      .addCase( getUnits.pending, ( state ) => {
        state.loadingUnitGet = true;
      })
      .addCase( getUnits.fulfilled, ( state, action ) => {
        state.loadingUnitGet = false;
        state.unitsList = action.payload!;
      })
      .addCase( getUnits.rejected, ( state ) => {
        state.loadingUnitGet = false;
      });
    builder
      .addCase( getTopics.pending, ( state ) => {
        state.loadingTopicGet = true;
      })
      .addCase( getTopics.fulfilled, ( state, action ) => {
        state.loadingTopicGet = false;
        state.topicsList = action.payload!;
      })
      .addCase( getTopics.rejected, ( state ) => {
        state.loadingTopicGet = false;
      });
    builder
      .addCase( getTopicsByUnitId.pending, ( state ) => {
        state.loadingTopicGet = true;
      })
      .addCase( getTopicsByUnitId.fulfilled, ( state, action ) => {
        state.loadingTopicGet = false;
        const filtereTopics: ITopic[] = [];
        action.payload!.topics.forEach(( item ) => {
          const alreadyExist = state.topicsList.find(( topic ) => topic.id === item.id );
          if ( !alreadyExist ) {
            filtereTopics.push( item );
          }
        });
        state.topicsList = [...state.topicsList, ...filtereTopics];
        state.topicsConsulted = [...state.topicsConsulted, action.payload!.unitId || ''];
      })
      .addCase( getTopicsByUnitId.rejected, ( state ) => {
        state.loadingTopicGet = false;
      });
    builder
      .addCase( createUnit.pending, ( state ) => {
        state.loadingUnitCreate = true;
      })
      .addCase( createUnit.fulfilled, ( state ) => {
        state.loadingUnitCreate = false;
      })
      .addCase( createUnit.rejected, ( state ) => {
        state.loadingUnitCreate = false;
      });
    builder
      .addCase( createTopic.pending, ( state ) => {
        state.loadingTopicCreate = true;
      })
      .addCase( createTopic.fulfilled, ( state ) => {
        state.loadingTopicCreate = false;
      })
      .addCase( createTopic.rejected, ( state ) => {
        state.loadingTopicCreate = false;
      });
    builder
      .addCase( deleteTopic.pending, ( state ) => {
        state.loadingTopicDelete = true;
      })
      .addCase( deleteTopic.fulfilled, ( state ) => {
        state.loadingTopicDelete = false;
      })
      .addCase( deleteTopic.rejected, ( state ) => {
        state.loadingTopicDelete = false;
      });
    builder
      .addCase( deleteUnit.pending, ( state ) => {
        state.loadingUnitDelete = true;
      })
      .addCase( deleteUnit.fulfilled, ( state ) => {
        state.loadingUnitDelete = false;
      })
      .addCase( deleteUnit.rejected, ( state ) => {
        state.loadingUnitDelete = false;
      });
    builder
      .addCase( updateUnit.pending, ( state ) => {
        state.loadingUnitUpdate = true;
      })
      .addCase( updateUnit.fulfilled, ( state ) => {
        state.loadingUnitUpdate = false;
      })
      .addCase( updateUnit.rejected, ( state ) => {
        state.loadingUnitUpdate = false;
      });
    builder
      .addCase( updateTopic.pending, ( state ) => {
        state.loadingTopicUpdate = true;
      })
      .addCase( updateTopic.fulfilled, ( state ) => {
        state.loadingTopicUpdate = false;
      })
      .addCase( updateTopic.rejected, ( state ) => {
        state.loadingTopicUpdate = false;
      });
  },
});

export const {
  clearUnitsAndTopics, addUnitInStore, addTopicInStore, setModalUnitsAndTopics, setCreateEditUnitsModal, setCreateEditTopicsModal, addUnitWithIdForActions, addTopicWithIdForActions, setDeleteUnitModal, setDeleteTopicModal,
  deleteUnitInStore, deleteTopicInStore, updateUnitInStore, updateTopicInStore,
} = unitsAndTopicsSlice.actions;

export default unitsAndTopicsSlice.reducer;
