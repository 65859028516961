import { Card, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(( theme: Theme ) => ({
  root: {
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .theme__header': {
      color: theme.palette.text.primary,
    },
    '& .theme__rows': {
      color: theme.palette.text.primary,
      fontSize: 14,
    },
  },
}));

const CardTableWrapper = ({ children }: { children: JSX.Element }) => {
  const classes = useStyles();

  return (
    <Card
      sx={{ height: '70vh', borderRadius: 0 }}
      elevation={0}
      className={classes.root}
    >
      {children}
    </Card>
  );
};

export default CardTableWrapper;
