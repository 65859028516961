import {
  Box, Grid, Button, Pagination, Typography,
} from '@mui/material';
import Announcement from '@mui/icons-material/Announcement';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { IBook } from '../interfaces';
import CardBook from './CardBook';
import usePaginatedBooks from '../hooks/usePaginatedBooks';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setPage } from '../../shared/slice/uiSlice';

interface IBooksPaginationProps {
  books: IBook[];
}

const BooksPagination = ({ books }: IBooksPaginationProps ) => {
  const dispatch = useAppDispatch();
  const { pagination, page } = useAppSelector(( state ) => state.ui );
  const { loadingGetPaginated } = useAppSelector(( state ) => state.book );
  const [wasClicked, setWasClicked] = useState<boolean>( false );
  const booksPerPage = 9;
  const pagesVisited = page * booksPerPage;

  const displayBooks = books
    .slice( pagesVisited, pagesVisited + booksPerPage )
    .map(( book: IBook ) => (
      <Grid item xs={12} sm={6} md={4} key={book.id}>
        <CardBook book={book} />
      </Grid>
    ));
  const pageCount = Math.ceil( books.length / booksPerPage );
  const getBooks = usePaginatedBooks( false );
  const isLast = page === pageCount - 1;
  const location = useLocation();
  const getMoreData = async ( value: number, next: number ) => {
    dispatch( setPage( value - 1 ));
    const lastPageValidator = value + next > pageCount;
    if ( lastPageValidator && !wasClicked ) {
      setWasClicked( true );
      switch ( true ) {
        case location.pathname === '/vendedor/libros':
          await getBooks();
          break;
        case location.pathname === '/estudiante/libros':
          await getBooks();
          break;
        default:
          break;
      }
    }
    setWasClicked( false );
  };

  return (
    <Box mt={4}>
      <Grid container spacing={2}>
        {displayBooks.length > 0 ? (
          <>
            { displayBooks }
          </>
        ) : (
          <Box textAlign="center" width="100%">
            <Announcement sx={{ width: '300px' }} />
            <Typography variant="h5">
              Ya no hay más resultados.
            </Typography>
          </Box>
        )}
      </Grid>
      <Box display="flex" alignItems="center" mt={4} justifyContent="flex-end">
        <Pagination
          count={pageCount}
          onChange={( event, value ) => { getMoreData( value, 0 ); }}
          page={page + 1}
          shape="rounded"
          color="primary"
          disabled={loadingGetPaginated}
          sx={{
            '& .Mui-selected': { color: 'white!important' },
            '& .MuiPagination-ul > li:last-child': {
              display: ( page + 1 ) === pageCount ? 'none' : 'block',
            },
          }}
        />
        {isLast && (
          <Button
            sx={{
              maxWidth: '32px',
              maxHeight: '32px',
              minWidth: '10px',
              borderRadius: '4px',
              ml: '3px',
              backgroundColor: 'transparent',
              color: 'grey!important',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&:disabled': {
                backgroundColor: 'transparent',
                color: 'rgba(0, 0, 0, 0.282)!important',
              },
            }}
            onClick={() => getMoreData( page + 1, 1 )}
            disabled={!pagination}
          >
            <NavigateNextIcon width="5px!important" />
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BooksPagination;
