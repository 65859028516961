import { Button, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface ITableMenuProps {
  id: string;
  handleOpenView?: ( id: string ) => void;
  handleOpenEdit: ( id: string ) => void;
  handleOpenDelete: ( id: string ) => void;
  canSee?: boolean;
  canEdit?: boolean;
}

const TableMenu = ({
  id, handleOpenView, handleOpenEdit, handleOpenDelete, canSee, canEdit,
}: ITableMenuProps ) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>( null );
  const open = Boolean( anchorEl );
  const handleClick = ( event: MouseEvent<HTMLButtonElement> ) => {
    setAnchorEl( event.currentTarget );
  };

  const handleClose = () => {
    setAnchorEl( null );
  };

  const handleView = () => {
    setAnchorEl( null );
    if ( handleOpenView ) {
      handleOpenView( id );
    }
  };

  const handleEdit = () => {
    setAnchorEl( null );
    handleOpenEdit( id );
  };

  const handleDelete = () => {
    setAnchorEl( null );
    handleOpenDelete( id );
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
        size="small"
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          '&:hover': {
            backgroundColor: '#DADADA',
          },
        }}
      >
        Acciones
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ '& .MuiPaper-root': { borderRadius: '10px', mt: 1 } }}
      >
        {canSee && (
          <MenuItem onClick={handleView}>Ver</MenuItem>
        )}
        {canEdit && (
          <MenuItem onClick={handleEdit}>Editar</MenuItem>
        )}
        <MenuItem onClick={handleDelete}>Eliminar</MenuItem>
      </Menu>
    </div>
  );
};

export default TableMenu;
