import { useAppSelector } from '../../../app/hooks';

const useBooksInitialState = () => {
  const { book } = useAppSelector(( state ) => state.book );

  return {
    name: book ? book.name : '',
    description: book ? book.description : '',
    editorial: book ? book.editorial : '',
    url: book ? book.url : '',
    urlDemo: book ? book.urlDemo : '',
    category: book ? book.category : '',
    subCategory: book ? book.subCategory : '',
    photo: null,
    newPhoto: false,
  };
};

export default useBooksInitialState;
