import {
  Card, CardContent, Typography, Box,
} from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { changePassword } from '../../actions/profileActions';
import { IPasswords } from '../../interfaces';
import PasswordForm from '../PasswordForm';

const CardPassword = ( ) => {
  const dispatch = useAppDispatch();
  const handleChangePassword = async ( newsPasswords: IPasswords ) => {
    await dispatch( changePassword( newsPasswords ));
  };
  return (
    <Card
      sx={{
        borderBottom: '1px solid #EAEAEA',
        boxShadow: `rgb(0 0 0 / 20%) 0px 10px 10px -4px, 
        rgb(0 0 0 / 14%) 0px 3px 4px 0px, 
        rgb(0 0 0 / 12%) 0px 1px 8px 0px`,
      }}
    >
      <Box
        p={2}
        display="flex"
        alignItems="center"
        sx={{
          borderBottom: '1px solid #EAEAEA',
        }}
      >
        <Typography
          variant="body2"
        >
          Cambiar Contraseña
        </Typography>
      </Box>
      <CardContent>
        <PasswordForm
          reinitialize={false}
          handleSendSubmit={handleChangePassword}
        />
      </CardContent>
    </Card>
  );
};

export default CardPassword;
