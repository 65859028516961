import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addDoc,
  collection, doc, getDocs, limit, orderBy, query, startAfter, updateDoc, where,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { uploadPhoto } from '../../../helpers/uploadPhoto';
import { auth, db } from '../../firebase';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import institutionConverter from '../converters/institutionConverter';
import {
  IInstitution, IInstitutionRegister, IInstitutionsFilterOptions, IInstitutionUpdate,
} from '../interfaces';
import {
  addInstitutionInStore, clearInstitutionsFilter, deleteInstitutionInStore, filterInstitutionsInStore, updateInstitutionInStore,
} from '../slice/institutionsSlice';

export const getInstitutions = createAsyncThunk(
  'institution/getInstitutions', async () => {
    try {
      const q = query( collection( db,
        'institutions' ),
      where( 'deleted', '==', false ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( institutionConverter ),
      );
      const institutions: IInstitution[] = [];
      querySnapshot.forEach( async ( item ) => {
        institutions.push({ ...item.data() });
      });
      return institutions;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getInstitutionsPaginated = createAsyncThunk(
  'institution/getInstitutionsPaginated', async ( institution: IInstitution, { dispatch }) => {
    try {
      const q = query( collection( db,
        'institutions' ),
      where( 'deleted', '==', false ),
      orderBy( 'created', 'asc' ), limit( 100 ),
      startAfter( new Date( institution.created )));
      const querySnapshot = await getDocs(
        q.withConverter( institutionConverter ),
      );
      const institutions: IInstitution[] = [];
      querySnapshot.forEach( async ( item ) => {
        institutions.push({ ...item.data() });
      });
      if ( institutions.length > 0 ) {
        dispatch( setNoMoreData( true ));
        toast.success( 'Datos Obtenidos' );
      } else {
        dispatch( setNoMoreData( false ));
        toast.info( 'No hay mas datos' );
      }
      return institutions;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const createInstitution = createAsyncThunk(
  'institution/createInstitution',
  async ( institution: IInstitutionRegister, { dispatch }) => {
    try {
      const photoFile = institution.photo as File;
      const dataToSend = {
        name: institution.name,
        email: institution.email,
        phone: institution.phone,
        created: new Date(),
        creator: auth.currentUser?.uid || '',
        deleted: false,
        active: true,
        photo: null,
      };
      const institutionCreated = await addDoc(
        collection( db, 'institutions' ), dataToSend,
      );
      toast.info( 'Empezamos a subir la foto' );
      const url = await uploadPhoto( institutionCreated.id, photoFile, 'institutions' );
      await updateDoc( doc(
        db,
        'institutions',
        institutionCreated.id || '',
      ), { photo: url });
      dispatch( addInstitutionInStore({
        id: institutionCreated.id,
        ...dataToSend,
        created: new Date( dataToSend.created ).getTime(),
        photo: url,
      }));
      toast.success( 'Institución Registrada Correctamente' );
    } catch ( err ) {
      toast.error( 'A ocurrido un error' );
    }
  },
);

export const updateInstitution = createAsyncThunk(
  'institution/updateInstitution',
  async ( institution: IInstitutionUpdate, { dispatch }) => {
    try {
      // Upload Photo for get download Url
      let url = '';
      if ( institution.newPhoto ) {
        const photoFile = institution.photo as File;
        toast.info( 'Empezamos a subir la foto' );
        url = await uploadPhoto( institution.previousInstitution.id, photoFile, 'institutions' ) as unknown as string;
      } else {
        url = institution.previousInstitution.photo;
      }
      const toUpdateInstitution = {
        name: institution.name,
        email: institution.email,
        phone: institution.phone,
        photo: url,
      };
      await updateDoc(
        doc( db, 'institutions', institution.previousInstitution.id ), toUpdateInstitution,
      );
      toast.success( 'Institución actualizada correctamente' );
      dispatch( updateInstitutionInStore({
        ...toUpdateInstitution,
        created: institution.previousInstitution.created,
        id: institution.previousInstitution.id,
        photo: url,
        creator: institution.previousInstitution.creator,
        deleted: institution.previousInstitution.deleted,
        active: institution.previousInstitution.active,
      }));
    } catch ( err ) {
      toast.error( 'A ocurrido un error.' );
    }
  },
);

export const deleteInstitution = createAsyncThunk(
  'institution/deleteInstitution', async ( id: string, { dispatch }) => {
    try {
      const q = doc( db, 'institutions', id );

      await updateDoc( q, { deleted: true });

      dispatch( deleteInstitutionInStore( id ));

      toast.success( 'Institución eliminada existosamente' );
    } catch ( error ) {
      toast.error( 'Error al intentar eliminar la institución' );
    }
  },
);

export const getInstitutionsWithFilters = createAsyncThunk(
  'institution/getInstitutionsWithFilters', async ( options: IInstitutionsFilterOptions, { dispatch }) => {
    try {
      toast.info( 'Obteniendo datos' );
      let q = query( collection( db,
        'institutions' ),
      where( 'deleted', '==', false ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      if ( options.active ) {
        q = query( q, where( 'active', '==', options.active.value ));
      }
      if ( options.from ) {
        q = query( q, where( 'created', '>=', options.from ));
      }
      if ( options.until ) {
        q = query( q, where( 'created', '<=', options.until ));
      }
      const querySnapshot = await getDocs(
        q.withConverter( institutionConverter ),
      );
      const institutions: IInstitution[] = [];
      querySnapshot.forEach( async ( item ) => {
        institutions.push({ ...item.data() });
      });
      dispatch( filterInstitutionsInStore( options ));
      toast.success( 'Datos obtenidos correctamente' );
      return institutions;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const deleteInstitutionsFilter = createAsyncThunk(
  'institution/deleteInstitutionsFilter', async ( options: IInstitutionsFilterOptions, { dispatch }) => {
    try {
      if ( options.active === null && options.from === null && options.until === null ) {
        dispatch( clearInstitutionsFilter());
        dispatch( setNoMoreData( true ));
      } else {
        dispatch( getInstitutionsWithFilters( options ));
      }
    } catch ( error ) {
      console.log( error );
    }
  },
);
