import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection, doc, getDocs, limit, orderBy, query, startAfter, updateDoc, where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';
import { db, functions } from '../../firebase';
import { setNoMoreData } from '../../shared/slice/uiSlice';
import sellerConverter from '../converters/sellerConverter';
import { ISeller, ISellerRegister, ISellersFilterOptions } from '../interfaces';
import {
  addSellerInStore, clearSellersFilter, deleteSellerInStore, filterSellersInStore,
} from '../slice/sellersSlice';

interface IRecentlyCreated {
  user: string
}

export const getSellers = createAsyncThunk(
  'seller/getSellers', async () => {
    try {
      const q = query( collection( db,
        'users' ),
      where( 'deleted', '==', false ),
      where( 'type', '==', 3 ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( sellerConverter ),
      );
      const sellers: ISeller[] = [];
      querySnapshot.forEach( async ( item ) => {
        sellers.push({ ...item.data() });
      });
      return sellers;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getSellersPaginated = createAsyncThunk(
  'seller/getSellersPaginated', async ( seller: ISeller, { dispatch }) => {
    try {
      const q = query( collection( db,
        'users' ),
      where( 'deleted', '==', false ),
      where( 'type', '==', 3 ),
      orderBy( 'created', 'asc' ), limit( 100 ),
      startAfter( new Date( seller.created )));
      const querySnapshot = await getDocs(
        q.withConverter( sellerConverter ),
      );
      const sellers: ISeller[] = [];
      querySnapshot.forEach( async ( item ) => {
        sellers.push({ ...item.data() });
      });
      if ( sellers.length > 0 ) {
        dispatch( setNoMoreData( true ));
        toast.success( 'Datos Obtenidos' );
      } else {
        dispatch( setNoMoreData( false ));
        toast.info( 'No hay mas datos' );
      }
      return sellers;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const createSeller = createAsyncThunk(
  'seller/createSeller',
  async ( seller: ISellerRegister, { dispatch }) => {
    try {
      const dataToSend = {
        ...seller,
        type: 3,
      };
      if ( seller.phone === '' ) {
        dataToSend.phone = null;
      }
      if ( seller.identification === '' ) {
        dataToSend.identification = null;
      }
      const cloudFunction = httpsCallable( functions, 'user-create' );
      const { data } = await cloudFunction( dataToSend );
      const studentCreated = data as IRecentlyCreated;
      dispatch( addSellerInStore({
        id: studentCreated.user,
        ...dataToSend,
        created: new Date().getTime(),
        active: true,
        deleted: false,
        identification: dataToSend.identification ? dataToSend.identification : '',
        phone: dataToSend.phone ? dataToSend.phone : '',
        photo: '',
      }));
      toast.success( 'Vendedor Registrado Correctamente' );
    } catch ( err ) {
      toast.error( 'A ocurrido un error' );
    }
  },
);

export const getSellersWithFilters = createAsyncThunk(
  'seller/getSellersWithFilters', async ( options: ISellersFilterOptions, { dispatch }) => {
    try {
      toast.info( 'Obteniendo datos' );
      let q = query( collection( db,
        'users' ),
      where( 'type', '==', 3 ),
      where( 'deleted', '==', false ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      if ( options.active ) {
        q = query( q, where( 'active', '==', options.active.value ));
      }
      if ( options.from ) {
        q = query( q, where( 'created', '>=', options.from ));
      }
      if ( options.until ) {
        q = query( q, where( 'created', '<=', options.until ));
      }
      const querySnapshot = await getDocs(
        q.withConverter( sellerConverter ),
      );
      const sellers: ISeller[] = [];
      querySnapshot.forEach( async ( item ) => {
        sellers.push({ ...item.data() });
      });
      dispatch( filterSellersInStore( options ));
      toast.success( 'Datos obtenidos correctamente' );
      return sellers;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const deleteSellersFilter = createAsyncThunk(
  'seller/deleteSellersFilter', async ( options: ISellersFilterOptions, { dispatch }) => {
    try {
      if ( options.active === null && options.from === null && options.until === null ) {
        dispatch( clearSellersFilter());
        dispatch( setNoMoreData( true ));
      } else {
        dispatch( getSellersWithFilters( options ));
      }
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const deleteSeller = createAsyncThunk(
  'seller/deleteSeller', async ( id: string, { dispatch }) => {
    try {
      const q = doc( db, 'users', id );

      await updateDoc( q, { deleted: true });

      dispatch( deleteSellerInStore( id ));

      toast.success( 'Vendedor eliminado existosamente' );
    } catch ( error ) {
      toast.error( 'Error al intentar eliminar al vendedor' );
    }
  },
);
