import { useAppSelector } from '../../../app/hooks';

const useQuestionInitialState = () => {
  const { question } = useAppSelector(( state ) => state.activity );
  return {
    id: question ? question.id : '',
    created: question ? question.created : new Date().getTime(),
    deleted: question ? question.deleted : false,
    answer: question ? question.answer : '',
    question: question ? question.question : '',
    type: question ? question.type : '',
    groupId: question ? question.groupId : '',
    sentence: question ? question.sentence : '',
    correctOption: question ? question.correctOption : '',
    incorrectOption1: question ? question.incorrectOption1 : '',
    incorrectOption2: question ? question.incorrectOption2 : '',
    incorrectOption3: question ? question.incorrectOption3 : '',
    indications: '',
    newFile: false,
    capital: question ? question.capital : false,
    accentMark: question ? question.accentMark : false,
    number1: question ? question.number1 : '',
    number2: question ? question.number2 : '',
    number3: question ? question.number3 : '',
    number4: question ? question.number4 : '',
    response1: question ? question.response1 : '',
    response2: question ? question.response2 : '',
    response3: question ? question.response3 : '',
    response4: question ? question.response4 : '',
  };
};

export default useQuestionInitialState;
