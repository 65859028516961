import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import FormDialog from '../../shared/custom/dialog/FormDialog';
import { setFilterInstitutionsModal, setInstitutionsModalState } from '../slice/institutionsSlice';
import FilterInstitutionsForm from './FilterInstitutionsForm';
import InstitutionsForm from './InstitutionsForm';

const InstitutionsDialogsContainer = () => {
  const dispatch = useAppDispatch();
  const {
    modal, loadingCreate, loadingUpdate, modalFilter, loadingFilter,
  } = useAppSelector(( state ) => state.institution );
  return (
    <>
      <FormDialog
        open={modal}
        title="Añadir Institución"
        closeFunction={() => dispatch( setInstitutionsModalState( !!loadingCreate || !!loadingUpdate ))}
      >
        <InstitutionsForm />
      </FormDialog>
      <FormDialog
        open={modalFilter}
        title="Filtrar Institución"
        closeFunction={() => dispatch( setFilterInstitutionsModal( !!loadingFilter ))}
      >
        <FilterInstitutionsForm />
      </FormDialog>
    </>
  );
};

export default InstitutionsDialogsContainer;
