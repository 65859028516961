import React from 'react';
import { Button, Box, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updatePhoto } from '../../auth/actions/authActions';
import { size5MB } from '../helper/5mb';

const typesFile = ['png', 'jpeg', 'jpg'];

const PhotoForm = ( ) => {
  const { loadingUpdate, userInfo } = useAppSelector(( state ) => state.auth );
  const inputFileRef = React.useRef<HTMLInputElement | null>( null );
  const dispatch = useAppDispatch();
  const onFileChangeCapture = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    if ( e.target.files && e.target.files.length > 0 ) {
      const format = e.target.files[0].type.split( '/' )[1];
      if ( size5MB( e.target.files[0].size )) {
        if ( typesFile.includes( format ) && userInfo ) {
          dispatch( updatePhoto({ file: e.target.files[0], user: userInfo }));
        } else {
          toast.error( 'Solo se admiten formatos PNG, JPEG, JPG' );
        }
      } else {
        toast.error( 'El tamaño maximo de la foto debe ser de 5MB' );
      }
    }
  };
  const onBtnClick = () => {
    if ( inputFileRef.current ) {
      inputFileRef.current.click();
    }
  };
  return (
    <Box textAlign="center" pt={2}>
      <input
        type="file"
        id="file"
        ref={inputFileRef}
        onChangeCapture={onFileChangeCapture}
        style={{ display: 'none' }}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
      <Button
        variant="contained"
        sx={{ color: 'white' }}
        onClick={() => onBtnClick()}
        endIcon={
          ( loadingUpdate )
          && ( <CircularProgress size={20} color="secondary" /> )
        }
        disabled={loadingUpdate}
      >
        Cambiar Foto
      </Button>
    </Box>
  );
};

export default PhotoForm;
