import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IStatistic } from '../interfaces';

const staticConverter = {
  toFirestore: ( statistic: IStatistic ) => ({
    ...statistic,
  }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot, options: SnapshotOptions,
  ): IStatistic => {
    const data = snapshot.data( options );

    const {
      category,
      created,
      deleted,
    } = data;

    return {
      category,
      created,
      deleted,
    } as IStatistic;
  },
};

export default staticConverter;
