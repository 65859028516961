import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICodeGeneration } from '../../codes/interfaces';
import {
  activateCode, getAllStatistic, getBooksStatistic, getCodesStatistic, getLast5Codes, getLast7days, getUsersStatistic,
} from '../actions/dashboardAction';
import { IStatistic, IStatistics, IStatisticUser } from '../interfaces';

interface IBookState {
  modalActivateCode: boolean;
  loading: boolean;
  last5Codes: ICodeGeneration[];
  loadingLast5Codes: boolean;
  usersStatistics: IStatisticUser;
  codesStatistic: IStatistic;
  booksStatistic: IStatistic;
  loadingUsersStatistic: boolean;
  loadingCodesStatistic: boolean;
  loadingBooksStatistic: boolean;
  loadingGeneralStatistic: boolean;
  loading7Days: boolean;
  generalStatistics: IStatistics;
  last7Days: IStatistics[];
}

const initialState: IBookState = {
  modalActivateCode: false,
  loading: false,
  last5Codes: [],
  loadingLast5Codes: false,
  usersStatistics: {
    category: 'users',
    sellers: 0,
    students: 0,
  },
  codesStatistic: {
    category: 'codes',
    deleted: 0,
    created: 0,
  },
  booksStatistic: {
    category: 'books',
    deleted: 0,
    created: 0,
  },
  loadingUsersStatistic: false,
  loadingCodesStatistic: false,
  loadingBooksStatistic: false,
  loadingGeneralStatistic: false,
  generalStatistics: {
    books: {
      deleted: 0,
      created: 0,
    },
    codes: {
      created: 0,
      used: 0,
      canceled: 0,
    },
    date: 1648184400000,
    createdAt: 1648184400000,
    period: 'all',
    sellers: {
      deleted: 0,
      created: 0,
    },
    students: {
      deleted: 0,
      created: 0,
    },
    type: 0,
    updatedAt: 1648184400000,
  },
  last7Days: [],
  loading7Days: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setActivateCodeModal: ( state, action: PayloadAction<boolean> ) => {
      state.modalActivateCode = action.payload;
    },
    clearDashboard: ( state ) => {
      state.modalActivateCode = false;
    },
  },
  extraReducers: ( builder ) => {
    builder
      .addCase( activateCode.pending, ( state ) => {
        state.loading = true;
      })
      .addCase( activateCode.fulfilled, ( state ) => {
        state.loading = false;
      })
      .addCase( activateCode.rejected, ( state ) => {
        state.loading = false;
      });
    builder
      .addCase( getLast5Codes.pending, ( state ) => {
        state.loadingLast5Codes = true;
      })
      .addCase( getLast5Codes.fulfilled, ( state, action ) => {
        state.loadingLast5Codes = false;
        state.last5Codes = action.payload!;
      })
      .addCase( getLast5Codes.rejected, ( state ) => {
        state.loadingLast5Codes = false;
      });
    builder
      .addCase( getUsersStatistic.pending, ( state ) => {
        state.loadingUsersStatistic = true;
      })
      .addCase( getUsersStatistic.fulfilled, ( state, action ) => {
        state.loadingUsersStatistic = false;
        state.usersStatistics = action.payload!;
      })
      .addCase( getUsersStatistic.rejected, ( state ) => {
        state.loadingUsersStatistic = false;
      });
    builder
      .addCase( getCodesStatistic.pending, ( state ) => {
        state.loadingCodesStatistic = true;
      })
      .addCase( getCodesStatistic.fulfilled, ( state, action ) => {
        state.loadingCodesStatistic = false;
        state.codesStatistic = action.payload!;
      })
      .addCase( getCodesStatistic.rejected, ( state ) => {
        state.loadingCodesStatistic = false;
      });
    builder
      .addCase( getBooksStatistic.pending, ( state ) => {
        state.loadingBooksStatistic = true;
      })
      .addCase( getBooksStatistic.fulfilled, ( state, action ) => {
        state.loadingBooksStatistic = false;
        state.booksStatistic = action.payload!;
      })
      .addCase( getBooksStatistic.rejected, ( state ) => {
        state.loadingBooksStatistic = false;
      });
    builder
      .addCase( getAllStatistic.pending, ( state ) => {
        state.loadingGeneralStatistic = true;
      })
      .addCase( getAllStatistic.fulfilled, ( state, action ) => {
        state.loadingGeneralStatistic = false;
        state.generalStatistics = action.payload!;
      })
      .addCase( getAllStatistic.rejected, ( state ) => {
        state.loadingGeneralStatistic = false;
      });
    builder
      .addCase( getLast7days.pending, ( state ) => {
        state.loading7Days = true;
      })
      .addCase( getLast7days.fulfilled, ( state, action ) => {
        state.loading7Days = false;
        state.last7Days = action.payload!;
      })
      .addCase( getLast7days.rejected, ( state ) => {
        state.loading7Days = false;
      });
  },
});

export const {
  clearDashboard, setActivateCodeModal,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
