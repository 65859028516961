import {
  FormHelperText, Grid, InputLabel, TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { IQuestion } from '../../interfaces';

const Embed = () => {
  const {
    touched,
    errors,
    getFieldProps,
    setFieldValue,
  } = useFormikContext<
  Pick<IQuestion,
  'sentence'>
  >();
  const { question: questionInStore, group } = useAppSelector(( state ) => state.activity );
  useEffect(() => {
    if ( !questionInStore && group ) {
      setFieldValue( 'type', group.type );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionInStore]);
  return (
    <Grid item xs={12}>
      <InputLabel htmlFor="sentence">URL</InputLabel>
      <TextField
        size="small"
        id="sentence"
        fullWidth
        placeholder="Ingrese la URL"
        error={touched.sentence && !!errors.sentence}
        helperText={touched.sentence && errors.sentence}
        sx={{
          '& .MuiOutlinedInput-root > input': {
            backgroundColor: '#F2F2F2',
          },
        }}
        {...getFieldProps( 'sentence' )}
      />
      <FormHelperText>
        Ejem: https://www.youtube.com/watch?v=dQw4w9WgXcQ
      </FormHelperText>
    </Grid>
  );
};

export default Embed;
