import {
  collection, getDocs, orderBy, query, where, limit, addDoc, doc, updateDoc,
} from 'firebase/firestore';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { db } from '../../../../firebase';
import unitConverter from '../converters/unitConverter';
import { ITopic, IUnit } from '../interfaces';
import topicConverter from '../converters/topicConverter';
import {
  addTopicInStore, addUnitInStore, deleteTopicInStore, deleteUnitInStore, updateTopicInStore, updateUnitInStore,
} from '../slice/unitsAndTopicsSlice';

interface IUnitRegisterData {
  name: string;
  description: string;
  bookId: string;
  bookName: string;
}

interface ITopicRegisterData {
  name: string;
  url: string;
  bookId: string;
  bookName: string;
  unitId: string;
}

export const getUnits = createAsyncThunk(
  'unitsTopics/getUnits', async ( bookId: string ) => {
    try {
      const q = query( collection( db,
        'units' ),
      where( 'deleted', '==', false ),
      where( 'bookId', '==', bookId ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( unitConverter ),
      );
      const units: IUnit[] = [];
      querySnapshot.forEach( async ( item ) => {
        units.push({ ...item.data() });
      });
      return units;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getTopics = createAsyncThunk(
  'unitsTopics/getTopics', async ( bookId: string ) => {
    try {
      const q = query( collection( db,
        'topics' ),
      where( 'deleted', '==', false ),
      where( 'bookId', '==', bookId ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( topicConverter ),
      );
      const topics: ITopic[] = [];
      querySnapshot.forEach( async ( item ) => {
        topics.push({ ...item.data() });
      });
      return topics;
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const getTopicsByUnitId = createAsyncThunk(
  'unitsTopics/getTopicsByUnitId', async ( unitId: string ) => {
    try {
      toast.info( 'Solicitando las unidades' );
      const q = query( collection( db,
        'topics' ),
      where( 'deleted', '==', false ),
      where( 'unitId', '==', unitId ),
      orderBy( 'created', 'asc' ), limit( 100 ));
      const querySnapshot = await getDocs(
        q.withConverter( topicConverter ),
      );
      const topics: ITopic[] = [];
      querySnapshot.forEach( async ( item ) => {
        topics.push({ ...item.data() });
      });
      if ( topics.length > 0 ) {
        toast.success( 'Temas obtenidos correctamente' );
      } else {
        toast.success( 'No hay temas para esta unidad' );
      }
      return { unitId, topics };
    } catch ( error ) {
      console.log( error );
    }
  },
);

export const createUnit = createAsyncThunk(
  'unitsTopics/createUnit',
  async ( data: IUnitRegisterData, { dispatch }) => {
    try {
      const dataToSend = {
        name: data.name,
        description: data.description,
        created: new Date(),
        deleted: false,
        bookId: data.bookId,
        bookName: data.bookName,
      };
      const unitCreated = await addDoc(
        collection( db, 'units' ), dataToSend,
      );
      dispatch( addUnitInStore({
        id: unitCreated.id,
        ...dataToSend,
        created: new Date( dataToSend.created ).getTime(),
      }));
      toast.success( 'Unidad agregada correctamente' );
    } catch ( err ) {
      toast.error( 'A ocurrido un error.' );
    }
  },
);

export const createTopic = createAsyncThunk(
  'unitsTopics/createTopic',
  async ( data: ITopicRegisterData, { dispatch }) => {
    try {
      const dataToSend = {
        name: data.name,
        url: data.url,
        created: new Date(),
        deleted: false,
        bookId: data.bookId,
        unitId: data.unitId,
      };
      const topicCreated = await addDoc(
        collection( db, 'topics' ), dataToSend,
      );
      dispatch( addTopicInStore({
        id: topicCreated.id,
        ...dataToSend,
        created: new Date( dataToSend.created ).getTime(),
      }));
      toast.success( 'Tema agregado correctamente' );
    } catch ( err ) {
      toast.error( 'A ocurrido un error.' );
    }
  },
);

export const deleteUnit = createAsyncThunk(
  'unitsTopics/deleteUnit', async ( id: string, { dispatch }) => {
    try {
      const q = doc( db, 'units', id );

      await updateDoc( q, { deleted: true });

      dispatch( deleteUnitInStore( id ));

      toast.success( 'Unidad eliminado existosamente' );
    } catch ( error ) {
      toast.error( 'Error al intentar eliminar la unidad' );
    }
  },
);

export const deleteTopic = createAsyncThunk(
  'unitsTopics/deleteTopic', async ( id: string, { dispatch }) => {
    try {
      const q = doc( db, 'topics', id );

      await updateDoc( q, { deleted: true });

      dispatch( deleteTopicInStore( id ));

      toast.success( 'Tema eliminado existosamente' );
    } catch ( error ) {
      toast.error( 'Error al intentar eliminar el tema' );
    }
  },
);

export const updateUnit = createAsyncThunk(
  'unitsTopics/updateUnit',
  async ( unit: IUnit, { dispatch }) => {
    try {
      await updateDoc(
        doc( db, 'units', unit.id ), { name: unit.name, description: unit.description },
      );
      toast.success( 'Unidad actualizada correctamente' );
      dispatch( updateUnitInStore({
        ...unit,
      }));
    } catch ( err ) {
      toast.error( 'A ocurrido un error.' );
    }
  },
);

export const updateTopic = createAsyncThunk(
  'unitsTopics/updateTopic',
  async ( topic: ITopic, { dispatch }) => {
    try {
      await updateDoc(
        doc( db, 'topics', topic.id ), { name: topic.name, url: topic.url },
      );
      toast.success( 'Tema actualizado correctamente' );
      dispatch( updateTopicInStore({
        ...topic,
      }));
    } catch ( err ) {
      toast.error( 'A ocurrido un error.' );
    }
  },
);
